export const mutations = {
  addEvaluations(state, evaluations){

     state.evaluations = [...evaluations.content] 

  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addEvaluation(state, evaluation){
    state.evaluations = [ ...state.evaluations, evaluation ]
  },
  replaceEvaluation(state, evaluation){
    state.evaluations = state.evaluations.map(struc => struc.id === evaluation.id ? evaluation : struc )
  },
  setEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}
