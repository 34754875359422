import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPrivileges(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.privileges = [ ...content ]
  },
  addPrivilege(state, privilege){
    state.privileges = [ ...state.privileges, privilege ]
  },
  replacePrivilege(state, privilege){
    state.privileges = state.privileges.map(struc => struc.id === privilege.id ? privilege : struc )
  },
  setPrivilege(state, privilege){
    state.privilege = privilege
  },
}