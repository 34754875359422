import { actionsBuilder } from "../_store-builder/actions";
import { mutationsBuilder } from "../_store-builder/mutations";
import { gettersBuilder } from "../_store-builder/getters";
import { stateBuilder } from "../_store-builder/state";
import { actions } from "./actions";

const storeName = "enquetePosteProgramme";

export default {
  namespaced: true,
  state: stateBuilder(storeName),
  mutations: mutationsBuilder(storeName),
  getters: gettersBuilder(storeName),
  actions: {
    ...actionsBuilder(storeName),
    ...actions,
  },
};
