import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addStageFormations(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.stageFormations = [ ...content ]
  },
  addStageFormation(state, stageFormation){
    if(stageFormation instanceof Array){
      state.stageFormations = [ ...stageFormation, ...state.stageFormations]
    }
    else {
      state.stageFormations = [ stageFormation, ...state.stageFormations]
    }
  },
  replaceStageFormation(state, stageFormation){
    state.stageFormations = state.stageFormations.map(struc => struc.id === stageFormation.id ? stageFormation : struc )
  },
  removeStageFormation(state, stageFormation){
    state.stageFormations = state.stageFormations.filter(struc => struc.id !== stageFormation.id)
  },
  setStageFormation(state, stageFormation){
    state.stageFormation = stageFormation
  },
}