import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addLiquidations(state, data){
    state.pagination = extractPaginationInfo(data)
    state.liquidations = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addLiquidation(state, liquidation){
    state.liquidations = [ ...state.liquidations, liquidation ]
  },
  replaceLiquidation(state, liquidation){
    state.liquidations = state.liquidations.map(struc => struc.id === liquidation.id ? liquidation : struc )
  },
  removeLiquidation(state, liquidation){
    state.liquidations = state.liquidations.filter(cand => cand.id !== liquidation.id)
  },
  setLiquidation(state, liquidation){
    state.liquidation = liquidation
  },
  setLiquidationEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}