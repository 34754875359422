import Vue from "vue";
import Vuex from "vuex";
import statistic from './statistic'
import role from './role'
import rome from './rome'
import famille from './famille'
import region from './region'
import categorie from './categorie'
import antenne from './antenne'
import agent from './agent'
import demandeur from './demandeur'
import affectation from './affectation'
import departement from './departement'
import commune from './commune'
import competence from './competence'
import fonction from './fonction'
import account from './account'
import niveau from './niveau'
import portail from './portail'
import setting from './setting'
import auth from './auth'
import cv from './cv'
import ethnie from './ethnie'
import actualite from './actualite'
import langue from './langue'
import instruction from './instruction'
import emailVerifier from './email-verifier'
import transfert from './tranfert-dossier'
import transfertEntreprise from './tranfert-entreprise'
import domaine from './domaine'
import nationalite from './nationalite'
import interet from './interet'
import position from './type-position'
import piece from './type-piece'
import parente from './type-parente'
import formationProfessionnelle from './formation-professionnelle'
import qualificationProfessionnelle from './qualification-professionnelle'
import moyenQualification from './moyen-qualification'
import serviceRendu from './service-rendu'
import sourceInfo from './source-info'
import organisme from './organisme'
import organismeFonction from './organisme-fonction'
import typeOrganisme from './type-organisme'
import nature from './nature'
import salaire from './salaire'
import subvenirBesoin from './subvenirBesoin'
import emploirech from './rechercher-emploi'
import pasdetravail from './pas-de-travail'
import netravailpas from './ne-travail-pas'
import moyenrechercheremploi from './moyen-de-recherche-emploi'
import zonevulnerable from './zone-vulnerable'
import participation from './participation'
import privilege from './privilege'
import typeActivite from './type-activite'
import rapportActivite from './rapport-activite'
import activitePlanifie from './activite-planifie'
import participationProgramme from './participation-programme'
import participationPlacement from './participation-placement'
import participationActivite from './participation-activite'
import statutProfessionnel from './statut-professionnel'
import typePrescription from './type-prescription'
import typeConsigne from './type-consigne'
import typeAvantage from './type-avantage'
import priseEnCharge from './prise-en-charge'
import grille from './grille'
import programme from './programme'
import prescription from './prescription'
import consigne from './consigne'
import typeContenu from './type-contenu'
import stageFormation from './stage-formation'
import niveauSatisfaction from './niveau-satisfaction'
import suiviPlacement from './suivi-placement'
import natureContrat from './nature-contrat'
import tageActivite from './tage-activite'
import media from './media'
import notification from './notification'
import offre from './offre'
import placement from './placement'
import assiduite from './assiduite'
import formeJuridique from './forme-juridique'
import chiffreAffaire from './chiffre-affaire'
import cycleProduction from './cycle-production'
import demandeurSelector from './demandeur-selector'
import rechercherProfil from './rechercher-profil'
import entrepriseSelector from './entreprise-selector'
import candidatureSelector from './candidature-selector'
import candidature from './candidature'
import naema from './naema'
import besoin from "./besoin";
import contrat from './contrat'
import parametre from "./parametre";
import evaluation from "./evaluation";
import microprojet from "./microprojet";
import npi from "./npi";

import audit from "./audit";
import auditeur from "./auditeur";
import auteurAudit from "./auteur-audit";
import constat from "./constat";
import atout from "./atout";
import critere from "./critere";
import domaineanalyse from "./domaine-analyse";
import actionrequise from "./action-requise";
import rapport from "./rapport";
import planaction from './plan-action'
import equipement from './equipement'
import evaluationConstat from "./evaluation-constat";
import offreExterne from "./offre-externe"

import entreprise from './entreprise'
import typePlacement from './type-placement'
import typeContrat from './type-contrat'
import presencePoste from './presence-poste'
import priseService from './prise-service'
import arretContrat from './arret-contrat'
import pointPresence from './point-presence'
import avantageFinancier from './avantage-financier'
// import uploader from './uploader/actions'
import missionnaire from './missionnaire'
import mission from './mission'
import liquidation from './liquidation'
import ordreMission from './ordre-mission'
import ligneBudgetaire from './ligne-budgetaire'
import payementLiquidation from './payement-liquidation'

import tentativeCandidature from './tentative-candiature'

import screening from './screening'
import screeningsecteur from './screening-secteur'
import screeningreponse from './screening-reponse'
import screeningquestionnaire from './screening-questionnaire'


import contratObjectif from './contrat-objectif'
import objectifAgent from './objectif-agent'
import objectifAntenne from './objectif-antenne'
import objectifParAntenne from './objectif-par-antenne'
import objectifParAgent from './objectif-par-agent'
import typeIndicateur from './type-indicateur'
import planFormation from './plan-formation'
import evaluationProfessionnelle from './evaluation-professionnelle'
import appreciationStagiaire from './appreciation-stagiaire'
import appreciationStage from './appreciation-stage'
import evaluationComportementale from './evaluation-comportementale'
import evaluationCompetence from './evaluation-competence'
import perspectiveOption from './perspective-option'
import typePerspective from './type-perspective'
import niveauCompetence from './niveau-competence'
import planCompetence from './plan-competence'
import photoConstat from './photo-constat'
import exporter from './exporter'

import maitriseLangue from './maitrise-langue'
import azoliPiece from './azoli-piece'
import azoliEntrepreneur from './azoli-entrepreneur'
import azoliSalarie from './azoli-salarie'
import apiClient from './api-client'

import prerequisMicroprojet from './prerequis-microprojet'
import besoinFormation from './besoin-formation'
import appreciationAptitude from './appreciation-aptitude'
import aptitudeEntrepreneuriale from './aptitude-entrepreneuriale'
import evaluationEntrepreneuriale from './evaluation-entrepreneuriale'
import reponseEntrepreneuriale from './reponse-entrepreneuriale'
import profilEntrepreneurial from './profil-entrepreneurial'
import force from './ffom'
import faiblesse from './ffom'
import opportunite from './ffom'
import menace from './ffom'
import credit from './credit'

import { buildStores } from "./_store-builder";

// import ficheApresStage from './fiche-apres-stage'
import enquetePosteProgramme from './enquete-poste-programme'

Vue.use(Vuex)

const storeNames = [
    'parcoursDemandeur',
    'artisanatSecteur',
    'artisanatBranche',
    'artisanatGroupe',
    'artisanatMetier',
    'etapeMicroprojet',
    'statutAcceptation',
    'raisonRetrait',
    'qualification',
    'emploi',
    'finOcLangue',
    'typeHandicap',
    // 'enquetePosteProgramme',
    'fichePosteProgramme',
];

export default new Vuex.Store({
  strict: true,
  modules: {
    // parcoursDemandeur: buildStore('parcoursDemandeur'),
    // artisanatSecteur: buildStore('artisanatSecteur'),
    // artisanatBranche: buildStore('artisanatBranche'),
    // artisanatGroupe: buildStore('artisanatGroupe'),
    // artisanatMetier: buildStore('artisanatMetier'),
    // etapeMicroprojet: buildStore('etapeMicroprojet'),
    // statutAcceptation: buildStore('statutAcceptation'),
    // raisonRetrait: buildStore('raisonRetrait'),
    // qualification: buildStore('qualification'),
    // emploi: buildStore('emploi'),
    // finOcLangue: buildStore('finOcLangue'),
    // typeHandicap: buildStore('typeHandicap'),
    ...buildStores(storeNames),
    enquetePosteProgramme,
    force,
    faiblesse,
    opportunite,
    menace,
    credit,
    besoinFormation,
    appreciationAptitude,
    prerequisMicroprojet,
    profilEntrepreneurial,
    aptitudeEntrepreneuriale,
    evaluationEntrepreneuriale,
    reponseEntrepreneuriale,
    
    apiClient,
    azoliPiece,
    azoliSalarie,
    maitriseLangue,
    azoliEntrepreneur,
    photoConstat,
    contratObjectif,
    objectifAgent,
    objectifParAgent,
    objectifParAntenne,
    objectifAntenne,
    typeIndicateur,
    exporter,
    screening,
    screeningsecteur,
    screeningreponse,
    screeningquestionnaire,
    affectation,
    liquidation,
    domaineanalyse,
    actionrequise,
    actionRequise: actionrequise,
    offreExterne,
    arretContrat,
    pointPresence,
    priseService,
    planFormation,
    planCompetence,
    presencePoste,
    critere,
    atout,
    rapport,
    equipement,
    planaction,
    payementLiquidation,
    typePerspective,
    perspectiveOption,
    evaluationConstat,
    tentativeCandidature,
    evaluationComportementale,
    evaluationProfessionnelle,
    appreciationStagiaire,
    appreciationStage,
    evaluationCompetence,
    niveauCompetence,
    constat,
    audit,
    auditeur,
    auteurAudit,
    agent,
    npi,
    naema,
    ethnie,
    parametre,
    actualite,
    langue,
    nature,
    demandeur,
    instruction,
    participation,
    microprojet,
    microProjet: microprojet,
    ligneBudgetaire,
    missionnaire,
    mission,
    ordreMission,
    grille,
    niveauSatisfaction,
    transfertEntreprise,
    transfertDossier: transfert,
    stageFormation,
    natureContrat,
    typeContrat,
    suiviPlacement,
    typePlacement,
    typeAvantage,
    placement,
    assiduite,
    categorie,
    nationalite,
    interet,
    media,
    region,
    famille,
    competence,
    parente,
    antenne,
    niveau,
    transfert,
    serviceRendu,
    sourceInfo,
    moyenQualification,
    statutProfessionnel,
    participationProgramme,
    participationPlacement,
    participationActivite,
    typePrescription,
    typeConsigne,
    typeOrganisme,
    organisme,
    rapportActivite,
    organismeFonction,
    formationProfessionnelle,
    qualificationProfessionnelle,
    priseEnCharge,
    avantageFinancier,
    position,
    piece,
    domaine,
    account,
    rome,
    programme,
    cv,
    auth,
    portail,
    commune,
    departement,
    prescription,
    consigne,
    emailVerifier,
    fonction,
    role,
    demandeurSelector,
    entrepriseSelector,
    rechercherProfil,
    statistic,
    setting,
    salaire,
    subvenirBesoin,
    emploirech,
    pasdetravail,
    netravailpas,
    moyenrechercheremploi,
    zonevulnerable,
    privilege,
    typeActivite,
    activitePlanifie,
    entreprise,
    typeContenu,
    tageActivite,
    notification,
    offre,
    formeJuridique,
    chiffreAffaire,
    cycleProduction,
    candidature,
    candidatureSelector,
    evaluation,
    besoin,
    contrat
  },
});
    