import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addPointPresences(state, data){
    state.pagination = extractPaginationInfo(data)
    state.pointPresences = [...data.content] 
  },
  replacePointPresence(state, pointPresence){
    state.pointPresences = state.pointPresences.map(struc => struc.id === pointPresence.id ? pointPresence : struc )
  },
  removePointPresence(state, pointPresence){
    state.pointPresences = state.pointPresences.filter(cand => cand.id !== pointPresence.id)
  },
  setPointPresence(state, pointPresence){
    state.pointPresence = pointPresence
  },
  setPointPresenceEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}