
export const espaceDesopRoutes = [
  {
    path: "/espce/desop",
    // name: "espace.ca",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "accueil",
        name: "espace.desop.home",
        redirect: { name: "espace.common.azolis.home", params: { userRole: 'desop' } },
      },
    
      {
        path: "demandeurs",
        name: "espace.desop.de.list",
        component: () => import("../views/espace/antenne/PorteFeuille.vue"),
      },      
    ],
  },
];
