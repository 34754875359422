// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchTypeAvantages({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typeAvantages?size=${pageSize}&page=${pageNumber}&sort=libelle,asc`)
    .then(({ data }) =>{
      commit('addTypeAvantages', data)
    })
  },
  createTypeAvantage({ commit }, typeAvantage){
    return post('/typeAvantages', typeAvantage)
    .then(({ data }) => {
      commit('addTypeAvantage', data)
    })
  },
  updateTypeAvantage({ commit }, typeAvantage){
    return put(`/typeAvantages/${typeAvantage.id}`, typeAvantage)
    .then(({ data }) =>{
      commit('replaceTypeAvantage', data)
    })
  },
  deleteTypeAvantage({ commit }, typeAvantage){
    return remove(`/typeAvantages/${typeAvantage.id}`, typeAvantage)
    .then(() =>{
      commit('removeTypeAvantage', typeAvantage)
    })
  },
  createOrUpdateTypeAvantage({ dispatch }, typeAvantage){
    if(typeAvantage.id !== undefined){
      return dispatch('updateTypeAvantage', typeAvantage)
    }
    else {
      return dispatch('createTypeAvantage', typeAvantage)
    }
  },
  selectTypeAvantage({ commit }, typeAvantage){
    commit('setTypeAvantage', typeAvantage)
  }
}