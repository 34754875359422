// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchFormationProfessionnelles({ commit }){
    return get('/formationProfessionnelles')
    .then(({ data }) =>{
      commit('addFormationProfessionnelles', data)
    })
  },
  createFormationProfessionnelle({ commit }, formationProfessionnelle){
    return post('/formationProfessionnelles', formationProfessionnelle)
    .then(({ data }) =>{
      commit('addFormationProfessionnelle', data)
    })
  },
  updateFormationProfessionnelle({ commit }, formationProfessionnelle){
    return put(`/formationProfessionnelles/${formationProfessionnelle.id}`, formationProfessionnelle)
    .then(({ data }) =>{
      commit('replaceFormationProfessionnelle', data)
    })
  },
  createOrUpdateFormationProfessionnelle({ dispatch }, formationProfessionnelle){
    if(formationProfessionnelle.id !== undefined){
      return dispatch('updateFormationProfessionnelle', formationProfessionnelle)
    }
    else {
      return dispatch('createFormationProfessionnelle', formationProfessionnelle)
    }
  },
  selectFormationProfessionnelle({ commit }, formationProfessionnelle){
    commit('setFormationProfessionnelle', formationProfessionnelle)
  }
}