export const mutations = {
  addDepartements(state, departements){
    state.departements = [ ...departements ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addArrondissements(state, arrondissements){
    state.arrondissements = [ ...arrondissements ]
  },
  addVilles(state, villes){
    state.villes = [ ...villes ]
  },
  addNations(state, nations){
    state.nations = [ ...nations ]
  },
  addDepartement(state, departement){
    state.departements = [ ...state.departements, departement ]
  },
  replaceDepartement(state, departement){
    state.departements = state.departements.map(struc => struc.id === departement.id ? departement : struc )
  },
  setDepartement(state, departement){
    state.departement = departement
  },
}