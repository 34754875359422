import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addEquipements(state, data){
    console.log(data)
    state.equipements = [ ...data.content ]
  },

  addEquipement(state, equipement){
    state.equipements = [ ...state.equipements, equipement ]
  },
  replaceEquipement(state, equipement){
    state.equipements = state.equipements.map(struc => struc.id === equipement.id ? equipement : struc )
  },
  setEquipement(state, equipement){
    state.equipement = equipement
  },
}