import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchParticipationActivites({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/participationActivites?${queryParams}`)
    .then(({ data }) =>{
      commit('addParticipationActivites', data)
      return data
    })
  },
  createParticipationActivite({ commit }, participationActivite){
    return post('/participationActivites', participationActivite)
    .then(({ data }) => {
      commit('addParticipationActivite', data)
      return data
    })
  },
  updateParticipationActivite({ commit }, participationActivite){
    return put(`/participationActivites/${participationActivite.id}`, participationActivite)
    .then(({ data }) =>{
      commit('replaceParticipationActivite', data)
      return data
    })
  },
  deleteParticipationActivite({ commit }, participationActivite){
    return remove(`/participationActivites/${participationActivite.id}`, participationActivite)
    .then(() =>{
      commit('removeParticipationActivite', participationActivite)
      return data
    })
  },
  createOrUpdateParticipationActivite({ dispatch }, participationActivite){
    if(participationActivite.id !== undefined){
      return dispatch('updateParticipationActivite', participationActivite)
    }
    else {
      return dispatch('createParticipationActivite', participationActivite)
    }
  },
  selectParticipationActivite({ commit }, participationActivite){
    commit('setParticipationActivite', participationActivite)
    return data
  }
}