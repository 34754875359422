export const mutations = {
  addQualificationProfessionnelles(state, qualificationProfessionnelles){
    state.qualificationProfessionnelles = [ ...qualificationProfessionnelles ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addQualificationProfessionnelle(state, qualificationProfessionnelle){
    state.qualificationProfessionnelles = [ ...state.qualificationProfessionnelles, qualificationProfessionnelle ]
  },
  replaceQualificationProfessionnelle(state, qualificationProfessionnelle){
    state.qualificationProfessionnelles = state.qualificationProfessionnelles.map(struc => struc.id === qualificationProfessionnelle.id ? qualificationProfessionnelle : struc )
  },
  setQualificationProfessionnelle(state, qualificationProfessionnelle){
    state.qualificationProfessionnelle = qualificationProfessionnelle
  },
}