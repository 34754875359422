export const getters = {
  niveauCompetences(state){
    return state.niveauCompetences
  },
  niveauCompetence(state){
    return state.niveauCompetence
  },
  pagination(state){
    return state.pagination
  }
}