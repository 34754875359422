import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPlanCompetences(state, data){
    state.pagination = extractPaginationInfo(data)
    state.planCompetences = [...data.content] 
  },
  addPlanCompetence(state, planCompetence){
    state.planCompetences = [ ...state.planCompetences, planCompetence ]
  },
  replacePlanCompetence(state, planCompetence){
    state.planCompetences = state.planCompetences.map(struc => struc.id === planCompetence.id ? planCompetence : struc )
  },
  removePlanCompetence(state, planCompetence){
    state.planCompetences = state.planCompetences.filter(cand => cand.id !== planCompetence.id)
  },
  setPlanCompetence(state, planCompetence){
    state.planCompetence = planCompetence
  },
  setPlanCompetenceEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}