import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addNiveauCompetences(state, data){
    state.pagination = extractPaginationInfo(data)
    state.niveauCompetences = [...data.content] 
  },
  addNiveauCompetence(state, niveauCompetence){
    state.niveauCompetences = [ ...state.niveauCompetences, niveauCompetence ]
  },
  replaceNiveauCompetence(state, niveauCompetence){
    state.niveauCompetences = state.niveauCompetences.map(struc => struc.id === niveauCompetence.id ? niveauCompetence : struc )
  },
  removeNiveauCompetence(state, niveauCompetence){
    state.niveauCompetences = state.niveauCompetences.filter(cand => cand.id !== niveauCompetence.id)
  },
  setNiveauCompetence(state, niveauCompetence){
    state.niveauCompetence = niveauCompetence
  },
  setNiveauCompetenceEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}