export const mutations = {
  addInterets(state, interets){
    state.interets = [ ...interets ]
  },
  addInteret(state, interet){
    state.interets = [ ...state.interets, interet ]
  },
  replaceInteret(state, interet){
    state.interets = state.interets.map(struc => struc.id === interet.id ? interet : struc )
  },
  setInteret(state, interet){
    state.interet = interet
  },
}