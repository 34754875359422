export const getters = {
  sourceInfos(state){
    return state.sourceInfos
  },
  communes(state){
    return state.communes
  },
  sourceInfo(state){
    return state.sourceInfo
  }
}