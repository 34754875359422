// import axios from 'axios'
import { post, put, get, downloadFile } from '@/api/api'
import ShortUniqueId from 'short-unique-id';
import { buildQueryParams } from '../../utils/pagination-util'
const uid = new ShortUniqueId({ length: 10 });


export const actions = {
  fetchEntrepriseSelectors({ commit, state, rootState }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    // console.log(rootState)
    return get(`/entreprises?${queryParams}`)
    .then(({ data }) => {
      commit('addEntrepriseSelectors', data)
    }).catch(err => console.error(err))

  },
}