export const getters = {
  rapports(state){
    return state.rapports
  },

  rapport(state){
    return state.rapport
  },
  pagination(state){
    return state.pagination
  }
}