import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPayementLiquidations(state, data){
    state.pagination = extractPaginationInfo(data)
    state.payementLiquidations = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addPayementLiquidation(state, payementLiquidation){
    state.payementLiquidations = [ ...state.payementLiquidations, payementLiquidation ]
  },
  replacePayementLiquidation(state, payementLiquidation){
    state.payementLiquidations = state.payementLiquidations.map(struc => struc.id === payementLiquidation.id ? payementLiquidation : struc )
  },
  removePayementLiquidation(state, payementLiquidation){
    state.payementLiquidations = state.payementLiquidations.filter(cand => cand.id !== payementLiquidation.id)
  },
  setPayementLiquidation(state, payementLiquidation){
    state.payementLiquidation = payementLiquidation
  },
  setPayementLiquidationEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}