export const mutations = {
  addAzoliEntrepreneurs(state, azoliEntrepreneurs){
    state.azoliEntrepreneurs = [ ...azoliEntrepreneurs ]
  },
  addAzoliEntrepreneur(state, azoliEntrepreneur){
    state.azoliEntrepreneurs = [ ...state.azoliEntrepreneurs, azoliEntrepreneur ]
  },
  replaceAzoliEntrepreneur(state, azoliEntrepreneur){
    state.azoliEntrepreneurs = state.azoliEntrepreneurs.map(struc => struc.id === azoliEntrepreneur.id ? azoliEntrepreneur : struc )
  },
  setAzoliEntrepreneur(state, azoliEntrepreneur){
    state.azoliEntrepreneur = azoliEntrepreneur
  },
}