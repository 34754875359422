export const getters = {
  roles(state){
    return state.roles
  },
  role(state){
    return state.role
  },
  pagination(state){
    return state.pagination
  }
}