
export const espaceUlpeRoutes = [
  {
    path: "/espace/ulpe",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "ulpe-home",
        name: "espace.ulpe.home",
        redirect: { name: "espace.common.azolis.list", params: { userRole: 'ulpe' } }
      }
    ],
  },
];
