// import axios from 'axios'
import { post, put, api, get} from '@/api/api'
// import ShortUniqueId from 'short-unique-id';
// const uid = new ShortUniqueId({ length: 10 });


export const actions = {
  fetchActualites({ commit, state }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    // queryParams = queryParams !== undefined? queryParams : 
    return get(`/nouvelles/admin?${queryParams}&size=${pageSize}&page=${pageNumber}&sorted=true&`)
    .then(({ data }) =>{
      console.log("action ---------------",data)
      commit('addActualites', data)
    })
  },
  fetchPetiteAnnonces({ commit, state }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    // queryParams = queryParams !== undefined? queryParams : 
    return get(`/nouvelles/publicites?${queryParams}&size=${pageSize}&page=${pageNumber}&sorted=true&`)
    .then(({ data }) =>{
      console.log("action ---------------",data)
      commit('addActualites', data)
    })
  },
  createActualite({ commit }, actualite){
    return post('/nouvelles', actualite)
    .then(({ data }) =>{
      commit('addActualite', data)
    })
  },
  deleteActualite({ commit }, actualite){
    return api.delete(`/nouvelles/${actualite.id}`, actualite)
    .then(({ data }) =>{
      commit('removeActualite', data)
    })
  },
  uneActualite({ commit }, idActualite){
    return api.get(`/nouvelles/${idActualite}`)
    .then(({ data }) =>{
      console.log(data)
      commit('addActualite', data)
      return data
    })
  },
  registerActualite({ commit }, actualite){
    return post('/nouvelles', actualite)
    .then(({ data }) =>{
      commit('addActualite', data)
    })
  },
  // registerActualite({ commit }, actualite){
  //   // /oauth/register/actualite
  //   return post('/nouvelles', {
  //     ...actualite,
  //     password: uid(),
  //     courriel: actualite.courriel
  //   })
  //   .then(({ data }) =>{
  //     commit('addActualite', data)
  //   })
  // },
  updateActualite({ commit }, actualite){
    return put(`/nouvelles/${actualite.id}`, actualite)
    .then(({ data }) =>{
      commit('replaceActualite', data)
    })
  },
  // createOrUpdateActualite({ dispatch }, actualite){
  //   actualite = {
  //     ...actualite,
  //     courriel: actualite.courriel
  //   }
  //   return dispatch('registerActualite', actualite)
  // },

  createOrUpdateActualite({ dispatch }, actualite){
    console.log("je")
    if(actualite.id !== undefined){
      return dispatch('updateActualite', actualite)
    }
    else {
      return dispatch('registerActualite', actualite)
    }
  },
  selectActualite({ commit }, actualite){
    commit('setActualite', actualite)
  }
}