export const getters = {
  typeOrganismes(state){
    return state.typeOrganismes
  },
  typeOrganisme(state){
    return state.typeOrganisme
  },
  pagination(state){
    return state.pagination
  }
}