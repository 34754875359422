import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAntennes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.antennes = [ ...content ]
  },
  addAntenne(state, antenne){
    state.antennes = [ ...state.antennes, antenne ]
  },
  replaceAntenne(state, antenne){
    state.antennes = state.antennes.map(el => el.id === antenne.id ? antenne : el )
  },
  removeAntenne(state, antenne){
    state.antennes = state.antennes.filter(el => el.id !== antenne.id )
  },
  setAntenne(state, antenne){
    state.antenne = antenne
  },
}