
export const espaceDemandeurRoutes = [
  {
    path: "/esapce/demandeur",
    name: "espace.de",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "/",
        name: "espace.de.home",
        //redirect: { name: 'espace.de.moncv' }
        // redirect: { name: "espace.de.cv.edit" },
        component: () => import("../views/espace/demandeur/DemandeurHome.vue"),
      },
      // {
      //   path: "/",
      //   name: "espace.de.cv.edit",
      //   // component: () => import("../views/espace/demandeur/DemandeurHome.vue"),
      // },
      {
        path: "/demandeur/profile",
        name: "espace.de.profile",
        component: () =>
          import("../views/espace/demandeur/DemandeurProfil.vue"),
      },
      {
        path: "/demandeur/mon-cv",
        name: "espace.de.moncv",
        component: () => import("../views/espace/demandeur/DemandeurMonCv.vue"),
      },
      {
        path: "/demandeur/cv/edit",
        name: "espace.de.cv.edit",
        component: () =>
          import("../views/espace/demandeur/DemandeurCvEdit.vue"),
      },
      {
        path: "/demandeur/mes-recherches-d-emplois",
        name: "espace.de.recherche-emplois",
        component: () => import("../views/portail/PortailOffreList.vue"),
      },
      {
        path: "/demandeur/mes-candidatures",
        redirect: {
          name: "espace.common.candidature.list",
          params: { userRole: "de" },
        },
        name: "espace.de.candidatures",
        // component: () => import("../views/espace/demandeur/DemandeurCandidatureList.vue"),
      },
      {
        path: "/demandeur/postuler/:offreSlug",
        name: "espace.de.postuler.offre",
        component: () => import("../views/portail/PostulerOffre.vue"),
        props: true,
      },
      {
        path: "/demandeur/signature",
        name: "espace.de.signature",
        component: () =>
          import("../views/espace/demandeur/DemandeurSignature.vue"),
        props: true,
        children: [
          {
            path: "/signature-numerique",
            name: "espace.common.signature.numerique",
            component: () =>
              import("../components/signature/BlockSignature.vue"),
          },
          {
            path: "/signature-import",
            name: "espace.common.signature.import",
            component: () =>
              import("../components/signature/BlockImportSignature.vue"),
          },
        ],
      },
    ],
  },
];
