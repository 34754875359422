export const getters = {
  demandeurSelectors(state){
    return state.demandeurSelectors
  },
  demandeurSelector(state){
    return state.demandeurSelector
  },
  priseEnCharge(state){
    return state.priseEnCharge
  },
  pagination(state){
    return state.pagination
  }
}