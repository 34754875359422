// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchAssiduites({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/assiduites?size=${pageSize}&page=${pageNumber}`)
    .then(({ data }) =>{
      commit('addAssiduites', data)
    })
  },
  createAssiduite({ commit }, assiduite){
    return post('/assiduites', assiduite)
    .then(({ data }) => {
      commit('addAssiduite', data)
    })
  },
  updateAssiduite({ commit }, assiduite){
    return put(`/assiduites/${assiduite.id}`, assiduite)
    .then(({ data }) =>{
      commit('replaceAssiduite', data)
    })
  },
  deleteAssiduite({ commit }, assiduite){
    return remove(`/assiduites/${assiduite.id}`, assiduite)
    .then(() =>{
      commit('removeAssiduite', assiduite)
    })
  },
  createOrUpdateAssiduite({ dispatch }, assiduite){
    if(assiduite.id !== undefined){
      return dispatch('updateAssiduite', assiduite)
    }
    else {
      return dispatch('createAssiduite', assiduite)
    }
  },
  selectAssiduite({ commit }, assiduite){
    commit('setAssiduite', assiduite)
  }
}