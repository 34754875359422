// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchFormeJuridiques({ commit }){
    return get('/formeJuridiques')
    .then(({ data }) =>{
      commit('addFormeJuridiques', data)
    })
  },
  createFormeJuridique({ commit }, formejuridique){
    return post('/formeJuridiques', formejuridique)
    .then(({ data }) =>{
      commit('addFormeJuridique', data)
    })
  },
  updateFormeJuridique({ commit }, formejuridique){
    return put(`/formeJuridiques/${formejuridique.id}`, formejuridique)
    .then(({ data }) =>{
      commit('replaceFormeJuridique', data)
    })
  },
  createOrUpdateFormeJuridique({ dispatch }, formejuridique){
    if(formejuridique.id !== undefined){
      return dispatch('updateFormeJuridique', formejuridique)
    }
    else {
      return dispatch('createFormeJuridique', formejuridique)
    }
  },
  selectFormeJuridique({ commit }, formejuridique){
    commit('setFormeJuridique', formejuridique)
  }
}