export const state = {
  instruction: undefined,
  instructions: [],
  communes: [],
  sousInstruction: undefined,
  sousInstructions: [],
  niveauAlphabetions: [
    { value: 'PAS_LIRE_PAS_ECRIRE', label: 'Je ne sais pas lire et écrire' },
    { value: 'LIRE_PAS_ECRIRE', label: 'Je peux lire mais pas écrire' },
    { value: 'LIRE_ET_ECRIRE', label: 'Je sais lire et écrire' },
  ],
  niveauLangues: [
    { value: 'PASSABLE', label: 'Bas Niveau' },
    { value: 'ABIEN', label: 'Assez Bien' },
    { value: 'BIEN', label: 'Bon Niveau' },
    { value: 'TBIEN', label: 'Très Bien' },
  ]
}