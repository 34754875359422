export const getters = {
  instructions(state){
    return state.instructions
  },
  communes(state){
    return state.communes
  },
  instruction(state){
    return state.instruction
  },
  niveauAlphabetions(state){
    return state.niveauAlphabetions
  },
  niveauAlphabetionsMap(state){
    let map = {}
    state.niveauAlphabetions.forEach(el => {
      map[el.value] = el.label
    })
    return map
  },
  niveauLanguesMap(state){
    let map = {}
    state.niveauLangues.forEach(el => {
      map[el.value] = el.label
    })
    return map
  },
  niveauLangues(state){
    return state.niveauLangues
  }
}