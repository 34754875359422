import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addSuiviPlacements(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.suiviPlacements = [ ...content ]
  },
  addSuiviPlacement(state, suiviPlacement){
    state.suiviPlacements = [ suiviPlacement, ...state.suiviPlacements]
  },
  replaceSuiviPlacement(state, suiviPlacement){
    state.suiviPlacements = state.suiviPlacements.map(struc => struc.id === suiviPlacement.id ? suiviPlacement : struc )
  },
  removeSuiviPlacement(state, suiviPlacement){
    state.suiviPlacements = state.suiviPlacements.filter(struc => struc.id !== suiviPlacement.id)
  },
  setSuiviPlacement(state, suiviPlacement){
    state.suiviPlacement = suiviPlacement
  },
}