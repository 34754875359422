
export const espaceCommonRoutes = [
  {
    path: "/espace/",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: ":userRole/azolis-list",
        name: "espace.common.azolis.list",
        component: () =>
          import("../views/espace/common/AzoliBeneficiaireList.vue"),
      },
      {
        path: ":userRole/azolis-doublons-list",
        name: "espace.common.azolis-doublons.list",
        component: () =>
          import("../views/espace/common/AzoliBeneficiaireDoublonList.vue"),
      },
      {
        path: ":userRole/espace-home",
        name: "espace.common.user.home",
        // redirect: { path:  }
        component: () => import("../views/espace/common/AzoliHome.vue"),
      },
      {
        path: ":userRole/azolis-home",
        name: "espace.common.azolis.home",
        component: () => import("../views/espace/common/AzoliHome.vue"),
      },
      {
        path: ":userRole/azolis-graphes",
        name: "espace.common.azolis.graphs",
        component: () =>
          import("../views/espace/common/AzoliGraphicsBoard.vue"),
      },
      {
        path: ":userRole/activites-agenda",
        name: "espace.common.activities.agenda",
        component: () => import("../views/espace/common/SuiviDemandeur.vue"),
      },
      {
        path: ":userRole/activites-list",
        name: "espace.common.activites.list",
        component: () => import("../views/espace/common/ActiviteAnpeList.vue"),
      },
      {
        path: ":userRole/participation-programme-list",
        name: "espace.common.participation-programme.list",
        component: () =>
          import("../views/espace/common/ParticipationProgrammeList.vue"),
      },
      {
        path: ":userRole/participation-placement-list",
        name: "espace.common.participation-placement.list",
        component: () =>
          import("../views/espace/common/ParticipationPlacementList.vue"),
      },
      {
        path: ":userRole/microprojets-list",
        name: "espace.common.microprojets.list",
        component: () =>
          import("../views/espace/common/MicroprojetList.vue"),
      },
      {
        path: ":userRole/agents-list",
        name: "espace.common.agent.list",
        component: () => import("../views/espace/common/AffectationDemandeur.vue"),
      },
      {
        path: ":userRole/inscriptions-incompletes-list",
        name: "espace.common.inscription.incomplete.list",
        component: () =>
          import("../views/espace/common/InscriptionIncompleteList.vue"),
      },
      {
        path: ":userRole/entreprises-affectation",
        name: "espace.common.entreprise.affectation",
        component: () =>
          import("../views/espace/common/AffectationEntreprise.vue"),
      },
      {
        path: ":userRole/prise-en-en-charge-list",
        name: "espace.common.followup.list",
        component: () => import("../views/espace/common/PriseEnChargeList.vue"),
      },
      {
        path: ":userRole/prise-en-charge/:demandeurId",
        name: "espace.common.de.followup",
        component: () => import("../views/espace/common/PriseEnCharge.vue"),
        props: true,
      },

      // {
      //   path: "participants",
      //   name: "espace.common.pa.list",
      //   component: () => import("../views/espace/antenne/ParticipantList.vue"),
      // },
      // {
      //   path: 'ce',
      //   name: "espace.common.co.list",
      //   component: () => import("../views/espace/antenne/ConseillerList.vue"),
      // },
      {
        path: ":userRole/de-prescription-list",
        name: "espace.common.de-prescription.list",
        component: () => import("../views/espace/common/PrescriptionList.vue"),
      },
      {
        path: ":userRole/de-consigne-list",
        name: "espace.common.de-consigne.list",
        component: () => import("../views/espace/common/ConsigneList.vue"),
      },
      {
        path: ":userRole/de-placement-list",
        name: "espace.common.de-placement.list",
        component: () => import("../views/espace/common/PlacementList.vue"),
      },
      {
        path: ":userRole/tentative-candidature-list",
        name: "espace.common.de-tentative-candidature.list",
        component: () => import("../views/espace/common/TentativeCandidatureList.vue"),
      },
      {
        path: ":userRole/payement-indemnitelist",
        name: "espace.common.payement-indemnite.list",
        component: () =>
          import("../views/espace/common/payement/PaymentIndemniteMangement.vue"),
      },
      {
        path: ":userRole/de-stage-formation-list",
        name: "espace.common.de-stage-formation.list",
        component: () =>
          import("../views/espace/common/StageFormationList.vue"),
      },
      {
        path: ":userRole/de-suivi-stage-formation-list",
        name: "espace.common.de-suivi-stage-formation.list",
        component: () =>
          import("../views/espace/common/SuiviStageFormationList.vue"),
      },
      {
        path: ":userRole/de-suivi-prorgamme-list",
        name: "espace.common.de-suivi-programme.list",
        component: () =>
          import("../views/espace/common/SuiviProgrammeList.vue"),
      },
      {
        path: ":userRole/entreprise-list",
        name: "espace.common.entreprise.list",
        component: () => import("../views/espace/common/EntrepriseList.vue"),
      },
      {
        path: ":userRole/candidature-list",
        name: "espace.common.candidature.list",
        component: () =>
          import("../views/espace/entreprise/CandidatureList.vue"),
      },
      {
        path: ":userRole/audit-list",
        name: "espace.common.audit.list",
        component: () =>
          import("../views/espace/entreprise/AuditList.vue"),
      },
      {
        path: ":userRole/rechercher-profil-demandeur",
        name: "espace.common.recherche.demandeur.profil",
        component: () =>
          import("../views/espace/common/RechercheProfilDemandeurList.vue"),
      },
      {
        path: ":userRole/entreprise-add",
        name: "espace.common.entreprise.add",
        component: () => import("../views/espace/entreprise/EntrepriseAdd.vue"),
      },
      {
        path: ":userRole/offre-externe-add",
        name: "espace.common.offre.externe.add",
        component: () => import("../views/espace/common/offre-externe/OffreExterneAdd.vue"),
      },
      {
        path: ":userRole/offre-externe-list",
        name: "espace.common.offre.externe.list",
        component: () => import("../views/espace/common/offre-externe/OffreExterneList.vue"),
      },
      {
        path: ":userRole/offre-externe-edit/:offreId",
        name: "espace.common.offre.externe.edit",
        component: () => import("../views/espace/common/offre-externe/OffreExterneEdit.vue"),
      },


      {
        path: ":userRole/entreprise-presence-poste-list",
        name: "espace.common.presence-poste.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PresencePosteList.vue"),
      },
      {
        path: ":userRole/entreprise-prise-service-list",
        name: "espace.common.prise-service.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PriseServiceList.vue"),
      },
      {
        path: ":userRole/entreprise-arret-service-list",
        name: "espace.common.arret-service.list",
        component: () =>
          import("../views/espace/entreprise/suivi/ArretServiceList.vue"),
      },
      {
        path: ":userRole/entreprise-point-presence-list",
        name: "espace.common.point-presence.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PointPresenceList.vue"),
      },
      {
        path: ":userRole/entreprise-places-list",
        name: "espace.common.places.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PlacesList.vue"),
      },
      {
        path: ":userRole/entreprise-suivi-placement-list",
        name: "espace.common.suivi-placement.list",
        component: () =>
          import("../views/espace/entreprise/suivi/SuiviPlacementList.vue"),
      },
      {
        path: ":userRole/anpe-suivi-evaluation-list",
        name: "espace.common.suivi-evaluation.list",
        component: () =>
          import("../views/espace/common/suivi-evaluation/SuiviEvaluationManagement.vue"),
      },
      {
        path: ":userRole/ordre-mission-management-list",
        name: "espace.common.ordre-mission-management.list",
        component: () =>
          import("../views/espace/common/mission/OrdreMissionManagement.vue"),
      },
      {
        path: ":userRole/audieur-list",
        name: "espace.common.auditeur.list",
        component: () =>
          import("../views/espace/entreprise/AuditeurList.vue"),
      },
      {
        path: ":userRole/fiche-poste-programme-list",
        name: "espace.common.fiche-poste-programme.list",
        component: () =>
          import("../views/espace/common/enquete-poste-programme/FichePosteProgrammeList.vue"),
      },
      {
        path: ":userRole/enquete-poste-programme-list",
        name: "espace.common.enquete-poste-programme.list",
        component: () =>
          import("../views/espace/common/enquete-poste-programme/EnquetePosteProgrammeList.vue"),
      },
    ],
  },
];
