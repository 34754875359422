// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchTypeConsignes({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typeConsignes?size=${pageSize}&page=${pageNumber}&sort=libelle`)
    .then(({ data }) =>{
      commit('addTypeConsignes', data)
    })
  },
  createTypeConsigne({ commit }, typeConsigne){
    return post('/typeConsignes', typeConsigne)
    .then(({ data }) => {
      commit('addTypeConsigne', data)
    })
  },
  updateTypeConsigne({ commit }, typeConsigne){
    return put(`/typeConsignes/${typeConsigne.id}`, typeConsigne)
    .then(({ data }) =>{
      commit('replaceTypeConsigne', data)
    })
  },
  deleteTypeConsigne({ commit }, typeConsigne){
    return remove(`/typeConsignes/${typeConsigne.id}`, typeConsigne)
    .then(() =>{
      commit('removeTypeConsigne', typeConsigne)
    })
  },
  createOrUpdateTypeConsigne({ dispatch }, typeConsigne){
    if(typeConsigne.id !== undefined){
      return dispatch('updateTypeConsigne', typeConsigne)
    }
    else {
      return dispatch('createTypeConsigne', typeConsigne)
    }
  },
  selectTypeConsigne({ commit }, typeConsigne){
    commit('setTypeConsigne', typeConsigne)
  }
}