import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPresencePostes(state, data){
    state.pagination = extractPaginationInfo(data)
    state.presencePostes = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addPresencePoste(state, presencePoste){
    state.presencePostes = [ ...state.presencePostes, presencePoste ]
  },
  replacePresencePoste(state, presencePoste){
    state.presencePostes = state.presencePostes.map(struc => struc.id === presencePoste.id ? presencePoste : struc )
  },
  removePresencePoste(state, presencePoste){
    state.presencePostes = state.presencePostes.filter(cand => cand.id !== presencePoste.id)
  },
  setPresencePoste(state, presencePoste){
    state.presencePoste = presencePoste
  },
  setPresencePosteEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}