export const getters = {
  participations(state){
    return state.participations
  },
  participation(state){
    return state.participation
  },
  pagination(state){
    return state.pagination
  }
}