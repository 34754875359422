<template>
  <PDialog
    :header="title"
    :modal="true"
    :visible.sync="display"
    :containerStyle="{ width: '40vw' }"
  >
    <template #header>
      <h3>Exportation en pdf</h3>
    </template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="card mt border-0">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <template>
            <div class="row grid-md">
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      v-uppercase
                      id="title"
                      class="form-control"
                      name="title"
                      v-model="editableItem.title"
                    />
                    <label for="title">Titre de l'état.</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                  <!-- <small class="text-help"></small> -->
                </ValidationProvider>
              </div>
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      v-uppercase
                      id="subtitle"
                      class="form-control"
                      name="subtitle"
                      v-model="editableItem.subtitle"
                    />
                    <label for="subtitle">Description du contenu du fichier</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                  <!-- <small class="text-help"></small> -->
                </ValidationProvider>
              </div>
            </div>
          </template>
        </form>
      </div>
    </ValidationObserver>

    <template #footer>
      <PButton
        label="Annuler"
        @click.prevent="close"
        icon="pi pi-times"
        class="p-button-text"
      />
      <PButton label="Valider" @click.prevent="submit" icon="pi pi-check" autofocus />
    </template>
  </PDialog>
</template>

<script>
import { XDialog } from "./plugin";

export default {
  data() {
    return {
      title: null,
      editableItem: {},
      display: false,
      params: {
        type: "PDF",
        title: null,
        subtitle: null,
      },
      onCancel: null,
      onAccept: null,
    };
  },
  beforeMount() {
    XDialog.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
  methods: {
    resetContent() {
      this.params = {
        type: "PDF",
        title: null,
        subtitle: null,
      };
    },
    show(params) {
      let { onCancel, onAccept, meta } = params;
      this.editableItem = {
        title: meta.title,
        subtitle: meta.subtitle,
      };
      this.onAccept = onAccept;
      this.onCancel = onCancel;

      this.resetContent();
      this.display = true;
    },
    submit() {
      if (this.onAccept != null) this.onAccept({ ...this.editableItem });
      if (this.onCancel != null) this.onCancel({ ...this.editableItem });
      this.hide();
    },
    close() {
      this.hide();
    },
    hide() {
      this.display = false;
    },
  },
};
</script>
