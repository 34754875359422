import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addDemandeurs(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.demandeurs = [ ...content ]
  },
  addDemandeur(state, demandeur){
    state.demandeurs = [ ...state.demandeurs, demandeur ]
  },
  replaceDemandeur(state, demandeur){
    state.demandeurs = state.demandeurs.map(struc => struc.id === demandeur.id ? demandeur : struc )
  },
  setDemandeur(state, demandeur){
    state.demandeur = demandeur
  },
  setPriseEnCharge(state, priseEnCharge){
    state.priseEnCharge = priseEnCharge
  },
  setPriseEnChargePrescriptions(state, prescriptions){
    state.priseEnCharge.prescriptions = [ ...prescriptions ]
  },
  setPriseEnChargeConsignes(state, consignes){
    state.priseEnCharge.consignes = [ ...consignes ]
  },
}