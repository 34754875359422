export const mutations = {
  addDomaines(state, domaines){
    state.domaines = [ ...domaines ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addDomaine(state, domaine){
    state.domaines = [ ...state.domaines, domaine ]
  },
  replaceDomaine(state, domaine){
    state.domaines = state.domaines.map(struc => struc.id === domaine.id ? domaine : struc )
  },
  setDomaine(state, domaine){
    state.domaine = domaine
  },
}