export const getters = {
  equipements(state){
    return state.equipements
  },

  equipement(state){
    return state.equipement
  },
  pagination(state){
    return state.pagination
  }
}