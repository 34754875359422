import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAvantageFinanciers(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.avantageFinanciers = [ ...content ]
  },
  addAvantageFinancier(state, avantageFinancier){
    state.avantageFinanciers = [ avantageFinancier, ...state.avantageFinanciers]
  },
  replaceAvantageFinancier(state, avantageFinancier){
    state.avantageFinanciers = state.avantageFinanciers.map(struc => struc.id === avantageFinancier.id ? avantageFinancier : struc )
  },
  removeAvantageFinancier(state, avantageFinancier){
    state.avantageFinanciers = state.avantageFinanciers.filter(struc => struc.id !== avantageFinancier.id)
  },
  setAvantageFinancier(state, avantageFinancier){
    state.avantageFinancier = avantageFinancier
  },
}