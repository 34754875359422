export const getters = {
  besoinFormations(state){
    return state.besoinFormations
  },
  besoinFormation(state){
    return state.besoinFormation
  },
  pagination(state){
    return state.pagination
  }
}