import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addRapportActivites(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.rapportActivites = [ ...content ]
  },
  addRapportActivite(state, rapportActivite){
    state.rapportActivites = [ rapportActivite, ...state.rapportActivites]
  },
  replaceRapportActivite(state, rapportActivite){
    state.rapportActivites = state.rapportActivites.map(struc => struc.id === rapportActivite.id ? rapportActivite : struc )
  },
  removeRapportActivite(state, rapportActivite){
    state.rapportActivites = state.rapportActivites.filter(struc => struc.id !== rapportActivite.id)
  },
  setRapportActivite(state, rapportActivite){
    state.rapportActivite = rapportActivite
  },
}