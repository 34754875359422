// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchSecteurs({ commit }){
    return get('/secteurscreenings')
    .then(({ data }) =>{
      commit('addSecteurs', data)
    })
  },

  createSecteur({ commit }, secteur){
    return post('/secteurscreenings', secteur)
    .then(({ data }) =>{
      commit('setSecteur', data)
      commit('addSecteur', data)
    })
  },
  updateSecteur({ commit }, secteur){
    return put(`/secteurscreenings/${secteur.id}`, secteur)
    .then(({ data }) =>{
      commit('setSecteur', data)
      commit('replaceSecteur', data)
    })
  },
  createOrUpdateSecteur({ dispatch }, secteur){
    if(secteur.id !== undefined){
      return dispatch('updateSecteur', secteur)
    }
    else {
      return dispatch('createSecteur', secteur)
    }
  },
  selectSecteur({ commit }, secteur){
    commit('setSecteur', secteur)
  }
}