// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchDomaines({ commit }){
    return get('/domaines')
    .then(({ data }) =>{
      commit('addDomaines', data)
    })
  },
  createDomaine({ commit }, domaine){
    return post('/domaines', domaine)
    .then(({ data }) =>{
      commit('addDomaine', data)
    })
  },
  updateDomaine({ commit }, domaine){
    return put(`/domaines/${domaine.id}`, domaine)
    .then(({ data }) =>{
      commit('replaceDomaine', data)
    })
  },
  createOrUpdateDomaine({ dispatch }, domaine){
    if(domaine.id !== undefined){
      return dispatch('updateDomaine', domaine)
    }
    else {
      return dispatch('createDomaine', domaine)
    }
  },
  selectDomaine({ commit }, domaine){
    commit('setDomaine', domaine)
  }
}