// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'


export const actions = {
  fetchSalaires({ commit }){
    return get('/salaires')
    .then(({ data }) =>{
      commit('addsalaires', data)
    })
  },
  createSalaire({ commit }, salaire){
    return post('/salaires', salaire)
    .then(({ data }) =>{
      commit('addsalaire', data)
    })
  },
  updateSalaire({ commit }, salaire){
    return put(`/salaires/${salaire.id}`, salaire)
    .then(({ data }) =>{
      commit('replacesalaire', data)
    })
  },
  createOrUpdatesalaire({ dispatch }, salaire){
    console.log("je suis dans action du store")
    if(salaire.id !== undefined){
      return dispatch('updatesalaire', salaire)
    }
    else {
      return dispatch('createsalaire', salaire)
    }
  },
  selectSalaire({ commit }, salaire){
    commit('setsalaire', salaire)
  },
  deleteSalaire({ commit }, salaire){
    return remove(`/salaires/${salaire.id}`, salaire)
    .then(({ data }) =>{
      commit('addsalaire', data)
    })
  }
}