// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchNiveaux({ commit }){
    return get('/niveaux')
    .then(({ data }) =>{
      commit('addNiveaux', data)
    })
  },
  createNiveau({ commit }, niveau){
    return post('/niveaux', niveau)
    .then(({ data }) =>{
      commit('addNiveau', data)
    })
  },
  updateNiveau({ commit }, niveau){
    return put(`/niveaux/${niveau.id}`, niveau)
    .then(({ data }) =>{
      commit('replaceNiveau', data)
    })
  },
  createOrUpdateNiveau({ dispatch }, niveau){
    if(niveau.id !== undefined){
      return dispatch('updateNiveau', niveau)
    }
    else {
      return dispatch('createNiveau', niveau)
    }
  },
  selectNiveau({ commit }, niveau){
    commit('setNiveau', niveau)
  }
}