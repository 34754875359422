// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchCompetences({ commit }){
    return get('/competences')
    .then(({ data }) =>{
      commit('addCompetences', data)
    })
  },
  createCompetence({ commit }, competence){
    return post('/competences', competence)
    .then(({ data }) =>{
      commit('addCompetence', data)
    })
  },
  updateCompetence({ commit }, competence){
    return put(`/competences/${competence.id}`, competence)
    .then(({ data }) =>{
      commit('replaceCompetence', data)
    })
  },
  createOrUpdateCompetence({ dispatch }, competence){
    if(competence.id !== undefined){
      return dispatch('updateCompetence', competence)
    }
    else {
      return dispatch('createCompetence', competence)
    }
  },
  selectCompetence({ commit }, competence){
    commit('setCompetence', competence)
  }
}