export const getters = {
  typeActivites(state){
    return state.typeActivites
  },
  typeActivite(state){
    return state.typeActivite
  },
  pagination(state){
    return state.pagination
  }
}