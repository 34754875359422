export const SIDE_MENU_ENTREPRISE = [
  {
    to: { name: "espace.entreprise.home" },
    label: "Accueil",
    icon: "fa fa-home",
  },
  {
    to: { name: "accounts.logout" },
    label: "Déconnexion",
    icon: "bi bi-power",
  },
]