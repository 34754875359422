export const mutations = {
  addAzoliSalaries(state, azoliSalaries){
    state.azoliSalaries = [ ...azoliSalaries ]
  },
  addAzoliSalarie(state, azoliSalarie){
    state.azoliSalaries = [ ...state.azoliSalaries, azoliSalarie ]
  },
  replaceAzoliSalarie(state, azoliSalarie){
    state.azoliSalaries = state.azoliSalaries.map(struc => struc.id === azoliSalarie.id ? azoliSalarie : struc )
  },
  setAzoliSalarie(state, azoliSalarie){
    state.azoliSalarie = azoliSalarie
  },
}