// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchTypeParentes({ commit }){
    return get('/typeParentes')
    .then(({ data }) =>{
      commit('addTypeParentes', data)
    })
  },
  createTypeParente({ commit }, typeParente){
    return post('/typeParentes', typeParente)
    .then(({ data }) =>{
      commit('addTypeParente', data)
    })
  },
  updateTypeParente({ commit }, typeParente){
    return put(`/typeParentes/${typeParente.id}`, typeParente)
    .then(({ data }) =>{
      commit('replaceTypeParente', data)
    })
  },
  createOrUpdateTypeParente({ dispatch }, typeParente){
    if(typeParente.id !== undefined){
      return dispatch('updateTypeParente', typeParente)
    }
    else {
      return dispatch('createTypeParente', typeParente)
    }
  },
  selectTypeParente({ commit }, typeParente){
    commit('setTypeParente', typeParente)
  }
}