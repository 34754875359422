import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAuteurAudits(state, data){
    state.pagination = extractPaginationInfo(data)
    state.auteurAudits = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addAuteurAudit(state, auteurAudit){
    state.auteurAudits = [ ...state.auteurAudits, auteurAudit ]
  },
  replaceAuteurAudit(state, auteurAudit){
    state.auteurAudits = state.auteurAudits.map(struc => struc.id === auteurAudit.id ? auteurAudit : struc )
  },
  removeAuteurAudit(state, auteurAudit){
    state.auteurAudits = state.auteurAudits.filter(cand => cand.id !== auteurAudit.id)
  },
  setAuteurAudit(state, auteurAudit){
    state.auteurAudit = auteurAudit
  },
  setAuteurAuditEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}