export const mutations = {
  addTypeParentes(state, typeParentes){
    state.typeParentes = [ ...typeParentes ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addTypeParente(state, typeParente){
    state.typeParentes = [ ...state.typeParentes, typeParente ]
  },
  replaceTypeParente(state, typeParente){
    state.typeParentes = state.typeParentes.map(struc => struc.id === typeParente.id ? typeParente : struc )
  },
  setTypeParente(state, typeParente){
    state.typeParente = typeParente
  },
}