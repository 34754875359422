export const getters = {
  typePrescriptions(state){
    return state.typePrescriptions
  },
  typePrescription(state){
    return state.typePrescription
  },
  pagination(state){
    return state.pagination
  }
}