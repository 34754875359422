<template>
  <div id="app">
    <x-dialog />
    <ConfirmDialog group="confirmDialog"></ConfirmDialog>
    <ConfirmPopup style="z-index: 1000" group="confirmPopup"></ConfirmPopup>
    <router-view />
  </div>
</template>

<script>
// import vueInternetChecker from "vue-internet-checker";
function addLeadingZero(event) {
  // get maxlength attr
  const maxLength = parseInt(event.target.getAttribute("maxlength"));
  // check and flag if negative
  const isNegative = parseInt(event.target.value) < 0;
  // "0".repeat(maxLength) <-- create default pad with maxlength given
  // Math.abs(event.target.value) to make sure we proceed with positive value
  // append zero and slice last of attr maxlength value
  let newValue = ("0".repeat(maxLength) + Math.abs(event.target.value).toString()).slice(
    -maxLength
  );
  // add - if flag negative is true
  if (isNegative) {
    newValue = "-" + newValue;
  }
  // change the value of input
  event.target.value = newValue;
}
// import EasyCamera from "easy-vue-camera";
// import { app as firebaseApp } from "./firebase";
import TakePicture from "./components/common/TakePicture.vue";
import { authMixin } from "./mixins/auth-mixin";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import { post } from "./api/api";
// import { dialogEventBus } from "./utils/event-bus";

// import {
//   getToken,
//   getMessaging,
//   onMessage,
//   deleteToken,
// } from "firebase/messaging";

import { register as registerSW } from "register-service-worker";

export default {
  name: "App",
  data: () => ({
    messaging: null,
    clientToken: null,
    statusMessage: null,
    picture: null,
    display: false,
  }),
  components: {
    ConfirmDialog,
    ConfirmPopup,
    TakePicture,
  },
  mixins: [authMixin],
  async created() {
    // this.$nextTick(() => {
    // document.querySelector("input[type=number]").addEventListener('input', addLeadingZero)
    // })
    // window.alert = function ( text ) { console.log( 'tried to alert: ' + text ); return true; }
    // this.$toasted.show("Toasted !!", {
    //   theme: "toasted-primary",
    //   position: "top-right",
    //   duration : 5000
    // })
    // this.messaging = getMessaging(firebaseApp);
    // if (this.isSubscribed()) this.clientToken = await this.getSubscribedToken();
    // await this.registerServiceWorker();
  },
  methods: {
    async registerServiceWorker() {
      let subscribeToken = this.subscribeToken;
      if ("Notification" in window && navigator.serviceWorker) {
        registerSW(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
          ready(reg) {
            console.log("Service worker is Ready");
            // subsctibe to FCM
            subscribeToken(reg);
          },
          registered(reg) {
            console.log("Service worker has been registered.");
            setInterval(() => {
              reg.update();
            }, 1000 * 60 * 30); // 30 min checks
          },
          cached() {
            // console.log('Content has been cached for offline use.');
          },
          updatefound() {
            console.log("New content is downloading.");
          },
          updated(reg) {
            console.log("New content is available; please refresh.");
            document.dispatchEvent(new CustomEvent("swUpdated", { detail: reg.waiting }));
          },
          offline() {
            console.log("No internet connection found.");
          },
          error(error) {
            console.error("Error during service worker registration:", error);
          },
        });
      }
    },
    async subscribeToken(reg) {
      let vapidKey =
        "BPHFOCIhVAnvSq3Vz4cQRaxxGHgxSf4j9_CTebsD78rPvT38n1bsCS1tLhMFMtTm3MvdvxTGRwf19kakUVobO7s";
      try {
        let token = await getToken(this.messaging, {
          vapidKey,
          serviceWorkerRegistration: reg,
        });
        console.log("Client token => ", token);
        // send token to server
        let res = await this.sendTokenToServer(token);
        console.log("Sent to server", res.data);
        // store returned uuid with token
        this.setSubscribedToken(res.data);
        this.listenForegroundMessage(reg);
      } catch (err) {
        console.error(err);
        this.unsetSubscribedToken();
        console.log("Retry to subscribe");
      }
    },
    async unsubscribeToken() {
      try {
        let unsubscribe = await deleteToken(this.messaging);
        console.log("Subscribed  ", unsubscribe);
        let res = await this.removeTokenFromServer(this.clientToken.uuid);
        console.log("Unsubscribed from server", res.data);
        await this.unsetSubscribedToken();
      } catch (err) {
        console.error(err);
      }
    },
    async listenForegroundMessage(reg) {
      if (!reg)
        reg = await navigator.serviceWorker.getRegistration(
          `${process.env.BASE_URL}firebase-messaging-sw.js`
        );
      onMessage(this.messaging, (payload) => {
        console.log("Message received. ", payload);
        let { notification, data } = payload;
        let notificationTitle = "Test title";
        let notificationBody = "Test body";
        if (notification && notification.title && notification.body) {
          notificationTitle = notification.title;
          notificationBody = notification.body;
        } else if (data && data.title && data.body) {
          notificationTitle = data.title;
          notificationBody = data.body;
        }
        // in window noti
        // this.$notify({
        //   group: "test",
        //   title: "[Foreground] " + notificationTitle,
        //   text: notificationBody,
        //   duration: 10000,
        // });
        const notificationOptions = {
          body: notificationBody,
        };
        if (reg)
          reg.showNotification("[Foreground] " + notificationTitle, notificationOptions);
      });
    },
    isSubscribed() {
      let ct = localStorage.getItem("clientToken");
      console.log("Subscribed", !!ct);
      return !!ct;
    },
    setSubscribedToken({ data }) {
      this.clientToken = data;
      console.log("stored in localstorage", this.clientToken);
      localStorage.setItem("clientToken", JSON.stringify(this.clientToken));
    },
    unsetSubscribedToken() {
      this.clientToken = null;
      localStorage.removeItem("clientToken");
      console.log("removed from localstorage", this.clientToken);
    },
    async requestPermission() {
      try {
        const permission = await Notification.requestPermission();
        console.log("GIVEN notify perms");
        console.log(permission);
      } catch (e) {
        console.error("Error : ", e);
      }
    },
    async getSubscribedToken() {
      let ct = localStorage.getItem("clientToken");
      if (ct) return JSON.parse(ct);
      else return null;
    },
    sendTokenToServer(token) {
      let dataObj = { token };
      if (this.clientToken && this.clientToken.uuid) {
        dataObj = { uuid: this.clientToken.uuid, token };
        console.log("before send to server", dataObj);
      }
      return post("/fcm/token/save", dataObj);
    },
    removeTokenFromServer(uuid) {
      return remove("/fcm/token/delete/" + uuid);
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.p-confirm-dialog {
  width: 30vw;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
