export const getters = {
  planCompetences(state){
    return state.planCompetences
  },
  planCompetence(state){
    return state.planCompetence
  },
  pagination(state){
    return state.pagination
  }
}