export const getters = {
  domaines(state) {
    return state.domaines;
  },
  communes(state) {
    return state.communes;
  },
  domaine(state) {
    return state.domaine;
  },
  pagination(state) {
    return state.pagination;
  },
};