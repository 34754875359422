// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchZoneVulnerables({ commit, state }, params = {}){
    console.clear()
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/categories?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=categorie`)
    .then(({ data }) =>{
      commit('addZoneVulnerables', data)
    })
  },
  createZoneVulnerable({ commit }, categorie){
    return post('/imports/zoneVulnerables', categorie)
    .then(({ data }) =>{
      commit('addZoneVulnerable', data)
    })
  },
  updateZoneVulnerable({ commit }, zoneVulnerable){
    return put(`/categories/${zoneVulnerable.id}`, zoneVulnerable)
    .then(({ data }) =>{
      commit('replaceZoneVulnerable', data)
    })
  },
  createOrUpdateZoneVulnerable({ dispatch }, zoneVulnerable){
    if(zoneVulnerable.id !== undefined){
      return dispatch('updateZoneVulnerable', zoneVulnerable)
    }
    else {
      return dispatch('createZoneVulnerable', zoneVulnerable)
    }
  },
  selectZoneVulnerable({ commit }, zoneVulnerable){
    commit('setZoneVulnerable', zoneVulnerable)
  }
}