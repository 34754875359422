export const getters = {
  photoConstats(state){
    return state.photoConstats
  },
  photoConstat(state){
    return state.photoConstat
  },
  pagination(state){
    return state.pagination
  }
}