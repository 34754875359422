import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addNiveauSatisfactions(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.niveauSatisfactions = [ ...content ]
  },
  addNiveauSatisfaction(state, niveauSatisfaction){
    state.niveauSatisfactions = [ niveauSatisfaction, ...state.niveauSatisfactions]
  },
  replaceNiveauSatisfaction(state, niveauSatisfaction){
    state.niveauSatisfactions = state.niveauSatisfactions.map(struc => struc.id === niveauSatisfaction.id ? niveauSatisfaction : struc )
  },
  removeNiveauSatisfaction(state, niveauSatisfaction){
    state.niveauSatisfactions = state.niveauSatisfactions.filter(struc => struc.id !== niveauSatisfaction.id)
  },
  setNiveauSatisfaction(state, niveauSatisfaction){
    state.niveauSatisfaction = niveauSatisfaction
  },
}