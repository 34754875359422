import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addQuestionScreenings(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.questionscreenings = [ ...content ]
  },

  addQuestionScreening(state, questionscreening){
    state.questionscreenings = [ ...state.questionscreenings, questionscreening ]
  },
  
  replaceQuestionScreening(state, questionscreening){
    state.questionscreenings = state.questionscreenings.map(struc => struc.id === questionscreening.id ? questionscreening : struc )
  },
  setQuestionScreening(state, questionscreening){
    state.questionscreening = questionscreening
  },
}