import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTransfertDossiers(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.transfertdossiers = [ ...content ]
  },
  addTransfertDossier(state, transfertdossier){
    state.transfertdossiers = [ ...state.transfertdossiers, transfertdossier ]
  },
  replaceTransfertDossier(state, transfertdossier){
    state.transfertdossiers = state.transfertdossiers.map(el => el.id === transfertdossier.id ? transfertdossier : el )
  },
  removeTransfertDossier(state, transfertdossier){
    state.transfertdossiers = state.transfertdossiers.filter(el => el.id !== transfertdossier.id )
  },
  setTransfertDossier(state, transfertdossier){
    state.transfertdossier = transfertdossier
  },
}