import { extractPaginationInfo } from "@/utils/pagination-util";

export const mutations = {
  addPlanactions(state, data) {
    state.planactions = [...data.content];
  },

  addPlanactionsSimple(state, data) {
    console.log(data);
    state.planactions = [...data];
  },

  addPlanaction(state, planaction) {
    state.planactions = [...state.planactions, planaction];
  },
  replacePlanaction(state, planaction) {
    state.planactions = state.planactions.map((struc) =>
      struc.id === planaction.id ? planaction : struc
    );
  },
  setPlanaction(state, planaction) {
    state.planaction = planaction;
  },
};
