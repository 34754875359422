export const getters = {
  objectifAgents(state){
    return state.objectifAgents
  },
  objectifAgent(state){
    return state.objectifAgent
  },
  pagination(state){
    return state.pagination
  }
}