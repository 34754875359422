<template>
  <!-- <camera
    @loading="loading"
    @started="started"
    @stopped="stopped"
    @paused="paused"
    @resumed="resumed"
    @camera-change="cameraChange"
    @snapshot="snapshot"
    @error="error"
  ></camera> -->
  <div class="d-flex flex-column justify-content-center align-items-center mt-2">
    <PImagePreview v-if="showPreview" :src="picture" alt="Image Text" preview />

    <EasyCamera
      ref="camera"
      v-if="showCamera"
      @input="capturedPicture"
      :fullscreen="true"
      :mustApprove="true"
      :autoDetectMobile="true"
      @close="cameraClosed"
      @approve="approvePicture"
    />
    <div v-if="showPreview">
      <button class="btn btn-warning btn-lg mt-4" @click="showCamera = true">
        <i class="bi bi-camera"></i>
        Take picture
      </button>
    </div>
  </div>
</template>

<script>
import EasyCamera from "easy-vue-camera";
import { FileService } from "../../api/file-service";
import { ENCRYPTED_FILE_UPLOAD_API_URL } from "../../config/api-config";
export default {
  props: {
    mustApprove: {
      type: Boolean,
      default: true,
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EasyCamera,
  },
  data() {
    return {
      picture: null,
      showCamera: true,
    };
  },
  methods: {
    approvePicture(pic) {
      this.showCamera = false;
      this.picture = pic;
      this.uploadPicture(pic);
    },
    capturedPicture(pic) {
      if (!this.mustApprove) {
        this.picture = pic;
        this.uploadPicture(pic);
      }
    },
    uploadPicture(pic) {
      const blob = this.b64toBlob(pic);
      var url = window.URL.createObjectURL(blob);
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "Photo-En.png", { type: "image/png" });
          const onSuccess = (evt) => {
            let resp = JSON.parse(evt.currentTarget.response);
            this.$emit("save", resp.url);
            this.$emit("input", resp.url);
            this.$emit("uploaded", resp.url);
          };
          FileService.uploadFile(
            ENCRYPTED_FILE_UPLOAD_API_URL,
            file,
            {},
            "POST",
            "file",
            onSuccess
          );
        });
    },
    cameraClosed() {
      this.showCamera = false;
      console.log("Close capturing: ", this.picture);
      this.$emit("close");
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    },
  },
};
</script>
