// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
    fetchBesoinFormations({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/besoinFormations?${queryParams}`)
    .then(({ data }) =>{
      commit('addBesoinFormations', data)
      return data.content
    })
  },
  fetchBesoinFormation({ commit, state }, params = {}){
    let { besoinFormationId } = params
    return get(`/besoinFormations/${besoinFormationId}`)
    .then(({ data }) =>{
      // commit('addBesoinFormation', data)
      return data
    })
  },
//   fetchBesoinFormationByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/besoinFormations/${besoinFormationId}/`)
//     .then(({ data }) =>{
//       // commit('addBesoinFormation', data)
//       return data
//     })
//   },
  createBesoinFormation({ commit }, besoinFormation){
    return post('/besoinFormations', besoinFormation)
    .then(({ data }) => {
      commit('addBesoinFormation', data)
      return data
    })
  },
  updateBesoinFormation({ commit }, besoinFormation){
    return put(`/besoinFormations/${besoinFormation.id}`, besoinFormation)
    .then(({ data }) =>{
      commit('replaceBesoinFormation', data)
      return data
    })
  },
  deleteBesoinFormation({ commit }, besoinFormation){
    return remove(`/besoinFormations/${besoinFormation.id}`, besoinFormation)
    .then(() =>{
      commit('removeBesoinFormation', besoinFormation)
    })
  },
  validerBesoinFormation({ commit }, besoinFormation){
      return put(`/besoinFormations/${besoinFormation.id}/valider`, besoinFormation)
      .then(({ data }) =>{
        commit('replaceBesoinFormation', data)
        return data
      })
    },
  createOrUpdateBesoinFormation({ dispatch }, besoinFormation){
    if(besoinFormation.id !== undefined){
      return dispatch('updateBesoinFormation', besoinFormation)
    }
    else {
      return dispatch('createBesoinFormation', besoinFormation)
    }
  },
  selectBesoinFormation({ commit }, besoinFormation){
    commit('setBesoinFormation', besoinFormation)
  }
}