export const getters = {
  criteres(state){
    return state.criteres
  },

  critere(state){
    return state.critere
  },
  pagination(state){
    return state.pagination
  }
}