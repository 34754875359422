import { post, put, get, api } from '@/api/api'


export const actions = {
  fetchTransfertDossiers({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/transfertDossiers?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addTransfertDossiers', data)
      return data
    })
  },
  transfererVersConseiller({ commit }, params = {}){
    let { agent, dossiers, affecterAccompagnateurMicroprojet } = params
    return post(`/transfertDossiers/transfertMultipleVersConseiller`,{
      dossiers: dossiers,
      transfereA: agent,
      affecterAccompagnateurMicroprojet
    }).then(({ data }) =>{
      console.log(data)
      commit('addDemandeurs', data)
    }).catch(err => console.error(err))
  },
  transfererVersAntenne({ commit }, params = {}){
    let { antenne, dossiers } = params
    return post(`/transfertDossiers/transfertMultipleVersAntenne`,{
      dossiers: dossiers,
      antenneDestination: antenne
    }).then(({ data }) =>{
      console.log(data)
    //   commit('addDemandeurs', data)
      return data
    }).catch(err => console.error(err))
  },
  createTransfertDossier({ commit }, transfertdossier){
    return post('/transfertdossiers', transfertdossier)
    .then(({ data }) =>{
      commit('addTransfertDossier', data)
    })
  },
  updateTransfertDossier({ commit }, transfertdossier){
    return put(`/transfertDossiers/${transfertdossier.id}`, transfertdossier)
    .then(({ data }) =>{
      commit('replaceTransfertDossier', data)
    })
  },
  deleteTransfertDossier({ commit }, transfertdossier){
    return api.delete(`/transfertDossiers/${transfertdossier.id}`)
    .then(() =>{
      commit('removeTransfertDossier', transfertdossier)
    })
  },
  createOrUpdateTransfertDossier({ dispatch }, transfertdossier){
    if(transfertdossier.id !== undefined){
      return dispatch('updateTransfertDossier', transfertdossier)
    }
    else {
      return dispatch('createTransfertDossier', transfertdossier)
    }
  },
  selectTransfertDossier({ commit }, transfertdossier){
    commit('setTransfertDossier', transfertdossier)
  }
}