// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchQualificationProfessionnelles({ commit }){
    return get('/qualificationProfessionnelles')
    .then(({ data }) =>{
      commit('addQualificationProfessionnelles', data)
    })
  },
  createQualificationProfessionnelle({ commit }, qualificationProfessionnelle){
    return post('/qualificationProfessionnelles', qualificationProfessionnelle)
    .then(({ data }) =>{
      commit('addQualificationProfessionnelle', data)
    })
  },
  updateQualificationProfessionnelle({ commit }, qualificationProfessionnelle){
    return put(`/qualificationProfessionnelles/${qualificationProfessionnelle.id}`, qualificationProfessionnelle)
    .then(({ data }) =>{
      commit('replaceQualificationProfessionnelle', data)
    })
  },
  createOrUpdateQualificationProfessionnelle({ dispatch }, qualificationProfessionnelle){
    if(qualificationProfessionnelle.id !== undefined){
      return dispatch('updateQualificationProfessionnelle', qualificationProfessionnelle)
    }
    else {
      return dispatch('createQualificationProfessionnelle', qualificationProfessionnelle)
    }
  },
  selectQualificationProfessionnelle({ commit }, qualificationProfessionnelle){
    commit('setQualificationProfessionnelle', qualificationProfessionnelle)
  }
}