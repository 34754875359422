// import axios from 'axios'
import { post, put, get, remove, downloadFile } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchParticipationPlacements({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/participationPlacements?${queryParams}`)
    .then(({ data }) =>{
      commit('addParticipationPlacements', data)
      return data
    })
  },
  fetchParticipationPlacementMesEmployers({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/participationPlacements/mesEmployers?${queryParams}`)
    .then(({ data }) =>{
      commit('addParticipationPlacements', data)
      return data
    })
  },
  fetchParticipationPlacementMesEmployersWithPayments({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/participationPlacements/mesEmployersWithPayments?${queryParams}`)
    .then(({ data }) =>{
      commit('addParticipationPlacements', data)
      return data
    })
  },
  fetchContratStage(ctx, { participationPlacementId }){
    return get(`/participationPlacements/${participationPlacementId}/contratStage`)
    .then(({ data }) =>{
      return data
    })
  },
  annulerParticipationPlacement(ctx, participationPlacement){
    return put(`/participationPlacements/${participationPlacement.id}/annulerParticipationPlacement`, participationPlacement)
    .then(({ data }) =>{
      return data
    })
  },
  createParticipationPlacement({ commit }, participationPlacement){
    return post('/participationPlacements', participationPlacement)
    .then(({ data }) => {
      commit('addParticipationPlacement', data)
      return data
    })
  },
  updateParticipationPlacement({ commit }, participationPlacement){
    return put(`/participationPlacements/${participationPlacement.id}`, participationPlacement)
    .then(({ data }) =>{
      commit('replaceParticipationPlacement', data)
      return data
    })
  },
  deleteParticipationPlacement({ commit }, participationPlacement){
    return remove(`/participationPlacements/${participationPlacement.id}`, participationPlacement)
    .then(() =>{
      commit('removeParticipationPlacement', participationPlacement)
      return data
    })
  },
  telechargerAttestationStage({}, params = {}){
    return downloadFile(`/participationPlacements/${params.id}/exportAttestationToPdf`)
    .then(({ data }) =>{
      return data
    })
  },
  createOrUpdateParticipationPlacement({ dispatch }, participationPlacement){
    if(participationPlacement.id !== undefined){
      return dispatch('updateParticipationPlacement', participationPlacement)
    }
    else {
      return dispatch('createParticipationPlacement', participationPlacement)
    }
  },
  selectParticipationPlacement({ commit }, participationPlacement){
    commit('setParticipationPlacement', participationPlacement)
    return data
  }
}