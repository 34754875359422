export const getters = {
  qualificationProfessionnelles(state){
    return state.qualificationProfessionnelles
  },
  communes(state){
    return state.communes
  },
  qualificationProfessionnelle(state){
    return state.qualificationProfessionnelle
  }
}