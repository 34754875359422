export const getters = {
  departements(state){
    return state.departements
  },
  communes(state){
    return state.communes
  },
  arrondissements(state){
    return state.arrondissements
  },
  villes(state){
    return state.villes
  },
  nations(state){
    return state.nations
  },
  departement(state){
    return state.departement
  }
}