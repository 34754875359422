
export const espaceProdijRoutes = [
  {
    path: "/espace/prodij",
    // name: "espace.admin.espace/admin",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "prodij-home",
        name: "espace.prodij.home",
        redirect: { name: "espace.admin.azolis.home" }
      }
    ],
  },
];
