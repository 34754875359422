// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'


export const actions = {
  fetchRechercherEmplois({ commit }){
    return get('/rechercheremplois')
    .then(({ data }) =>{
      commit('addRechercherEmplois', data)
    })
  },
  createRechercherEmploi({ commit }, rechercheremploi){
    return post('/rechercheremplois', rechercheremploi)
    .then(({ data }) =>{
      commit('addRechercherEmplois', data)
    })
  },
  updateRechercherEmploi({ commit }, rechercheremploi){
    return put(`/rechercheremplois/${rechercheremploi.id}`, rechercheremploi)
    .then(({ data }) =>{
      commit('replaceRechercherEmplois', data)
    })
  },
  createOrUpdateRechercherEmploi({ dispatch }, rechercheremploi){
    if(rechercheremploi.id !== undefined){
      return dispatch('updateRechercherEmploi', rechercheremploi)
    }
    else {
      return dispatch('createRechercherEmploi', rechercheremploi)
    }
  },
  selectRechercherEmploi({ commit }, rechercheremploi){
    commit('setRechercherEmploi', rechercheremploi)
  },
  deleteRechercherEmploi({ commit }, rechercheremploi){
    return remove(`/rechercheremplois/${rechercheremploi.id}`, rechercheremploi)
    .then(({ data }) =>{
      commit('addRechercherEmplois', data)
    })
  }
}