// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
    fetchProfilEntrepreneurials({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/profilEntrepreneurials?${queryParams}`)
    .then(({ data }) =>{
      commit('addProfilEntrepreneurials', data)
      return data.content
    })
  },
  fetchProfilEntrepreneurial({ commit, state }, params = {}){
    let { profilEntrepreneurialId } = params
    return get(`/profilEntrepreneurials/${profilEntrepreneurialId}`)
    .then(({ data }) =>{
      // commit('addProfilEntrepreneurial', data)
      return data
    })
  },
//   fetchProfilEntrepreneurialByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/profilEntrepreneurials/${profilEntrepreneurialId}/`)
//     .then(({ data }) =>{
//       // commit('addProfilEntrepreneurial', data)
//       return data
//     })
//   },
  createProfilEntrepreneurial({ commit }, profilEntrepreneurial){
    return post('/profilEntrepreneurials', profilEntrepreneurial)
    .then(({ data }) => {
      commit('addProfilEntrepreneurial', data)
      return data
    })
  },
  updateProfilEntrepreneurial({ commit }, profilEntrepreneurial){
    return put(`/profilEntrepreneurials/${profilEntrepreneurial.id}`, profilEntrepreneurial)
    .then(({ data }) =>{
      commit('replaceProfilEntrepreneurial', data)
      return data
    })
  },
  deleteProfilEntrepreneurial({ commit }, profilEntrepreneurial){
    return remove(`/profilEntrepreneurials/${profilEntrepreneurial.id}`, profilEntrepreneurial)
    .then(() =>{
      commit('removeProfilEntrepreneurial', profilEntrepreneurial)
    })
  },
  validerProfilEntrepreneurial({ commit }, profilEntrepreneurial){
      return put(`/profilEntrepreneurials/${profilEntrepreneurial.id}/valider`, profilEntrepreneurial)
      .then(({ data }) =>{
        commit('replaceProfilEntrepreneurial', data)
        return data
      })
    },
  createOrUpdateProfilEntrepreneurial({ dispatch }, profilEntrepreneurial){
    if(profilEntrepreneurial.id !== undefined){
      return dispatch('updateProfilEntrepreneurial', profilEntrepreneurial)
    }
    else {
      return dispatch('createProfilEntrepreneurial', profilEntrepreneurial)
    }
  },
  selectProfilEntrepreneurial({ commit }, profilEntrepreneurial){
    commit('setProfilEntrepreneurial', profilEntrepreneurial)
  }
}