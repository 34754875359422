import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchMissions({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/missions?${queryParams}`)
    .then(({ data }) =>{
      commit('addMissions', data)
      return data
    })
  },
  fetchMission({ commit, state }, params = {}){
    let { missionId } = params
    return get(`/missions/${missionId}`)
    .then(({ data }) =>{
      commit('setMission', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { missionId } = params
    return downloadFileWithAxiosPost(`/missions/exportToPdf`, 'file.pdf', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setMission', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { missionId } = params
    return downloadFileWithAxiosPost(`/missions/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setMission', data)
      return data
    })
  },
  createMission({ commit }, mission){
    return post('/missions', mission)
    .then(({ data }) =>{
      commit('addMission', data)
      return data
    })
  },
  updateMission({ commit }, mission){
    return put(`/missions/${mission.id}`, mission)
    .then(({ data }) =>{
      commit('replaceMission', data)
      commit('setMission', data)

      return data
    })
  },
  createOrUpdateMission({ dispatch }, mission){
    if(mission.id !== undefined){
      return dispatch('updateMission', mission)
    }
    else {
      return dispatch('createMission', mission)
    }
  },
  selectMission({ commit }, mission){
    commit('setMission', mission)
  }
}