import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addReponsescreenings(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.reponsescreenings = [ ...content ]
  },

  addReponsescreenings2(state, data){
    state.reponsescreenings = [ ...data ]
  },

  addReponsescreening(state, reponsescreening){
    state.reponsescreenings = [ ...state.reponsescreenings, reponsescreening ]
  },
  
  replaceReponsescreening(state, reponsescreening){
    state.reponsescreenings = state.reponsescreenings.map(struc => struc.id === reponsescreening.id ? reponsescreening : struc )
  },
  setReponsescreening(state, reponsescreening){
    state.reponsescreening = reponsescreening
  },
}