export const getters = {
  formationProfessionnelles(state){
    return state.formationProfessionnelles
  },
  communes(state){
    return state.communes
  },
  formationProfessionnelle(state){
    return state.formationProfessionnelle
  }
}