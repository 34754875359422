import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import { AuthService } from "../api/user-service";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((record) => record.meta.auth);

    const loggedIn = AuthService.isUserLogined();
    const userRole =
        AuthService.userRoleName() != null
            ? AuthService.userRoleName().toLowerCase()
            : null;

    // if(to.params && to.params.userRole && to.params.userRole.toLowerCase() != userRole) alert('Non authorisé')

    if (loggedIn && to.name != null && to.name.includes(".login")) {
        return ['ENTREPRISE', 'DE'].includes(userRole.toUpperCase())? next({ name: `espace.${userRole}.home`, params: { userRole } })
        : next({ name: "espace.common.user.home", params: { userRole } });
    } else if (authRequired && !loggedIn) {
        return next({
            name: "accounts.login.de",
            query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
        });
    } else if (
        (loggedIn &&
            to.params &&
            to.params.userRole &&
            to.params.userRole.toLowerCase() != userRole) ||
        (to.name.includes("espace.admin") && userRole != "admin")
    ) {
        return next({ name: `espace.${userRole}.home`, params: { userRole } });
    }
    next();
});

export default router;
