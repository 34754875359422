export const getters = {
  familles(state){
    return state.familles
  },
  famille(state){
    return state.famille
  },
  pagination(state){
    return state.pagination
  }
}