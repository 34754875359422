export const mutations = {
  addNeTravailPass(state, netravailpass){
    state.neTravailPass = [ ...netravailpass ]
  },
  addNeTravailPas(state, netravailpas){
    state.neTravailPass = [ ...state.neTravailPass, netravailpas ]
  },
  replaceNeTravailPas(state, netravailpas){
    state.neTravailPass = state.neTravailPass.map(struc => struc.id === netravailpas.id ? netravailpas : struc )
  },
  setNeTravailPas(state, netravailpas){
    state.neTravailPas = netravailpas
  },
}