export const getters = {
  evaluationStagiaires(state){
    return state.evaluationStagiaires
  },
  evaluationStagiaire(state){
    return state.evaluationStagiaire
  },
  pagination(state){
    return state.pagination
  }
}