export const getters = {
  niveauSatisfactions(state){
    return state.niveauSatisfactions
  },
  niveauSatisfaction(state){
    return state.niveauSatisfaction
  },
  pagination(state){
    return state.pagination
  }
}