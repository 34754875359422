// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchTypeContenus({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typeContenus?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=typeContenu`)
    .then(({ data }) =>{
      commit('addTypeContenus', data)
    })
  },
  createTypeContenu({ commit }, typeContenu){
    return post('/typeContenus', typeContenu)
    .then(({ data }) => {
      commit('addTypeContenu', data)
    })
  },
  updateTypeContenu({ commit }, typeContenu){
    return put(`/typeContenus/${typeContenu.id}`, typeContenu)
    .then(({ data }) =>{
      commit('replaceTypeContenu', data)
    })
  },
  deleteTypeContenu({ commit }, typeContenu){
    return remove(`/typeContenus/${typeContenu.id}`, typeContenu)
    .then(() =>{
      commit('removeTypeContenu', typeContenu)
    })
  },
  createOrUpdateTypeContenu({ dispatch }, typeContenu){
    if(typeContenu.id !== undefined){
      return dispatch('updateTypeContenu', typeContenu)
    }
    else {
      return dispatch('createTypeContenu', typeContenu)
    }
  },
  selectTypeContenu({ commit }, typeContenu){
    commit('setTypeContenu', typeContenu)
  }
}