import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addParticipationPlacements(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.participationPlacements = [ ...content ]
  },
  addParticipationPlacement(state, participationPlacement){
    state.participationPlacements = [ participationPlacement, ...state.participationPlacements]
  },
  replaceParticipationPlacement(state, participationPlacement){
    state.participationPlacements = state.participationPlacements.map(struc => struc.id === participationPlacement.id ? participationPlacement : struc )
  },
  removeParticipationPlacement(state, participationPlacement){
    state.participationPlacements = state.participationPlacements.filter(struc => struc.id !== participationPlacement.id)
  },
  setParticipationPlacement(state, participationPlacement){
    state.participationPlacement = participationPlacement
  },
}