// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

export const actions = {
  fetchParametres({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/settings?size=${pageSize}&page=${pageNumber}`).then(
      ({ data }) => {
        commit("addParametres", data);
        return data
      }
    );
  },
  fetchParametre({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/settings/1?size=${pageSize}&page=${pageNumber}`).then(
      ({ data }) => {
        // commit("addParametre", data);
        return data
      }
    );
  },
  createParametre({ commit }, parametre){
    return post("/settings", parametre).then(({ data }) => {
      commit("addParametre", data);
    });
  },
  updateParametre({ commit }, parametre){
    return new Promise((resolve, reject) => {
      put(`/settings/${parametre.id}`, parametre)
      .then(({ data }) =>{
        commit('replaceParametre', data)
        resolve(data)

      }).catch(err => reject(err))
    })
  },
  deleteParametre({ commit }, parametre){
    return remove(`/settings/${parametre.id}`, parametre).then(() => {
      commit("removeParametre", parametre);
    });
  },
  createOrUpdateParametre({ dispatch }, parametre){
    if(parametre.id !== undefined){
      return dispatch('updateParametre', parametre)
    }
    else {
      return dispatch('createParametre', parametre)
    }
  },
  selectParametre({ commit }, parametre){
    commit('setParametre', parametre)
  }
}