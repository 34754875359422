// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

export const actions = {
  fetchGrilles({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/grilles?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=grille`)
    .then(({ data }) =>{
      commit('addGrilles', data)
    })
  },
  fetchGrilleModules({ state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size, grilleId } = params
    if(grilleId == null) throw Error(`'grilleId' not provided in 'grille' store 'fetchGrilleModules'`)
    return new Promise((resolv, reject) => {
      return get(`grilles/${grilleId}/modules?size=${pageSize}&page=${pageNumber}&omit=grille&sort=id,asc`)
      .then(({ data }) =>{
        // commit('addGrilles', data)
        const { content } = data
        resolv(content)
      }).catch(err => reject(err))
    })
  },
  createGrille({ commit }, grille){
    return post('/grilles', grille)
    .then(({ data }) => {
      commit('addGrille', data)
    })
  },
  updateGrille({ commit }, grille){
    return put(`/grilles/${grille.id}`, grille)
    .then(({ data }) =>{
      commit('replaceGrille', data)
    })
  },
  deleteGrille({ commit }, grille){
    return remove(`/grilles/${grille.id}`, grille)
    .then(() =>{
      commit('removeGrille', grille)
    })
  },
  createOrUpdateGrille({ dispatch }, grille){
    if(grille.id !== undefined){
      return dispatch('updateGrille', grille)
    }
    else {
      return dispatch('createGrille', grille)
    }
  },
  selectGrille({ commit }, grille){
    commit('setGrille', grille)
  }
}