import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAgents(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.agents = [ ...content ]
  },
  addAgentWithStatistics(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.agentWithStatistics = [ ...content ]
  },
  addAgent(state, agent){
    state.agents = [ ...state.agents, agent ]
  },
  replaceAgent(state, agent){
    state.agents = state.agents.map(struc => struc.id === agent.id ? agent : struc )
  },
  setAgent(state, agent){
    state.agent = agent
  },
}