export const getters = {
  placements(state){
    return state.placements
  },
  placement(state){
    return state.placement
  },
  pagination(state){
    return state.pagination
  }
}