import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  adddossiers(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.dossiers = [ ...content ]
  },
  adddossier(state, dossier){
    state.dossiers = [ ...state.dossiers, dossier ]
  },
  replacedossier(state, dossier){
    state.dossiers = state.dossiers.map(struc => struc.id === dossier.id ? dossier : struc )
  },
  setdossier(state, dossier){
    state.dossier = dossier
  },
}