import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPlanFormations(state, data){
    state.pagination = extractPaginationInfo(data)
    state.planFormations = [...data.content] 
  },
  addPlanFormation(state, planFormation){
    state.planFormations = [ ...state.planFormations, planFormation ]
  },
  replacePlanFormation(state, planFormation){
    state.planFormations = state.planFormations.map(struc => struc.id === planFormation.id ? planFormation : struc )
  },
  removePlanFormation(state, planFormation){
    state.planFormations = state.planFormations.filter(cand => cand.id !== planFormation.id)
  },
  setPlanFormation(state, planFormation){
    state.planFormation = planFormation
  },
  setPlanFormationEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}