import { post, put, get} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchOffres({ commit, state }, params={}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/offresExternes?${queryParams}`).then(({ data }) => {
      commit("addOffres", data);
    });
  },

  fetchOffres2({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(
      `/offresExternes?size=${pageSize}&page=${pageNumber}&sort=libelle`
    ).then(({ data }) => {
      commit("addConsignes", data);
      return data;
    });
  },

  fetchOffresPublished({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/offresExternes/published?${queryParams}&sort=id,desc`).then(({ data }) => {
      commit("addOffres", data);
      return data;
    });
  },
  fetchOffreExterneSharableMesage({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/offresExternes/sharableMessage?${queryParams}`).then(({ data }) => {
      return data
    });
  },
  getOneOffrePublished({ commit },offreId){
    console.log(offreId)
    return get(`/offresExternes/pushised/one/${offreId}`).then(({ data }) => {
      commit("setOffre", data);
      return data;
    });
  },
  getOneOffre({ commit },offreId){
    console.log(offreId)
    return get(`/offresExternes/${offreId}`).then(({ data }) => {
      commit("setOffre", data);
      return data;
    });
  },
  createOffre({ commit }, offre){
    return post("/offresExternes", offre).then(({ data }) => {
      commit("addOffre", data);
    });
  },
  updateOffre({ commit }, offre){
    return put(`/offresExternes/${offre.id}`, offre).then(({ data }) => {
      commit("replaceOffre", data);
      return data;
    });
  },
  createOrUpdateOffre({ dispatch }, offre){
    if(offre.id !== undefined){
      return dispatch('updateOffre', offre)
    }
    else {
      return dispatch('createOffre', offre)
    }
  },
  selectoffre({ commit }, offre){
    commit('setOffre', offre)
  },
  validationOffre({ commit }, offre){
    return put(`/offresExternes/validation/${offre.id}`, offre).then(
      ({ data }) => {
        commit("replaceOffre", data);
        return data;
      }
    );
  },
  publierMesOffres({ commit }, offre){
    return put(`/offresExternes/publierMesOffres`, offre).then(
      ({ data }) => {
        return data;
      }
    );
  },
}