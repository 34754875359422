import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypeActivites(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typeActivites = [ ...content ]
  },
  addTypeActivite(state, typeActivite){
    state.typeActivites = [ typeActivite, ...state.typeActivites]
  },
  replaceTypeActivite(state, typeActivite){
    state.typeActivites = state.typeActivites.map(struc => struc.id === typeActivite.id ? typeActivite : struc )
  },
  removeTypeActivite(state, typeActivite){
    state.typeActivites = state.typeActivites.filter(struc => struc.id !== typeActivite.id)
  },
  setTypeActivite(state, typeActivite){
    state.typeActivite = typeActivite
  },
}