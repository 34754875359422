import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addEntrepriseSelectors(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.entrepriseSelectors = [ ...content ]
  },
  addEntrepriseSelector(state, entrepriseSelector){
    state.entrepriseSelectors = [ ...state.entrepriseSelectors, entrepriseSelector ]
  },
  replaceEntrepriseSelector(state, entrepriseSelector){
    state.entrepriseSelectors = state.entrepriseSelectors.map(struc => struc.id === entrepriseSelector.id ? entrepriseSelector : struc )
  },
  setEntrepriseSelector(state, entrepriseSelector){
    state.entrepriseSelector = entrepriseSelector
  },
}