// import axios from 'axios'
import { post, put, get, downloadFile } from '@/api/api'
import ShortUniqueId from 'short-unique-id';
// import { FileService, } from '../../api/file-service';
import { API_URL } from '../../config/api-config'
import { buildQueryParams } from '../../utils/pagination-util'
const uid = new ShortUniqueId({ length: 10 });


export const actions = {
  fetchRechercherProfils({ commit, state, rootState }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/demandeurs/rechercherProfils?${queryParams}`)
    .then(({ data }) => {
      commit('addRechercherProfils', data)
    }).catch(err => console.error(err))
  },
}