// import axios from 'axios'
import { post, put, get} from '@/api/api'
import ShortUniqueId from 'short-unique-id';
const uid = new ShortUniqueId({ length: 10 });


export const actions = {
  fetchDossiers({ commit, state }, params = {}){
    let { pageNumber=0, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    return get(`/dossiers?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addDossiers', data)
    })
  },
  createDossier({ commit }, dossier){
    return post('/dossiers', dossier)
    .then(({ data }) =>{
      commit('addDossier', data)
    })
  },
  registerDossier({ commit }, dossier){
    // /oauth/register/dossier
    return post('/dossiers', {
      ...dossier,
      password: uid(),
      courriel: dossier.courriel
    })
    .then(({ data }) =>{
      commit('addDossier', data)
    })
  },
  updateDossier({ commit }, dossier){
    return put(`/dossiers/${dossier.id}`, dossier)
    .then(({ data }) =>{
      commit('replaceDossier', data)
    })
  },
  createOrUpdateDossier({ dispatch }, dossier){
      dossier = {
        ...dossier,
        courriel: dossier.courriel
      }
      return dispatch('registerDossier', dossier)
    // if(dossier.id !== undefined){
    //   return dispatch('updateDossier', dossier)
    // }
    // else {
    //   return dispatch('createDossier', dossier)
    // }
  },
  selectDossier({ commit }, dossier){
    commit('setDossier', dossier)
  }
}