// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchSourceInfos({ commit }){
    return get('/sourceInfos')
    .then(({ data }) =>{
      commit('addSourceInfos', data)
    })
  },
  createSourceInfo({ commit }, sourceInfo){
    return post('/sourceInfos', sourceInfo)
    .then(({ data }) =>{
      commit('addSourceInfo', data)
    })
  },
  updateSourceInfo({ commit }, sourceInfo){
    return put(`/sourceInfos/${sourceInfo.id}`, sourceInfo)
    .then(({ data }) =>{
      commit('replaceSourceInfo', data)
    })
  },
  createOrUpdateSourceInfo({ dispatch }, sourceInfo){
    if(sourceInfo.id !== undefined){
      return dispatch('updateSourceInfo', sourceInfo)
    }
    else {
      return dispatch('createSourceInfo', sourceInfo)
    }
  },
  selectSourceInfo({ commit }, sourceInfo){
    commit('setSourceInfo', sourceInfo)
  }
}