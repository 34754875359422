export const state = {
  dossier: undefined,
  dossiers: [],
  editingBock: false,
  pagination: { size: 10 },
  editableItem: {
    nom: null,
    prenom: null,
    sexe: null,
    email: null,
    adresse: null,
    lieuNaissance: null,
    paysNaissance: null,
    niveauInstruction: null,
    disponible: null,
    souscontrat: null,
    dejaInscrit: null,
    estInstruit: null,
    publicProfil: true,
    ifu: null,
    npi: null,
    demandeurActif: true,
    dateNais: null,
    codePostal: null,
    password: null,
    password1: null,
    commune: null,
    departement: null,
    phone: null,
  },
  filter: {
    query: ''
  }
}