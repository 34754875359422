import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchPayementLiquidations({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/payementLiquidations?${queryParams}`)
    .then(({ data }) =>{
      commit('addPayementLiquidations', data)
      return data
    })
  },
  fetchPayementLiquidation({ commit, state }, params = {}){
    let { payementLiquidationId } = params
    return get(`/payementLiquidations/${payementLiquidationId}`)
    .then(({ data }) =>{
      commit('setPayementLiquidation', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { payementLiquidationId } = params
    return downloadFileWithAxiosPost(`/payementLiquidations/exportToPdf`, 'file.pdf', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setPayementLiquidation', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { payementLiquidationId } = params
    return downloadFileWithAxiosPost(`/payementLiquidations/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setPayementLiquidation', data)
      return data
    })
  },
  createPayementLiquidation({ commit }, payementLiquidation){
    return post('/payementLiquidations', payementLiquidation)
    .then(({ data }) =>{
      commit('addPayementLiquidation', data)
      return data
    })
  },
  genererListeDePresence({ commit }, params){
    return post('/payementLiquidations/genererListePresence', params)
    .then(({ data }) =>{
      commit('addPayementLiquidations', data)
      return data
    })
  },
  updatePayementLiquidation({ commit }, payementLiquidation){
    return put(`/payementLiquidations/${payementLiquidation.id}`, payementLiquidation)
    .then(({ data }) =>{
      commit('replacePayementLiquidation', data)
      commit('setPayementLiquidation', data)

      return data
    })
  },
  createOrUpdatePayementLiquidation({ dispatch }, payementLiquidation){
    if(payementLiquidation.id !== undefined){
      return dispatch('updatePayementLiquidation', payementLiquidation)
    }
    else {
      return dispatch('createPayementLiquidation', payementLiquidation)
    }
  },
  selectPayementLiquidation({ commit }, payementLiquidation){
    commit('setPayementLiquidation', payementLiquidation)
  }
}