import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addOrganismeFonctions(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.organismeFonctions = [ ...content ]
  },
  addOrganismeFonction(state, organismeFonction){
    state.organismeFonctions = [ ...state.organismeFonctions, organismeFonction ]
  },
  replaceOrganismeFonction(state, organismeFonction){
    state.organismeFonctions = state.organismeFonctions.map(struc => struc.id === organismeFonction.id ? organismeFonction : struc )
  },
  setOrganismeFonction(state, organismeFonction){
    state.organismeFonction = organismeFonction
  },
}