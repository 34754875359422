import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addProfilEntrepreneurials(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.profilEntrepreneurials = [ ...content ]
  },
  addProfilEntrepreneurial(state, profilEntrepreneurial){
    state.profilEntrepreneurials = [ profilEntrepreneurial, ...state.profilEntrepreneurials]
  },
  replaceProfilEntrepreneurial(state, profilEntrepreneurial){
    state.profilEntrepreneurials = state.profilEntrepreneurials.map(struc => struc.id === profilEntrepreneurial.id ? profilEntrepreneurial : struc )
  },
  removeProfilEntrepreneurial(state, profilEntrepreneurial){
    state.profilEntrepreneurials = state.profilEntrepreneurials.filter(struc => struc.id !== profilEntrepreneurial.id)
  },
  setProfilEntrepreneurial(state, profilEntrepreneurial){
    state.profilEntrepreneurial = profilEntrepreneurial
  },
}