import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchEvaluationComportementales({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/evaluationComportementales?${queryParams}`)
    .then(({ data }) =>{
      commit('addEvaluationComportementales', data)
      return data
    })
  },
  fetchEvaluationComportementale({ commit, state }, params = {}){
    let { evaluationComportementaleId } = params
    return get(`/evaluationComportementales/${evaluationComportementaleId}`)
    .then(({ data }) =>{
      commit('setEvaluationComportementale', data)
      return data
    })
  },
  exportToPdf({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/evaluationComportementales/exportToPdf?${queryParams}`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
        // 'N° CONTRAT' : 'participationPlacement.placement.contract.numeroContrat',
        // 'DATE PLACEMENT' : '',
        'N° PLACEMENT' : 'participationPlacement.placement.reference',
        'MOIS POINT DE PRESENCE' : 'pointPresence.moisAnnee',
        'PERIODE DU' : 'pointPresence.dateDu',
        'PERIODE AU' : 'pointPresence.dateAu',
      },
    })
    .then(({ data }) =>{
      commit('setEvaluationComportementale', data)
      return data
    })
  },
  exportToExcel({ commit, state  }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/evaluationComportementales/exportToExcel?${queryParams}`, 'file.xlsx', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setEvaluationComportementale', data)
      return data
    })
  },
  createEvaluationComportementale({ commit }, evaluationComportementale){
    return post('/evaluationComportementales', evaluationComportementale)
    .then(({ data }) =>{
      commit('addEvaluationComportementale', data)
      return data
    })
  },
  genererEvaluationComportementales({ commit }, { placement }){
    return post('/evaluationComportementales/genererEvaluationComportementale', {
      placement
    })
    .then(({ data }) =>{
      commit('addEvaluationComportementales', data)
      return data
    })
  },
  updateEvaluationComportementale({ commit }, evaluationComportementale){
    return put(`/evaluationComportementales/${evaluationComportementale.id}`, evaluationComportementale)
    .then(({ data }) =>{
      commit('replaceEvaluationComportementale', data)
      commit('setEvaluationComportementale', data)

      return data
    })
  },
  createOrUpdateEvaluationComportementale({ dispatch }, evaluationComportementale){
    if(evaluationComportementale.id !== undefined){
      return dispatch('updateEvaluationComportementale', evaluationComportementale)
    }
    else {
      return dispatch('createEvaluationComportementale', evaluationComportementale)
    }
  },
  selectEvaluationComportementale({ commit }, evaluationComportementale){
    commit('setEvaluationComportementale', evaluationComportementale)
  }
}