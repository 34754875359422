export const getters = {
  azoliPieces(state){
    return state.azoliPieces
  },
  communes(state){
    return state.communes
  },
  azoliPiece(state){
    return state.azoliPiece
  }
}