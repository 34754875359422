import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addOffres(state, data){
    state.pagination = extractPaginationInfo(data)
    state.offres = [ ...data.content ]
  },
  addOffre(state, offre){
    state.offres = [offre, ...state.offres ]
  },
  replaceOffre(state, offre){
    state.offres = state.offres.map(struc => struc.id === offre.id ? offre : struc )
  },
  setOffre(state, offre){
    state.offre = offre
  },
}