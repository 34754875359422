export const mutations = {
  addNiveaux(state, niveaux){
    state.niveaux = [ ...niveaux ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addNiveau(state, niveau){
    state.niveaux = [ ...state.niveaux, niveau ]
  },
  replaceNiveau(state, niveau){
    state.niveaux = state.niveaux.map(struc => struc.id === niveau.id ? niveau : struc )
  },
  setNiveau(state, niveau){
    state.niveau = niveau
  },
}