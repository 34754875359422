import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypeConsignes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typeConsignes = [ ...content ]
  },
  addTypeConsigne(state, typeConsigne){
    state.typeConsignes = [ typeConsigne, ...state.typeConsignes]
  },
  replaceTypeConsigne(state, typeConsigne){
    state.typeConsignes = state.typeConsignes.map(struc => struc.id === typeConsigne.id ? typeConsigne : struc )
  },
  removeTypeConsigne(state, typeConsigne){
    state.typeConsignes = state.typeConsignes.filter(struc => struc.id !== typeConsigne.id)
  },
  setTypeConsigne(state, typeConsigne){
    state.typeConsigne = typeConsigne
  },
}