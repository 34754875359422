export const getters = {
  chiffreAffaires(state){
    return state.chiffreAffaires
  },
  communes(state){
    return state.communes
  },
  chiffreAffaire(state){
    return state.chiffreAffaire
  }
}