<template>
  <select
    :value="innerValue"
    v-bind="$attrs"
    :disabled="disabled"
    v-on="{
      ...$listeners,
      input: onInput,
    }"
  >
    <slot>
      <!-- <option v-if="" :value="emptyValue" class="text-muted">Sélectionner</option> -->
      <option
        :disabled="disabled"
        :value="optionInnerValue(opt)"
        v-for="(opt, i) in options"
        :key="i"
      >
        {{ opt[optionLabel] }}
      </option>
    </slot>
  </select>
</template>

<script>
export default {
  props: {
    options: [],
    value: null,
    optionValue: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    optionLabel: null,
    emitObject: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    innerValue(val) {
      return this.value instanceof Object ? this.value[this.optionValue] : this.value;
    },
    emptyValue() {
      return { [this.optionValue]: null, [this.optionLabel]: `Sélectionner` };
    },
  },
  methods: {
    optionInnerValue(opt) {
      return opt instanceof Object ? opt[this.optionValue] : opt;
    },
    optionInnerLabel(opt) {
      return opt instanceof Object ? opt[this.optionLabel] : opt;
    },
    onInput(e) {
      if (this.emitObject) {
        this.$emit("input", this.options[e.target.selectedIndex]);
      } else {
        this.$emit("input", this.options[e.target.selectedIndex][this.optionValue]);
      }
    },
  },
};
</script>
