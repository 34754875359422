import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTageActivites(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.tageActivites = [ ...content ]
  },
  addTageActivite(state, tageActivite){
    state.tageActivites = [ tageActivite, ...state.tageActivites]
  },
  replaceTageActivite(state, tageActivite){
    state.tageActivites = state.tageActivites.map(struc => struc.id === tageActivite.id ? tageActivite : struc )
  },
  removeTageActivite(state, tageActivite){
    state.tageActivites = state.tageActivites.filter(struc => struc.id !== tageActivite.id)
  },
  setTageActivite(state, tageActivite){
    state.tageActivite = tageActivite
  },
}