// import axios from 'axios'
import { post, put, get} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchAgents({ commit, state }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    
    return get(`/agents?${queryParams}&size=${pageSize}&page=${pageNumber}&sort=nom&`)
    .then(({ data }) =>{
      commit('addAgents', data)
      return data
    })
  },
  fetchAgent({ commit, state }, params = {}){
    const  { agentId } = params;
    if(agentId == undefined) throw new Error("Missing param 'agentId'.")
    return get(`/agents/${agentId}`)
    .then(({ data }) =>{
      return data
    })
  },
  fetchAntenneAgents({ commit, state, rootState }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    queryParams = queryParams !== undefined? queryParams.replace('?','') : ''
    console.log(rootState)
    return get(`/agents?${queryParams}&size=${pageSize}&page=${pageNumber}&sort=nom&`)
    .then(({ data }) =>{
      commit('addAgents', data)
    }).catch(err => console.error(err))
  },
  fetchAntenneAgentConseillers({ commit, state, rootState }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    
    // let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    // queryParams = queryParams !== undefined? queryParams.replace('?','') : ''
    // return get(`/agents/conseillers?${queryParams}&fonctionIn=CA,CE,ULPE&page=${pageNumber}&sort=nom&`)
    return get(`/agents/conseillers?${queryParams}&fonctionIn=CA,CE,ULPE&sort=nom&`)
    .then(({ data }) =>{
      commit('addAgents', data)
    }).catch(err => console.error(err))
  },
  fetchAntenneAgentConseillerWithEntrepriseStatistics({ commit, state, rootState }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    queryParams = queryParams !== undefined? queryParams.replace('?','') : ''
    return get(`/agents/conseillerWithEntrepriseStatistics?${queryParams}&fonctionIn=CA,CE,ULPE&page=${pageNumber}&sort=nom&`)
    .then(({ data }) =>{
      commit('addAgentWithStatistics', data)
    }).catch(err => console.error(err))
  },
  fetchAntenneAgentConseillerWithDeStatistics({ commit, state, rootState }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    queryParams = queryParams !== undefined? queryParams.replace('?','') : ''
    // console.log(rootState, state, params)
    return get(`/agents/conseillerWithDeStatistics?${queryParams}&fonctionIn=CA,CE,ULPE&page=${pageNumber}&sort=nom&`)
    .then(({ data }) =>{
      commit('addAgentWithStatistics', data)
    }).catch(err => console.error(err))
  },
  createAgent({ commit }, agent){
    return post('/agents', agent)
    .then(({ data }) =>{
      commit('addAgent', data)
    })
  },
  registerAgent({ commit }, agent){
    return post('/agents', {
      ...agent,
      courriel: agent.courriel
    })
    .then(({ data }) =>{
      commit('addAgent', data)
    })
  },
  resetAgentAccount({ commit }, agents){

    return put('/agents/resetAccount', agents)
    .then(({ data }) =>{
      commit('addAgent', data)
    })
  },
  updateAgent({ commit }, agent){
    return put(`/agents/${agent.id}`, agent)
    .then(({ data }) =>{
      commit('replaceAgent', data)
    })
  },
  createOrUpdateAgent({ dispatch }, agent){
      agent = {
        ...agent,
        courriel: agent.courriel
      }
      // return  dispatch('registerAgent', agent)
    if(agent.id !== undefined){
      return dispatch('updateAgent', agent)
    }
    else {
      return dispatch('createAgent', agent)
    }
  },
  selectAgent({ commit }, agent){
    commit('setAgent', agent)
  }
}