export const getters = {
  presencePostes(state){
    return state.presencePostes
  },
  presencePoste(state){
    return state.presencePoste
  },
  pagination(state){
    return state.pagination
  }
}