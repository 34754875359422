export const getters = {
  participationProgrammes(state){
    return state.participationProgrammes
  },
  participationProgramme(state){
    return state.participationProgramme
  },
  pagination(state){
    return state.pagination
  }
}