import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addProgrammes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.programmes = [ ...content ]
  },
  addProgramme(state, programme){
    state.programmes = [ ...state.programmes, programme ]
  },
  replaceProgramme(state, programme){
    state.programmes = state.programmes.map(struc => struc.id === programme.id ? programme : struc )
  },
  setProgramme(state, programme){
    state.programme = programme
  },
}