export const mutations = {
  setStampStatistics(state, stampStatistics){
    state.stampStatistics = stampStatistics instanceof Array? [...stampStatistics] : stampStatistics
  },
  setActeStampStatistics(state, acteStampStatistics){
    state.acteStampStatistics = acteStampStatistics instanceof Array? [...acteStampStatistics] : acteStampStatistics
  },
  setActiveStructure(state, activeStructure){
    state.activeStructure = activeStructure instanceof Array? [...activeStructure] : activeStructure
  },
  setDeCount(state, deCountData){
    const deCount = { }
    let total = 0
    deCountData.forEach(el => {
      deCount[el[0]]=el[1]
      total += el[1]
    })
    deCount['TOTAL'] = total
    state.deCount = deCount
  },
  setActiviteCount(state, activiteCountData){
    const activiteCount = { }
    let total = 0
    activiteCountData.forEach(el => {
      activiteCount[el[0]]=el[1]
      total += el[1]
    })
    activiteCount['TOTAL'] = total
    state.activiteCount = activiteCount
  }
  // setActiviteCount(state, activiteCountData){
  //   const activiteCount = { }
  //   let total = 0
  //   activiteCountData.forEach(el => {
  //     activiteCount[el[0]]=el[1]
  //     total += el[1]
  //   })
  //   activiteCount['TOTAL'] = total
  //   state.activiteCount = activiteCount
  // }
}