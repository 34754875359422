export const getters = {
  constats(state){
    return state.constats
  },

  constat(state){
    return state.constat
  },
  pagination(state){
    return state.pagination
  }
}