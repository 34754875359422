export const getters = {
  pointPresences(state){
    return state.pointPresences
  },
  pointPresence(state){
    return state.pointPresence
  },
  pagination(state){
    return state.pagination
  }
}