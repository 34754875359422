// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
    fetchAppreciationAptitudes({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/appreciationAptitudes?${queryParams}`)
    .then(({ data }) =>{
      commit('addAppreciationAptitudes', data)
      return data.content
    })
  },
  fetchAppreciationAptitude({ commit, state }, params = {}){
    let { appreciationAptitudeId } = params
    return get(`/appreciationAptitudes/${appreciationAptitudeId}`)
    .then(({ data }) =>{
      // commit('addAppreciationAptitude', data)
      return data
    })
  },
//   fetchAppreciationAptitudeByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/appreciationAptitudes/${appreciationAptitudeId}/`)
//     .then(({ data }) =>{
//       // commit('addAppreciationAptitude', data)
//       return data
//     })
//   },
  createAppreciationAptitude({ commit }, appreciationAptitude){
    return post('/appreciationAptitudes', appreciationAptitude)
    .then(({ data }) => {
      commit('addAppreciationAptitude', data)
      return data
    })
  },
  updateAppreciationAptitude({ commit }, appreciationAptitude){
    return put(`/appreciationAptitudes/${appreciationAptitude.id}`, appreciationAptitude)
    .then(({ data }) =>{
      commit('replaceAppreciationAptitude', data)
      return data
    })
  },
  deleteAppreciationAptitude({ commit }, appreciationAptitude){
    return remove(`/appreciationAptitudes/${appreciationAptitude.id}`, appreciationAptitude)
    .then(() =>{
      commit('removeAppreciationAptitude', appreciationAptitude)
    })
  },
  validerAppreciationAptitude({ commit }, appreciationAptitude){
      return put(`/appreciationAptitudes/${appreciationAptitude.id}/valider`, appreciationAptitude)
      .then(({ data }) =>{
        commit('replaceAppreciationAptitude', data)
        return data
      })
    },
  createOrUpdateAppreciationAptitude({ dispatch }, appreciationAptitude){
    if(appreciationAptitude.id !== undefined){
      return dispatch('updateAppreciationAptitude', appreciationAptitude)
    }
    else {
      return dispatch('createAppreciationAptitude', appreciationAptitude)
    }
  },
  selectAppreciationAptitude({ commit }, appreciationAptitude){
    commit('setAppreciationAptitude', appreciationAptitude)
  }
}