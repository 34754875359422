// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchAtouts({ commit }){
    return get('/atouts')
    .then(({ data }) =>{
      commit('addAtouts', data)
    })
  },
  createAtout({ commit }, atout){
    return post('/atouts', atout)
    .then(({ data }) =>{
      commit('addAtout', data)
    })
  },
  updateAtout({ commit }, atout){
    return put(`/atouts/${atout.id}`, atout)
    .then(({ data }) =>{
      commit('replaceAtout', data)
    })
  },
  createOrUpdateAtout({ dispatch }, atout){
    if(atout.id !== undefined){
      return dispatch('updateAtout', atout)
    }
    else {
      return dispatch('createAtout', atout)
    }
  },
  selectAtout({ commit }, atout){
    commit('setAtout', atout)
  },

  getAuditByEntreprise({ commit }, id){
    return get(`/atouts/aut/${id}`)
    .then(({ data }) =>{
      commit('addAtouts', data)
      return data
    })
  },

  getAtoutsByEntreprise({ commit }, id){
    return get(`/atouts/entreprise/${id}`)
    .then(({ data }) =>{
      commit('addAtouts', data)
      return data
    })
  },

}