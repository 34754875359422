import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchPhotoConstats({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/photoConstats?${queryParams}`)
    .then(({ data }) =>{
      commit('addPhotoConstats', data)
      return data
    })
  },
  fetchPhotoConstatsByConstat({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let {constatId} = params
    return get(`/photoConstats?${queryParams}&constatId=${constatId}`)
    .then(({ data }) =>{
      commit('addPhotoConstats', data)
      return data
    })
  },
  fetchPhotoConstat({ commit, state }, params = {}){
    let { photoConstatId } = params
    return get(`/photoConstats/${photoConstatId}`)
    .then(({ data }) =>{
      commit('setPhotoConstat', data)
      return data
    })
  },
  createPhotoConstat({ commit }, photoConstat){
    return post('/photoConstats', photoConstat)
    .then(({ data }) =>{
      commit('addPhotoConstat', data)
      return data
    })
  },
  updatePhotoConstat({ commit }, photoConstat){
    return put(`/photoConstats/${photoConstat.id}`, photoConstat)
    .then(({ data }) =>{
      commit('replacePhotoConstat', data)
      commit('setPhotoConstat', data)

      return data
    })
  },
  createOrUpdatePhotoConstat({ dispatch }, photoConstat){
    if(photoConstat.id !== undefined){
      return dispatch('updatePhotoConstat', photoConstat)
    }
    else {
      return dispatch('createPhotoConstat', photoConstat)
    }
  },
  selectPhotoConstat({ commit }, photoConstat){
    commit('setPhotoConstat', photoConstat)
  }
}