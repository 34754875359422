import { TokenService } from '@/api/localStorage'

let userRoles = TokenService.getTokenInfo().roles || []
let roleName = userRoles.length > 0? userRoles[0].slice(5, userRoles[0].length).toLowerCase() : ''

export const MENU_DEMANDEUR = [
  {
    to: { name: "espace.de.home" },
    label: "Accueil",
    icon: "fa fa-home",
  },
  {
    to: { name: "espace.de.moncv" },
    label: "Mon Dossier(CV/Fiche enquête)",
  },
  {
    to: { name: "espace.common.candidature.list", params: { userRole: roleName } },
    label: "Mes candidatures",
  },
  {
    to: { name: "espace.de.recherche-emplois", params: { userRole: roleName } },
    label: "Les offres d'emploi",
  },
  {
    to: { name: "espace.de.signature" }, //, params: { userRole: "de" }
    label: "Dépôt de signature",
  },
];