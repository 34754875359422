import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addParticipationProgrammes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.participationProgrammes = [ ...content ]
  },
  addParticipationProgramme(state, participationProgramme){
    state.participationProgrammes = [ participationProgramme, ...state.participationProgrammes]
  },
  replaceParticipationProgramme(state, participationProgramme){
    state.participationProgrammes = state.participationProgrammes.map(struc => struc.id === participationProgramme.id ? participationProgramme : struc )
  },
  removeParticipationProgramme(state, participationProgramme){
    state.participationProgrammes = state.participationProgrammes.filter(struc => struc.id !== participationProgramme.id)
  },
  setParticipationProgramme(state, participationProgramme){
    state.participationProgramme = participationProgramme
  },
}