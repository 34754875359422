import { caseConverter } from "../../utils/case-convert";

export const stateBuilder = (storeName) => {
    // const capitalizedStoreName = caseConverter.toCaptilizedCamelCase(storeName);
    const lowerCaseStoreName = caseConverter.toLowercaseCamelCase(storeName);
    const state = {
        [lowerCaseStoreName]: undefined,
        [`${lowerCaseStoreName}s`]: [],
        pagination: { size: 25 },
        filter: {
            query: "",
        },
    };
    return state;
};
