import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addZoneVulnerables(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.zoneVulnerables = [ ...content ]
  },
  addZoneVulnerable(state, zoneVulnerable){
    state.zoneVulnerables = [ ...state.coneVulnerables, zoneVulnerable ]
  },
  replaceZoneVulnerable(state, zoneVulnerable){
    state.coneVulnerables = state.zoneVulnerables.map(struc => struc.id === zoneVulnerable.id ? zoneVulnerable : struc )
  },
  setZoneVulnerable(state, zoneVulnerable){
    state.zoneVulnerable = zoneVulnerable
  },
}