// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchNatureContrats({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/natureContrats?size=${pageSize}&page=${pageNumber}&sort=libelle,asc`)
    .then(({ data }) =>{
      commit('addNatureContrats', data)
    })
  },
  createNatureContrat({ commit }, natureContrat){
    return post('/natureContrats', natureContrat)
    .then(({ data }) => {
      commit('addNatureContrat', data)
    })
  },
  updateNatureContrat({ commit }, natureContrat){
    return put(`/natureContrats/${natureContrat.id}`, natureContrat)
    .then(({ data }) =>{
      commit('replaceNatureContrat', data)
    })
  },
  deleteNatureContrat({ commit }, natureContrat){
    return remove(`/natureContrats/${natureContrat.id}`, natureContrat)
    .then(() =>{
      commit('removeNatureContrat', natureContrat)
    })
  },
  createOrUpdateNatureContrat({ dispatch }, natureContrat){
    if(natureContrat.id !== undefined){
      return dispatch('updateNatureContrat', natureContrat)
    }
    else {
      return dispatch('createNatureContrat', natureContrat)
    }
  },
  selectNatureContrat({ commit }, natureContrat){
    commit('setNatureContrat', natureContrat)
  }
}