import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addEntreprises(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.entreprises = [ ...content ]
  },
  addEntreprise(state, entreprise){
    state.entreprises = [ ...state.entreprises, entreprise ]
  },
  replaceEntreprise(state, entreprise){
    state.entreprises = state.entreprises.map(struc => struc.id === entreprise.id ? entreprise : struc )
  },
  setEntreprise(state, entreprise){
    state.entreprise = entreprise
  },
}