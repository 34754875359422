import { caseConverter } from "../../utils/case-convert";

export const gettersBuilder = (storeName) => {
    const lowerCaseStoreName = caseConverter.toLowercaseCamelCase(storeName)
    const getters = {
        [`${lowerCaseStoreName}s`]: (state)  =>{
            return state[`${lowerCaseStoreName}s`];
        },
        [`${lowerCaseStoreName}`](state) {
            return state[lowerCaseStoreName];
        },
        pagination(state) {
            return state.pagination;
        },
    };

    return getters;
};
