// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchCategories({ commit, state }, params = {}){
    console.clear()
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/categories?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=categorie`)
    .then(({ data }) =>{
      commit('addCategories', data)
    })
  },
  createCategorie({ commit }, categorie){
    return post('/categories', categorie)
    .then(({ data }) =>{
      commit('addCategorie', data)
    })
  },
  updateCategorie({ commit }, categorie){
    return put(`/categories/${categorie.id}`, categorie)
    .then(({ data }) =>{
      commit('replaceCategorie', data)
    })
  },
  createOrUpdateCategorie({ dispatch }, categorie){
    if(categorie.id !== undefined){
      return dispatch('updateCategorie', categorie)
    }
    else {
      return dispatch('createCategorie', categorie)
    }
  },
  selectCategorie({ commit }, categorie){
    commit('setCategorie', categorie)
  }
}