// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchEthnies({ commit, state }, params = {}){
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/ethnies?size=${pageSize}&page=${pageNumber}&sort=nom`)
    .then(({ data }) =>{
      commit('addEthnies', data)
    })
  },
  createEthnie({ commit }, ethnie){
    return post('/ethnies', ethnie)
    .then(({ data }) =>{
      commit('addEthnie', data)
    })
  },
  updateEthnie({ commit }, ethnie){
    return put(`/ethnies/${ethnie.id}`, ethnie)
    .then(({ data }) =>{
      commit('replaceEthnie', data)
    })
  },
  createOrUpdateEthnie({ dispatch }, ethnie){
    if(ethnie.id !== undefined){
      return dispatch('updateEthnie', ethnie)
    }
    else {
      return dispatch('createEthnie', ethnie)
    }
  },
  selectEthnie({ commit }, ethnie){
    commit('setEthnie', ethnie)
  }
}