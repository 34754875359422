export const getters = {
  ethnies(state){
    return state.ethnies
  },
  ethnie(state){
    return state.ethnie
  },
  pagination(state){
    return state.pagination
  }
}