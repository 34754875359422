// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchRapportActivites({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return new Promise((resolv, reject) => {
      get(`/rapportActivites?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=rapportActivite`)
      .then(({ data }) =>{
        resolv(data)
        commit('addRapportActivites', data)
      }).catch(err => reject(err))
    })
  },
  createRapportActivite({ commit }, rapportActivite){
    return post('/rapportActivites', rapportActivite)
    .then((resp) => {
      commit('addRapportActivite', resp.data)
      commit('setRapportActivite', resp.data)
      return resp
    }).catch(err => Promise.reject(err))
  },
  updateRapportActivite({ commit }, rapportActivite){
    return put(`/rapportActivites/${rapportActivite.id}`, rapportActivite)
    .then((resp) =>{

      commit('replaceRapportActivite', resp.data)
      commit('setRapportActivite', resp.data)
      return resp
    }).catch(err => Promise.reject(err))
  },
  cloturerRapportActivite({ commit, dispatch }, rapportActivite){
    // return new Promise((resolv, reject) => {
      return dispatch('createOrUpdateRapportActivite', {
        ...rapportActivite,
        // activite:  { id: rapportActivite.activite instanceof Object? rapportActivite.activite.id : rapportActivite.activite  }
      }).then((rapport) => {
        return put(`/rapportActivites/${rapport.id}/cloturerRapport`, {
          ...rapport,
          // activite: rapport.activite instanceof Number? { id: rapport.activite } : rapport.activite
          // activite:  { id: rapport.activite }
        }).then(({ data }) =>{
          commit('replaceRapportActivite', data)
          commit('activitePlanifie/cloturerActivitePlanifie', data.activite, { root: true })
          commit('setRapportActivite', data)
          return data
        }).catch(err => err)
      }).catch(err => err)
      
    // })
  },
  deleteRapportActivite({ commit }, rapportActivite){
    return remove(`/rapportActivites/${rapportActivite.id}`, rapportActivite)
    .then(() =>{
      commit('removeRapportActivite', rapportActivite)
    })
  },
  createOrUpdateRapportActivite({ commit, dispatch }, rapportActivite){
    rapportActivite = {
      ...rapportActivite,
      // activite: rapportActivite.activite instanceof Number? { id: rapportActivite.activite } : rapportActivite.activite
    }
    return new Promise((resolv, reject) => {
      if(rapportActivite.id != undefined){
        return dispatch('updateRapportActivite', rapportActivite).then(({ data }) => {
          return resolv(data)
        }).catch(err => reject(err))
      }
      else {
        return dispatch('createRapportActivite', rapportActivite).then(({ data }) => {
            return resolv(data)
          }).catch(err => reject(err))
      }
    })

  },
  selectRapportActivite({ commit }, rapportActivite){
    commit('setRapportActivite', rapportActivite)
  }
}