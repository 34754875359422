export const mutations = {
  addsalaires(state, salaires){
    state.salaires = [ ...salaires ]
  },
  addsalaire(state, salaire){
    state.salaires = [ ...state.salaires, salaire ]
  },
  replacesalaire(state, salaire){
    state.salaires = state.salaires.map(struc => struc.id === salaire.id ? salaire : struc )
  },
  setsalaire(state, salaire){
    state.salaire = salaire
  },
}