import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addMissionnaires(state, data){
    state.pagination = extractPaginationInfo(data)
    state.missionnaires = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addMissionnaire(state, missionnaire){
    state.missionnaires = [ ...state.missionnaires, missionnaire ]
  },
  replaceMissionnaire(state, missionnaire){
    state.missionnaires = state.missionnaires.map(struc => struc.id === missionnaire.id ? missionnaire : struc )
  },
  removeMissionnaire(state, missionnaire){
    state.missionnaires = state.missionnaires.filter(cand => cand.id !== missionnaire.id)
  },
  setMissionnaire(state, missionnaire){
    state.missionnaire = missionnaire
  },
  setMissionnaireEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}