// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchSuiviPlacements({ commit, state }, params = {}){
    // let { pageNumber=0, pageSize=state.pagination.size } = params
    let queryParams = buildQueryParams(params, state.pagination)


    return get(`/suiviPlacements?${queryParams}`)
    .then(({ data }) =>{
      commit('addSuiviPlacements', data)
    })
  },
  createSuiviPlacement({ commit }, suiviPlacement){
    return post('/suiviPlacements', suiviPlacement)
    .then(({ data }) => {
      commit('addSuiviPlacement', data)
    })
  },
  updateSuiviPlacement({ commit }, suiviPlacement){
    return put(`/suiviPlacements/${suiviPlacement.id}`, suiviPlacement)
    .then(({ data }) =>{
      commit('replaceSuiviPlacement', data)
    })
  },
  deleteSuiviPlacement({ commit }, suiviPlacement){
    return remove(`/suiviPlacements/${suiviPlacement.id}`, suiviPlacement)
    .then(() =>{
      commit('removeSuiviPlacement', suiviPlacement)
    })
  },
  createOrUpdateSuiviPlacement({ dispatch }, suiviPlacement){
    if(suiviPlacement.id !== undefined){
      return dispatch('updateSuiviPlacement', suiviPlacement)
    }
    else {
      return dispatch('createSuiviPlacement', suiviPlacement)
    }
  },
  selectSuiviPlacement({ commit }, suiviPlacement){
    commit('setSuiviPlacement', suiviPlacement)
  }
}