import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'
import { state } from './state'
import activitePlanifie from '../activite-planifie'

export default{
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    activitePlanifie
  } 
}