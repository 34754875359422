export const getters = {
  avantageFinanciers(state){
    return state.avantageFinanciers
  },
  avantageFinancier(state){
    return state.avantageFinancier
  },
  pagination(state){
    return state.pagination
  }
}