export const getters = {
  planactions(state){
    return state.planactions
  },

  planaction(state){
    return state.planaction
  },
  pagination(state){
    return state.pagination
  }
}