// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchRomes({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/romes?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=famille`)
    .then(({ data }) =>{
      commit('addRomes', data)
    })
  },
  createRome({ commit }, rome){
    return post('/romes', rome)
    .then(({ data }) =>{
      commit('addRome', data)
    })
  },
  updateRome({ commit }, rome){
    return put(`/romes/${rome.id}`, rome)
    .then(({ data }) =>{
      commit('replaceRome', data)
    })
  },
  createOrUpdateRome({ dispatch }, rome){
    if(rome.id !== undefined){
      return dispatch('updateRome', rome)
    }
    else {
      return dispatch('createRome', rome)
    }
  },
  selectRome({ commit }, rome){
    commit('setRome', rome)
  }
}