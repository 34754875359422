export const getters = {
  atouts(state){
    return state.atouts
  },

  atout(state){
    
    return state.atout
  },
  pagination(state){
    return state.pagination
  }
}