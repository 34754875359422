// import axios from 'axios'
import { post, put, get, remove, downloadFile } from "@/api/api";
import { uploadFile } from "../../api/file-service";
import { API_URL } from "../../config/api-config";
import { buildQueryParams } from "../../utils/pagination-util";

export const actions = {
    fetchMicroprojets({ commit, state }, params = {}) {
        let queryParams = buildQueryParams(params, state.pagination);
        return get(`/microprojets?${queryParams}`).then(({ data }) => {
            commit("addMicroprojets", data);
            return data.content;
        });
    },
    fetchMicroprojet({ commit, state }, params = {}) {
        let { microprojetId } = params;
        return get(`/microprojets/${microprojetId}`).then(({ data }) => {
            // commit('addMicroprojet', data)
            return data;
        });
    },
    createMicroprojet({ commit }, microprojet) {
        return post("/microprojets", microprojet).then(({ data }) => {
            commit("addMicroprojet", data);
            return data;
        });
    },
    updateMicroprojet({ commit }, microprojet) {
        return put(`/microprojets/${microprojet.id}`, microprojet).then(
            ({ data }) => {
                commit("replaceMicroprojet", data);
                return data;
            }
        );
    },
    deleteMicroprojet({ commit }, microprojet) {
        return remove(`/microprojets/${microprojet.id}`, microprojet).then(
            () => {
                commit("removeMicroprojet", microprojet);
            }
        );
    },
    validerMicroprojet({ commit }, microprojet) {
        return put(
            `/microprojets/${microprojet.id}/statutValidation`,
            microprojet
        ).then(({ data }) => {
            commit("replaceMicroprojet", data);
            return data;
        });
    },
    retirerMicroProjet({ commit }, microprojet) {
        return put(
            `/microprojets/${microprojet.id}/retraitMicroprojet`,
            microprojet
        ).then(({ data }) => {
            commit("replaceMicroprojet", data);
            return data;
        });
    },
    updateStatutValidation({ commit }, microprojet) {
        return put(
            `/microprojets/${microprojet.id}/statutValidation`,
            microprojet
        ).then(({ data }) => {
            commit("replaceMicroprojet", data);
            return data;
        });
    },
    updateStatutTransmission({ commit }, microprojet) {
        return put(
            `/microprojets/${microprojet.id}/statutTransmission`,
            microprojet
        ).then(({ data }) => {
            commit("replaceMicroprojet", data);
            return data;
        });
    },
    createOrUpdateMicroprojet({ dispatch }, microprojet) {
        if (microprojet.id !== undefined) {
            return dispatch("updateMicroprojet", microprojet);
        } else {
            return dispatch("createMicroprojet", microprojet);
        }
    },
    selectMicroprojet({ commit }, microprojet) {
        commit("setMicroprojet", microprojet);
    },
    telechargerMicroprojet({}, params = {}) {
        return downloadFile(
            `/microprojets/${params.id}/exportToExcel`,
            `MICROPROJET_${params.titre}.xlsx`
        ).then(({ data }) => {
            return data;
        });
    },
    modifierMicroprojet({}, params = {}) {
        const { reference, file } = params;
        console.log(params)
        const data = { reference };
        return new Promise((resolv, reject) => {
            const onSuccess = (d) => {
                return resolv(d);
            };
            const onError = (err) => {
                console.log(err)
                return reject(err);
            };

            const onProgress = (val) => {
                console.log(val)
            };

            uploadFile(
                `${API_URL}/microprojets/${reference}/modifierMicroprojet`,
                file,
                data,
                "POST",
                "file",
                onSuccess,
                onError,
                onProgress
            );
        });
    },
    suspendreMicroprojet({}, params = {}) {
        const { reference, file, raisonRetraitId, commentaireRetrait } = params;
        console.log(params)
        const data = { reference, raisonRetraitId, commentaireRetrait };
        return new Promise((resolv, reject) => {
            const onSuccess = (d) => {
                return resolv(d);
            };
            const onError = (err) => {
                console.log(err)
                return reject(err);
            };

            const onProgress = (val) => {
                console.log(val)
            };

            uploadFile(
                `${API_URL}/microprojets/${reference}/suspendreMicroprojet`,
                file,
                data,
                "POST",
                "file",
                onSuccess,
                onError,
                onProgress
            );
        });
    },
    clonerMicroprojet({}, params = {}) {
        const { reference, file } = params;
        console.log(params)
        const data = { reference };
        return new Promise((resolv, reject) => {
            const onSuccess = (d) => {
                return resolv(d);
            };
            const onError = (err) => {
                console.log(err)
                return reject(err);
            };

            const onProgress = (val) => {
                console.log(val)
            };

            uploadFile(
                `${API_URL}/microprojets/${reference}/clonerMicroprojet`,
                file,
                data,
                "POST",
                "file",
                onSuccess,
                onError,
                onProgress
            );
        });
    },
};
