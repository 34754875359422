import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addCriteres(state, data){
    state.criteres = [ ...data.content ]
  },

  addCritere(state, critere){
    state.criteres = [ ...state.criteres, critere ]
  },
  replaceCritere(state, critere){
    state.criteres = state.criteres.map(struc => struc.id === critere.id ? critere : struc )
  },
  setCritere(state, critere){
    state.critere = critere
  },
}