
export const espaceAuditeurRoutes = [
  {
    path: "/espace/auditeur",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "auditeur-home",
        name: "espace.auditeur.home",
        redirect: { name: "espace.common.entreprise.list", params: { userRole: 'auditeur' } }
      }
    ],
  },
];
