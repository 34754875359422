import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypePerspectives(state, data){
    state.pagination = extractPaginationInfo(data)
    state.typePerspectives = [...data.content] 
  },
  addTypePerspective(state, typePerspective){
    state.typePerspectives = [ ...state.typePerspectives, typePerspective ]
  },
  replaceTypePerspective(state, typePerspective){
    state.typePerspectives = state.typePerspectives.map(struc => struc.id === typePerspective.id ? typePerspective : struc )
  },
  removeTypePerspective(state, typePerspective){
    state.typePerspectives = state.typePerspectives.filter(cand => cand.id !== typePerspective.id)
  },
  setTypePerspective(state, typePerspective){
    state.typePerspective = typePerspective
  },
  setTypePerspectiveEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}