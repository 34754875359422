// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchNaemas({ commit }){
    return get('/naemas')
    .then(({ data }) =>{
      commit('addNaemas', data)
    })
  },
  createNaema({ commit }, naema){
    return post('/naemas', naema)
    .then(({ data }) =>{
      commit('addNaema', data)
    })
  },
  updateNaema({ commit }, naema){
    return put(`/naemas/${naema.id}`, naema)
    .then(({ data }) =>{
      commit('replaceNaema', data)
    })
  },
  createOrUpdateNaema({ dispatch }, naema){
    if(naema.id !== undefined){
      return dispatch('updateNaema', naema)
    }
    else {
      return dispatch('createNaema', naema)
    }
  },
  selectNaema({ commit }, naema){
    commit('setNaema', naema)
  }
}