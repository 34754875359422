import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypePrescriptions(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typePrescriptions = [ ...content ]
  },
  addTypePrescription(state, typePrescription){
    state.typePrescriptions = [ typePrescription, ...state.typePrescriptions]
  },
  replaceTypePrescription(state, typePrescription){
    state.typePrescriptions = state.typePrescriptions.map(struc => struc.id === typePrescription.id ? typePrescription : struc )
  },
  removeTypePrescription(state, typePrescription){
    state.typePrescriptions = state.typePrescriptions.filter(struc => struc.id !== typePrescription.id)
  },
  setTypePrescription(state, typePrescription){
    state.typePrescription = typePrescription
  },
}