import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addDomaineAnalyses(state, data){
    state.domaineanalyses = [ ...data.content ]
  },

  addDomaineAnalyse(state, domaineanalyse){
    state.domaineanalyses = [ ...state.domaineanalyses, domaineanalyse ]
  },
  replaceDomaineAnalyse(state, domaineanalyse){
    state.domaineanalyses = state.domaineanalyses.map(struc => struc.id === domaineanalyse.id ? domaineanalyse : struc )
  },
  setDomaineAnalyse(state, domaineanalyse){
    state.domaineanalyse = domaineanalyse
  },
}