export const getters = {
  grilles(state){
    return [
      // { id: null, libelle: 'Aucun', code: 'Aucun' },
      ...state.grilles
    ]
  },
  grille(state){
    return state.grille
  },
  pagination(state){
    return state.pagination
  }
}