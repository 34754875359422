import { dashRoutes } from './espace-admin'
import { globalRoutes } from './global'
import { espaceDemandeurRoutes } from './espace-demandeur'
import { espaceConseillerRoutes } from './espace-conseiller'
import { espaceEntrepriseRoutes } from './espace-entreprise'
import { espaceAntenneRoutes } from './espace-antenne'
import { espaceProdijRoutes } from './espace-prodij'
import { espaceUlpeRoutes } from './espace-ulpe'
import { espaceAuditeurRoutes } from './espace-auditeur'
import { espaceDesopRoutes } from './espace-desop'
import { espaceCommonRoutes } from './espace-common'

export const routes = [
  ...globalRoutes,
  ...dashRoutes,
  ...espaceDemandeurRoutes,
  ...espaceAntenneRoutes,
  ...espaceDesopRoutes,
  ...espaceProdijRoutes,
  ...espaceUlpeRoutes,
  ...espaceEntrepriseRoutes,
  ...espaceConseillerRoutes,
  ...espaceCommonRoutes,
  ...espaceAuditeurRoutes
  // { path: '*', beforeEnter: (to, from, next) => { next('/404') } }
];
