import { TokenService } from './localStorage'
import axios from 'axios'
import { API_URL }  from '../config/api-config'

export function downloadFile(url, filename='file.pdf') {
    return new Promise((resolve,reject) => {
      if(!url.startsWith('http')){
        url = API_URL + url 
      }
      axios.get(url, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + TokenService.getToken()
        }
      }).then((response) => {
        
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
  
        fileLink.href = fileURL
        fileLink.setAttribute('download', filename)
        document.body.appendChild(fileLink)
  
        fileLink.click()
        resolve(fileURL)
  
      }).catch(err=> { reject(err) })
    }) 
  }


  export function uploadFile(url, file, data, method='POST', fileKey='file', onSuccess=undefined, onError=undefined, onProgress=undefined){
    
    if (!url || url === '') {
      console.log('Please provide the target url')
    }
    else if (!method || method === '') {
      console.log('Please provide file upload url ( POST / PUT )')
    }
    let formData = new FormData()

    //Send request only when user choosed a file
    if(file)
      formData.append(fileKey, file);

    //Append extra data
    if(data){
      for(var key in data){
        if(key !== fileKey){
          formData.append(key, data[key]);
        }
      }
    }
    console.log('Default upload mode')
    var xhr = new XMLHttpRequest()
    
    xhr.open(method, url)
    xhr.setRequestHeader("Authorization", 'Bearer ' + TokenService.getToken())
    
    xhr.onreadystatechange = (e) => {
      if(xhr.readyState == 4) {
        if(xhr.status !== 200){
          console.log(xhr.statusText, e)
          onError({
            code: xhr.status,
            detail: e
          })
        }
        else {
          onSuccess(e, xhr)
        }
      }
    }
    xhr.upload.onprogress = (oEvent) => {
      if (oEvent.lengthComputable) {
        let percentComplete = Math.round(oEvent.loaded * 100 / oEvent.total)
        console.log(percentComplete)
        onProgress(percentComplete)
      }
      else {
        // Unable to compute progress information since the total size is unknown
        onProgress(false)
      }
    },
    xhr.send(formData)
  }


export const FileService = {
    downloadFile,
    uploadFile
}