import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addOrdreMissions(state, data){
    state.pagination = extractPaginationInfo(data)
    state.ordreMissions = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addOrdreMission(state, ordreMission){
    state.ordreMissions = [ ...state.ordreMissions, ordreMission ]
  },
  replaceOrdreMission(state, ordreMission){
    state.ordreMissions = state.ordreMissions.map(struc => struc.id === ordreMission.id ? ordreMission : struc )
  },
  removeOrdreMission(state, ordreMission){
    state.ordreMissions = state.ordreMissions.filter(cand => cand.id !== ordreMission.id)
  },
  setOrdreMission(state, ordreMission){
    state.ordreMission = ordreMission
  },
  setOrdreMissionEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}