import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addAudits(state, data){
    console.log(data)
    state.audits = [ ...data.content ]
  },

  addAudit(state, audit){
    state.audits = [ ...state.audits, audit ]
  },
  replaceAudit(state, audit){
    state.audits = state.audits.map(struc => struc.id === audit.id ? audit : struc )
  },
  setAudit(state, audit){
    state.audit = audit
  },
}