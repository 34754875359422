
export const espaceAntenneRoutes = [
  {
    path: "/esapce/antenne",
    // name: "espace.ca",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "accueil",
        name: "espace.ca.home",
        redirect: { name: "espace.common.azolis.home", params: { userRole: 'ca' } },
      },
      
      // {
      //   path: "ajout/demandeur",
      //   name: "espace.ca.de.add",
      //   component: () => import("../views/espace/antenne/DemandeurAdd.vue"),
      // },
      {
        path: "demandeurs",
        name: "espace.ca.de.list",
        component: () => import("../views/espace/antenne/PorteFeuille.vue"),
      },      
    ],
  },
];
