import { post, put, get, api } from '@/api/api'


export const actions = {
  fetchAntennes({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/antennes?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addAntennes', data)
    })
  },
  transfererDemandeur({ commit, state, rootState }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    queryParams = queryParams !== undefined? queryParams.replace('?','') : ''
    console.log(rootState)
    return get(`/antennes/156/transfertDossiers/transfertVersConseiller?${queryParams}&size=${pageSize}&page=${pageNumber}&sorted=true&`)
    .then(({ data }) =>{
      console.log(data)
      commit('addDemandeurs', data)
    }).catch(err => console.error(err))
  },
  createAntenne({ commit }, antenne){
    return post('/antennes', antenne)
    .then(({ data }) =>{
      commit('addAntenne', data)
    })
  },
  updateAntenne({ commit }, antenne){
    return put(`/antennes/${antenne.id}`, antenne)
    .then(({ data }) =>{
      commit('replaceAntenne', data)
    })
  },
  deleteAntenne({ commit }, antenne){
    return api.delete(`/antennes/${antenne.id}`)
    .then(() =>{
      commit('removeAntenne', antenne)
    })
  },
  createOrUpdateAntenne({ dispatch }, antenne){
    if(antenne.id !== undefined){
      return dispatch('updateAntenne', antenne)
    }
    else {
      return dispatch('createAntenne', antenne)
    }
  },
  selectAntenne({ commit }, antenne){
    commit('setAntenne', antenne)
  }
}