import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addRomes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.romes = [ ...content ]
  },
  addRome(state, rome){
    state.romes = [ ...state.romes, rome ]
  },
  replaceRome(state, rome){
    state.romes = state.romes.map(struc => struc.id === rome.id ? rome : struc )
  },
  setRome(state, rome){
    state.rome = rome
  },
}