export const getters = {
  typePerspectives(state){
    return state.typePerspectives
  },
  typePerspective(state){
    return state.typePerspective
  },
  pagination(state){
    return state.pagination
  }
}