export const mutations = {
  addRechercherEmplois(state, rechercheremplois){
    state.rechercheremplois = [ ...rechercheremplois ]
  },
  addRechercherEmploi(state, rechercheremploi){
    state.rechercheremplois = [ ...state.rechercheremplois, rechercheremploi ]
  },
  replaceRechercherEmploi(state, rechercheremploi){
    state.rechercheremplois = state.rechercheremplois.map(struc => struc.id === rechercheremploi.id ? rechercheremploi : struc )
  },
  setRechercherEmploi(state, rechercheremploi){
    state.rechercheremploi = rechercheremploi
  },
}