export const getters = {
  transfertdossiers(state){
    return state.transfertdossiers
  },
  transfertdossier(state){
    return state.transfertdossier
  },
  transfertDossiers(state){
    return state.transfertdossiers
  },
  transfertDossier(state){
    return state.transfertdossier
  },
  pagination(state){
    return state.pagination
  }
}