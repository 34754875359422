// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchRoles({ commit, state }, params = {}){
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/roles?size=${pageSize}&page=${pageNumber}&sort=name&omit=role`)
    .then(({ data }) =>{
      commit('addRoles', data)
    })
  },
  createRole({ commit }, role){
    return post('/roles', role)
    .then(({ data }) =>{
      commit('addRole', data)
    })
  },
  updateRole({ commit }, role){
    return put(`/roles/${role.id}`, role)
    .then(({ data }) =>{
      commit('replaceRole', data)
    })
  },
  createOrUpdateRole({ dispatch }, role){
    if(role.id !== undefined){
      return dispatch('updateRole', role)
    }
    else {
      return dispatch('createRole', role)
    }
  },
  selectRole({ commit }, role){
    commit('setRole', role)
  }
}