import { post, put, get, remove } from "@/api/api";
import { buildQueryParams } from "../../utils/pagination-util";
import { caseConverter } from "../../utils/case-convert";

export const actionsBuilder = (storeName) => {
    const capitalizedStoreName = caseConverter.toCaptilizedCamelCase(storeName)
    const lowerCaseStoreName = caseConverter.toLowercaseCamelCase(storeName)
    const actions = {
        [`fetch${capitalizedStoreName}s`]: ({ commit, state }, params = {}) => {
            let queryParams = buildQueryParams(params, state.pagination);
            const { extraQueryArg = "" } = params;
            return get(`/${lowerCaseStoreName}s?${queryParams}&${extraQueryArg}`).then(
                ({ data }) => {
                    commit(`add${capitalizedStoreName}s`, data);
                    return data;
                }
            );
        },
        [`fetch${capitalizedStoreName}`]({ commit }, params = {}) {
            let { id } = params;
            return get(`/${lowerCaseStoreName}s/${id}`).then(
                ({ data }) => {
                    commit(`set${capitalizedStoreName}`, data);
                    return data; 
                }
            );
        },
        [`create${capitalizedStoreName}`]({ commit }, payload) {
            return post(`/${lowerCaseStoreName}s`, payload).then(({ data }) => {
                commit(`add${capitalizedStoreName}`, data);
                return data;
            });
        },
        [`update${capitalizedStoreName}`]({ commit }, payload) {
            return put(
                `/${lowerCaseStoreName}s/${payload.id}`,
                payload
            ).then(({ data }) => {
                commit(`replace${capitalizedStoreName}`, data);
                commit(`set${capitalizedStoreName}`, data);
                return data;
            });
        },
        [`createOrUpdate${capitalizedStoreName}`]({ dispatch }, payload) {
            if (payload.id !== undefined) {
                return dispatch(`update${capitalizedStoreName}`, payload);
            } else {
                return dispatch(`create${capitalizedStoreName}`, payload);
            }
        },
        [`delete${capitalizedStoreName}`]({ commit }, payload) {
            return remove(`/${lowerCaseStoreName}s/${payload.id}`, payload).then(({ data }) => {
                commit(`remove${capitalizedStoreName}`, data);
                return data;
            });
        },
    };
    return actions;
};
