export const getters = {
  activitePlanifies(state){
    return state.activitePlanifies
  },
  activitePlanifie(state){
    return state.activitePlanifie
  },
  rapportActivite(state){
    return state.rapportActivite
  },
  pagination(state){
    return state.pagination
  }
}