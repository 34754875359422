export const mutations = {
  addMoyenQualifications(state, moyenQualifications){
    state.moyenQualifications = [ ...moyenQualifications ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addMoyenQualification(state, moyenQualification){
    state.moyenQualifications = [ ...state.moyenQualifications, moyenQualification ]
  },
  replaceMoyenQualification(state, moyenQualification){
    state.moyenQualifications = state.moyenQualifications.map(struc => struc.id === moyenQualification.id ? moyenQualification : struc )
  },
  setMoyenQualification(state, moyenQualification){
    state.moyenQualification = moyenQualification
  },
}