// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchProgrammes({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/programmes?size=${pageSize}&page=${pageNumber}&sort=nom`)
    .then(({ data }) =>{
      commit('addProgrammes', data)
    })
  },
  createProgramme({ commit }, programme){
    return post('/programmes', programme)
    .then(({ data }) =>{
      commit('addProgramme', data)
    })
  },
  updateProgramme({ commit }, programme){
    return put(`/programmes/${programme.id}`, programme)
    .then(({ data }) =>{
      commit('replaceProgramme', data)
    })
  },
  createOrUpdateProgramme({ dispatch }, programme){
    if(programme.id !== undefined){
      return dispatch('updateProgramme', programme)
    }
    else {
      return dispatch('createProgramme', programme)
    }
  },
  selectProgramme({ commit }, programme){
    commit('setProgramme', programme)
  }
}