export const getters = {
  evaluations(state){
    return state.evaluations
  },
  evaluation(state){
    return state.evaluation
  },
  pagination(state){
    return state.pagination
  }
}