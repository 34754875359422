import { extractPaginationInfo } from "@/utils/pagination-util";
import { caseConverter } from "../../utils/case-convert";

export const mutationsBuilder = (storeName) => {
    const capitalizedStoreName = caseConverter.toCaptilizedCamelCase(storeName);
    const lowerCaseStoreName = caseConverter.toLowercaseCamelCase(storeName);
    const mutations = {
        [`add${capitalizedStoreName}s`](state, data) {
            state.pagination = extractPaginationInfo(data);
            state[`${lowerCaseStoreName}s`] = [...data.content];
        },
        [`replace${capitalizedStoreName}`](state, payload) {
            state[`${lowerCaseStoreName}s`] = state[`${lowerCaseStoreName}s`].map((el) =>
                el.id === payload.id ? payload : el
            );
        },
        [`remove${capitalizedStoreName}`](state, payload) {
            state[`${lowerCaseStoreName}s`] = state[`${lowerCaseStoreName}s`].filter(
                (cand) => cand.id !== payload.id
            );
        },
        [`set${capitalizedStoreName}`](state, payload) {
            state[`${lowerCaseStoreName}`] = payload;
        },
    };

    return mutations;
};
