import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchLigneBudgetaires({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/ligneBudgetaires?${queryParams}`)
    .then(({ data }) =>{
      commit('addLigneBudgetaires', data)
      return data
    })
  },
  fetchLigneBudgetaire({ commit, state }, params = {}){
    let { ligneBudgetaireId } = params
    return get(`/ligneBudgetaires/${ligneBudgetaireId}`)
    .then(({ data }) =>{
      commit('setLigneBudgetaire', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { ligneBudgetaireId } = params
    return downloadFileWithAxiosPost(`/ligneBudgetaires/exportToPdf`, 'file.pdf', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setLigneBudgetaire', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { ligneBudgetaireId } = params
    return downloadFileWithAxiosPost(`/ligneBudgetaires/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setLigneBudgetaire', data)
      return data
    })
  },
  createLigneBudgetaire({ commit }, ligneBudgetaire){
    return post('/ligneBudgetaires', ligneBudgetaire)
    .then(({ data }) =>{
      commit('addLigneBudgetaire', data)
      return data
    })
  },
  genererListeDePresence({ commit }, params){
    return post('/ligneBudgetaires/genererListePresence', params)
    .then(({ data }) =>{
      commit('addLigneBudgetaires', data)
      return data
    })
  },
  updateLigneBudgetaire({ commit }, ligneBudgetaire){
    return put(`/ligneBudgetaires/${ligneBudgetaire.id}`, ligneBudgetaire)
    .then(({ data }) =>{
      commit('replaceLigneBudgetaire', data)
      commit('setLigneBudgetaire', data)

      return data
    })
  },
  createOrUpdateLigneBudgetaire({ dispatch }, ligneBudgetaire){
    if(ligneBudgetaire.id !== undefined){
      return dispatch('updateLigneBudgetaire', ligneBudgetaire)
    }
    else {
      return dispatch('createLigneBudgetaire', ligneBudgetaire)
    }
  },
  selectLigneBudgetaire({ commit }, ligneBudgetaire){
    commit('setLigneBudgetaire', ligneBudgetaire)
  }
}