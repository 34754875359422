import jwt_decode from "jwt-decode";

const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const EXP_TOKEN_KEY = 'expiration'

const TokenService = {
  getToken () {
    return localStorage.getItem(TOKEN_KEY)
  },
  
  getAuthorization () {
    return localStorage.getItem(TOKEN_KEY)? 'Bearer ' + localStorage.getItem(TOKEN_KEY) : ''
  },

  saveToken (accessToken) {
    const TOKEN_LIFE = 24 * 60 * 60 * 1000
    localStorage.setItem(TOKEN_KEY, accessToken)
    localStorage.setItem(EXP_TOKEN_KEY, new Date().getTime() + TOKEN_LIFE)
  },

  removeToken () {
    localStorage.removeItem(TOKEN_KEY)
  },

  getRefreshToken () {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  },

  saveRefreshToken (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  },

  removeRefreshToken () {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },

  getTokenInfo(){
    const token = localStorage.getItem(TOKEN_KEY)
    if (typeof token !== 'string') return {}
    const decoded = jwt_decode(token)
    // console.log(decoded)
    // console.log(new Date(decoded.exp))
    return decoded
  },

  isUserLogined () {

    const token = localStorage.getItem(TOKEN_KEY)
    if (typeof token !== 'string') return false

    const auth_version = localStorage.getItem('auth_version')
    if(auth_version !== '1.0.1') {
      localStorage.removeItem(TOKEN_KEY)
      return false
    }

    const decoded = jwt_decode(token)
    // console.log(new Date(decoded.exp))
    // const iatDate =  new Date(0)
    // iatDate.setUTCSeconds(decoded.iat)
    // console.log(iatDate, iatDate.getTime() > new Date().getTime())

    // if(iatDate.getTime() < new Date().getTime()) console.log(new Date("2024-04-05T11:09:00Z"))

    const expDate =  new Date(0)
    expDate.setUTCSeconds(decoded.exp)
    // console.log(expDate.getTime() > new Date().getTime())
    return expDate.getTime() > new Date().getTime()
  }

}

export { TokenService }
