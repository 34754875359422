export const getters = {
  priseEnCharges(state){
    return state.priseEnCharges
  },
  priseEnCharge(state){
    return state.priseEnCharge
  },
  pagination(state){
    return state.pagination
  }
}