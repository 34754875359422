export const mutations = {
  addSubvenirBesoins(state, subvenirBesoins){
    state.subvenirBesoins = [ ...subvenirBesoins ]
  },
  addSubvenirBesoin(state, subvenirBesoin){
    state.subvenirBesoins = [ ...state.subvenirBesoins, subvenirBesoin ]
  },
  replaceSubvenirBesoin(state, subvenirBesoin){
    state.subvenirBesoins = state.subvenirBesoins.map(struc => struc.id === subvenirBesoin.id ? subvenirBesoin : struc )
  },
  setSubvenirBesoin(state, subvenirBesoin){
    state.subvenirBesoin = subvenirBesoin
  },
}