import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addRapports(state, data){
    console.log(data)
    state.rapports = [ ...data.content ]
  },

  addRapport(state, rapport){
    state.rapports = [ ...state.rapports, rapport ]
  },
  replaceRapport(state, rapport){
    state.rapports = state.rapports.map(struc => struc.id === rapport.id ? rapport : struc )
  },
  setRapport(state, rapport){
    state.rapport = rapport
  },
}