export const getters = {
  maitriseLangues(state){
    return state.maitriseLangues
  },
  communes(state){
    return state.communes
  },
  maitriseLangue(state){
    return state.maitriseLangue
  }
}