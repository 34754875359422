import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addFormeJuridiques(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.formeJuridiques = [ ...content ]
  },

  addFormeJuridique(state, formejuridique){
    state.formeJuridiques = [ ...state.formejuridiques, formejuridique ]
  },
  replaceFormeJuridique(state, formejuridique){
    state.formeJuridiques = state.formejuridiques.map(struc => struc.id === formejuridique.id ? formejuridique : struc )
  },
  setFormeJuridique(state, formejuridique){
    state.formeJuridique = formejuridique
  },
}