// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchNations({ commit }){
    return get('/pays')
    .then(({ data }) =>{
      commit('addNations', data)
    })
  },
  fetchDepartements({ commit }){
    return get('/departements')
    .then(({ data }) =>{
      commit('addDepartements', data)
    })
  },
  fetchDepartementCommunes({ commit }, params={}){
    const { departementId } = params
    return get(`/departements/${departementId}/communes`)
    .then(({ data }) =>{
      commit('addCommunes', data)
    })
  },
  fetchCommuneArrondissements({ commit }, params={}){
    const { communeId } = params
    return get(`/communes/${communeId}/arrondissements`)
    .then(({ data }) =>{
      commit('addArrondissements', data)
    })
  },
  fetchArrondissementVilles({ commit }, params={}){
    const { arrondissementId } = params
    return get(`/arrondissements/${arrondissementId}/villes`)
    .then(({ data }) =>{
      commit('addVilles', data)
    })
  },
  createDepartement({ commit }, departement){
    return post('/departements', departement)
    .then(({ data }) =>{
      commit('addDepartement', data)
    })
  },
  updateDepartement({ commit }, departement){
    return put(`/departements/${departement.id}`, departement)
    .then(({ data }) =>{
      commit('replaceDepartement', data)
    })
  },
  createOrUpdateDepartement({ dispatch }, departement){
    if(departement.id !== undefined){
      return dispatch('updateDepartement', departement)
    }
    else {
      return dispatch('createDepartement', departement)
    }
  },
  selectDepartement({ commit }, departement){
    commit('setDepartement', departement)
  }
}