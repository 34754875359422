import { post, put, get, downloadFileWithAxiosPost, downloadFile} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchAppreciationStages({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/appreciationStages?${queryParams}`)
    .then(({ data }) =>{
      commit('addAppreciationStages', data)
      return data
    })
  },
  fetchAppreciationStage({ commit, state }, params = {}){
    let { appreciationStageId } = params
    return get(`/appreciationStages/${appreciationStageId}`)
    .then(({ data }) =>{
      commit('setAppreciationStage', data)
      return data
    })
  },
  exportToPdf({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/appreciationStages/exportToPdf?${queryParams}`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
        // 'N° CONTRAT' : 'participationPlacement.placement.contract.numeroContrat',
        // 'DATE PLACEMENT' : '',
        'N° PLACEMENT' : 'participationPlacement.placement.reference',
        'MOIS POINT DE PRESENCE' : 'pointPresence.moisAnnee',
        'PERIODE DU' : 'pointPresence.dateDu',
        'PERIODE AU' : 'pointPresence.dateAu',
      },
    })
    .then(({ data }) =>{
      commit('setAppreciationStage', data)
      return data
    })
  },
  exportToExcel({ commit, state  }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/appreciationStages/exportToExcel?${queryParams}`, 'file.xlsx', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setAppreciationStage', data)
      return data
    })
  },
  telechargerAppreciationStage({}, params = {}){
    return downloadFile(`/appreciationStages/${params.id}/exportAppreciationStageToPdf`)
    .then(({ data }) =>{
      return data
    })
  },
  createAppreciationStage({ commit }, appreciationStage){
    return post('/appreciationStages', appreciationStage)
    .then(({ data }) =>{
      commit('addAppreciationStage', data)
      return data
    })
  },
  genererAppreciationStages({ commit }, { placement }){
    return post('/appreciationStages/genererAppreciationStage', {
      placement
    })
    .then(({ data }) =>{
      commit('addAppreciationStages', data)
      return data
    })
  },
  updateAppreciationStage({ commit }, appreciationStage){
    return put(`/appreciationStages/${appreciationStage.id}`, appreciationStage)
    .then(({ data }) =>{
      commit('replaceAppreciationStage', data)
      commit('setAppreciationStage', data)

      return data
    })
  },
  createOrUpdateAppreciationStage({ dispatch }, appreciationStage){
    if(appreciationStage.id !== undefined){
      return dispatch('updateAppreciationStage', appreciationStage)
    }
    else {
      return dispatch('createAppreciationStage', appreciationStage)
    }
  },
  selectAppreciationStage({ commit }, appreciationStage){
    commit('setAppreciationStage', appreciationStage)
  }
}