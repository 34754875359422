import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addTentativeCandidatures(state, data){
    console.log(data)
    state.tentativeCandidatures = [ ...data.content ]
  },

  addTentativeCandidature(state, tentativeCandidature){
    state.tentativeCandidatures = [ ...state.tentativeCandidatures, tentativeCandidature ]
  },
  replaceTentativeCandidature(state, tentativeCandidature){
    state.tentativeCandidatures = state.tentativeCandidatures.map(struc => struc.id === tentativeCandidature.id ? tentativeCandidature : struc )
  },
  setTentativeCandidature(state, tentativeCandidature){
    state.tentativeCandidature = tentativeCandidature
  },
}