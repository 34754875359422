export const getters = {
  ligneBudgetaires(state){
    return state.ligneBudgetaires
  },
  ligneBudgetaire(state){
    return state.ligneBudgetaire
  },
  pagination(state){
    return state.pagination
  }
}