import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addObjectifAgents(state, data){
    state.pagination = extractPaginationInfo(data)
    state.objectifAgents = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addObjectifAgent(state, objectifAgent){
    state.objectifAgents = [ ...state.objectifAgents, objectifAgent ]
  },
  replaceObjectifAgent(state, objectifAgent){
    state.objectifAgents = state.objectifAgents.map(struc => struc.id === objectifAgent.id ? objectifAgent : struc )
  },
  removeObjectifAgent(state, objectifAgent){
    state.objectifAgents = state.objectifAgents.filter(cand => cand.id !== objectifAgent.id)
  },
  setObjectifAgent(state, objectifAgent){
    state.objectifAgent = objectifAgent
  },
  setObjectifAgentEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}