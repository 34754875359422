export const getters = {
  rapportActivites(state){
    return state.rapportActivites
  },
  rapportActivite(state){
    return state.rapportActivite
  },
  pagination(state){
    return state.pagination
  }
}