import { TokenService } from '@/api/localStorage'

const AuthService =  {
  loginedUser () {
    let user = localStorage.getItem('user')
    return typeof user === 'string' ? JSON.parse(user) : user
  },
  roles () {
    const tokenInfo = TokenService.getTokenInfo()
    return tokenInfo.roles || [] 
  },
  userRole () {
    const tokenInfo = TokenService.getTokenInfo()
    return tokenInfo.roles? tokenInfo.roles[0] : null 
  },
  userPrivileges () {
    const tokenInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || {}) : {}
    let attributions = tokenInfo.roles && tokenInfo.roles[0]?tokenInfo.roles[0].privileges.map(el => el.name) : []
    if(tokenInfo.attributions){
        attributions = [...attributions, ...tokenInfo.attributions.map(el => el.name)]
    } else{
        //Disconnect the user
        // // TokenService.clear()
        // TokenService.removeToken()
        // TokenService.removeRefreshToken()
        // window.location.reload()
        
    }
    return attributions
  },
  userRoleName () {
    const tokenInfo = TokenService.getTokenInfo()
    return tokenInfo.roles? tokenInfo.roles[0].slice(5) : null
  },
  isLogined () {
    return TokenService.isUserLogined()
  },
  isUserLogined () {
    return TokenService.isUserLogined()
  }
}

export { AuthService }
