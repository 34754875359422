export const getters = {
  typePositions(state){
    return state.typePositions
  },
  communes(state){
    return state.communes
  },
  typePosition(state){
    return state.typePosition
  }
}