export const getters = {
  fonctions(state){
    return state.fonctions
  },
  fonction(state){
    return state.fonction
  },
  pagination(state){
    return state.pagination
  }
}