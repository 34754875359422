export const getters = {
  typePlacements(state){
    return state.typePlacements
  },
  typePlacement(state){
    return state.typePlacement
  },
  pagination(state){
    return state.pagination
  }
}