import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addLigneBudgetaires(state, data){
    state.pagination = extractPaginationInfo(data)
    state.ligneBudgetaires = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addLigneBudgetaire(state, ligneBudgetaire){
    state.ligneBudgetaires = [ ...state.ligneBudgetaires, ligneBudgetaire ]
  },
  replaceLigneBudgetaire(state, ligneBudgetaire){
    state.ligneBudgetaires = state.ligneBudgetaires.map(struc => struc.id === ligneBudgetaire.id ? ligneBudgetaire : struc )
  },
  removeLigneBudgetaire(state, ligneBudgetaire){
    state.ligneBudgetaires = state.ligneBudgetaires.filter(cand => cand.id !== ligneBudgetaire.id)
  },
  setLigneBudgetaire(state, ligneBudgetaire){
    state.ligneBudgetaire = ligneBudgetaire
  },
  setLigneBudgetaireEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}