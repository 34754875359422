import { TokenService } from '@/api/localStorage'

let userRoles = TokenService.getTokenInfo().roles || []
let roleName = userRoles.length > 0? userRoles[0].slice(5, userRoles[0].length).toLowerCase() : ''

export const MENU_AGENT = [
  {
    to: { name: "espace.common.azolis.home", params: { userRole: roleName } },
    label: "Accueil",
    privileges: [ 'MENU.HOME_STATISTIC' ]
  },
  {
    to: { name: "espace.common.azolis.list", params: { userRole: roleName } },
    label: "Mon porte-feuille",
    privileges: [ 'MENU.PORTE_FEUILLE' ]
  },
  {
    to: { name: "espace.common.activities.agenda", params: { userRole: roleName } },
    label: "Planning",
    privileges: [ 'MENU.PLANNING' ]
  },
  {
    to: { name: "espace.common.activites.list", params: { userRole: roleName } },
    label: "Tâches planifiées",
    privileges: [ 'MENU.ACTIVITE_ANPE' ]
  },
  {
    to: { name: "espace.common.followup.list", params: { userRole: roleName } },
    label: "Prise en charge",
    privileges: [ 'MENU.PRISE_EN_CHARGE' ]
  },
  {
    to: { name: "espace.entreprise.offre.list", params: { userRole: roleName } },
    label: "Offres",
    privileges: [ 'MENU.OFFRE_LIST' ]
  },
  // {
  //   to: { name: "espace.admin.offre.souscrire" },
  //   label: "Les offres souscrire",
  // },
  // {
  //   to: { name: 'espace.entreprise.candidature.list', params: { userRole: roleName }},
  //   label: 'Candidatures'
  // },
  {
    to: {
      name: "espace.common.candidature.list",
      params: { userRole: roleName },
    },
    label: "Candidatures",
    privileges: [ 'MENU.CANDIDATURE_LIST' ]
  },
  {
    to: {
      name: "espace.common.recherche.demandeur.profil",
      params: { userRole: roleName },
    },
    label: "Matching",
    privileges: [ 'MENU.MATCHING' ]
  },
  {
    to: {
      name: "espace.common.suivi-placement.list",
      params: { userRole: roleName },
    },
    label: "Suivi Placements",
    privileges: [ 'MENU.SUIVI_PLACEMENT_LIST' ]
  },
  {
    to: {
      name: "espace.common.suivi-evaluation.list",
      params: { userRole: roleName },
    },
    label: "Contrat Objectif",
    privileges: [ 'MENU.CONTRAT_OBJECTIF_MANAGEMENT_LIST' ]
  },
  
  {
    to: {
      name: "espace.common.ordre-mission-management.list",
      params: { userRole: roleName },
    },
    label: "Ordre de missions",
    privileges: [ 'MENU.ORDRE_MISSION_MANAGEMENT_LIST' ]
  },
  
  {
    to: {
      name: "espace.common.inscription.incomplete.list",
      params: { userRole: roleName },
    },
    label: "Inscriptions incomplètes",
    privileges: [ 'MENU.INSCRIPTION_LIST' ]
  },


  
];
