import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAppreciationStages(state, data){
    state.pagination = extractPaginationInfo(data)
    state.appreciationStages = [...data.content] 
  },
  addAppreciationStage(state, appreciationStage){
    state.appreciationStages = [ ...state.appreciationStages, appreciationStage ]
  },
  replaceAppreciationStage(state, appreciationStage){
    state.appreciationStages = state.appreciationStages.map(struc => struc.id === appreciationStage.id ? appreciationStage : struc )
  },
  removeAppreciationStage(state, appreciationStage){
    state.appreciationStages = state.appreciationStages.filter(cand => cand.id !== appreciationStage.id)
  },
  setAppreciationStage(state, appreciationStage){
    state.appreciationStage = appreciationStage
  },
  setAppreciationStageEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}