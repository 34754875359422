import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchAppreciationStagiaires({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/appreciationStagiaires?${queryParams}`)
    .then(({ data }) =>{
      commit('addAppreciationStagiaires', data)
      return data
    })
  },
  fetchAppreciationStagiaire({ commit, state }, params = {}){
    let { appreciationStagiaireId } = params
    return get(`/appreciationStagiaires/${appreciationStagiaireId}`)
    .then(({ data }) =>{
      commit('setAppreciationStagiaire', data)
      return data
    })
  },
  exportToPdf({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/appreciationStagiaires/exportToPdf?${queryParams}`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
        // 'N° CONTRAT' : 'participationPlacement.placement.contract.numeroContrat',
        // 'DATE PLACEMENT' : '',
        'N° PLACEMENT' : 'participationPlacement.placement.reference',
        'MOIS POINT DE PRESENCE' : 'pointPresence.moisAnnee',
        'PERIODE DU' : 'pointPresence.dateDu',
        'PERIODE AU' : 'pointPresence.dateAu',
      },
    })
    .then(({ data }) =>{
      commit('setAppreciationStagiaire', data)
      return data
    })
  },
  exportToExcel({ commit, state  }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/appreciationStagiaires/exportToExcel?${queryParams}`, 'file.xlsx', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setAppreciationStagiaire', data)
      return data
    })
  },
  createAppreciationStagiaire({ commit }, appreciationStagiaire){
    return post('/appreciationStagiaires', appreciationStagiaire)
    .then(({ data }) =>{
      commit('addAppreciationStagiaire', data)
      return data
    })
  },
  genererAppreciationStagiaires({ commit }, { placement }){
    return post('/appreciationStagiaires/genererAppreciationStagiaire', {
      placement
    })
    .then(({ data }) =>{
      commit('addAppreciationStagiaires', data)
      return data
    })
  },
  updateAppreciationStagiaire({ commit }, appreciationStagiaire){
    return put(`/appreciationStagiaires/${appreciationStagiaire.id}`, appreciationStagiaire)
    .then(({ data }) =>{
      commit('replaceAppreciationStagiaire', data)
      commit('setAppreciationStagiaire', data)

      return data
    })
  },
  createOrUpdateAppreciationStagiaire({ dispatch }, appreciationStagiaire){
    if(appreciationStagiaire.id !== undefined){
      return dispatch('updateAppreciationStagiaire', appreciationStagiaire)
    }
    else {
      return dispatch('createAppreciationStagiaire', appreciationStagiaire)
    }
  },
  selectAppreciationStagiaire({ commit }, appreciationStagiaire){
    commit('setAppreciationStagiaire', appreciationStagiaire)
  }
}