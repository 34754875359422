import { defineAbility } from '@casl/ability'
import { AuthService } from '../api/user-service'
// import privilege from '../store/privilege'

export const abilityDefinition = (can) => {
  let userRole = AuthService.userRoleName()
  if(userRole == 'CONSEILLER') userRole = 'CE'

  const userPrivileges = AuthService.userPrivileges()
  
  userPrivileges.forEach(privilege => {
    let privilegeSpit = privilege.split('.')
    let [ obj, action ] = privilegeSpit
    if(obj && action) can(action, obj)
  })
  
  
  let canWithAnyRole = async (action, obj, roles=[]) => {
    if(roles.includes(userRole)) {
      can(action, obj)
    }
  }

  canWithAnyRole('create', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN', 'FE', 'COACH_SWEDD' ])
  canWithAnyRole('edit', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('list', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('export', 'Azoli', [ 'DESOP', 'PRODIJ', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('read', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('validate', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'DG', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.beneficiaire', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.telephone', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.whatsapp', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.departement', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.commune', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.arrondissment', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.ville', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.ae', 'Azoli', [ 'DESOP', 'PRODIJ', 'DG', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.metierAppris', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.metierDesire', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('filter.status', 'Azoli', [ 'DESOP', 'PRODIJ', 'ULPE', 'AE', 'DG', 'CE', 'CA', 'ADMIN' ])
  canWithAnyRole('stats.see', 'Azoli', [ 'DESOP', 'PRODIJ', 'DG', 'ADMIN', 'CA' ])
  canWithAnyRole('seeAffectationStatus', 'Azoli', [ 'ADMIN', 'CA', 'DG','DESOP', 'PRODIJ' ])


  canWithAnyRole('create', 'Offre', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ENTREPRISE' ])
  canWithAnyRole('update', 'Offre', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('publish', 'Offre', [ 'ADMIN', 'CA', 'DG', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('approve', 'Offre', [ 'ADMIN', 'CA', 'DG', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('close', 'Offre', [ 'ADMIN', 'CA', 'DG', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('cancel', 'Offre', [ 'ADMIN', 'CA', 'DG', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('validate', 'Offre', 'ADMIN', [ 'CE', 'ULPE', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('export', 'Offre', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('statistic', 'Offre', [ 'ADMIN', 'CA', 'CE', 'DG', 'ULPE', 'DESOP', 'PRODIJ' ])


  canWithAnyRole('seeAffectationStatus', 'Entreprise', [ 'ADMIN', 'CA', 'DG', 'DESOP', 'PRODIJ' ])
  canWithAnyRole('generate', 'Contrat', [ 'ADMIN', 'CE', 'CA', 'DG', 'DESOP', 'PRODIJ' ])
  
  
  // canWithAnyRole('accept', 'Candidature', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ENTREPRISE' ])
  canWithAnyRole('recommend', 'Candidature', [ 'ADMIN',  'ADMIN','CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE'  ])
  canWithAnyRole('accept', 'Candidature', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ENTREPRISE', 'ULPE' ])
  canWithAnyRole('require', 'Candidature', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ENTREPRISE' ])
  canWithAnyRole('treat', 'Candidature', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE'  ])
  canWithAnyRole('seeStatistic', 'Candidature', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE'  ])
  canWithAnyRole('seeHeader', 'Candidature', [ 'ADMIN', 'CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE'  ])
  
  
  
  canWithAnyRole('add', 'ActionRequise', [ 'ADMIN',  'ADMIN','CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE', 'AUDITEUR'  ])
  canWithAnyRole('edit', 'ActionRequise', [ 'ADMIN',  'ADMIN','CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE', 'AUDITEUR'  ])
  canWithAnyRole('add', 'PlanAction', [ 'ADMIN',  'ADMIN','CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE', 'AUDITEUR'  ])
  canWithAnyRole('edit', 'PlanAction', [ 'ADMIN',  'ADMIN','CA', 'CE', 'DG', 'DESOP', 'PRODIJ', 'ULPE', 'AUDITEUR', 'ENTREPRISE'  ])


  //Can you can 
  canWithAnyRole('transferToAntenne', 'FinOc', [ 'CA', 'ENTREPRISE', 'ADMIN' ])
  canWithAnyRole('transferToAntenne', 'FinOc', [ 'CA', 'DG', 'ADMIN' ])
  canWithAnyRole('transferToAntenne', 'FinOc', [ 'CA', 'DG', 'ADMIN' ])


}

export default defineAbility(abilityDefinition)
