export const getters = {
  evaluationProfessionnelles(state){
    return state.evaluationProfessionnelles
  },
  evaluationProfessionnelle(state){
    return state.evaluationProfessionnelle
  },
  pagination(state){
    return state.pagination
  }
}