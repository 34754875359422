export const  extractPaginationInfo = (data) => {
  const { 
    pageable,
    totalPages,
    numberOfElements,
    number,
    totalElements,
    last,
    first,
    size,
    empty
  } = data

  return {
    ...pageable,
    totalElements,
    totalPages,
    last,
    first,
    size,
    numberOfElements,
    number,
    loading: false,
    empty,
  }
}

export const  buildQueryParams = (params = {}, defaultPagination={}) => {
  let { pageNumber= params.page? params.page :  0, queryParams, pageSize = params.pageSize? params.pageSize: defaultPagination.size } = params
  queryParams = queryParams !== undefined? queryParams.replace('?','') : ''

  if(queryParams.indexOf('size=') === -1){
    queryParams = `${queryParams}&size=${pageSize}`
  }

  if(queryParams.indexOf('page=') === -1){
    queryParams = `${queryParams}&page=${pageNumber}`
  }

  return `${queryParams}`
}

export const  buildQueryUrl = (params = {}, defaultPagination={}) => {
  let { pageNumber= params.page? params.page :  0, queryParams, pageSize = params.pageSize? params.pageSize: defaultPagination.size } = params
  queryParams = queryParams !== undefined? queryParams.replace('?','') : ''

  return `${queryParams}&size=${pageSize}&page=${pageNumber}&sorted=true&`
}

export const  extractDataContent = (data) => {
  return data && data.content? data.content : data

}

