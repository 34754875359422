// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchCommunes({ commit }){
    return get('/communes?&sort=nom')
    .then(({ data }) =>{
      commit('addCommunes', data)
    })
  },
  createCommune({ commit }, commune){
    return post('/communes', commune)
    .then(({ data }) =>{
      commit('addCommune', data)
    })
  },
  updateCommune({ commit }, commune){
    return put(`/communes/${commune.id}`, commune)
    .then(({ data }) =>{
      commit('replaceCommune', data)
    })
  },
  createOrUpdateCommune({ dispatch }, commune){
    if(commune.id !== undefined){
      return dispatch('updateCommune', commune)
    }
    else {
      return dispatch('createCommune', commune)
    }
  },
  selectCommune({ commit }, commune){
    commit('setCommune', commune)
  }
}