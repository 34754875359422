import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addSecteurs(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.secteurs = [ ...content ]
  },

  addSecteur(state, secteur){
    state.secteurs = [ ...state.secteurs, secteur ]
  },
  
  replaceSecteur(state, secteur){
    state.secteurs = state.secteurs.map(struc => struc.id === secteur.id ? secteur : struc )
  },
  setSecteur(state, secteur){
    state.secteur = secteur
  },
}