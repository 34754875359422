export const getters = {
  typeAvantages(state){
    return state.typeAvantages
  },
  typeAvantage(state){
    return state.typeAvantage
  },
  pagination(state){
    return state.pagination
  }
}