// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchCriteres({ commit }){
    return get('/criteres?&sort=id,desc')
    .then(({ data }) =>{
      commit('addCriteres', data)
    })
  },
  createCritere({ commit }, critere){
    return post('/criteres', critere)
    .then(({ data }) =>{
      commit('addCritere', data)
    })
  },
  updateCritere({ commit }, critere){
    return put(`/criteres/${critere.id}`, critere)
    .then(({ data }) =>{
      commit('replaceCritere', data)
    })
  },
  createOrUpdateCritere({ dispatch }, critere){
    if(critere.id !== undefined){
      return dispatch('updateCritere', critere)
    }
    else {
      return dispatch('createCritere', critere)
    }
  },
  selectCritere({ commit }, critere){
    commit('setCritere', critere)
  }
}