export const getters = {
  affectations(state){
    return state.affectations
  },
  affectation(state){
    return state.affectation
  },
  pagination(state){
    return state.pagination
  }
}