import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addEvaluationComportementales(state, data){
    state.pagination = extractPaginationInfo(data)
    state.evaluationComportementales = [...data.content] 
  },
  addEvaluationComportementale(state, evaluationComportementale){
    state.evaluationComportementales = [ ...state.evaluationComportementales, evaluationComportementale ]
  },
  replaceEvaluationComportementale(state, evaluationComportementale){
    state.evaluationComportementales = state.evaluationComportementales.map(struc => struc.id === evaluationComportementale.id ? evaluationComportementale : struc )
  },
  removeEvaluationComportementale(state, evaluationComportementale){
    state.evaluationComportementales = state.evaluationComportementales.filter(cand => cand.id !== evaluationComportementale.id)
  },
  setEvaluationComportementale(state, evaluationComportementale){
    state.evaluationComportementale = evaluationComportementale
  },
  setEvaluationComportementaleEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}