import { fetchLogin, fetchSignUp } from '@/api/api'
import { TokenService } from '@/api/localStorage'
import ability from '../../acsl/define-ability'
import Vue from "vue"

import { abilitiesPlugin } from '@casl/vue'


export default {
  namespaced: true,
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
  },
  getters: {
    loginedUser (state) {
      let user = state.user && state.user.username ? state.user : localStorage.getItem('user')
      return typeof user === 'string' ? JSON.parse(user) : user
    },
    permissions (state) {
      return state.user.permissions
    },
    roles () {
      const tokenInfo = TokenService.getTokenInfo()
      return tokenInfo.roles || [] 
    },
    userRole () {
      const tokenInfo = TokenService.getTokenInfo()
      return tokenInfo.roles? tokenInfo.roles[0] : null 
    },
    isLogined () {
      return TokenService.isUserLogined()
    },
    isUserLogined () {
      return TokenService.isUserLogined()
    }

  },
  mutations: {
    setUser (state, payload) {
      localStorage.setItem('user', JSON.stringify(payload))
      state.user = payload
    },
    can (state, permission) {
      return state.permissions && state.permissions.indexOf(permission) > -1
    }
  },
  actions: {
    async login (context, credentials) {
      return new Promise((resolve, reject) => {
        return fetchLogin(credentials).then(({ data }) => {
        localStorage.setItem('auth_version', '1.0.1')
          context.commit('setUser', { ...data })
          TokenService.saveToken(data.accessToken) // The backend sends the token in `accessToken` not 'token'          // TokenService.saveRefreshToken(data.refresh) // The backend sends the token in `accessToken` not 'token'          
          Vue.use(abilitiesPlugin, ability, {
            useGlobalProperties: true
          })
          resolve(data.user)
        }).catch(err => {
          reject(err)
        })
      })
    },
    async signUp (context, credentials) {
      return new Promise((resolve, reject) => {
        return fetchSignUp(credentials).then(({ data }) => {
          context.commit('setUser', data.user)
          TokenService.saveToken(data.accessToken) // The backend sends the token in `accessToken` not 'token'
          // TokenService.saveRefreshToken(data.refresh) // The backend sends the token in `accessToken` not 'token'
          resolve(data.user)
        }).catch(err => {
          reject(err)
        })
      })
    },
    forceLogout () {
      TokenService.removeToken()
      TokenService.removeRefreshToken()
    },
    logout () {
      // Remove the token and remove Authorization header from Api Service as well
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      window.location.reload()
    }
  }
}
