export const mutations = {
  addSourceInfos(state, sourceInfos){
    state.sourceInfos = [ ...sourceInfos ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addSourceInfo(state, sourceInfo){
    state.sourceInfos = [ ...state.sourceInfos, sourceInfo ]
  },
  replaceSourceInfo(state, sourceInfo){
    state.sourceInfos = state.sourceInfos.map(struc => struc.id === sourceInfo.id ? sourceInfo : struc )
  },
  setSourceInfo(state, sourceInfo){
    state.sourceInfo = sourceInfo
  },
}