import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchAuteurAudits({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/auteurAudits?${queryParams}`)
    .then(({ data }) =>{
      commit('addAuteurAudits', data)
      return data
    })
  },
  fetchAuteurAudit({ commit, state }, params = {}){
    let { auteurAuditId } = params
    return get(`/auteurAudits/${auteurAuditId}`)
    .then(({ data }) =>{
      commit('setAuteurAudit', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { auteurAuditId } = params
    return downloadFileWithAxiosPost(`/auteurAudits/exportToPdf`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setAuteurAudit', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { auteurAuditId } = params
    return downloadFileWithAxiosPost(`/auteurAudits/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setAuteurAudit', data)
      return data
    })
  },
  createAuteurAudit({ commit }, auteurAudit){
    return post('/auteurAudits', auteurAudit)
    .then(({ data }) =>{
      commit('addAuteurAudit', data)
      return data
    })
  },
  updateAuteurAudit({ commit }, auteurAudit){
    return put(`/auteurAudits/${auteurAudit.id}`, auteurAudit)
    .then(({ data }) =>{
      commit('replaceAuteurAudit', data)
      commit('setAuteurAudit', data)

      return data
    })
  },
  createOrUpdateAuteurAudit({ dispatch }, auteurAudit){
    if(auteurAudit.id !== undefined){
      return dispatch('updateAuteurAudit', auteurAudit)
    }
    else {
      return dispatch('createAuteurAudit', auteurAudit)
    }
  },
  selectAuteurAudit({ commit }, auteurAudit){
    commit('setAuteurAudit', auteurAudit)
  }
}