// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchCycleProductions({ commit }){
    return get('/cycleProductions')
    .then(({ data }) =>{
      commit('addCycleProductions', data)
    })
  },
  createCycleProduction({ commit }, cycleproduction){
    return post('/cycleProductions', cycleproduction)
    .then(({ data }) =>{
      commit('addCycleProduction', data)
    })
  },
  updateCycleProduction({ commit }, cycleproduction){
    return put(`/cycleProductions/${cycleproduction.id}`, cycleproduction)
    .then(({ data }) =>{
      commit('replaceCycleProduction', data)
    })
  },
  createOrUpdateCycleProduction({ dispatch }, cycleproduction){
    if(cycleproduction.id !== undefined){
      return dispatch('updateCycleProduction', cycleproduction)
    }
    else {
      return dispatch('createCycleProduction', cycleproduction)
    }
  },
  selectCycleProduction({ commit }, cycleproduction){
    commit('setCycleProduction', cycleproduction)
  }
}