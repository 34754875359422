import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPriseEnCharges(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.priseEnCharges = [ ...content ]
  },
  addPriseEnCharge(state, priseEnCharge){
    state.priseEnCharges = [ priseEnCharge, ...state.priseEnCharges]
  },
  replacePriseEnCharge(state, priseEnCharge){
    state.priseEnCharges = state.priseEnCharges.map(struc => struc.id === priseEnCharge.id ? priseEnCharge : struc )
  },
  cloturerPriseEnCharge(state, priseEnChargeId){
    state.priseEnCharges = state.priseEnCharges.map(struc => struc.id == priseEnChargeId ? {
      ...struc,
      statusActivite: 'CLOTUREE'
    } : struc )
  },
  removePriseEnCharge(state, priseEnCharge){
    state.priseEnCharges = state.priseEnCharges.filter(struc => struc.id !== priseEnCharge.id)
  },
  setPriseEnCharge(state, priseEnCharge){
    state.priseEnCharge = priseEnCharge
  },
}