export const SIDE_MENU_DEMANDEUR = [
  {
    to: { name: "espace.de.home" },
    label: "Accueil",
    icon: "fa fa-home",
  },
  {
    to: { name: "accounts.logout" },
    label: "Déconnexion",
    icon: "bi bi-power",
  },
]