export const getters = {
  categories(state){
    return state.categories
  },
  categorie(state){
    return state.categorie
  },
  pagination(state){
    return state.pagination
  }
}