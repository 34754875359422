import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchTypeIndicateurs({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/typeIndicateurs?${queryParams}`)
    .then(({ data }) =>{
      commit('addTypeIndicateurs', data)
      return data
    })
  },
  fetchTypeIndicateur({ commit, state }, params = {}){
    let { typeIndicateurId } = params
    return get(`/typeIndicateurs/${typeIndicateurId}`)
    .then(({ data }) =>{
      commit('setTypeIndicateur', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { typeIndicateurId } = params
    return downloadFileWithAxiosPost(`/typeIndicateurs/exportToPdf`, 'file.pdf', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setTypeIndicateur', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { typeIndicateurId } = params
    return downloadFileWithAxiosPost(`/typeIndicateurs/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setTypeIndicateur', data)
      return data
    })
  },
  createTypeIndicateur({ commit }, typeIndicateur){
    return post('/typeIndicateurs', typeIndicateur)
    .then(({ data }) =>{
      commit('addTypeIndicateur', data)
      return data
    })
  },
  updateTypeIndicateur({ commit }, typeIndicateur){
    return put(`/typeIndicateurs/${typeIndicateur.id}`, typeIndicateur)
    .then(({ data }) =>{
      commit('replaceTypeIndicateur', data)
      commit('setTypeIndicateur', data)

      return data
    })
  },
  createOrUpdateTypeIndicateur({ dispatch }, typeIndicateur){
    if(typeIndicateur.id !== undefined){
      return dispatch('updateTypeIndicateur', typeIndicateur)
    }
    else {
      return dispatch('createTypeIndicateur', typeIndicateur)
    }
  },
  selectTypeIndicateur({ commit }, typeIndicateur){
    commit('setTypeIndicateur', typeIndicateur)
  }
}