export const getters = {
  appreciationStages(state){
    return state.appreciationStages
  },
  appreciationStage(state){
    return state.appreciationStage
  },
  pagination(state){
    return state.pagination
  }
}