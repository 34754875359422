// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchNatures({ commit }){
    return get('/natures')
    .then(({ data }) =>{
      commit('addNatures', data)
    })
  },
  createNature({ commit }, nature){
    return post('/natures', nature)
    .then(({ data }) =>{
      commit('addNature', data)
    })
  },
  updateNature({ commit }, nature){
    return put(`/natures/${nature.id}`, nature)
    .then(({ data }) =>{
      commit('replaceNature', data)
    })
  },
  createOrUpdateNature({ dispatch }, nature){
    if(nature.id !== undefined){
      return dispatch('updateNature', nature)
    }
    else {
      return dispatch('createNature', nature)
    }
  },
  selectNature({ commit }, nature){
    commit('setNature', nature)
  }
}