export const mutations = {
  addTypePieces(state, typePieces){
    state.typePieces = [ ...typePieces ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addTypePiece(state, typePiece){
    state.typePieces = [ ...state.typePieces, typePiece ]
  },
  replaceTypePiece(state, typePiece){
    state.typePieces = state.typePieces.map(struc => struc.id === typePiece.id ? typePiece : struc )
  },
  setTypePiece(state, typePiece){
    state.typePiece = typePiece
  },
}