export const mutations = {
  addInstructions(state, instructions){
    state.instructions = [ ...instructions ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addInstruction(state, instruction){
    state.instructions = [ ...state.instructions, instruction ]
  },
  replaceInstruction(state, instruction){
    state.instructions = state.instructions.map(struc => struc.id === instruction.id ? instruction : struc )
  },
  setInstruction(state, instruction){
    state.instruction = instruction
  },
}