export const getters = {
  entrepriseSelectors(state){
    return state.entrepriseSelectors
  },
  entrepriseSelector(state){
    return state.entrepriseSelector
  },
  priseEnCharge(state){
    return state.priseEnCharge
  },
  pagination(state){
    return state.pagination
  }
}