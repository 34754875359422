// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchStatutProfessionnels({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/statutProfessionnels?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=statutProfessionnel`)
    .then(({ data }) =>{
      commit('addStatutProfessionnels', data)
    })
  },
  createStatutProfessionnel({ commit }, statutProfessionnel){
    return post('/statutProfessionnels', statutProfessionnel)
    .then(({ data }) => {
      commit('addStatutProfessionnel', data)
    })
  },
  updateStatutProfessionnel({ commit }, statutProfessionnel){
    return put(`/statutProfessionnels/${statutProfessionnel.id}`, statutProfessionnel)
    .then(({ data }) =>{
      commit('replaceStatutProfessionnel', data)
    })
  },
  deleteStatutProfessionnel({ commit }, statutProfessionnel){
    return remove(`/statutProfessionnels/${statutProfessionnel.id}`, statutProfessionnel)
    .then(() =>{
      commit('removeStatutProfessionnel', statutProfessionnel)
    })
  },
  createOrUpdateStatutProfessionnel({ dispatch }, statutProfessionnel){
    if(statutProfessionnel.id !== undefined){
      return dispatch('updateStatutProfessionnel', statutProfessionnel)
    }
    else {
      return dispatch('createStatutProfessionnel', statutProfessionnel)
    }
  },
  selectStatutProfessionnel({ commit }, statutProfessionnel){
    commit('setStatutProfessionnel', statutProfessionnel)
  }
}