export const mutations = {
  addCycleProductions(state, cycleproductions){
    console.log(cycleproductions)
    state.cycleProductions = [ ...cycleproductions ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addCycleProduction(state, cycleproduction){
    state.cycleProductions = [ ...state.cycleproductions, cycleproduction ]
  },
  replaceCycleProduction(state, cycleproduction){
    state.cycleProductions = state.cycleproductions.map(struc => struc.id === cycleproduction.id ? cycleproduction : struc )
  },
  setCycleProduction(state, cycleproduction){
    state.cycleProduction = cycleproduction
  },
}