export const getters = {
  ffoms(state){
    return state.ffoms
  },
  ffom(state){
    return state.ffom
  },
  pagination(state){
    return state.pagination
  }
}