export const caseConverter = {
    toCaptilizedCamelCase: (val) => {
        let lowerName = val;
        return (
            lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length)
        );
    },
    toLowercaseCamelCase: (val) => {
        return val;
    },
};
