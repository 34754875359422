import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypeOrganismes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typeOrganismes = [ ...content ]
  },
  addTypeOrganisme(state, typeOrganisme){
    state.typeOrganismes = [ typeOrganisme, ...state.typeOrganismes]
  },
  replaceTypeOrganisme(state, typeOrganisme){
    state.typeOrganismes = state.typeOrganismes.map(struc => struc.id === typeOrganisme.id ? typeOrganisme : struc )
  },
  removeTypeOrganisme(state, typeOrganisme){
    state.typeOrganismes = state.typeOrganismes.filter(struc => struc.id !== typeOrganisme.id)
  },
  setTypeOrganisme(state, typeOrganisme){
    state.typeOrganisme = typeOrganisme
  },
}