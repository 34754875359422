export const getters = {
  demandeurs(state){
    return state.demandeurs
  },
  demandeur(state){
    return state.demandeur
  },
  priseEnCharge(state){
    return state.priseEnCharge
  },
  pagination(state){
    return state.pagination
  }
}