export const getters = {
  organismeFonctions(state){
    return state.organismeFonctions
  },
  organismeFonction(state){
    return state.organismeFonction
  },
  pagination(state){
    return state.pagination
  }
}