export const getters = {
  suiviPlacements(state){
    return state.suiviPlacements
  },
  suiviPlacement(state){
    return state.suiviPlacement
  },
  pagination(state){
    return state.pagination
  }
}