import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypeContrats(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typeContrats = [ ...content ]
  },
  addTypeContrat(state, typeContrat){
    state.typeContrats = [ typeContrat, ...state.typeContrats]
  },
  replaceTypeContrat(state, typeContrat){
    state.typeContrats = state.typeContrats.map(struc => struc.id === typeContrat.id ? typeContrat : struc )
  },
  removeTypeContrat(state, typeContrat){
    state.typeContrats = state.typeContrats.filter(struc => struc.id !== typeContrat.id)
  },
  setTypeContrat(state, typeContrat){
    state.typeContrat = typeContrat
  },
}