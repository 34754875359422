import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPrerequisMicroprojets(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.prerequisMicroprojets = [ ...content ]
  },
  addPrerequisMicroprojet(state, prerequisMicroprojet){
    state.prerequisMicroprojets = [ prerequisMicroprojet, ...state.prerequisMicroprojets]
  },
  replacePrerequisMicroprojet(state, prerequisMicroprojet){
    state.prerequisMicroprojets = state.prerequisMicroprojets.map(struc => struc.id === prerequisMicroprojet.id ? prerequisMicroprojet : struc )
  },
  removePrerequisMicroprojet(state, prerequisMicroprojet){
    state.prerequisMicroprojets = state.prerequisMicroprojets.filter(struc => struc.id !== prerequisMicroprojet.id)
  },
  setPrerequisMicroprojet(state, prerequisMicroprojet){
    state.prerequisMicroprojet = prerequisMicroprojet
  },
}