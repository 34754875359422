export const mutations = {
  addNotifications(state, data){
    state.notifications = [...data.content] 
  },
  addNotification(state, notification){
    state.notifications = [ ...state.notifications, notification ]
  },
  replaceNotification(state, notification){
    state.notifications = state.notifications.map(struc => struc.id === notification.id ? notification : struc )
  },
  setNotification(state, notification){
    state.notification = notification
  },
}