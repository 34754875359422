import { post, put, get, remove } from "@/api/api";
import { buildQueryParams } from "../../utils/pagination-util";

export const actions = {
    fetchPlacements({ commit, state }, params = {}) {
        let queryParams = buildQueryParams(params, state.pagination);
        const { extraQueryArg = "" } = params;
        return get(`/placements?${queryParams}&${extraQueryArg}`).then(
            ({ data }) => {
                commit("addPlacements", data);
                return data;
            }
        );
    },
    createPlacement({ commit }, placement) {
        return post("/placements", placement).then(({ data }) => {
            commit("addPlacement", data);
            return data;
        });
    },
    updatePlacement({ commit }, placement) {
        return put(`/placements/${placement.id}`, placement).then(
            ({ data }) => {
                commit("replacePlacement", data);
                return data;
            }
        );
    },
    deletePlacement({ commit }, placement) {
        return remove(`/placements/${placement.id}`, placement).then(() => {
            commit("removePlacement", placement);
            return data;
        });
    },
    createOrUpdatePlacement({ dispatch }, placement) {
        if (placement.id !== undefined) {
            return dispatch("updatePlacement", placement);
        } else {
            return dispatch("createPlacement", placement);
        }
    },
    archiverPlacement(ctx, placement){
        return put(`/placements/${placement.id}/archiverPlacement`, placement)
        .then(({ data }) =>{
            return data
        })
    },
    selectPlacement({ commit }, placement) {
        commit("setPlacement", placement);
    },
};
