export const mutations = {
  addLangues(state, data){
    state.langues = [ ...data ]
  },
  addLangue(state, langue){
    state.langues = [ ...state.langues, langue ]
  },
  replaceLangue(state, langue){
    state.langues = state.langues.map(struc => struc.id === langue.id ? langue : struc )
  },
  setLangue(state, langue){
    state.langue = langue
  },
}