export const getters = {
  ordreMissions(state){
    return state.ordreMissions
  },
  ordreMission(state){
    return state.ordreMission
  },
  pagination(state){
    return state.pagination
  }
}