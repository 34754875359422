import { mapActions, mapGetters } from 'vuex'
import { AuthService } from '../api/user-service'
export const authMixin = {
  computed: {
    ...mapGetters({
      isLogined: 'auth/isLogined',
      isUserLogined: 'auth/isUserLogined',
      $isUserLogined: 'auth/isUserLogined',
      $user: 'auth/loginedUser',
      $loginedUser: 'auth/loginedUser',
      $roles: 'auth/roles',
      $userRole: 'auth/userRole',
      $permissions: 'auth/permissions'
    }),
    currentUserHome(){
      let roleName = AuthService.userRoleName() || ''
    //   return 'espace.' + roleName.toLowerCase()  + '.home'
      return ['ADMIN', 'DE', 'ENTREPRISE'].includes(roleName)? 'espace.' + roleName.toLowerCase()  + '.home' : 'espace.common.user.home'
    },
    $userRoleName(){
      return AuthService.userRoleName() || ''
    },
    isEntreprise() {
      return this.$userRole == "ROLE_ENTREPRISE";
    },
    mxAuthCurrentUserSpaceName(){
      let spaceName = AuthService.userRoleName() || ''
      if(this.$userRole== 'ROLE_ANTENNE' || this.$userRole== 'ROLE_CA'){
        spaceName = 'Chef Antenne'
      }
      else  if(this.$userRole== 'ROLE_ULPE'){
        spaceName = 'ULPE'
      }
      else  if(this.$userRole== 'ROLE_DE'){
        spaceName = 'Offreur de competence'
      }
      else  if(this.$userRole == 'ROLE_ADMIN'){
        spaceName = 'Administrateur'
      }
      else  if(this.$userRole == 'ROLE_AE'){
        spaceName = 'Agent Enrégistreur'
      }
      else  if(this.$userRole == 'ROLE_DG'){
        spaceName = 'Décideur'
      }
      else  if(this.$userRole == 'ROLE_DESOP'){
        spaceName = 'DESOP'
      }
      else  if(this.$userRole == 'ROLE_CCOM'){
        spaceName = 'MAIRIE'
      }
      else  if(this.$userRole == 'ROLE_PRODIJ'){
        spaceName = 'Dispositif Azoli'
      }
      else  if(this.$userRole == 'ROLE_ENTREPRISE'){
        spaceName = 'Demandeur de compétence'
      }
      return spaceName;
    },
    currentUserProfile(){
      return 'espace.'+this.$route.name.split('.')[1]+'.home'
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      forceLogout: 'auth/forceLogout',
    }),
    $userHasRole(roleName){
      roleName = roleName.toUpperCase()
      if(roleName && !roleName.startsWith('ROLE_'))
        roleName = 'ROLE_' + roleName

      return this.$userRole == roleName;

    },
    $isUserWithId(id){
      return this.$user && this.$user.user && this.$user.user.id == id
    }  
},
}