import { extractPaginationInfo, extractDataContent } from '@/utils/pagination-util'
export const mutations = {
  addOrganismes(state, data){
    const content =  extractDataContent(data)
    state.pagination = extractPaginationInfo(data)

    state.organismes = [ ...content ]
  },
  addTypeOrganismes(state, data){
    const content =  extractDataContent(data)
    state.pagination = extractPaginationInfo(data)
    state.typeOrganismes = [ ...content ]
  },
  addOrganismeFonctions(state, data){
    const content =  extractDataContent(data)
    state.pagination = extractPaginationInfo(data)
    state.organismeFonctions = [ ...content ]
  },
  addOrganisme(state, organisme){
    state.organismes = [ ...state.organismes, organisme ]
  },
  replaceOrganisme(state, organisme){
    state.organismes = state.organismes.map(struc => struc.id === organisme.id ? organisme : struc )
  },
  setOrganisme(state, organisme){
    state.organisme = organisme
  },
}