export const getters = {
  evaluationEntrepreneuriales(state){
    return state.evaluationEntrepreneuriales
  },
  evaluationEntrepreneuriale(state){
    return state.evaluationEntrepreneuriale
  },
  pagination(state){
    return state.pagination
  }
}