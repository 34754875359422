export const getters = {
  rechercherProfils(state){
    return state.rechercherProfils
  },
  rechercherProfil(state){
    return state.rechercherProfil
  },
  priseEnCharge(state){
    return state.priseEnCharge
  },
  pagination(state){
    return state.pagination
  }
}