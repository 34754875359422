import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAuditeurs(state, data){
    state.pagination = extractPaginationInfo(data)
    state.auditeurs = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addAuditeur(state, auditeur){
    state.auditeurs = [ ...state.auditeurs, auditeur ]
  },
  replaceAuditeur(state, auditeur){
    state.auditeurs = state.auditeurs.map(struc => struc.id === auditeur.id ? auditeur : struc )
  },
  removeAuditeur(state, auditeur){
    state.auditeurs = state.auditeurs.filter(cand => cand.id !== auditeur.id)
  },
  setAuditeur(state, auditeur){
    state.auditeur = auditeur
  },
  setAuditeurEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}