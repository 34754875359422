export const getters = {
  perspectiveOptions(state){
    return state.perspectiveOptions
  },
  perspectiveOption(state){
    return state.perspectiveOption
  },
  pagination(state){
    return state.pagination
  }
}