export const getters = {
  consignes(state){
    return state.consignes
  },
  consigne(state){
    return state.consigne
  },
  pagination(state){
    return state.pagination
  }
}