export const getters = {
  planFormations(state){
    return state.planFormations
  },
  planFormation(state){
    return state.planFormation
  },
  pagination(state){
    return state.pagination
  }
}