// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchTypeActivites({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typeActivites?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=typeActivite`)
    .then(({ data }) =>{
      commit('addTypeActivites', data)
    })
  },
  genererTitreActivite({ commit, state }, params = { }){
    const { id } = params
    return get(`/typeActivites/${id}/genererTitreActivite`)
    .then(({ data }) =>{
      return data
    })
  },
  createTypeActivite({ commit }, typeActivite){
    return post('/typeActivites', typeActivite)
    .then(({ data }) => {
      commit('addTypeActivite', data)
    })
  },
  updateTypeActivite({ commit }, typeActivite){
    return put(`/typeActivites/${typeActivite.id}`, typeActivite)
    .then(({ data }) =>{
      commit('replaceTypeActivite', data)
    })
  },
  deleteTypeActivite({ commit }, typeActivite){
    return remove(`/typeActivites/${typeActivite.id}`, typeActivite)
    .then(() =>{
      commit('removeTypeActivite', typeActivite)
    })
  },
  createOrUpdateTypeActivite({ dispatch }, typeActivite){
    if(typeActivite.id !== undefined){
      return dispatch('updateTypeActivite', typeActivite)
    }
    else {
      return dispatch('createTypeActivite', typeActivite)
    }
  },
  selectTypeActivite({ commit }, typeActivite){
    commit('setTypeActivite', typeActivite)
  }
}