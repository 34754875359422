export const getters = {
  priseServices(state){
    return state.priseServices
  },
  priseService(state){
    return state.priseService
  },
  pagination(state){
    return state.pagination
  }
}