// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchMoyenQualifications({ commit }){
    return get('/moyenQualifications')
    .then(({ data }) =>{
      commit('addMoyenQualifications', data)
    })
  },
  createMoyenQualification({ commit }, moyenQualification){
    return post('/moyenQualifications', moyenQualification)
    .then(({ data }) =>{
      commit('addMoyenQualification', data)
    })
  },
  updateMoyenQualification({ commit }, moyenQualification){
    return put(`/moyenQualifications/${moyenQualification.id}`, moyenQualification)
    .then(({ data }) =>{
      commit('replaceMoyenQualification', data)
    })
  },
  createOrUpdateMoyenQualification({ dispatch }, moyenQualification){
    if(moyenQualification.id !== undefined){
      return dispatch('updateMoyenQualification', moyenQualification)
    }
    else {
      return dispatch('createMoyenQualification', moyenQualification)
    }
  },
  selectMoyenQualification({ commit }, moyenQualification){
    commit('setMoyenQualification', moyenQualification)
  }
}