export const getters = {
  domaineanalyses(state){
    return state.domaineanalyses
  },

  domaineanalyse(state){
    return state.domaineanalyse
  },
  pagination(state){
    return state.pagination
  }
}