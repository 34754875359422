export const getters = {
  typePieces(state){
    return state.typePieces
  },
  communes(state){
    return state.communes
  },
  typePiece(state){
    return state.typePiece
  }
}