
export const espaceConseillerRoutes = [
  {
    path: "/esapce/conseiller",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "/espace/ae/home",
        name: "espace.ae.home",
        redirect: {
          name: "espace.common.azolis.home",
          params: { userRole: "ae" },
        },
      },
      {
        path: "/ce-home",
        name: "espace.ce.home",
        redirect: {
          name: "espace.common.azolis.home",
          params: { userRole: 'ce' },
        },
      },
      {
        path: "/demandeurs/add",
        name: "espace.ce.de.add",
        component: () => import("../views/espace/conseiller/DemandeurAdd.vue"),
      },
      {
        path: "/fiche/:demandeurId",
        name: "espace.ce.azoli.edit",
        component: () =>
          import("../views/espace/conseiller/FicheAzoliEdit.vue"),
        props: true,
      },
      {
        path: "/fiche/:demandeurId/detail",
        name: "espace.ce.de.detail",
        component: () =>
          import("../views/espace/conseiller/DemandeurDetailAzoli.vue"),
        props: true,
      },
      {
        path: "/demandeurs/list",
        name: "espace.ce.de.list",
        component: () => import("../views/espace/conseiller/PorteFeuille.vue"),
      },
     
    ],
  },
];
