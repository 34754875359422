
export const MENU_ENTREPRISE = [
  {
    to: { name: "espace.entreprise.home", params: { userRole: "entreprise" } },
    label: "Accueil",
  },
  {
    to: {
      name: "espace.entreprise.dossier.edit",
      params: { userRole: "entreprise" },
    },
    label: "Mon dossier",
  },
  {
    to: {
      name: "espace.entreprise.offre.list",
      params: { userRole: "entreprise" },
    },
    label: "Mes offres et soumissions",
  },
  {
    to: {
      name: "espace.entreprise.convention.list",
      params: { userRole: "entreprise" },
    },
    label: "Mes conventions",
  },
  // {
  //   to: {
  //     name: "espace.entreprise.presence.poste.list",
  //     params: { userRole: "entreprise" },
  //   },
  //   label: "Mes employés",
  // },
  {
    to: {
      name: "espace.entreprise.suivi-placement.list",
      params: { userRole: "entreprise" },
    },
    label: "Suivi placements",
  },
  {
    to: {
      name: "espace.common.audit.list",
      params: { userRole: "entreprise" },
    },
    label: "Audit environnemental",
  },
];