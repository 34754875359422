export const getters = {
  tentativeCandidatures(state){
    
    return state.tentativeCandidatures
  },

  tentativeCandidature(state){
    
    return state.tentativeCandidature
  },
  pagination(state){
    return state.pagination
  }
}