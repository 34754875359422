export const mutations = {
  addFormationProfessionnelles(state, formationProfessionnelles){
    state.formationProfessionnelles = [ ...formationProfessionnelles ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addFormationProfessionnelle(state, formationProfessionnelle){
    state.formationProfessionnelles = [ ...state.formationProfessionnelles, formationProfessionnelle ]
  },
  replaceFormationProfessionnelle(state, formationProfessionnelle){
    state.formationProfessionnelles = state.formationProfessionnelles.map(struc => struc.id === formationProfessionnelle.id ? formationProfessionnelle : struc )
  },
  setFormationProfessionnelle(state, formationProfessionnelle){
    state.formationProfessionnelle = formationProfessionnelle
  },
}