// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchPrivileges({ commit, state }, params = {}){
    console.clear()
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/privileges?size=${pageSize}&page=${pageNumber}&sort=name&omit=privilege`)
    .then(({ data }) =>{
      commit('addPrivileges', data)
    })
  },
  createPrivilege({ commit }, privilege){
    return post('/privileges', privilege)
    .then(({ data }) =>{
      commit('addPrivilege', data)
    })
  },
  updatePrivilege({ commit }, privilege){
    return put(`/privileges/${privilege.id}`, privilege)
    .then(({ data }) =>{
      commit('replacePrivilege', data)
    })
  },
  createOrUpdatePrivilege({ dispatch }, privilege){
    if(privilege.id !== undefined){
      return dispatch('updatePrivilege', privilege)
    }
    else {
      return dispatch('createPrivilege', privilege)
    }
  },
  selectPrivilege({ commit }, privilege){
    commit('setPrivilege', privilege)
  }
}