// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchStageFormations({ commit, state }, params = {}){
    // let { pageNumber=0, pageSize=state.pagination.size } = params
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/stageFormations?${queryParams}`)
    .then(({ data }) =>{
      commit('addStageFormations', data)
    })
  },
  createStageFormation({ commit }, stageFormation){
    return post('/stageFormations', stageFormation)
    .then(({ data }) => {
      commit('addStageFormation', data)
    })
  },
  updateStageFormation({ commit }, stageFormation){
    return put(`/stageFormations/${stageFormation.id}`, stageFormation)
    .then(({ data }) =>{
      commit('replaceStageFormation', data)
    })
  },
  deleteStageFormation({ commit }, stageFormation){
    return remove(`/stageFormations/${stageFormation.id}`, stageFormation)
    .then(() =>{
      commit('removeStageFormation', stageFormation)
    })
  },
  createOrUpdateStageFormation({ dispatch }, stageFormation){
    if(stageFormation.id !== undefined){
      return dispatch('updateStageFormation', stageFormation)
    }
    else {
      return dispatch('createStageFormation', stageFormation)
    }
  },
  selectStageFormation({ commit }, stageFormation){
    commit('setStageFormation', stageFormation)
  }
}