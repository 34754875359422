// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchDomaineAnalyses({ commit }){
    return get('/domaineanalyses?size=30&page=0')
    .then(({ data }) =>{
      commit('addDomaineAnalyses', data)
    })
  },
  createDomaineAnalyse({ commit }, domaineanalyse){
    return post('/domaineanalyses', domaineanalyse)
    .then(({ data }) =>{
      commit('addDomaineAnalyse', data)
    })
  },
  updateDomaineAnalyse({ commit }, domaineanalyse){
    return put(`/domaineanalyses/${domaineanalyse.id}`, domaineanalyse)
    .then(({ data }) =>{
      commit('replaceDomaineAnalyse', data)
    })
  },
  createOrUpdateDomaineAnalyse({ dispatch }, domaineanalyse){
    if(domaineanalyse.id !== undefined){
      return dispatch('updateDomaineAnalyse', domaineanalyse)
    }
    else {
      return dispatch('createDomaineAnalyse', domaineanalyse)
    }
  },
  selectDomaineAnalyse({ commit }, domaineanalyse){
    commit('setDomaineAnalyse', domaineanalyse)
  }
}