import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchTypePerspectives({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/typePerspectives?${queryParams}`)
    .then(({ data }) =>{
      commit('addTypePerspectives', data)
      return data
    })
  },
  fetchTypePerspective({ commit, state }, params = {}){
    let { typePerspectiveId } = params
    return get(`/typePerspectives/${typePerspectiveId}`)
    .then(({ data }) =>{
      commit('setTypePerspective', data)
      return data
    })
  },
  exportToPdf({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/typePerspectives/exportToPdf?${queryParams}`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
        // 'N° CONTRAT' : 'participationPlacement.placement.contract.numeroContrat',
        // 'DATE PLACEMENT' : '',
        'N° PLACEMENT' : 'participationPlacement.placement.reference',
        'MOIS POINT DE PRESENCE' : 'pointPresence.moisAnnee',
        'PERIODE DU' : 'pointPresence.dateDu',
        'PERIODE AU' : 'pointPresence.dateAu',
      },
    })
    .then(({ data }) =>{
      commit('setTypePerspective', data)
      return data
    })
  },
  exportToExcel({ commit, state  }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/typePerspectives/exportToExcel?${queryParams}`, 'file.xlsx', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setTypePerspective', data)
      return data
    })
  },
  createTypePerspective({ commit }, typePerspective){
    return post('/typePerspectives', typePerspective)
    .then(({ data }) =>{
      commit('addTypePerspective', data)
      return data
    })
  },
  genererTypePerspectives({ commit }, { placement }){
    return post('/typePerspectives/genererTypePerspective', {
      placement
    })
    .then(({ data }) =>{
      commit('addTypePerspectives', data)
      return data
    })
  },
  updateTypePerspective({ commit }, typePerspective){
    return put(`/typePerspectives/${typePerspective.id}`, typePerspective)
    .then(({ data }) =>{
      commit('replaceTypePerspective', data)
      commit('setTypePerspective', data)

      return data
    })
  },
  createOrUpdateTypePerspective({ dispatch }, typePerspective){
    if(typePerspective.id !== undefined){
      return dispatch('updateTypePerspective', typePerspective)
    }
    else {
      return dispatch('createTypePerspective', typePerspective)
    }
  },
  selectTypePerspective({ commit }, typePerspective){
    commit('setTypePerspective', typePerspective)
  }
}