import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addContrats(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.contrats = [ ...content ]
  },
  addContrat(state, contrat){
    state.contrats = [ contrat, ...state.contrats]
  },
  replaceContrat(state, contrat){
    state.contrats = state.contrats.map(struc => struc.id === contrat.id ? contrat : struc )
  },
  removeContrat(state, contrat){
    state.contrats = state.contrats.filter(struc => struc.id !== contrat.id)
  },
  setContrat(state, contrat){
    state.contrat = contrat
  },
}