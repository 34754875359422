// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'


export const actions = {
  fetchMoyenRechercherEmplois({ commit }){
    return get('/moyens')
    .then(({ data }) =>{
      commit('addMoyenRechercherEmplois', data)
    })
  },
  createMoyenRechercherEmploi({ commit }, moyen){
    return post('/moyens', moyen)
    .then(({ data }) =>{
      commit('addMoyenRechercherEmploi', data)
    })
  },
  updateMoyenRechercherEmploi({ commit }, moyen){
    return put(`/moyens/${moyen.id}`, moyen)
    .then(({ data }) =>{
      commit('replaceMoyenRechercherEmploi', data)
    })
  },
  createOrUpdateMoyenRechercherEmploi({ dispatch }, moyen){
    if(moyen.id !== undefined){
      return dispatch('updateMoyenRechercherEmploi', moyen)
    }
    else {
      return dispatch('createMoyenRechercherEmploi', moyen)
    }
  },
  selectMoyenRechercherEmploi({ commit }, moyen){
    commit('setMoyenRechercherEmploi', moyen)
  },
  deleteMoyenRechercherEmploi({ commit }, moyen){
    return remove(`/moyens/${moyen.id}`, moyen)
    .then(({ data }) =>{
      commit('addMoyenRechercherEmploi', data)
    })
  }
}