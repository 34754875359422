import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addConsignes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.consignes = [ ...content ]
  },
  addConsigne(state, consigne){
    state.consignes = [ consigne, ...state.consignes]
  },
  replaceConsigne(state, consigne){
    state.consignes = state.consignes.map(struc => struc.id === consigne.id ? consigne : struc )
  },
  removeConsigne(state, consigne){
    state.consignes = state.consignes.filter(struc => struc.id !== consigne.id)
  },
  setConsigne(state, consigne){
    state.consigne = consigne
  },
}