// import axios from 'axios'
import { post, put, get,remove} from '@/api/api'


export const actions = {
  fetchSubvenirBesoins({ commit }){
    return get('/sbesoin')
    .then(({ data }) =>{
      commit('addSubvenirBesoins', data)
    })
  },
  createSubvenirBesoin({ commit }, subvenirBesoin){
    return post('/sbesoin', subvenirBesoin)
    .then(({ data }) =>{
      commit('addSubvenirBesoin', data)
    })
  },
  updateSubvenirBesoin({ commit }, subvenirBesoin){
    return put(`/sbesoin/${subvenirBesoin.id}`, subvenirBesoin)
    .then(({ data }) =>{
      commit('replaceSubvenirBesoin', data)
    })
  },
  createOrUpdateSubvenirBesoin({ dispatch }, subvenirBesoin){
    if(subvenirBesoin.id !== undefined){
      return dispatch('updateSubvenirBesoin', subvenirBesoin)
    }
    else {
      return dispatch('createSubvenirBesoin', subvenirBesoin)
    }
  },
  selectSubvenirBesoin({ commit }, subvenirBesoin){
    commit('setSubvenirBesoin', subvenirBesoin)
  },
  deleteSubvenirBesoin({ commit }, subvenirBesoin){
    return remove(`/sbesoin/${subvenirBesoin.id}`, subvenirBesoin)
    .then(({ data }) =>{
      commit('addSubvenirBesoin', data)
    })
  }
}