// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'


export const actions = {
  fetchPasDeTravails({ commit }){
    return get('/pasdetravails')
    .then(({ data }) =>{
      commit('addPasDeTravails', data)
    })
  },
  createPasDeTravail({ commit }, pasdetravail){
    return post('/pasdetravails', pasdetravail)
    .then(({ data }) =>{
      commit('addPasDeTravail', data)
    })
  },
  updatePasDeTravail({ commit }, pasdetravail){
    return put(`/pasdetravails/${pasdetravail.id}`, pasdetravail)
    .then(({ data }) =>{
      commit('replacePasDeTravail', data)
    })
  },
  createOrUpdatePasDeTravail({ dispatch }, pasdetravail){
    if(pasdetravail.id !== undefined){
      return dispatch('updatePasDeTravail', pasdetravail)
    }
    else {
      return dispatch('createPasDeTravail', pasdetravail)
    }
  },
  selectPasDeTravail({ commit }, pasdetravail){
    commit('setPasDeTravail', pasdetravail)
  },
  deletePasDeTravail({ commit }, pasdetravail){
    return remove(`/pasdetravails/${pasdetravail.id}`, pasdetravail)
    .then(({ data }) =>{
      commit('addPasDeTravails', data)
    })
  }
}