// import axios from 'axios'
import { FileService } from '../../api/file-service'
import { post} from '@/api/api'


export const actions = {
  downloadFile(context, params = {}) {
    let { url, fileName } = params;
    if (url == null)
      throw Error(`'url' not provided. store.media.downloadFile`);

    if (fileName == null) fileName = url.split("/").pop();

    return FileService.downloadFile(url, fileName);
  },
  uploadSignature(context, params = {  }) {
    console.log(params);
    let responsePromise = post(this.uploadUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {},
    });

    responsePromise
      .then((resp) => {
        this.$emit("finish", resp.data);
      })
      .catch((err) => {
        console.log(err);
        this.notifyServerError();
      });

    return responsePromise;
  },
  uploadEncryptedFile(context, params = {  }) {
    console.log(params);
    let responsePromise = post(this.uploadUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {},
    });

    responsePromise
      .then((resp) => {
        this.$emit("finish", resp.data);
      })
      .catch((err) => {
        console.log(err);
        this.notifyServerError();
      });

    return responsePromise;
  },
};