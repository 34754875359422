// import axios from 'axios'
import { get } from '@/api/api'
// import ShortUniqueId from 'short-unique-id';
// const uid = new ShortUniqueId({ length: 10 });

export const actions = {
  fetchActualites({ commit, state }, params = {}){
    let { pageNumber=0, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    // queryParams = queryParams !== undefined? queryParams : 
    return get(`/nouvelles/published?size=${pageSize}&page=${pageNumber}&sorted=true&`)
    .then(({ data }) =>{
      commit('addActualites', data)
    })
  },
  fetchPetiteAnnonces({ commit, state }, params = {}){
    let { pageNumber=0, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    return get(`/nouvelles/publicites?size=${pageSize}&page=${pageNumber}&sorted=true&`)
    .then(({ data }) =>{
      commit('addPublicites', data)
    })
  },
  fetchFaqs({ commit, state }, params = {}){
    let { pageNumber=0, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    // queryParams = queryParams !== undefined? queryParams : 
    return get(`/faqs/published?size=${pageSize}&page=${pageNumber}&sorted=true&`)
    .then(({ data }) =>{
      commit('addFaqs', data)
    })
  },
  selectActualite({ commit }, actualite){
    commit('setActualite', actualite)
  }
}