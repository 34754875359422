// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
fetchFfoms({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/ffoms?${queryParams}`)
    .then(({ data }) =>{
      commit('addFfoms', data)
      return data.content
    })
  },
  fetchFfom({ commit, state }, params = {}){
    let { ffomId } = params
    return get(`/ffoms/${ffomId}`)
    .then(({ data }) =>{
      // commit('addFfom', data)
      return data
    })
  },
//   fetchFfomByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/ffoms/${ffomId}/`)
//     .then(({ data }) =>{
//       // commit('addFfom', data)
//       return data
//     })
//   },
  createFfom({ commit }, ffom){
    return post('/ffoms', ffom)
    .then(({ data }) => {
      commit('addFfom', data)
      return data
    })
  },
  updateFfom({ commit }, ffom){
    return put(`/ffoms/${ffom.id}`, ffom)
    .then(({ data }) =>{
      commit('replaceFfom', data)
      return data
    })
  },
  deleteFfom({ commit }, ffom){
    return remove(`/ffoms/${ffom.id}`, ffom)
    .then(() =>{
      commit('removeFfom', ffom)
    })
  },
  validerFfom({ commit }, ffom){
      return put(`/ffoms/${ffom.id}/valider`, ffom)
      .then(({ data }) =>{
        commit('replaceFfom', data)
        return data
      })
    },
  createOrUpdateFfom({ dispatch }, ffom){
    if(ffom.id !== undefined){
      return dispatch('updateFfom', ffom)
    }
    else {
      return dispatch('createFfom', ffom)
    }
  },
  selectFfom({ commit }, ffom){
    commit('setFfom', ffom)
  }
}