export const getters = {
  evaluationComportementales(state){
    return state.evaluationComportementales
  },
  evaluationComportementale(state){
    return state.evaluationComportementale
  },
  pagination(state){
    return state.pagination
  }
}