export const getters = {
  privileges(state){
    return state.privileges
  },
  privilege(state){
    return state.privilege
  },
  pagination(state){
    return state.pagination
  }
}