// import axios from 'axios'
import { post, put, get, downloadFile } from '@/api/api'
import ShortUniqueId from 'short-unique-id';
// import { FileService, } from '../../api/file-service';
import { API_URL } from '../../config/api-config'
import {buildQueryParams } from '../../utils/pagination-util'
const uid = new ShortUniqueId({ length: 10 });


export const actions = {
  fetchEntreprises({ commit, state, rootState }, params = {}) {
    // let { pageNumber= params.page? params.page :  0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    // queryParams = queryParams !== undefined? queryParams.replace('?','') : ''
    // let queryParams = buildQueryParams(params, state.pagination)
    // return get(`/agents/156/entreprises?${queryParams}&size=${pageSize}&page=${pageNumber}&sorted=true&`)
    // .then(({ data }) =>{
    //   commit('addEntreprises', data)
    // }).catch(err => console.error(err))
    let queryParams = buildQueryParams(params, state.pagination);
    console.log(rootState);
    return get(`/entreprises?${queryParams}`)
      .then(({ data }) => {
        commit("addEntreprises", data);
        return data
      })
      .catch((err) => console.error(err));
  },
  fetchEntreprises2({ commit, state }, params = {}) {
    let queryParams = buildQueryParams(params, state.pagination);
    return get(`/entreprises?${queryParams}`)
      .then(({ data }) => {
        commit("addEntreprises", data);
        return data
      })
      .catch((err) => console.error(err));
  },

  // fetchEntreprises2({ commit }) {
  //   let queryParams = buildQueryParams(params, state.pagination);
  //   return get(`/entreprises?${queryParams}`)
  //     .then(({ data }) => {
  //       commit("setEntreprise", data);
  //     })
  //     .catch((err) => console.error(err));
  // },
  fetchMonDossier({ commit }) {
    return get(`/entreprises/moi`)
      .then(({ data }) => {
        commit("setEntreprise", data);
        return data
      })
      .catch((err) => console.error(err));
  },
  fetchDossier({ commit }, params) {
    let { entrepriseId } = params;
    return get(`/entreprises/${entrepriseId}`)
      .then(({ data }) => {
        commit("setEntreprise", data);
        return data
      })
      .catch((err) => console.error(err));
  },

  fetchMonProfil({ commit }) {
    // let { entrepriseId } = params;
    // return get(`/oauth/profile`)
    return get(`/entreprises/moi`)
      .then(({ data }) => {
        commit("setEntreprise", data);
        console.log(data);
        return data;
      })
      .catch((err) => console.error(err));
  },
  updateDossier({ dispatch }, dossier) {
    return dispatch("updateEntreprise", dossier);
  },
  validerDossier({ commit }, entreprise) {
    const { azoli, id } = entreprise;
    return put(`/entreprises/${id}/dossiers/valider`, azoli).then(
      ({ data }) => {
        commit("replaceEntreprise", data);
        return data
      }
    );
  },
  oneEntreprise({ commit }, entreprise) {
   
    return get(`/entreprises/${entreprise.id}`, entreprise).then(({ data }) => {
      commit("replaceEntreprise", data);
      return data
    });
  },
  createEntreprise({ commit }, entreprise) {
    return post("/entreprises", entreprise).then(({ data }) => {
      commit("addEntreprise", data);
      return data
    });
  },
  downloadAzoli(context, entreprise) {
    return downloadFile(
      `${API_URL}/entreprises/${entreprise.id}/pdf`,
      `fichier${entreprise.id}.pdf`
    );
    // return Promise((resolve) => {
    //   resolve()
    // })
  },
  registerEntreprise({ commit }, entreprise) {
    // /oauth/register/entreprise
    return post("/entreprises", {
      ...entreprise,
      password: uid(),
      courriel: entreprise.courriel,
    }).then(({ data }) => {
      commit("addEntreprise", data);
      return data
    });
  },
  updateEntreprise({ commit }, entreprise) {
    // const { azoli, id } = entreprise;
    // azoli.maitriseLangues = []
    return put(`/entreprises/${entreprise.id}`, entreprise).then(({ data }) => {
      commit("replaceEntreprise", data);
    });
  },
  updateLogoEntreprise({ commit }, {entrepriseId, logoUrl}) {
    return put(`/entreprises/${entrepriseId}/updateLogo`, {
        entrepriseId,
        logoUrl
    }).then(({ data }) => {
      return data
    });
  },
  createOrUpdateEntreprise({ dispatch }, entreprise) {
    entreprise = {
      ...entreprise,
      courriel: entreprise.courriel,
    };
    // return dispatch("registerEntreprise", entreprise);
    if (entreprise.id !== undefined) {
      return dispatch("updateEntreprise", entreprise);
    } else {
      return dispatch("createEntreprise", entreprise);
    }
  },
  selectEntreprise({ commit }, entreprise) {
    commit("setEntreprise", entreprise);
  },
};