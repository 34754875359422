import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAppreciationAptitudes(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.appreciationAptitudes = [ ...content ]
  },
  addAppreciationAptitude(state, appreciationAptitude){
    state.appreciationAptitudes = [ appreciationAptitude, ...state.appreciationAptitudes]
  },
  replaceAppreciationAptitude(state, appreciationAptitude){
    state.appreciationAptitudes = state.appreciationAptitudes.map(struc => struc.id === appreciationAptitude.id ? appreciationAptitude : struc )
  },
  removeAppreciationAptitude(state, appreciationAptitude){
    state.appreciationAptitudes = state.appreciationAptitudes.filter(struc => struc.id !== appreciationAptitude.id)
  },
  setAppreciationAptitude(state, appreciationAptitude){
    state.appreciationAptitude = appreciationAptitude
  },
}