// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
    fetchEvaluationEntrepreneuriales({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/evaluationEntrepreneuriales?${queryParams}`)
    .then(({ data }) =>{
      commit('addEvaluationEntrepreneuriales', data)
      return data.content
    })
  },
  fetchEvaluationEntrepreneuriale({ commit, state }, params = {}){
    let { evaluationEntrepreneurialeId } = params
    return get(`/evaluationEntrepreneuriales/${evaluationEntrepreneurialeId}`)
    .then(({ data }) =>{
      // commit('addEvaluationEntrepreneuriale', data)
      return data
    })
  },
//   fetchEvaluationEntrepreneurialeByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/evaluationEntrepreneuriales/${evaluationEntrepreneurialeId}/`)
//     .then(({ data }) =>{
//       // commit('addEvaluationEntrepreneuriale', data)
//       return data
//     })
//   },
  createEvaluationEntrepreneuriale({ commit }, evaluationEntrepreneuriale){
    return post('/evaluationEntrepreneuriales', evaluationEntrepreneuriale)
    .then(({ data }) => {
      commit('addEvaluationEntrepreneuriale', data)
      return data
    })
  },
  updateEvaluationEntrepreneuriale({ commit }, evaluationEntrepreneuriale){
    return put(`/evaluationEntrepreneuriales/${evaluationEntrepreneuriale.id}`, evaluationEntrepreneuriale)
    .then(({ data }) =>{
      commit('replaceEvaluationEntrepreneuriale', data)
      return data
    })
  },
  deleteEvaluationEntrepreneuriale({ commit }, evaluationEntrepreneuriale){
    return remove(`/evaluationEntrepreneuriales/${evaluationEntrepreneuriale.id}`, evaluationEntrepreneuriale)
    .then(() =>{
      commit('removeEvaluationEntrepreneuriale', evaluationEntrepreneuriale)
    })
  },
  validerEvaluationEntrepreneuriale({ commit }, evaluationEntrepreneuriale){
      return put(`/evaluationEntrepreneuriales/${evaluationEntrepreneuriale.id}/valider`, evaluationEntrepreneuriale)
      .then(({ data }) =>{
        commit('replaceEvaluationEntrepreneuriale', data)
        return data
      })
    },
  createOrUpdateEvaluationEntrepreneuriale({ dispatch }, evaluationEntrepreneuriale){
    if(evaluationEntrepreneuriale.id !== undefined){
      return dispatch('updateEvaluationEntrepreneuriale', evaluationEntrepreneuriale)
    }
    else {
      return dispatch('createEvaluationEntrepreneuriale', evaluationEntrepreneuriale)
    }
  },
  selectEvaluationEntrepreneuriale({ commit }, evaluationEntrepreneuriale){
    commit('setEvaluationEntrepreneuriale', evaluationEntrepreneuriale)
  }
}