import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addMissions(state, data){
    state.pagination = extractPaginationInfo(data)
    state.missions = [...data.content] 
  },
  addMission(state, mission){
    state.missions = [ ...state.missions, mission ]
  },
  replaceMission(state, mission){
    state.missions = state.missions.map(struc => struc.id === mission.id ? mission : struc )
  },
  removeMission(state, mission){
    state.missions = state.missions.filter(cand => cand.id !== mission.id)
  },
  setMission(state, mission){
    state.mission = mission
  },
  setMissionEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}