import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addGrilles(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.grilles = [ ...content ]
  },
  addGrille(state, grille){
    state.grilles = [ grille, ...state.grilles]
  },
  replaceGrille(state, grille){
    state.grilles = state.grilles.map(struc => struc.id === grille.id ? grille : struc )
  },
  removeGrille(state, grille){
    state.grilles = state.grilles.filter(struc => struc.id !== grille.id)
  },
  setGrille(state, grille){
    state.grille = grille
  },
}