export const getters = {
  candidatures(state){
    return state.candidatures
  },
  candidature(state){
    return state.candidature
  },
  evaluation(state){
    return state.evaluation
  },
  pagination(state){
    return state.pagination
  }
}