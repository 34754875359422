// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchRegions({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/regions?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=region`)
    .then(({ data }) =>{
      commit('addRegions', data)
    })
  },
  createRegion({ commit }, region){
    return post('/regions', region)
    .then(({ data }) => {
      commit('addRegion', data)
    })
  },
  updateRegion({ commit }, region){
    return put(`/regions/${region.id}`, region)
    .then(({ data }) =>{
      commit('replaceRegion', data)
    })
  },
  deleteRegion({ commit }, region){
    return remove(`/regions/${region.id}`, region)
    .then(() =>{
      commit('removeRegion', region)
    })
  },
  createOrUpdateRegion({ dispatch }, region){
    if(region.id !== undefined){
      return dispatch('updateRegion', region)
    }
    else {
      return dispatch('createRegion', region)
    }
  },
  selectRegion({ commit }, region){
    commit('setRegion', region)
  }
}