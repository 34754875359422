export const getters = {
  serviceRendus(state){
    return state.serviceRendus
  },
  communes(state){
    return state.communes
  },
  serviceRendu(state){
    return state.serviceRendu
  }
}