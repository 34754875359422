// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchTypeOrganismes({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typeOrganismes?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=typeOrganisme`)
    .then(({ data }) =>{
      commit('addTypeOrganismes', data)
    })
  },
  createTypeOrganisme({ commit }, typeOrganisme){
    return post('/typeOrganismes', typeOrganisme)
    .then(({ data }) => {
      commit('addTypeOrganisme', data)
    })
  },
  updateTypeOrganisme({ commit }, typeOrganisme){
    return put(`/typeOrganismes/${typeOrganisme.id}`, typeOrganisme)
    .then(({ data }) =>{
      commit('replaceTypeOrganisme', data)
    })
  },
  deleteTypeOrganisme({ commit }, typeOrganisme){
    return remove(`/typeOrganismes/${typeOrganisme.id}`, typeOrganisme)
    .then(() =>{
      commit('removeTypeOrganisme', typeOrganisme)
    })
  },
  createOrUpdateTypeOrganisme({ dispatch }, typeOrganisme){
    if(typeOrganisme.id !== undefined){
      return dispatch('updateTypeOrganisme', typeOrganisme)
    }
    else {
      return dispatch('createTypeOrganisme', typeOrganisme)
    }
  },
  selectTypeOrganisme({ commit }, typeOrganisme){
    commit('setTypeOrganisme', typeOrganisme)
  }
}