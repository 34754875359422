export const getters = {
  organismes(state){
    return state.organismes
  },
  organismeFonctions(state){
    return state.organismeFonctions
  },
  typeOrganismes(state){
    return state.typeOrganismes
  },
  organisme(state){
    return state.organisme
  },
  pagination(state){
    return state.pagination
  }
}