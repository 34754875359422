import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addObjectifAntennes(state, data){
    state.pagination = extractPaginationInfo(data)
    state.objectifAntennes = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addObjectifAntenne(state, objectifAntenne){
    state.objectifAntennes = [ ...state.objectifAntennes, objectifAntenne ]
  },
  replaceObjectifAntenne(state, objectifAntenne){
    state.objectifAntennes = state.objectifAntennes.map(struc => struc.id === objectifAntenne.id ? objectifAntenne : struc )
  },
  removeObjectifAntenne(state, objectifAntenne){
    state.objectifAntennes = state.objectifAntennes.filter(cand => cand.id !== objectifAntenne.id)
  },
  setObjectifAntenne(state, objectifAntenne){
    state.objectifAntenne = objectifAntenne
  },
  setObjectifAntenneEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}