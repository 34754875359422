// import axios from 'axios'
import { post, put, get } from "@/api/api";
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  
  fetchPlanactions({ commit, state }, params = {}) {
    let queryParams = buildQueryParams(params, state.pagination)

    return get(`/planactions?${queryParams}`).then(({ data }) => {
      commit("addPlanactions", data);
    });
  },
  fetchPlanactionsConstat({ commit }, id) {
    return get(`/planactions/constat/${id}`).then(({ data }) => {
      commit("addPlanactions", data);
    });
  },

  fetchPlanactionsActionRequise({ commit }, id) {
    return get(`/planactions/actionrequise/${id}`).then(({ data }) => {
      commit("addPlanactions", data);
      
    });
  },
  fetchPlanactionsEntreprise({ commit }, id) {
    return get(`/planactions/entreprise/${id}`).then(({ data }) => {
      return data;
    });
  },

  fetchPlanactionsEntrepriseDomaine({ commit }, id) {
    return get(`/planactions/entreprise/${id}/domaine`).then(({ data }) => {
      commit("addPlanactionsSimple", data);
      return data;
    });
  },
  createPlanaction({ commit }, planaction) {
    return post("/planactions", planaction).then(({ data }) => {
      commit("addPlanaction", data);
    });
  },
  updatePlanaction({ commit }, planaction) {
    return put(`/planactions/${planaction.id}`, planaction).then(({ data }) => {
      commit("replacePlanaction", data);
    });
  },
  createOrUpdatePlanaction({ dispatch }, planaction) {
    if (planaction.id !== undefined) {
      return dispatch("updatePlanaction", planaction);
    } else {
      return dispatch("createPlanaction", planaction);
    }
  },
  selectPlanaction({ commit }, planaction) {
    commit("setPlanaction", planaction);
  },
};
