export const getters = {
  objectifAntennes(state){
    return state.objectifAntennes
  },
  objectifAntenne(state){
    return state.objectifAntenne
  },
  pagination(state){
    return state.pagination
  }
}