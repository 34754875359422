import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addMicroprojets(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.microprojets = [ ...content ]
  },
  addMicroprojet(state, microprojet){
    state.microprojets = [ microprojet, ...state.microprojets]
  },
  replaceMicroprojet(state, microprojet){
    state.microprojets = state.microprojets.map(struc => struc.id === microprojet.id ? microprojet : struc )
  },
  removeMicroprojet(state, microprojet){
    state.microprojets = state.microprojets.filter(struc => struc.id !== microprojet.id)
  },
  setMicroprojet(state, microprojet){
    state.microprojet = microprojet
  },
}