import { post, put, get, downloadFileWithAxiosPost, downloadFile } from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchEvaluationProfessionnelles({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/evaluationProfessionnelles?${queryParams}`)
    .then(({ data }) =>{
      commit('addEvaluationProfessionnelles', data)
      return data
    })
  },
  fetchEvaluationProfessionnelle({ commit, state }, params = {}){
    let { evaluationProfessionnelleId } = params
    return get(`/evaluationProfessionnelles/${evaluationProfessionnelleId}`)
    .then(({ data }) =>{
      commit('setEvaluationProfessionnelle', data)
      return data
    })
  },
  exportToPdf({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/evaluationProfessionnelles/exportToPdf?${queryParams}`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
        // 'N° CONTRAT' : 'participationPlacement.placement.contract.numeroContrat',
        // 'DATE PLACEMENT' : '',
        'N° PLACEMENT' : 'participationPlacement.placement.reference',
        'MOIS POINT DE PRESENCE' : 'pointPresence.moisAnnee',
        'PERIODE DU' : 'pointPresence.dateDu',
        'PERIODE AU' : 'pointPresence.dateAu',
      },
    })
    .then(({ data }) =>{
      commit('setEvaluationProfessionnelle', data)
      return data
    })
  },
  telechargerEvaluationProfessionnellle({}, params = {}){
    return downloadFile(`/evaluationProfessionnelles/${params.id}/exportEvaluationProfessionnelleToPdf`)
    .then(({ data }) =>{
      return data
    })
  },
  exportToExcel({ commit, state  }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/evaluationProfessionnelles/exportToExcel?${queryParams}`, 'file.xlsx', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setEvaluationProfessionnelle', data)
      return data
    })
  },
  createEvaluationProfessionnelle({ commit }, evaluationProfessionnelle){
    return post('/evaluationProfessionnelles', evaluationProfessionnelle)
    .then(({ data }) =>{
      commit('addEvaluationProfessionnelle', data)
      return data
    })
  },
  genererEvaluationProfessionnelles({ commit }, { placement }){
    return post('/evaluationProfessionnelles/genererEvaluationProfessionnelle', {
      placement
    })
    .then(({ data }) =>{
      commit('addEvaluationProfessionnelles', data)
      return data
    })
  },
  updateEvaluationProfessionnelle({ commit }, evaluationProfessionnelle){
    return put(`/evaluationProfessionnelles/${evaluationProfessionnelle.id}`, evaluationProfessionnelle)
    .then(({ data }) =>{
      commit('replaceEvaluationProfessionnelle', data)
      commit('setEvaluationProfessionnelle', data)

      return data
    })
  },
  createOrUpdateEvaluationProfessionnelle({ dispatch }, evaluationProfessionnelle){
    if(evaluationProfessionnelle.id !== undefined){
      return dispatch('updateEvaluationProfessionnelle', evaluationProfessionnelle)
    }
    else {
      return dispatch('createEvaluationProfessionnelle', evaluationProfessionnelle)
    }
  },
  selectEvaluationProfessionnelle({ commit }, evaluationProfessionnelle){
    commit('setEvaluationProfessionnelle', evaluationProfessionnelle)
  }
}