export const getters = {
  offres(state){
    return state.offres
  },
  communes(state){
    return state.communes
  },
  offre(state){
    return state.offre
  },
  pagination(state){
    return state.pagination
  }
}