// import axios from 'axios'
import { post, put, get} from '@/api/api'
// import ShortUniqueId from 'short-unique-id';
// const uid = new ShortUniqueId({ length: 10 });
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchApiClients({ commit, state }, params = {}){
    let { pageNumber=0, queryParams, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    
    return get(`/apiClients?${queryParams}&size=${pageSize}&page=${pageNumber}&sort=nom&`)
    .then(({ data }) =>{
      commit('addApiClients', data)
    })
  },
  createApiClient({ commit }, apiClient){
    return post('/apiClients', apiClient)
    .then(({ data }) =>{
      commit('addApiClient', data)
    })
  },
  registerApiClient({ commit }, apiClient){
    return post('/apiClients', {
      ...apiClient,
      courriel: apiClient.courriel
    })
    .then(({ data }) =>{
      commit('addApiClient', data)
    })
  },
  resetApiClientAccount({ commit }, apiClients){
    return put('/apiClients/resetAccount', apiClients)
    .then(({ data }) =>{
      commit('addApiClient', data)
    })
  },
  updateApiClient({ commit }, apiClient){
    return put(`/apiClients/${apiClient.id}`, apiClient)
    .then(({ data }) =>{
      commit('replaceApiClient', data)
    })
  },
  createOrUpdateApiClient({ dispatch }, apiClient){
      apiClient = {
        ...apiClient,
        courriel: apiClient.courriel
      }
      // return  dispatch('registerApiClient', apiClient)
    if(apiClient.id !== undefined){
      return dispatch('updateApiClient', apiClient)
    }
    else {
      return dispatch('createApiClient', apiClient)
    }
  },
  selectApiClient({ commit }, apiClient){
    commit('setApiClient', apiClient)
  }
}