export const getters = {
  appreciationAptitudes(state){
    return state.appreciationAptitudes
  },
  appreciationAptitude(state){
    return state.appreciationAptitude
  },
  pagination(state){
    return state.pagination
  }
}