// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
fetchCandidaturesList({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/candidatures/list?${queryParams}`)
    .then(({ data }) =>{
      commit('addCandidatures', data)
    }).catch(err => err)
  },
  fetchCandidatures({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/candidatures?${queryParams}`)
    .then(({ data }) =>{
      commit('addCandidatures', data)
    }).catch(err => err)
  },
  fetchTopNCandidatures({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/candidatures/topCandidatures?sort=evaluation.totalPoints,desc&${queryParams}`)
    .then(({ data }) =>{
      commit('addCandidatures', data)
    }).catch(err => err)
  },
  fetchCandidature({ commit, state }, params = {}){
    let { candidatureId } = params
    return get(`/candidatures/${candidatureId}`)
    .then(({ data }) =>{
      commit('setCandidature', data)
      return data
    }).catch(err => err)
  },

  fetchCandidatureEvaluation({ commit, state }, params = {}){
    let { candidatureId } = params
    return get(`/candidatures/${candidatureId}/evaluation`)
    .then(({ data }) =>{
      commit('setCandidatureEvaluation', data)
      return data
    }).catch(err => err)
  },

  autoEvaluerCandidatures({ commit }, {offreId}){
    return post(`candidatures/offres/${offreId}/autoEvaluerCandidature`, {
        offreId
    })
    .then(({ data }) =>{
      return data
    }).catch(err => err)
  },
  
  createCandidature({ commit }, candidature){
    return post('/candidatures', candidature)
    .then(({ data }) =>{
      commit('addCandidature', data)
      return data
    }).catch(err => err)
  },
  updateCandidature({ commit }, candidature){
    return put(`/candidatures/${candidature.id}`, candidature)
    .then(({ data }) =>{
      commit('replaceCandidature', data)
      commit('setCandidature', data)

      return data
    }).catch(err => err)
  },
  recommanderProfils({ commit }, params = {}){
    const { offreId, demandeurs } = params
    return post(`/offres/${offreId}/candidatures/recommanderProfils`, { offreId, demandeurs })
    .then(({ data }) =>{
      return data
    }).catch(err => err)
  },
  transmettrePropositionCandidatures({ commit }, transmission){
    let { offreId } = transmission
    console.log(transmission)
    return post(`/offres/${offreId}/candidatures/transmettrePropositions/`, transmission)
    .then(({ data }) =>{
    //   commit('setCandidature', data)
      return data
    }).catch(err => err)
  },
  accepterPropostionCandidature({ commit }, { candidatureId, placementValidation }){
    return put(`/candidatures/${candidatureId}/accepterCandidature/`, placementValidation)
    .then(({ data }) =>{
      commit('setCandidature', data)
      return data
    }).catch(err => err)
  },
  accepterCandidaturesEnMasse({ commit }, placementValidation){
    return put(`/candidatures/acceptationCandidature/acceptationMultiple`, placementValidation)
    .then(({ data }) =>{
      commit('setCandidature', data)
      return data
    }).catch(err => err)
  },
  exigerPropostionCandidature({ commit }, { candidatureId, placementValidation }){
    return put(`/candidatures/${candidatureId}/exigerCandidature/`, placementValidation)
    .then(({ data }) =>{
      commit('setCandidature', data)
      return data
    }).catch(err => err)
  },
  retirerRecommanationCandidature({ commit }, candidature){
    let { id } = candidature
    return remove(`/candidatures/${id}`)
    .then(({ data }) =>{
      commit('setCandidature', candidature)
      commit('removeCandidature', candidature)
      return data
    }).catch(err => err)
  },
  createOrUpdateCandidature({ dispatch }, candidature){
    if(candidature.id !== undefined){
      return dispatch('updateCandidature', candidature)
    }
    else {
      return dispatch('createCandidature', candidature)
    }
  },
  selectCandidature({ commit }, candidature){
    commit('setCandidature', candidature)
  }
}