import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addStatutProfessionnels(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.statutProfessionnels = [ ...content ]
  },
  addStatutProfessionnel(state, statutProfessionnel){
    state.statutProfessionnels = [ statutProfessionnel, ...state.statutProfessionnels]
  },
  replaceStatutProfessionnel(state, statutProfessionnel){
    state.statutProfessionnels = state.statutProfessionnels.map(struc => struc.id === statutProfessionnel.id ? statutProfessionnel : struc )
  },
  removeStatutProfessionnel(state, statutProfessionnel){
    state.statutProfessionnels = state.statutProfessionnels.filter(struc => struc.id !== statutProfessionnel.id)
  },
  setStatutProfessionnel(state, statutProfessionnel){
    state.statutProfessionnel = statutProfessionnel
  },
}