import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addRechercherProfils(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.rechercherProfils = [ ...content ]
  },
  addRechercherProfil(state, rechercherProfil){
    state.rechercherProfils = [ ...state.rechercherProfils, rechercherProfil ]
  },
  replaceRechercherProfil(state, rechercherProfil){
    state.rechercherProfils = state.rechercherProfils.map(struc => struc.id === rechercherProfil.id ? rechercherProfil : struc )
  },
  setRechercherProfil(state, rechercherProfil){
    state.rechercherProfil = rechercherProfil
  },
}