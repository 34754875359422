// import axios from 'axios'
import { post, put, get} from '@/api/api'
import ShortUniqueId from 'short-unique-id';
const uid = new ShortUniqueId({ length: 10 });


export const actions = {
  fetchAffectations({ commit, state }, params = {}){
    let { pageNumber=0, pageSize= params.pageSize? params.pageSize: state.pagination.size } = params
    return get(`/affectations?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addAffectations', data)
    })
  },
  createAffectation({ commit }, affectation){
    return post('/affectations', affectation)
    .then(({ data }) =>{
      commit('addAffectation', data)
    })
  },
  registerAffectation({ commit }, affectation){
    // /oauth/register/affectation
    return post('/affectations', {
      ...affectation,
      password: uid(),
      courriel: affectation.courriel
    })
    .then(({ data }) =>{
      commit('addAffectation', data)
    })
  },
  updateAffectation({ commit }, affectation){
    return put(`/affectations/${affectation.id}`, affectation)
    .then(({ data }) =>{
      commit('replaceAffectation', data)
    })
  },
  createOrUpdateAffectation({ dispatch }, affectation){
      affectation = {
        ...affectation,
        courriel: affectation.courriel
      }
      return dispatch('registerAffectation', affectation)
    // if(affectation.id !== undefined){
    //   return dispatch('updateAffectation', affectation)
    // }
    // else {
    //   return dispatch('createAffectation', affectation)
    // }
  },
  selectAffectation({ commit }, affectation){
    commit('setAffectation', affectation)
  }
}