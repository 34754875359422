// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchTypePositions({ commit }){
    return get('/typePositions')
    .then(({ data }) =>{
      commit('addTypePositions', data)
    })
  },
  createTypePosition({ commit }, typePosition){
    return post('/typePositions', typePosition)
    .then(({ data }) =>{
      commit('addTypePosition', data)
    })
  },
  updateTypePosition({ commit }, typePosition){
    return put(`/typePositions/${typePosition.id}`, typePosition)
    .then(({ data }) =>{
      commit('replaceTypePosition', data)
    })
  },
  createOrUpdateTypePosition({ dispatch }, typePosition){
    if(typePosition.id !== undefined){
      return dispatch('updateTypePosition', typePosition)
    }
    else {
      return dispatch('createTypePosition', typePosition)
    }
  },
  selectTypePosition({ commit }, typePosition){
    commit('setTypePosition', typePosition)
  }
}