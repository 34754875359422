// import axios from 'axios'
import { post, put, get } from '@/api/api'
import {buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchParticipations({ commit, state, rootState }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    console.log(rootState)
    return get(`/participations?${queryParams}`)
    .then(({ data }) => {
      commit('addParticipations', data)
      return data
    }).catch(err => console.error(err))
  },
  fetchParticipation({ commit }, params = {}){
    const { participationId } = params
    if(participationId == undefined) throw `'participationId' required in 'store.fetchParticipation.fetchParticipation' `
    return get(`/participations/${participationId}`)
    .then(({ data }) => {
      commit('setParticipation', data)
      return data

    }).catch(err => console.error(err))
  },
  createParticipation({ commit }, participation){
    return post('/participations', participation)
    .then(({ data }) => {
      commit('addParticipation', data)
      return data
    })
  },
  confirmerParticipation({ commit }, confirmation){
    const { participationId } = confirmation
    if(participationId == undefined) throw Error("'participationId' required in 'store.participation.confirmerParticipation'")
    return put(`/participations/${participationId}/confirmation`, confirmation)
    .then(({ data }) => {
      commit('replaceParticipation', data)
      return data
    })
  },
  renseignerPresence({ commit }, presence){
    const { participationId } = presence
    if(participationId == undefined) throw Error("'participationId' required in 'store.participation.confirmerParticipation'")
    return put(`/participations/${participationId}/presence`, presence)
    .then(({ data }) => {
      commit('replaceParticipation', data)
      return data
    })
  },
  updateParticipation({ commit }, participation){
    const { id } = participation
    return put(`/participations/${id}/`, participation)
    .then(({ data }) => {
      commit('replaceParticipation', data)
      return data
    })
  },
  // createOrUpdateParticipation({ dispatch }, participation){
  //   return dispatch('registerParticipation', participation)
  // },
  selectParticipation({ commit }, participation){
    commit('setParticipation', participation)
  }
}