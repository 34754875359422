export const getters = {
  notifications(state){
    return state.notifications
  },
  notification(state){
    return state.notification
  },
  pagination(state){
    return state.pagination
  }
}