import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addNaemas(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.naemas = [ ...content ]
  },

  addNaema(state, naema){
    state.naemas = [ ...state.naemas, naema ]
  },
  replaceNaema(state, naema){
    state.naemas = state.naemas.map(struc => struc.id === naema.id ? naema : struc )
  },
  setNaema(state, naema){
    state.naema = naema
  },
}