import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addScreenings(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.screenings = [ ...content ]
  },

  addScreening(state, screening){
    state.screenings = [ ...state.screenings, screening ]
  },
  
  replaceScreening(state, screening){
    state.screenings = state.screenings.map(struc => struc.id === screening.id ? screening : struc )
  },
  setScreening(state, screening){
    state.screening = screening
  },
}