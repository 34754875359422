import { post, put, get, downloadFileWithAxiosPost, downloadFile} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchPlanFormations({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/planFormations?${queryParams}`)
    .then(({ data }) =>{
      commit('addPlanFormations', data)
      return data
    })
  },
  fetchPlanFormation({ commit, state }, params = {}){
    let { planFormationId } = params
    return get(`/planFormations/${planFormationId}`)
    .then(({ data }) =>{
      commit('setPlanFormation', data)
      return data
    })
  },
  exportToPdf({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/planFormations/exportToPdf?${queryParams}`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
        // 'N° CONTRAT' : 'participationPlacement.placement.contract.numeroContrat',
        // 'DATE PLACEMENT' : '',
        'N° PLACEMENT' : 'participationPlacement.placement.reference',
        'MOIS POINT DE PRESENCE' : 'pointPresence.moisAnnee',
        'PERIODE DU' : 'pointPresence.dateDu',
        'PERIODE AU' : 'pointPresence.dateAu',
      },
    })
    .then(({ data }) =>{
      commit('setPlanFormation', data)
      return data
    })
  },
  exportToExcel({ commit, state  }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/planFormations/exportToExcel?${queryParams}`, 'file.xlsx', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setPlanFormation', data)
      return data
    })
  },
  createPlanFormation({ commit }, planFormation){
    return post('/planFormations', planFormation)
    .then(({ data }) =>{
      commit('addPlanFormation', data)
      return data
    })
  },
  telechargerPlanFormation({}, params = {}){
    return downloadFile(`/planFormations/${params.id}/exportPlanFormationToPdf`)
    .then(({ data }) =>{
      return data
    })
  },
  genererPlanFormations({ commit }, { entreprise }){
    return post('/planFormations/genererPlanFormation', {
      entreprise
    })
    .then(({ data }) =>{
      commit('addPlanFormations', data)
      return data
    })
  },
  updatePlanFormation({ commit }, planFormation){
    return put(`/planFormations/${planFormation.id}`, planFormation)
    .then(({ data }) =>{
      commit('replacePlanFormation', data)
      commit('setPlanFormation', data)

      return data
    })
  },
  createOrUpdatePlanFormation({ dispatch }, planFormation){
    if(planFormation.id !== undefined){
      return dispatch('updatePlanFormation', planFormation)
    }
    else {
      return dispatch('createPlanFormation', planFormation)
    }
  },
  selectPlanFormation({ commit }, planFormation){
    commit('setPlanFormation', planFormation)
  }
}