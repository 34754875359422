import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addRegions(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.regions = [ ...content ]
  },
  addRegion(state, region){
    state.regions = [ region, ...state.regions]
  },
  replaceRegion(state, region){
    state.regions = state.regions.map(struc => struc.id === region.id ? region : struc )
  },
  removeRegion(state, region){
    state.regions = state.regions.filter(struc => struc.id !== region.id)
  },
  setRegion(state, region){
    state.region = region
  },
}