// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

export const actions = {
  fetchTypePrescriptions({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typePrescriptions?size=${pageSize}&page=${pageNumber}&sort=libelle`)
    .then(({ data }) =>{
      commit('addTypePrescriptions', data)
    })
  },
  createTypePrescription({ commit }, typePrescription){
    return post('/typePrescriptions', typePrescription)
    .then(({ data }) => {
      commit('addTypePrescription', data)
    })
  },
  updateTypePrescription({ commit }, typePrescription){
    return put(`/typePrescriptions/${typePrescription.id}`, typePrescription)
    .then(({ data }) =>{
      commit('replaceTypePrescription', data)
    })
  },
  deleteTypePrescription({ commit }, typePrescription){
    return remove(`/typePrescriptions/${typePrescription.id}`, typePrescription)
    .then(() =>{
      commit('removeTypePrescription', typePrescription)
    })
  },
  createOrUpdateTypePrescription({ dispatch }, typePrescription){
    if(typePrescription.id !== undefined){
      return dispatch('updateTypePrescription', typePrescription)
    }
    else {
      return dispatch('createTypePrescription', typePrescription)
    }
  },
  selectTypePrescription({ commit }, typePrescription){
    commit('setTypePrescription', typePrescription)
  }
}