// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchQuestionScreenings({ commit }){
    return get('/questionscreenings')
    .then(({ data }) =>{
      commit('addQuestionScreenings', data)
    })
  },
  createQuestionScreening({ commit }, questionscreening){
    return post('/questionscreenings', questionscreening)
    .then(({ data }) =>{
      commit('setQuestionScreening', data)
      commit('addQuestionScreening', data)
    })
  },
  updateQuestionScreening({ commit }, questionscreening){
    return put(`/questionscreenings/${questionscreening.id}`, questionscreening)
    .then(({ data }) =>{
      commit('setQuestionScreening', data)
      commit('replaceQuestionScreening', data)
    })
  },
  createOrUpdateQuestionScreening({ dispatch }, questionscreening){
    if(questionscreening.id !== undefined){
      return dispatch('updateQuestionScreening', questionscreening)
    }
    else {
      return dispatch('createQuestionScreening', questionscreening)
    }
  },
  selectQuestionScreening({ commit }, questionscreening){
    commit('setQuestionScreening', questionscreening)
  }
}