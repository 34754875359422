// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchTypeOrganismes({ commit, state }, params = {}){
    console.clear()
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typeOrganismes?size=${pageSize}&page=${pageNumber}&sort=libelle`)
    .then(({ data }) =>{
      commit('addTypeOrganismes', data)
    })
  },
  fetchOrganismesByTypeOrganimeId({ commit, state }, params = {}){
    let { typeOrganismeId } = params
    if(typeOrganismeId == undefined){
      throw Error(`organisme/fetchOrganismesByTypeOrganimeId requires 'typeOrganismeId'`)
    }
    
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/organismes?typeOrganisme=${typeOrganismeId}&size=${pageSize}&page=${pageNumber}&sort=intitule`)
    .then(({ data }) =>{
      commit('addOrganismes', data)
    })
  },
  fetchOrganismeFonctionByOrganimeId({ commit, state }, params = {}){
    let { organismeId } = params
    if(organismeId == undefined){
      throw Error(`organisme/fetchOrganismeFonctionByOrganimeId requires 'organismeId'`)
    }
    
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/organismeFonctions?organismeId=${organismeId}&size=${pageSize}&page=${pageNumber}&sort=nom`)
    .then(({ data }) =>{
      commit('addOrganismeFonctions', data)
    })
  },
  fetchOrganismes({ commit, state }, params = {}){
    console.clear()
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/organismes?size=${pageSize}&page=${pageNumber}&sort=intitule`)
    .then(({ data }) =>{
      commit('addOrganismes', data)
    })
  },
  createOrganisme({ commit }, organisme){
    return post('/organismes', organisme)
    .then(({ data }) =>{
      commit('addOrganisme', data)
    })
  },
  updateOrganisme({ commit }, organisme){
    return put(`/organismes/${organisme.id}`, organisme)
    .then(({ data }) =>{
      commit('replaceOrganisme', data)
    })
  },
  createOrUpdateOrganisme({ dispatch }, organisme){
    if(organisme.id !== undefined){
      return dispatch('updateOrganisme', organisme)
    }
    else {
      return dispatch('createOrganisme', organisme)
    }
  },
  selectOrganisme({ commit }, organisme){
    commit('setOrganisme', organisme)
  }
}