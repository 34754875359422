import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypePlacements(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typePlacements = [ ...content ]
  },
  addTypePlacement(state, typePlacement){
    state.typePlacements = [ typePlacement, ...state.typePlacements]
  },
  replaceTypePlacement(state, typePlacement){
    state.typePlacements = state.typePlacements.map(struc => struc.id === typePlacement.id ? typePlacement : struc )
  },
  removeTypePlacement(state, typePlacement){
    state.typePlacements = state.typePlacements.filter(struc => struc.id !== typePlacement.id)
  },
  setTypePlacement(state, typePlacement){
    state.typePlacement = typePlacement
  },
}