// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchAvantageFinanciers({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/avantageFinanciers?size=${pageSize}&page=${pageNumber}`)
    .then(({ data }) =>{
      commit('addAvantageFinanciers', data)
    })
  },
  createAvantageFinancier({ commit }, avantageFinancier){
    return post('/avantageFinanciers', avantageFinancier)
    .then(({ data }) => {
      commit('addAvantageFinancier', data)
    })
  },
  updateAvantageFinancier({ commit }, avantageFinancier){
    return put(`/avantageFinanciers/${avantageFinancier.id}`, avantageFinancier)
    .then(({ data }) =>{
      commit('replaceAvantageFinancier', data)
    })
  },
  deleteAvantageFinancier({ commit }, avantageFinancier){
    return remove(`/avantageFinanciers/${avantageFinancier.id}`, avantageFinancier)
    .then(() =>{
      commit('removeAvantageFinancier', avantageFinancier)
    })
  },
  createOrUpdateAvantageFinancier({ dispatch }, avantageFinancier){
    if(avantageFinancier.id !== undefined){
      return dispatch('updateAvantageFinancier', avantageFinancier)
    }
    else {
      return dispatch('createAvantageFinancier', avantageFinancier)
    }
  },
  selectAvantageFinancier({ commit }, avantageFinancier){
    commit('setAvantageFinancier', avantageFinancier)
  }
}