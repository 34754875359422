export const espaceEntrepriseRoutes = [
  {
    path: "/espace/entreprise",
    // name: "espace.admin.espace/admin",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "accueil",
        name: "espace.entreprise.home",
        component: () =>
          import("../views/espace/entreprise/EntrepriseHome.vue"),
        props: true,
      },
      {
        path: "entrerpise/dossier",
        name: "espace.entreprise.dossier.edit",
        component: () =>
          import("../views/espace/entreprise/FicheEntrepriseEdit.vue"),
        // redirect: {
        //   name: 'espace.entreprise.edit'
        // }
      },
      {
        path: "/fiche-entreprise/:entrepriseId",
        name: "espace.entreprise.edit",
        component: () =>
          import("../views/espace/entreprise/FicheEntrepriseEdit.vue"),
        props: true,
      },
      {
        path: "/entreprise/add",
        name: "espace.entreprise.add",
        component: () => import("../views/espace/entreprise/EntrepriseAdd.vue"),
      },
      {
        path: "/entreprise-offre-list",
        name: "espace.entreprise.offre.list",
        component: () => import("../views/espace/entreprise/OffreList.vue"),
      },
      {
        path: "/entreprise-candidature-list",
        name: "espace.entreprise.candidature.list",
        component: () =>
          import("../views/espace/entreprise/CandidatureList.vue"),
      },
      {
        path: "/entreprise-presence-poste-list",
        name: "espace.entreprise.presence.poste.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PresencePosteList.vue"),
      },
      {
        path: "/entreprise-prise-service-list",
        name: "espace.entreprise.prise.service.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PriseServiceList.vue"),
      },
      {
        path: "/entreprise-arret-service-list",
        name: "espace.entreprise.arret.service.list",
        component: () =>
          import("../views/espace/entreprise/suivi/ArretServiceList.vue"),
      },
      {
        path: "/entreprise-point-presence-list",
        name: "espace.entreprise.point.presence.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PointPresenceList.vue"),
      },
      {
        path: "/entreprise-places-list",
        name: "espace.entreprise.places.list",
        component: () =>
          import("../views/espace/entreprise/suivi/PlacesList.vue"),
      },
      {
        path: "/entreprise-suivi-placement-list",
        name: "espace.entreprise.suivi-placement.list",
        component: () =>
          import("../views/espace/entreprise/suivi/SuiviPlacementList.vue"),
      },
      {
        path: "/entreprise-offre-add",
        name: "espace.entreprise.offre.add",
        component: () => import("../views/espace/entreprise/OffreAdd.vue"),
      },
      {
        path: "/entreprise-offre-edit/:offreId",
        name: "espace.entreprise.offre.edit",
        component: () => import("../views/espace/entreprise/OffreEdit.vue"),
        props: true,
      },
      ,
      {
        path: "/entreprise-besoins",
        name: "espace.entreprise.besoin.list",
        component: () => import("../views/espace/entreprise/BesoinList.vue"),
        props: true,
      },
      {
        path: "/entreprise-besion-add",
        name: "espace.entreprise.besion.add",
        component: () => import("../views/espace/entreprise/BesoinAdd.vue"),
      },
      {
        path: "/entreprise-convention-list",
        name: "espace.entreprise.convention.list",
        component: () =>
          import("../views/espace/entreprise/ConventionList.vue"),
      },

    ],
  },
];

