import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypeAvantages(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typeAvantages = [ ...content ]
  },
  addTypeAvantage(state, typeAvantage){
    state.typeAvantages = [ typeAvantage, ...state.typeAvantages]
  },
  replaceTypeAvantage(state, typeAvantage){
    state.typeAvantages = state.typeAvantages.map(struc => struc.id === typeAvantage.id ? typeAvantage : struc )
  },
  removeTypeAvantage(state, typeAvantage){
    state.typeAvantages = state.typeAvantages.filter(struc => struc.id !== typeAvantage.id)
  },
  setTypeAvantage(state, typeAvantage){
    state.typeAvantage = typeAvantage
  },
}