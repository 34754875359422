import { post } from "../../api/api";

export const actions = {
    genererFichesEnquetePosteProgramme({ commit }, payload) {
        const { enquetePosteProgrammeId } = payload;
        return post(
            `enquetePosteProgrammes/${enquetePosteProgrammeId}/genererFichesEnquetePosteProgramme`,
            {
                enquetePosteProgrammeId,
            }
        ).then(({ data }) => {
            return data;
        });
    },
};
