import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addParticipations(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.participations = [ ...content ]
  },
  addParticipation(state, participation){
    state.participations = [ ...state.participations, participation ]
  },
  replaceParticipation(state, participation){
    state.participations = state.participations.map(struc => struc.id === participation.id ? participation : struc )
  },
  setParticipation(state, participation){
    state.participation = participation
  },
}