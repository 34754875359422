import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addContratObjectifs(state, data){
    state.pagination = extractPaginationInfo(data)
    state.contratObjectifs = [...data.content] 
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addContratObjectif(state, contratObjectif){
    state.contratObjectifs = [ ...state.contratObjectifs, contratObjectif ]
  },
  replaceContratObjectif(state, contratObjectif){
    state.contratObjectifs = state.contratObjectifs.map(struc => struc.id === contratObjectif.id ? contratObjectif : struc )
  },
  removeContratObjectif(state, contratObjectif){
    state.contratObjectifs = state.contratObjectifs.filter(cand => cand.id !== contratObjectif.id)
  },
  setContratObjectif(state, contratObjectif){
    state.contratObjectif = contratObjectif
  },
  setContratObjectifEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}