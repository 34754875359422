// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchEquipements({ commit }){
    return get('/equipements')
    .then(({ data }) =>{
      commit('addEquipements', data)
    })
  },
  fetchEntrepriseEquipements({ commit },id){
    return get(`/equipements/entreprise/${id}`)
    .then(({ data }) =>{
      commit('addEquipements', data)
    })
  },
  createEquipement({ commit }, equipement){
    return post('/equipements', equipement)
    .then(({ data }) =>{
      commit('addEquipement', data)
    })
  },
  updateEquipement({ commit }, equipement){
    return put(`/equipements/${equipement.id}`, equipement)
    .then(({ data }) =>{
      commit('replaceEquipement', data)
    })
  },
  createOrUpdateEquipement({ dispatch }, equipement){
    if(equipement.id !== undefined){
      return dispatch('updateEquipement', equipement)
    }
    else {
      return dispatch('createEquipement', equipement)
    }
  },
  selectEquipement({ commit }, equipement){
    commit('setEquipement', equipement)
  }
}