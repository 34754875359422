export const getters = {
  programmes(state){
    return state.programmes
  },
  programme(state){
    return state.programme
  },
  pagination(state){
    return state.pagination
  }
}