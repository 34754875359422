// import axios from 'axios'
import { post, put, get, remove  } from '../../api/api'


export const actions = {
  fetchAzoliEntrepreneurs({ commit }){
    return get('/azoliEntrepreneurs')
    .then(({ data }) =>{
      commit('addAzoliEntrepreneurs', data)
      return data
    })
  },
  createAzoliEntrepreneur({ commit }, azoliEntrepreneur){
    return post('/azoliEntrepreneurs', azoliEntrepreneur)
    .then(({ data }) =>{
      commit('addAzoliEntrepreneur', data)
      return data
    })
  },
  updateAzoliEntrepreneur({ commit }, azoliEntrepreneur){
    return put(`/azoliEntrepreneurs/${azoliEntrepreneur.id}`, azoliEntrepreneur)
    .then(({ data }) =>{
      commit('replaceAzoliEntrepreneur', data)
      return data
    })
  },
  createOrUpdateAzoliEntrepreneur({ dispatch }, azoliEntrepreneur){
    if(azoliEntrepreneur.id !== undefined){
      return dispatch('updateAzoliEntrepreneur', azoliEntrepreneur)
    }
    else {
      return dispatch('createAzoliEntrepreneur', azoliEntrepreneur)
    }
  },
  deleteAzoliEntrepreneur({ commit }, {id}){
    return remove(`/azoliEntrepreneurs/${id}`)
    .then(({ data }) =>{
      return data
    })
  }
}