// import axios from 'axios'
import { post, put, get, remove, downloadFile } from '@/api/api'
import {buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchContrats({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination);
    return get(`/contrats?${queryParams}`)
    .then(({ data }) =>{
      commit('addContrats', data)
      return data
    })
  },
  fetchContrat({ commit, state }, params = {}){
    // let queryParams = buildQueryParams(params, state.pagination);
    console.clear()
    console.log(params)
    return get(`/contrats/${params.id}/`)
    .then(({ data }) =>{
      commit('setContrat', data)
      return data
    })
  },
  telechargerContrat({ commit, state }, params = {}){
    console.clear()
    console.log(params)
    return downloadFile(`/contrats/${params.id}/exportToPdf`)
    .then(({ data }) =>{
      return data
    })


    // let route = this.$router.resolve({
    //   ,
    //   query: {
    //     ...pathParams.query,
    //     metaTitle: editedMeta.title,
    //     metaSubtitle: editedMeta.subtitle
    //   }
    // });
    // // this.$xdialog.hide()
    // window.open(route.href, "_blank");
  },
  generateContrat({commit, state}, contrat) {
    return post(`/contrats/genererContrat?`, contrat)
      .then(({data}) => {
          commit('setContrat', contrat)
          commit('addContrat', data)
          return data;
      })
  },
  apposerSignatureContrat({commit, state}, params = {}) {
    return put(`/contrats/${params.contratId}/apposerSignature?`, params)
      .then(({data}) => {
          commit('setContrat', data)
          commit('replaceContrat', data)
          return data;
      })
  },
  createContrat({ commit }, contrat){
    return post('/contrats', contrat)
    .then(({ data }) => {
      commit('addContrat', data)
      return data
    })
  },
  updateContrat({ commit }, contrat){
    return put(`/contrats/${contrat.id}`, contrat)
    .then(({ data }) =>{
      commit('replaceContrat', data)
      return data
    })
  },
  deleteContrat({ commit }, contrat){
    return remove(`/contrats/${contrat.id}`, contrat)
    .then(() =>{
      commit('removeContrat', contrat)
      return data
    })
  },
  createOrUpdateContrat({ dispatch }, contrat){
    if(contrat.id !== undefined){
      return dispatch('updateContrat', contrat)
    }
    else {
      return dispatch('createContrat', contrat)
    }
  },
  selectContrat({ commit }, contrat){
    commit('setContrat', contrat)
  }
}