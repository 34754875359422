import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchContratObjectifs({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/contratObjectifs?${queryParams}`)
    .then(({ data }) =>{
      commit('addContratObjectifs', data)
      return data
    })
  },
  fetchContratObjectifParAgents({ commit, state }, { contratObjectifId, agentId}){
    return get(`/contratObjectifs/${contratObjectifId}/perAgents/${agentId}/`)
    .then(({ data }) =>{
    //   commit('addContratObjectifs', data)
      return data
    })
  },
  fetchContratObjectifParAntennes({ commit, state }, {contratObjectifId, antenneId}){
    return get(`/contratObjectifs/${contratObjectifId}/parAntennes/${antenneId}/`)
    .then(({ data }) =>{
    //   commit('addContratObjectifs', data)
      return data
    })
  },
  fetchContratObjectif({ commit, state }, params = {}){
    let { contratObjectifId } = params
    return get(`/contratObjectifs/${contratObjectifId}`)
    .then(({ data }) =>{
      commit('setContratObjectif', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { contratObjectifId } = params
    return downloadFileWithAxiosPost(`/contratObjectifs/exportToPdf`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setContratObjectif', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { contratObjectifId } = params
    return downloadFileWithAxiosPost(`/contratObjectifs/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setContratObjectif', data)
      return data
    })
  },
  createContratObjectif({ commit }, contratObjectif){
    return post('/contratObjectifs', contratObjectif)
    .then(({ data }) =>{
      commit('addContratObjectif', data)
      return data
    })
  },
  updateContratObjectif({ commit }, contratObjectif){
    return put(`/contratObjectifs/${contratObjectif.id}`, contratObjectif)
    .then(({ data }) =>{
      commit('replaceContratObjectif', data)
      commit('setContratObjectif', data)

      return data
    })
  },
  createOrUpdateContratObjectif({ dispatch }, contratObjectif){
    if(contratObjectif.id !== undefined){
      return dispatch('updateContratObjectif', contratObjectif)
    }
    else {
      return dispatch('createContratObjectif', contratObjectif)
    }
  },
  selectContratObjectif({ commit }, contratObjectif){
    commit('setContratObjectif', contratObjectif)
  }
}