// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchNationalites({ commit }){
    return get('/nationalites')
    .then(({ data }) =>{
      commit('addNationalites', data)
    })
  },
  createNationalite({ commit }, nationalite){
    return post('/nationalites', nationalite)
    .then(({ data }) =>{
      commit('addNationalite', data)
    })
  },
  updateNationalite({ commit }, nationalite){
    return put(`/nationalites/${nationalite.id}`, nationalite)
    .then(({ data }) =>{
      commit('replaceNationalite', data)
    })
  },
  createOrUpdateNationalite({ dispatch }, nationalite){
    if(nationalite.id !== undefined){
      return dispatch('updateNationalite', nationalite)
    }
    else {
      return dispatch('createNationalite', nationalite)
    }
  },
  selectNationalite({ commit }, nationalite){
    commit('setNationalite', nationalite)
  }
}