export const getters = {
  assiduites(state){
    return state.assiduites
  },
  assiduite(state){
    return state.assiduite
  },
  pagination(state){
    return state.pagination
  }
}