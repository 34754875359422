export const getters = {
  participationPlacements(state){
    return state.participationPlacements
  },
  participationPlacement(state){
    return state.participationPlacement
  },
  pagination(state){
    return state.pagination
  }
}