export const getters = {
  transfertEntreprises(state){
    return state.transfertEntreprises
  },
  transfertEntreprise(state){
    return state.transfertEntreprise
  },
  pagination(state){
    return state.pagination
  }
}