// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchServiceRendus({ commit }){
    return get('/serviceRendus')
    .then(({ data }) =>{
      commit('addServiceRendus', data)
    })
  },
  createServiceRendu({ commit }, serviceRendu){
    return post('/serviceRendus', serviceRendu)
    .then(({ data }) =>{
      commit('addServiceRendu', data)
    })
  },
  updateServiceRendu({ commit }, serviceRendu){
    return put(`/serviceRendus/${serviceRendu.id}`, serviceRendu)
    .then(({ data }) =>{
      commit('replaceServiceRendu', data)
    })
  },
  createOrUpdateServiceRendu({ dispatch }, serviceRendu){
    if(serviceRendu.id !== undefined){
      return dispatch('updateServiceRendu', serviceRendu)
    }
    else {
      return dispatch('createServiceRendu', serviceRendu)
    }
  },
  selectServiceRendu({ commit }, serviceRendu){
    commit('setServiceRendu', serviceRendu)
  }
}