export const getters = {
  niveaux(state){
    return state.niveaux
  },
  communes(state){
    return state.communes
  },
  niveau(state){
    return state.niveau
  }
}