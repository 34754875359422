// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchInterets({ commit }){
    return get('/interets')
    .then(({ data }) =>{
      commit('addInterets', data)
    })
  },
  createInteret({ commit }, interet){
    return post('/interets', interet)
    .then(({ data }) =>{
      commit('addInteret', data)
    })
  },
  updateInteret({ commit }, interet){
    return put(`/interets/${interet.id}`, interet)
    .then(({ data }) =>{
      commit('replaceInteret', data)
    })
  },
  createOrUpdateInteret({ dispatch }, interet){
    if(interet.id !== undefined){
      return dispatch('updateInteret', interet)
    }
    else {
      return dispatch('createInteret', interet)
    }
  },
  selectInteret({ commit }, interet){
    commit('setInteret', interet)
  }
}