import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addPriseServices(state, data){
    state.pagination = extractPaginationInfo(data)
    state.priseServices = [...data.content] 
  },
  replacePriseService(state, priseService){
    state.priseServices = state.priseServices.map(struc => struc.id === priseService.id ? priseService : struc )
  },
  removePriseService(state, priseService){
    state.priseServices = state.priseServices.filter(cand => cand.id !== priseService.id)
  },
  setPriseService(state, priseService){
    state.priseService = priseService
  },
  setPriseServiceEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}