import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addFfoms(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.ffoms = [ ...content ]
  },
  addFfom(state, ffom){
    state.ffoms = [ ffom, ...state.ffoms]
  },
  replaceFfom(state, ffom){
    state.ffoms = state.ffoms.map(struc => struc.id === ffom.id ? ffom : struc )
  },
  removeFfom(state, ffom){
    state.ffoms = state.ffoms.filter(struc => struc.id !== ffom.id)
  },
  setFfom(state, ffom){
    state.ffom = ffom
  },
}