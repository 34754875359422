export const getters = {
  auditeurs(state){
    return state.auditeurs
  },
  auditeur(state){
    return state.auditeur
  },
  pagination(state){
    return state.pagination
  }
}