export const getters = {
  typeContenus(state){
    return state.typeContenus
  },
  typeContenu(state){
    return state.typeContenu
  },
  pagination(state){
    return state.pagination
  }
}