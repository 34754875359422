import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPhotoConstats(state, data){
    state.pagination = extractPaginationInfo(data)
    state.photoConstats = [...data.content] 
  },
  addPhotoConstat(state, photoConstat){
    state.photoConstats = [ ...state.photoConstats, photoConstat ]
  },
  replacePhotoConstat(state, photoConstat){
    state.photoConstats = state.photoConstats.map(struc => struc.id === photoConstat.id ? photoConstat : struc )
  },
  removePhotoConstat(state, photoConstat){
    state.photoConstats = state.photoConstats.filter(cand => cand.id !== photoConstat.id)
  },
  setPhotoConstat(state, photoConstat){
    state.photoConstat = photoConstat
  },
  setPhotoConstatEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}