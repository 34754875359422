import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addEvaluationProfessionnelles(state, data){
    state.pagination = extractPaginationInfo(data)
    state.evaluationProfessionnelles = [...data.content] 
  },
  addEvaluationProfessionnelle(state, evaluationProfessionnelle){
    state.evaluationProfessionnelles = [ ...state.evaluationProfessionnelles, evaluationProfessionnelle ]
  },
  replaceEvaluationProfessionnelle(state, evaluationProfessionnelle){
    state.evaluationProfessionnelles = state.evaluationProfessionnelles.map(struc => struc.id === evaluationProfessionnelle.id ? evaluationProfessionnelle : struc )
  },
  removeEvaluationProfessionnelle(state, evaluationProfessionnelle){
    state.evaluationProfessionnelles = state.evaluationProfessionnelles.filter(cand => cand.id !== evaluationProfessionnelle.id)
  },
  setEvaluationProfessionnelle(state, evaluationProfessionnelle){
    state.evaluationProfessionnelle = evaluationProfessionnelle
  },
  setEvaluationProfessionnelleEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}