import Vue from 'vue'
import XDialogView from './xDialog.vue'

export const XDialog = {
  install(Vue, options) {
    this.EventBus = new Vue()
    
    Vue.component('x-dialog', XDialogView)
    Vue.prototype.$xdialog = {
      show(params) {
        XDialog.EventBus.$emit('show', params)
      }
    }
  }
}