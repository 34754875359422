import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addFamilles(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.familles = [ ...content ]
  },
  addFamille(state, famille){
    state.familles = [ ...state.familles, famille ]
  },
  replaceFamille(state, famille){
    state.familles = state.familles.map(struc => struc.id === famille.id ? famille : struc )
  },
  setFamille(state, famille){
    state.famille = famille
  },
}