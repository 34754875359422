import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addRoles(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.roles = [ ...content ]
  },
  addRole(state, role){
    state.roles = [ ...state.roles, role ]
  },
  replaceRole(state, role){
    state.roles = state.roles.map(struc => struc.id === role.id ? role : struc )
  },
  setRole(state, role){
    state.role = role
  },
}