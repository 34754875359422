export const getters = {
  moyenQualifications(state){
    return state.moyenQualifications
  },
  communes(state){
    return state.communes
  },
  moyenQualification(state){
    return state.moyenQualification
  }
}