export const getters = {
  auteurAudits(state){
    return state.auteurAudits
  },
  auteurAudit(state){
    return state.auteurAudit
  },
  pagination(state){
    return state.pagination
  }
}