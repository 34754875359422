import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addCandidatureSelectors(state, data){
    const { content } = data;
    state.pagination = extractPaginationInfo(data);
     state.candidatureSelectors = [...content] 

  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addCandidatureSelector(state, candidatureSelector){
    state.candidatureSelectors = [ ...state.candidatureSelectors, candidatureSelector ]
  },
  replaceCandidatureSelector(state, candidatureSelector){
    state.candidatureSelectors = state.candidatureSelectors.map(struc => struc.id === candidatureSelector.id ? candidatureSelector : struc )
  },
  setCandidatureSelector(state, candidatureSelector){
    state.candidatureSelector = candidatureSelector
  },
  setCandidatureSelectorEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}