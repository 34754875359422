export const MENU_PRODIJ = [
  {
    to: { name: 'espace.common.azolis.home', params: { userRole: 'prodij' }},
    label: 'Globale'
  },
  {
    to: { name: 'espace.common.azolis.graphs', params: { userRole: 'prodij' }},
    label: 'Graphes',
    menus : [
      {
        to : { name : 'espace.common.azolis.graphs'},
        label : 'test'
        
      }
    ]
  },
  {
    to: { name: 'espace.common.azolis.list', params: { userRole: 'prodij' }},
    label: 'Gestion des bénéficiaires'
  },
]