export const getters = {
  reponseEntrepreneuriales(state){
    return state.reponseEntrepreneuriales
  },
  reponseEntrepreneuriale(state){
    return state.reponseEntrepreneuriale
  },
  pagination(state){
    return state.pagination
  }
}