export const getters = {
  dossiers(state){
    return state.dossiers
  },
  dossier(state){
    return state.dossier
  },
  pagination(state){
    return state.pagination
  }
}