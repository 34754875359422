export const getters = {
  tageActivites(state){
    return state.tageActivites
  },
  tageActivite(state){
    return state.tageActivite
  },
  pagination(state){
    return state.pagination
  }
}