export const mutations = {
  addTypePositions(state, typePositions){
    state.typePositions = [ ...typePositions ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addTypePosition(state, typePosition){
    state.typePositions = [ ...state.typePositions, typePosition ]
  },
  replaceTypePosition(state, typePosition){
    state.typePositions = state.typePositions.map(struc => struc.id === typePosition.id ? typePosition : struc )
  },
  setTypePosition(state, typePosition){
    state.typePosition = typePosition
  },
}