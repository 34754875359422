export const getters = {
  typeConsignes(state){
    return state.typeConsignes
  },
  typeConsigne(state){
    return state.typeConsigne
  },
  pagination(state){
    return state.pagination
  }
}