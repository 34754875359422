import { post, put, get} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchBesoins({ commit, state }, params={}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/besoinsPersonnels?${queryParams}`)
    .then(({ data }) =>{
      commit('addBesoins', data)
      return data
    })
  },
  createBesoin({ commit }, besoin){
    return post('/besoinsPersonnels', besoin)
    .then(({ data }) =>{
      commit('addBesoin', data)
      return data
    })
  },
  updateBesoin({ commit }, besoin){
    return put(`/besoinsPersonnels/${besoin.id}`, besoin)
    .then(({ data }) =>{
      commit('replaceBesoin', data)
      return data
    })
  },
  createOrUpdateBesoin({ dispatch }, besoin){
    if(besoin.id !== undefined){
      return dispatch('updateBesoin', besoin)
    }
    else {
      return dispatch('createBesoin', besoin)
    }
  },
  selectBesoin({ commit }, besoin){
    commit('setBesoin', besoin)
  },
  deleteAzoliBesoin({ commit }, {id}){
    return remove(`/besoinsPersonnels/${id}`)
    .then(({ data }) =>{
      return data
    })
  }
}