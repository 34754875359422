// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
    fetchReponseEntrepreneuriales({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/reponseEntrepreneuriales?${queryParams}`)
    .then(({ data }) =>{
      commit('addReponseEntrepreneuriales', data)
      return data.content
    })
  },
  fetchReponseEntrepreneuriale({ commit, state }, params = {}){
    let { reponseEntrepreneurialeId } = params
    return get(`/reponseEntrepreneuriales/${reponseEntrepreneurialeId}`)
    .then(({ data }) =>{
      // commit('addReponseEntrepreneuriale', data)
      return data
    })
  },
//   fetchReponseEntrepreneurialeByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/reponseEntrepreneuriales/${reponseEntrepreneurialeId}/`)
//     .then(({ data }) =>{
//       // commit('addReponseEntrepreneuriale', data)
//       return data
//     })
//   },
  createReponseEntrepreneuriale({ commit }, reponseEntrepreneuriale){
    return post('/reponseEntrepreneuriales', reponseEntrepreneuriale)
    .then(({ data }) => {
      commit('addReponseEntrepreneuriale', data)
      return data
    })
  },
  updateReponseEntrepreneuriale({ commit }, reponseEntrepreneuriale){
    return put(`/reponseEntrepreneuriales/${reponseEntrepreneuriale.id}`, reponseEntrepreneuriale)
    .then(({ data }) =>{
      commit('replaceReponseEntrepreneuriale', data)
      return data
    })
  },
  deleteReponseEntrepreneuriale({ commit }, reponseEntrepreneuriale){
    return remove(`/reponseEntrepreneuriales/${reponseEntrepreneuriale.id}`, reponseEntrepreneuriale)
    .then(() =>{
      commit('removeReponseEntrepreneuriale', reponseEntrepreneuriale)
    })
  },
  validerReponseEntrepreneuriale({ commit }, reponseEntrepreneuriale){
      return put(`/reponseEntrepreneuriales/${reponseEntrepreneuriale.id}/valider`, reponseEntrepreneuriale)
      .then(({ data }) =>{
        commit('replaceReponseEntrepreneuriale', data)
        return data
      })
    },
  createOrUpdateReponseEntrepreneuriale({ dispatch }, reponseEntrepreneuriale){
    if(reponseEntrepreneuriale.id !== undefined){
      return dispatch('updateReponseEntrepreneuriale', reponseEntrepreneuriale)
    }
    else {
      return dispatch('createReponseEntrepreneuriale', reponseEntrepreneuriale)
    }
  },
  selectReponseEntrepreneuriale({ commit }, reponseEntrepreneuriale){
    commit('setReponseEntrepreneuriale', reponseEntrepreneuriale)
  }
}