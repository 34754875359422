import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addActionRequises(state, data){
    // console.log(data.content+" mutations added")
    state.actionRequises = [ ...data.content ]
    // console.log(state.actionRequises)
  },

  addActionRequise(state, actionrequise){
    state.actionRequises = [ ...state.actionRequises, actionrequise ]
  },
  replaceActionRequise(state, actionrequise){
    state.actionRequises = state.actionRequises.map(struc => struc.id === actionrequise.id ? actionrequise : struc )
  },
  setActionRequise(state, actionrequise){
    state.actionRequise = actionrequise
  },
}