export const getters = {
  prescriptions(state){
    return state.prescriptions
  },
  prescription(state){
    return state.prescription
  },
  contenuPrescriptions(state){
    return state.contenuPrescriptions
  },
  contenuPrescription(state){
    return state.contenuPrescription
  },
  pagination(state){
    return state.pagination
  }
}