import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addFonctions(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.fonctions = [ ...content ]
  },
  addFonction(state, fonction){
    state.fonctions = [ ...state.fonctions, fonction ]
  },
  replaceFonction(state, fonction){
    state.fonctions = state.fonctions.map(struc => struc.id === fonction.id ? fonction : struc )
  },
  setFonction(state, fonction){
    state.fonction = fonction
  },
}