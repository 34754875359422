export const getters = {
  stageFormations(state){
    return state.stageFormations
  },
  stageFormation(state){
    return state.stageFormation
  },
  pagination(state){
    return state.pagination
  }
}