// import axios from 'axios'
import { post, put, get, downloadFile} from '@/api/api'


export const actions = {
  fetchAudits({ commit }){
    return get('/audits')
    .then(({ data }) =>{
      commit('addAudits', data)
      return data
    })
  },
  fetchEntrepriseAudits({ commit },id){
    return get(`/audits/entreprise/${id}`)
    .then(({ data }) =>{
      commit('addAudits', data)
      return data
    })
  },
  getAuditByEntreprise({ commit },id){
    return get(`/audits/entreprise/${id}/one`)
    .then(({ data }) =>{
      return data
    })
  },
  createAudit({ commit }, audit){
    return post('/audits', audit)
    .then(({ data }) =>{
      commit('addAudit', data)
      return data
    })
  },
  telechargerRapportAudit({}, params = {}){
    return downloadFile(`/audits/${params.id}/exportRapportToPdf`, `AUDIT_${params.raisonSocial}.pdf`)
    .then(({ data }) =>{
      return data
    })
  },
  telechargerRapportAuditDocx({}, params = {}){
    return downloadFile(`/audits/${params.id}/exportRapportToDocx`, `AUDIT_${params.raisonSocial}.docx`)
    .then(({ data }) =>{
      return data
    })
  },
  telechargerPlanActionAudit({}, params = {}){
    return downloadFile(`/audits/${params.id}/exportPlanActionToPdf`, `PLAN_ACTION_${params.raisonSocial}.pdf`)
    .then(({ data }) =>{
      return data
    })
  },
  transmettreAuditPourCorrection({ commit }, {auditId}){
    return put(`/audits/${auditId}/transmettrePourCorrection`,{})
    .then(({ data }) =>{
      commit('replaceAudit', data)
      return data
    })
  },
  joindreFichierRecommandation({ commit }, audit){
    return put(`/audits/${audit.id}/lierFichierRecommandation`, audit)
    .then(({ data }) =>{
      commit('replaceAudit', data)
      return data
    })
  },
  cloturerAudit({ commit }, audit){
    return put(`/audits/${audit.id}/cloturerAudit`, audit)
    .then(({ data }) =>{
      commit('replaceAudit', data)
      return data
    })
  },
  updateAudit({ commit }, audit){
    return put(`/audits/${audit.id}`, audit)
    .then(({ data }) =>{
      commit('replaceAudit', data)
      return data
    })
  },
  createOrUpdateAudit({ dispatch }, audit){
    if(audit.id !== undefined){
      return dispatch('updateAudit', audit)
    }
    else {
      return dispatch('createAudit', audit)
    }
  },
  selectAudit({ commit }, audit){
    commit('setAudit', audit)
  }
}