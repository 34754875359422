import { post } from '@/api/api'
import { EmailVerifier } from '@/api/email-verifier'

// code: "a_3656"
// dateCreation: "2021-12-16T12:54:28.975+00:00"
// email: "ing.huguesboni@gmail.com"
// id: 4
// validated: false
// version: "2021-12-16T12:54:28.975+00:00"

export default {
  namespaced: true,
  state: {
    email: '',
    emailVerified: localStorage.getItem('token') || '',
  },
  getters: {
    isLogined () {
      return EmailVerifier.isEmailVerified()
    }
  },
  mutations: {
    setEmail (state, payload) {
      EmailVerifier.saveEmail(payload)
      state.email = payload
    }
  },
  actions: {
    async verifyEmail (context, email) {
      return new Promise((resolve, reject) => {
        return post('/validation/add', { email }).then(({ data }) => {
          console.log(data)
          // EmailVerifier.saveEmail(email)
          context.commit('setEmail', email)

          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    async verifyCode (context, code) {
      return new Promise((resolve, reject) => {
        return post('/validation/activate', { 
          code,
          email: EmailVerifier.getCurrentEmail()
        }).then(({ data }) => {
          console.log(data)

          EmailVerifier.setEmailVerified(true)
          // context.commit('setCode', { ...data })

          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    forget () {
      EmailVerifier.removeEmail()
    }
  }
}
