// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchScreenings({ commit }){
    return get('/screenings')
    .then(({ data }) =>{
      commit('addScreenings', data)
    })
  },
  fetchScreeningsMicroProjet({ commit },microprojetId){
    return get(`/screenings/microprojet/${microprojetId}`)
    .then(({ data }) =>{
      commit('setScreening', data)
      return data
    })
  },
  createScreening({ commit }, screening){
    return post('/screenings', screening)
    .then(({ data }) =>{
      commit('setScreening', data)
      commit('addScreening', data)
    })
  },
  updateScreening({ commit }, screening){
    return put(`/screenings/${screening.id}`, screening)
    .then(({ data }) =>{
      commit('setScreening', data)
      commit('replaceScreening', data)
    })
  },
  createOrUpdateScreening({ dispatch }, screening){
    if(screening.id !== undefined){
      return dispatch('updateScreening', screening)
    }
    else {
      return dispatch('createScreening', screening)
    }
  },
  selectScreening({ commit }, screening){
    commit('setScreening', screening)
  }
}