import Vue from 'vue'

Vue.directive('uppercase', {
  update: function (el) {
    el.value = el.value.toUpperCase()
    el.dispatchEvent(new Event('change')) 
  }
})

Vue.directive('telephone', {
  update: function (el) {
    el.value = Math.abs(parseInt(el.value))
  }
})

Vue.directive('maxlength', {
  bind (el, binding) {
    const maxLength = binding.expression;
    callback = (e) => {
      if (e.target.value.length > maxLength) {
        e.target.value = e.target.value.substr(0, maxLength);

        const event = document.createEvent('Event');
        event.initEvent('input', true, true);
        e.target.dispatchEvent(event);
      }
    };
    el.addEventListener('input', callback);
  },
  unbind (el) {
      el.removeEventListener('input', callback);
  }
});

Vue.directive('numeric-only', {
  bind(el, binding, vnode) {
    let handlerInput = (e) => {
      const hasNumberRgx = /^[0-9]*$/;
      const numberRgx = /[^0-9]/g;
      if (hasNumberRgx.test(e.target.value) === false) {
        const start = e.target.selectionStart;
        const end = e.target.selectionEnd;
        e.target.value = e.target.value.replace(numberRgx, '');
        e.target.setSelectionRange(start, end);
        e.target.dispatchEvent(new CustomEvent('input'))
      }
    };
    el.addEventListener('input', handlerInput);
  }
})


Vue.directive('alphabetic-only', {
  bind(el, binding, vnode) {
    let handlerInput = (e) => {
      const hasAlphabeticRgx = /^[A-Za-z]+$/;
      const alphabeticRgx = /[^a-zA-Z]+/g;
      if (hasAlphabeticRgx.test(e.target.value) === false) {
        const start = e.target.selectionStart;
        const end = e.target.selectionEnd;
        e.target.value = e.target.value.replace(alphabeticRgx, '');
        e.target.setSelectionRange(start, end);
        e.target.dispatchEvent(new CustomEvent('input'))
      }
    };
    el.addEventListener('input', handlerInput);
  }
})


Vue.directive('forceCursorToEnd', {
  update: function (el) {
    // el.focus()
    // // el.value = "1111111111111111111111111222222222";
    // el.scrollLeft = el.scrollWidth

    var len = el.value.length;
              
    // Mostly for Web Browsers
    if (el.setSelectionRange) {
        // el.focus();
        el.setSelectionRange(len, len);
    }
    else if (el.createTextRange) {
        var t = el.createTextRange();
        t.collapse(true);
        t.moveEnd('character', len);
        t.moveStart('character', len);
        t.select()
    }
  }
})

Vue.directive('positiveNumber', {
  update: function (el) {
    el.value = Math.abs(parseInt(el.value))
  }
})