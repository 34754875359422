export const mutations = {
  addMoyenRechercherEmplois(state, moyenRechercherEmplois){
    state.moyenRechercherEmplois = [ ...moyenRechercherEmplois ]
  },
  addMoyenRechercherEmploi(state, moyenRechercherEmploi){
    state.moyenRechercherEmplois = [ ...state.moyenRechercherEmplois, moyenRechercherEmploi ]
  },
  replaceMoyenRechercherEmploi(state, moyenRechercherEmploi){
    state.moyenRechercherEmplois = state.moyenRechercherEmplois.map(struc => struc.id === moyenRechercherEmploi.id ? moyenRechercherEmploi : struc )
  },
  setMoyenRechercherEmploi(state, moyenRechercherEmploi){
    state.moyenRechercherEmploi = moyenRechercherEmploi
  },
}