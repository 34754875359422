// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchFonctions({ commit, state }, params = {}){
    console.clear()
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/fonctions?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addFonctions', data)
    })
  },
  createFonction({ commit }, fonction){
    return post('/fonctions', fonction)
    .then(({ data }) =>{
      commit('addFonction', data)
    })
  },
  updateFonction({ commit }, fonction){
    return put(`/fonctions/${fonction.id}`, fonction)
    .then(({ data }) =>{
      commit('replaceFonction', data)
    })
  },
  createOrUpdateFonction({ dispatch }, fonction){
    if(fonction.id !== undefined){
      return dispatch('updateFonction', fonction)
    }
    else {
      return dispatch('createFonction', fonction)
    }
  },
  selectFonction({ commit }, fonction){
    commit('setFonction', fonction)
  }
}