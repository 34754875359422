// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchFamilles({ commit, state }, params = {}){
    console.clear()
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/familles?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=famille`)
    .then(({ data }) =>{
      commit('addFamilles', data)
    })
  },
  createFamille({ commit }, famille){
    return post('/familles', famille)
    .then(({ data }) =>{
      commit('addFamille', data)
    })
  },
  updateFamille({ commit }, famille){
    return put(`/familles/${famille.id}`, famille)
    .then(({ data }) =>{
      commit('replaceFamille', data)
    })
  },
  createOrUpdateFamille({ dispatch }, famille){
    if(famille.id !== undefined){
      return dispatch('updateFamille', famille)
    }
    else {
      return dispatch('createFamille', famille)
    }
  },
  selectFamille({ commit }, famille){
    commit('setFamille', famille)
  }
}