import { TokenService } from '@/api/localStorage'
import { MENU_ADMIN } from './menu/admin'
import { MENU_PRODIJ } from './menu/prodij'
import { MENU_ENTREPRISE } from './menu/entreprise'
import { SIDE_MENU_ENTREPRISE } from './menu-side-bar/entreprise'
import { MENU_DEMANDEUR } from './menu/demandeur'
import { SIDE_MENU_DEMANDEUR } from './menu-side-bar/demandeur'
import { MENU_AGENT } from './menu/agent'
import { SIDE_MENU_AGENT } from './menu-side-bar/agent'

const mappify = (arr) =>{
  let map = {}
  arr.forEach(el => {
    map[el.value] = el.label
  })
  return map
}


const userPrivileges = () => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
  let privileges = []
  if(user.roles instanceof Array && user.roles.length > 0){
    privileges = user.roles[0].privileges
  }
  return privileges.map(p => p.name)
}

export default {
  namespaced: true,
  
  state: {
    MENU_ADMIN,
    MENU_AGENT,
    SIDE_MENU_AGENT,
    MENU_DE: MENU_DEMANDEUR,
    SIDE_MENU_DE: SIDE_MENU_DEMANDEUR,
    MENU_PRODIJ,
    MENU_ENTREPRISE,
    SIDE_MENU_ENTREPRISE,    
    niveauAlphabetions: [
      { value: 'PAS_LIRE_PAS_ECRIRE', label: 'Je ne sais pas lire et écrire' },
      { value: 'LIRE_PAS_ECRIRE', label: 'Je peux lire mais pas écrire' },
      { value: 'LIRE_ET_ECRIRE', label: 'Je sais lire et écrire' },
    ],
    plageSalaires: [
      { value: 'MOINS_DE_40000', label: 'Moins de 52 000 FCFA' },
      { value: 'ENTRE_40000_ET_70000', label: '[52 000, 70 000 [' },
      { value: 'PLUS_DE_70000', label: '70 000 FCFA ou plus' },
    ],
    raisonEmplois: [
      { value: 'PERTE_DU_PRECEDENT', label: 'Perte de l\'emploi précédent' },
      { value: 'NOUVELLE_INSERTION', label: 'Nouvelle insertion sur le marché du travail (n\'a jamais travaillé)' },
    ],
    typeEmplois: [
      { value: 'SALARIE', label: 'Salarié' },
      { value: 'AUTO_EMPLOI', label: 'Auto-emploi' },
      { value: 'INDIFFERENT', label: 'Indifférent' },
    ], 
    typePosteRhs: [
      { value: 'PROMOTEUR', label: 'PROMOTEUR' },
      { value: 'OCCASIOMNEL', label: 'OCCASIOMNEL' },
      { value: 'PERMANENT', label: 'PERMANENT' },
    ],
    typeArretContrats: [
      { value: 'DEMISSION', label: 'Démission' },
      { value: 'ABANDON', label: 'ABandon' },
      { value: 'SUSPENSION', label: 'Suspension' },
      { value: 'FIN_CONTRAT', label: 'Fin de contrat' },
      { value: 'AUTRES', label: 'Autres' },
    ],
    statutDossiers: [
      { value: 'NOUVEAU', label: 'Sans dossier' },
      { value: 'EN_COURS', label: 'En cours' },
      { value: 'TERMINE', label: 'Terminé' },
      { value: 'VALIDE', label: 'Validé' },
    ],
    modeDeParticipations: [
      { value: 'EN_LIGNE', label: 'En ligne' },
      { value: 'SUR_SITE', label: 'Sur site' },
    ],
    typeOffres: [
      { code: 'INTERNE', label: 'OFFRE INTERNE' },
      { code: 'EXTERNE', label: 'OFFRE EXTERNE' },
    ],
    typeActualiteOptions: [
      { code: 'ACTUALITE', label: 'NOUVELLES' },
      { code: 'PETIE_ANNONCE', label: 'PETITE ANNONCE' },
    ],
    niveauServices: [
      {
        code: null,
        label: "",
      },
      {
        code: "SERVICE_DELEGUE",
        label: "SERVICE DELEGUE",
      },
      {
        code: "SERVICE_SEMI_DELEGUE",
        label: "SERVICE SEMI DELEGUE",
      }
    ],
    uniteExperiences: [
      {
        code: null,
        label: "",
      },
      {
        code: "JOUR",
        label: "JOUR",
      },
      {
        code: "SEMAINE",
        label: "SEMAINE",
      },
      {
        code: "MOIS",
        label: "MOIS",
      },
      {
        code: "ANNEE",
        label: "ANNEE",
      },
    ],
    formeJuridique:[
      { code:'SNC', label :'Société en non collectif' },
      { code:'SCS', label :'Société en commandite simple' },
      { code:'SARL', label :'Société a responsabilité limitée' },
      { code:'SA', label :'Société anonyme' },
      { code:'SEP', label :'Société en participation' },
      { code:'GIE', label :'Groupement d\'intérêt économique' },
      { code:'EI', label :'Entreprise individuelle' },
      { code:'SURL', label :'Société unipersonnelle à responsabilité limitée' },
      { code:'ONG', label :'ONG'},
      { code:'AUTRES', label :'Autre(à préciser)' },
    ],
    chiffreAffaires:[
      { code: null, label:'', description:'' },
      { code: 'ENTRE_0_ET_30_MILLIONS', label:'Entre 0 et 30 Millions non compris',description:'Micro et petite entreprise soumise au système minimal de trésorerie' },
      { code: 'ENTRE_30_ET_100_MILLIONS', label:'Entre 30 et 100 Millions non compris',description:'Moyenne entreprise soumise au système allégé de trésorerie' },
      { code: 'AU_MOINS_100_MILLIONS', label:'Au moins 100 Millions',description:'Entreprise soumise au système normal de trésorerie' }
    ],
    cycleProductions:[
      { code: null, label:'' },
      { code:'SAISONNIER', label:'Saisonnier' },
      { code:'TEMPS_PLEIN', label:'Temps plein' }
    ],
    productionRotations:[
      {code: null, label:'' },

      { code: 'OUI', label:'Oui' },
      { code: 'NON', label:'Non' }
    ],
    typePersonnels:[
      {code: null, label:'' },

      { code:'PERSONNL_DE_PRODUCTION', label: 'Effectif du personnel de production' },
      { code: 'PERSONNEL_DE_DIRECTION', label: 'Effectif du personnel de direction' },
    ],
    heureHebdomadaires: [
      {
        code: "PLEIN",
        label: "PLEIN",
      },
      {
        code: "PARTIEL",
        label: "PARTIEL",
      },
      {
        code: "SAISONNIER",
        label: "SAISONNIER",
      },
      {
        code: "AUTRE",
        label: "AUTRE",
      },
    ],
    demandeurTypeOptions: [
      { value: null, label: 'Tous' },
      { value: 'PPI', label: 'PPI' },
      { value: 'CI', label: 'CI' },
      { value: 'INSTRUIT', label: 'INSTRUIT' },
    ],
    demandeurSexeOptions: [
      { value: null, label: 'Tous' },
      { value: 'M', label: 'M' },
      { value: 'F', label: 'F' },
    ],
    demandeurVulnerabiliteOptions: [
        { value: null, label: 'Vunérabilité du DE' },
        { value: 'VULNERABLE', label: 'VULNERABLE' },
        { value: 'NON_VULNERABLE', label: 'NON VULNERABLE' },
    ],
    anneeExpriences:[
      {id: 1, code: 1, label:'1 ans'},
      {id: 2, code: 2, label:'2 ans'},
      {id: 3, code: 3, label:'3 ans'},
      {id: 4, code: 4, label:'4 ans'},
      {id: 5, code: 5, label:'5 ans et plus'},
    ],
    anneeExpriences:[
      {id: 1, code: 1, label:'1 ans'},
      {id: 2, code: 2, label:'2 ans'},
      {id: 3, code: 3, label:'3 ans'},
      {id: 4, code: 4, label:'4 ans'},
      {id: 5, code: 5, label:'5 ans et plus'},
    ]

  },
  getters: {
    userMenus(state){
      const privileges = userPrivileges()
      let userRoles = TokenService.getTokenInfo().roles || []
      
      let roleName = userRoles.length > 0? userRoles[0].slice(5, userRoles[0].length) : ''
      const isAgent = ![ 'ENTREPRISE', 'DE', 'ADMIN'].includes(roleName)
      let menus = userRoles.length > 0? (!isAgent? state['MENU_'+userRoles[0].slice(5, userRoles[0].length)] || [] : state['MENU_AGENT']) : []
      return !isAgent? menus : menus.filter(menu => (menu.privileges != null && menu.privileges.length >0? menu.privileges.filter(el =>  privileges.includes(el)) : []).length > 0)
    },
    userSideMenus(state){
      const privileges = userPrivileges()
      let userRoles = TokenService.getTokenInfo().roles || []
      let roleName = userRoles.length > 0? userRoles[0].slice(5, userRoles[0].length) : ''
      const isAgent = ![ 'ENTREPRISE', 'DE'].includes(roleName)

      let menus = userRoles.length > 0? (!isAgent? state['SIDE_MENU_'+userRoles[0].slice(5, userRoles[0].length)] || [] : state['SIDE_MENU_AGENT']) : []
      return !isAgent? menus : menus.filter(
        menu => (
          menu.privileges != null && menu.privileges.length >0?
          menu.privileges.filter(el =>  privileges.includes(el))
            : []
          ).length > 0
        )
    },
    raisonEmplois(state){
      return state.raisonEmplois
    },
    typeOffres(state){
      return state.typeOffres
    },
    typeArretContrats(state){
      return state.typeArretContrats
    },
    typeActualiteOptions(state){
      return state.typeActualiteOptions
    },
    niveauServices(state){
      return state.niveauServices
    },
    typePosteRhs(state){
      return state.typePosteRhs
    },
    heureHebdomadaires(state){
      return state.heureHebdomadaires
    },
    uniteExperiences(state){
      return state.uniteExperiences
    },
    raisonEmploisMap(state){
      return mappify(state.raisonEmplois)
    },
    typeEmplois(state){
      return state.typeEmplois
    },
    typeEmploisMap(state){
      return mappify(state.typeEmplois)
    },
    plageSalaires(state){
      return state.plageSalaires
    },
    statutDossiers(state){
      return state.statutDossiers
    },
    plageSalairesMap(state){
      return mappify(state.plageSalaires)
    },
    formeJuridique(state){
      return state.formeJuridique
    },
    chiffreAffaires(state){
      return state.chiffreAffaires
    },
    cycleProductions(state){
      return state.cycleProductions
    },
    demandeurTypeOptions(state){
      return state.demandeurTypeOptions
    },
    demandeurSexeOptions(state){
      return state.demandeurSexeOptions
    },
    demandeurVulnerabiliteOptions(state){
      return state.demandeurVulnerabiliteOptions
    },
    productionRotations(state){
      return state.productionRotations
    },
    typePersonnels(state){
      return state.typePersonnels
    },
    modeDeParticipations(state){
      return state.modeDeParticipations
    },    
    anneeExpriences(state){
      return state.anneeExpriences
    },    
  },
  mutations: {
  },
  actions: {

  }
}
