import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addActivitePlanifies(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.activitePlanifies = [ ...content ]
  },
  addActivitePlanifie(state, activitePlanifie){
    state.activitePlanifies = [ activitePlanifie, ...state.activitePlanifies]
  },
  replaceActivitePlanifie(state, activitePlanifie){
    state.activitePlanifies = state.activitePlanifies.map(struc => struc.id === activitePlanifie.id ? activitePlanifie : struc )
  },
  cloturerActivitePlanifie(state, activitePlanifieId){
    state.activitePlanifies = state.activitePlanifies.map(struc => struc.id == activitePlanifieId ? {
      ...struc,
      statusActivite: 'CLOTUREE'
    } : struc )
  },
  removeActivitePlanifie(state, activitePlanifie){
    state.activitePlanifies = state.activitePlanifies.filter(struc => struc.id !== activitePlanifie.id)
  },
  setActivitePlanifie(state, activitePlanifie){
    state.activitePlanifie = activitePlanifie
  },
  setRapportActivite(state, rapportActivite){
    state.rapportActivite = rapportActivite
  },
}