import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addCategories(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.categories = [ ...content ]
  },
  addCategorie(state, categorie){
    state.categories = [ ...state.categories, categorie ]
  },
  replaceCategorie(state, categorie){
    state.categories = state.categories.map(struc => struc.id === categorie.id ? categorie : struc )
  },
  setCategorie(state, categorie){
    state.categorie = categorie
  },
}