export const mutations = {
  addBesoins(state, besoins){
    console.log(besoins.content);
    state.besoins = [ ...besoins.content ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addBesoin(state, besoin){
    state.besoins = [ ...state.besoins, besoin ]
  },
  replaceBesoin(state, besoin){
    state.besoins = state.besoins.map(struc => struc.id === besoin.id ? besoin : struc )
  },
  setBesoin(state, besoin){
    state.besoin = besoin
  },
}