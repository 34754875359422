export const getters = {
  audits(state){
    
    return state.audits
  },

  audit(state){
    
    return state.audit
  },
  pagination(state){
    
    return state.pagination
  }
}