export const mutations = {
  addNatures(state, natures){
    state.natures = [ ...natures ]
  },
  addNature(state, nature){
    state.natures = [ ...state.natures, nature ]
  },
  replaceNature(state, nature){
    state.natures = state.natures.map(struc => struc.id === nature.id ? nature : struc )
  },
  setNature(state, nature){
    state.nature = nature
  },
}