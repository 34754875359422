export const getters = {
  evaluationCompetences(state){
    return state.evaluationCompetences
  },
  evaluationCompetence(state){
    return state.evaluationCompetence
  },
  pagination(state){
    return state.pagination
  }
}