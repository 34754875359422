import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchMissionnaires({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/missionnaires?${queryParams}`)
    .then(({ data }) =>{
      commit('addMissionnaires', data)
      return data
    })
  },
  fetchMissionnaire({ commit, state }, params = {}){
    let { missionnaireId } = params
    return get(`/missionnaires/${missionnaireId}`)
    .then(({ data }) =>{
      commit('setMissionnaire', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { missionnaireId } = params
    return downloadFileWithAxiosPost(`/missionnaires/exportToPdf`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setMissionnaire', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { missionnaireId } = params
    return downloadFileWithAxiosPost(`/missionnaires/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setMissionnaire', data)
      return data
    })
  },
  createMissionnaire({ commit }, missionnaire){
    return post('/missionnaires', missionnaire)
    .then(({ data }) =>{
      commit('addMissionnaire', data)
      return data
    })
  },
  updateMissionnaire({ commit }, missionnaire){
    return put(`/missionnaires/${missionnaire.id}`, missionnaire)
    .then(({ data }) =>{
      commit('replaceMissionnaire', data)
      commit('setMissionnaire', data)

      return data
    })
  },
  createOrUpdateMissionnaire({ dispatch }, missionnaire){
    if(missionnaire.id !== undefined){
      return dispatch('updateMissionnaire', missionnaire)
    }
    else {
      return dispatch('createMissionnaire', missionnaire)
    }
  },
  selectMissionnaire({ commit }, missionnaire){
    commit('setMissionnaire', missionnaire)
  }
}