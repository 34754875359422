export const getters = {
  payementLiquidations(state){
    return state.payementLiquidations
  },
  payementLiquidation(state){
    return state.payementLiquidation
  },
  pagination(state){
    return state.pagination
  }
}