import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPlacements(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.placements = [ ...content ]
  },
  addPlacement(state, placement){
    state.placements = [ placement, ...state.placements]
  },
  replacePlacement(state, placement){
    state.placements = state.placements.map(struc => struc.id === placement.id ? placement : struc )
  },
  removePlacement(state, placement){
    state.placements = state.placements.filter(struc => struc.id !== placement.id)
  },
  setPlacement(state, placement){
    state.placement = placement
  },
}