export const mutations = {
  addMaitriseLangues(state, maitriseLangues){
    state.maitriseLangues = [ ...maitriseLangues ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addMaitriseLangue(state, maitriseLangue){
    state.maitriseLangues = [ ...state.maitriseLangues, maitriseLangue ]
  },
  replaceMaitriseLangue(state, maitriseLangue){
    state.maitriseLangues = state.maitriseLangues.map(struc => struc.id === maitriseLangue.id ? maitriseLangue : struc )
  },
  setMaitriseLangue(state, maitriseLangue){
    state.maitriseLangue = maitriseLangue
  },
}