// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchTypePieces({ commit }){
    return get('/typePieces')
    .then(({ data }) =>{
      commit('addTypePieces', data)
    })
  },
  createTypePiece({ commit }, typePiece){
    return post('/typePieces', typePiece)
    .then(({ data }) =>{
      commit('addTypePiece', data)
    })
  },
  updateTypePiece({ commit }, typePiece){
    return put(`/typePieces/${typePiece.id}`, typePiece)
    .then(({ data }) =>{
      commit('replaceTypePiece', data)
    })
  },
  createOrUpdateTypePiece({ dispatch }, typePiece){
    if(typePiece.id !== undefined){
      return dispatch('updateTypePiece', typePiece)
    }
    else {
      return dispatch('createTypePiece', typePiece)
    }
  },
  selectTypePiece({ commit }, typePiece){
    commit('setTypePiece', typePiece)
  }
}