import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypeIndicateurs(state, data){
    state.pagination = extractPaginationInfo(data)
    state.typeIndicateurs = [...data.content] 
  },
  addTypeIndicateur(state, typeIndicateur){
    state.typeIndicateurs = [ ...state.typeIndicateurs, typeIndicateur ]
  },
  replaceTypeIndicateur(state, typeIndicateur){
    state.typeIndicateurs = state.typeIndicateurs.map(struc => struc.id === typeIndicateur.id ? typeIndicateur : struc )
  },
  removeTypeIndicateur(state, typeIndicateur){
    state.typeIndicateurs = state.typeIndicateurs.filter(cand => cand.id !== typeIndicateur.id)
  },
  setTypeIndicateur(state, typeIndicateur){
    state.typeIndicateur = typeIndicateur
  },
  setTypeIndicateurEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}