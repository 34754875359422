import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTransfertDossiers(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.transfertEntreprises = [ ...content ]
  },
  addTransfertDossier(state, transfertEntreprise){
    state.transfertEntreprises = [ ...state.transfertEntreprises, transfertEntreprise ]
  },
  replaceTransfertDossier(state, transfertEntreprise){
    state.transfertEntreprises = state.transfertEntreprises.map(el => el.id === transfertEntreprise.id ? transfertEntreprise : el )
  },
  removeTransfertDossier(state, transfertEntreprise){
    state.transfertEntreprises = state.transfertEntreprises.filter(el => el.id !== transfertEntreprise.id )
  },
  setTransfertDossier(state, transfertEntreprise){
    state.transfertEntreprise = transfertEntreprise
  },
}