export const getters = {
  candidatureSelectors(state){
    return state.candidatureSelectors
  },
  candidatureSelector(state){
    return state.candidatureSelector
  },
  evaluation(state){
    return state.evaluation
  },
  pagination(state){
    return state.pagination
  }
}