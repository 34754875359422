import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addTypeContenus(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.typeContenus = [ ...content ]
  },
  addTypeContenu(state, typeContenu){
    state.typeContenus = [ typeContenu, ...state.typeContenus]
  },
  replaceTypeContenu(state, typeContenu){
    state.typeContenus = state.typeContenus.map(struc => struc.id === typeContenu.id ? typeContenu : struc )
  },
  removeTypeContenu(state, typeContenu){
    state.typeContenus = state.typeContenus.filter(struc => struc.id !== typeContenu.id)
  },
  setTypeContenu(state, typeContenu){
    state.typeContenu = typeContenu
  },
}