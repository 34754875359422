import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchObjectifAntennes({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/objectifAntennes?${queryParams}`)
    .then(({ data }) =>{
      commit('addObjectifAntennes', data)
      return data
    })
  },
  fetchObjectifExecutiveAntennes({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/objectifAntennes/executiveAntennes?${queryParams}`)
    .then(({ data }) =>{
    //   commit('addObjectifAgents', data)
      return data
    })
  },
//   fetchObjectifParAntennes({ commit, state }, params = {}){
//     let queryParams = buildQueryParams(params, state.pagination)
//     return get(`/objectifAgents/parAntennes?${queryParams}`)
//     .then(({ data }) =>{
//       commit('addObjectifAgents', data)
//       return data
//     })
//   },
  fetchObjectifAntenne({ commit, state }, params = {}){
    let { objectifAntenneId } = params
    return get(`/objectifAntennes/${objectifAntenneId}`)
    .then(({ data }) =>{
      commit('setObjectifAntenne', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { objectifAntenneId } = params
    return downloadFileWithAxiosPost(`/objectifAntennes/exportToPdf`, 'file.pdf', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setObjectifAntenne', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { objectifAntenneId } = params
    return downloadFileWithAxiosPost(`/objectifAntennes/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setObjectifAntenne', data)
      return data
    })
  },
  createObjectifAntenne({ commit }, objectifAntenne){
    return post('/objectifAntennes', objectifAntenne)
    .then(({ data }) =>{
      commit('addObjectifAntenne', data)
      return data
    })
  },
  updateObjectifAntenne({ commit }, objectifAntenne){
    return put(`/objectifAntennes/${objectifAntenne.id}`, objectifAntenne)
    .then(({ data }) =>{
      commit('replaceObjectifAntenne', data)
      commit('setObjectifAntenne', data)

      return data
    })
  },
  createOrUpdateObjectifAntenne({ dispatch }, objectifAntenne){
    if(objectifAntenne.id !== undefined){
      return dispatch('updateObjectifAntenne', objectifAntenne)
    }
    else {
      return dispatch('createObjectifAntenne', objectifAntenne)
    }
  },
  selectObjectifAntenne({ commit }, objectifAntenne){
    commit('setObjectifAntenne', objectifAntenne)
  }
}