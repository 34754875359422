// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
    fetchCredits({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/credits?${queryParams}`)
    .then(({ data }) =>{
      commit('addCredits', data)
      return data.content
    })
  },
  fetchCredit({ commit, state }, params = {}){
    let { creditId } = params
    return get(`/credits/${creditId}`)
    .then(({ data }) =>{
      // commit('addCredit', data)
      return data
    })
  },
//   fetchCreditByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/credits/${creditId}/`)
//     .then(({ data }) =>{
//       // commit('addCredit', data)
//       return data
//     })
//   },
  createCredit({ commit }, credit){
    return post('/credits', credit)
    .then(({ data }) => {
      commit('addCredit', data)
      return data
    })
  },
  updateCredit({ commit }, credit){
    return put(`/credits/${credit.id}`, credit)
    .then(({ data }) =>{
      commit('replaceCredit', data)
      return data
    })
  },
  deleteCredit({ commit }, credit){
    return remove(`/credits/${credit.id}`, credit)
    .then(() =>{
      commit('removeCredit', credit)
    })
  },
  validerCredit({ commit }, credit){
      return put(`/credits/${credit.id}/valider`, credit)
      .then(({ data }) =>{
        commit('replaceCredit', data)
        return data
      })
    },
  createOrUpdateCredit({ dispatch }, credit){
    if(credit.id !== undefined){
      return dispatch('updateCredit', credit)
    }
    else {
      return dispatch('createCredit', credit)
    }
  },
  selectCredit({ commit }, credit){
    commit('setCredit', credit)
  }
}