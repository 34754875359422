// import axios from 'axios'
import { post, put, get, remove  } from '../../api/api'


export const actions = {
  fetchAzoliSalaries({ commit }){
    return get('/azoliSalaries')
    .then(({ data }) =>{
      commit('addAzoliSalaries', data)
      return data
    })
  },
  createAzoliSalarie({ commit }, azoliSalarie){
    return post('/azoliSalaries', azoliSalarie)
    .then(({ data }) =>{
      commit('addAzoliSalarie', data)
      return data
    })
  },
  updateAzoliSalarie({ commit }, azoliSalarie){
    return put(`/azoliSalaries/${azoliSalarie.id}`, azoliSalarie)
    .then(({ data }) =>{
      commit('replaceAzoliSalarie', data)
      return data
    })
  },
  createOrUpdateAzoliSalarie({ dispatch }, azoliSalarie){
    if(azoliSalarie.id !== undefined){
      return dispatch('updateAzoliSalarie', azoliSalarie)
    }
    else {
      return dispatch('createAzoliSalarie', azoliSalarie)
    }
  },
  deleteAzoliSalarie({ commit }, {id}){
    return remove(`/azoliSalaries/${id}`)
    .then(({ data }) =>{
      return data
    })
  }
}