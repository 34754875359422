export const mutations = {
  addChiffreAffaires(state, chiffreaffaires){
    state.chiffreAffaires = [ ...chiffreaffaires ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addChiffreAffaire(state, chiffreaffaire){
    state.chiffreAffaires = [ ...state.chiffreAffaires, chiffreaffaire ]
  },
  replaceChiffreAffaire(state, chiffreaffaire){
    state.chiffreAffaires = state.chiffreAffaires.map(struc => struc.id === chiffreaffaire.id ? chiffreaffaire : struc )
  },
  setChiffreAffaire(state, chiffreaffaire){
    state.chiffreAffaire = chiffreaffaire
  },
}