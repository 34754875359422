export const getters = {
  actualites(state){
    return state.actualites
  },
  publicites(state){
    return state.publicites
  },
  faqs(state){
    return state.faqs
  },
  actualite(state){
    return state.actualite
  },
  pagination(state){
    return state.pagination
  }
}