export const mutations = {
  addNatureContrats(state, data){
    state.natureContrats = [ ...data ]
  },
  addNatureContrat(state, natureContrat){
    state.natureContrats = [ natureContrat, ...state.natureContrats]
  },
  replaceNatureContrat(state, natureContrat){
    state.natureContrats = state.natureContrats.map(struc => struc.id === natureContrat.id ? natureContrat : struc )
  },
  removeNatureContrat(state, natureContrat){
    state.natureContrats = state.natureContrats.filter(struc => struc.id !== natureContrat.id)
  },
  setNatureContrat(state, natureContrat){
    state.natureContrat = natureContrat
  },
}