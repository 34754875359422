import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPrescriptions(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.prescriptions = [ ...content ]
  },
  addPrescription(state, prescription){
    state.prescriptions = [ prescription, ...state.prescriptions]
  },
  replacePrescription(state, prescription){
    state.prescriptions = state.prescriptions.map(struc => struc.id === prescription.id ? prescription : struc )
  },
  removePrescription(state, prescription){
    state.prescriptions = state.prescriptions.filter(struc => struc.id !== prescription.id)
  },
  setPrescription(state, prescription){
    state.prescription = prescription
  },

  addContenuPrescriptions(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.contenuPrescriptions = [ ...content ]
  },
  addContenuPrescription(state, contenuPrescription){
    state.prescriptions = [ contenuPrescription, ...state.contenuPrescriptions]
  },
  replaceContenuPrescription(state, contenuPrescription){
    state.contenuPrescriptions = state.contenuPrescriptions.map(struc => struc.id === contenuPrescription.id ? contenuPrescription : struc )
  },
  removeContenuPrescription(state, contenuPrescription){
    state.contenuPrescriptions = state.contenuPrescriptions.filter(struc => struc.id !== contenuPrescription.id)
  },
  setContenuPrescription(state, contenuPrescription){
    state.contenuPrescription = contenuPrescription
  },
}