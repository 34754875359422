import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAssiduites(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.assiduites = [ ...content ]
  },
  addAssiduite(state, assiduite){
    state.assiduites = [ assiduite, ...state.assiduites]
  },
  replaceAssiduite(state, assiduite){
    state.assiduites = state.assiduites.map(struc => struc.id === assiduite.id ? assiduite : struc )
  },
  removeAssiduite(state, assiduite){
    state.assiduites = state.assiduites.filter(struc => struc.id !== assiduite.id)
  },
  setAssiduite(state, assiduite){
    state.assiduite = assiduite
  },
}