export const getters = {
  antennes(state){
    return state.antennes
  },
  antenne(state){
    return state.antenne
  },
  pagination(state){
    return state.pagination
  }
}