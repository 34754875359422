export const getters = {
  besoins(state) {
    return state.besoins;
  },
  communes(state) {
    return state.communes;
  },
  besoin(state) {
    return state.besoin;
  },
  pagination(state) {
    return state.pagination;
  },
};