export const getters = {
  liquidations(state){
    return state.liquidations
  },
  liquidation(state){
    return state.liquidation
  },
  pagination(state){
    return state.pagination
  }
}