// import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addActualites(state, data){
    const { content } = data
    console.log(content)
  // state.pagination = extractPaginationInfo(data)

    state.actualites = [ ...content ]
  },
  addActualite(state, actualite){
    state.actualites = [ ...state.actualites, actualite ]
  },
  replaceActualite(state, actualite){
    state.actualites = state.actualites.map(struc => struc.id === actualite.id ? actualite : struc )
  },
  setActualite(state, actualite){
    state.actualite = actualite
  },
}