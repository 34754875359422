import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAffectations(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.affectations = [ ...content ]
  },
  addAffectation(state, affectation){
    state.affectations = [ ...state.affectations, affectation ]
  },
  replaceAffectation(state, affectation){
    state.affectations = state.affectations.map(struc => struc.id === affectation.id ? affectation : struc )
  },
  setAffectation(state, affectation){
    state.affectation = affectation
  },
}