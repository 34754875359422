export const getters = {
  competences(state){
    return state.competences
  },
  communes(state){
    return state.communes
  },
  competence(state){
    return state.competence
  }
}