import { post, put, get, remove} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchPriseServices({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/priseServices?${queryParams}`)
    .then(({ data }) =>{
      commit('addPriseServices', data)
      return data
    })
  },
  fetchPriseService({ commit, state }, params = {}){
    let { priseServiceId } = params
    return get(`/priseServices/${priseServiceId}`)
    .then(({ data }) =>{
      commit('setPriseService', data)
      return data
    })
  },
  createPriseService({ commit }, priseService){
    return post('/priseServices', priseService)
    .then(({ data }) =>{
      commit('addPriseService', data)
      return data
    })
  },
  updatePriseService({ commit }, priseService){
    return put(`/priseServices/${priseService.id}`, priseService)
    .then(({ data }) =>{
      commit('replacePriseService', data)
      commit('setPriseService', data)

      return data
    })
  },
  createOrUpdatePriseService({ dispatch }, priseService){
    if(priseService.id !== undefined){
      return dispatch('updatePriseService', priseService)
    }
    else {
      return dispatch('createPriseService', priseService)
    }
  },
  selectPriseService({ commit }, priseService){
    commit('setPriseService', priseService)
  }
}