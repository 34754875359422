import { actionsBuilder } from "./actions";
import { mutationsBuilder } from "./mutations";
import { gettersBuilder } from "./getters";
import { stateBuilder } from "./state";


export const buildStores = (storeNames) => {
    let storeMap = {};
    storeNames.forEach((storeName) => {
      storeMap[storeName] = buildStore(storeName);
    });
    return storeMap;
};

  export const buildStore = (storeName) => {
    return {
        namespaced: true,
        state: stateBuilder(storeName),
        mutations: mutationsBuilder(storeName),
        getters: gettersBuilder(storeName),
        actions: actionsBuilder(storeName),
    };
};
