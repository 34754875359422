// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchTentativeCandidatures({ commit }){
    return get('/tentativeCandidatures')
    .then(({ data }) =>{
      commit('addTentativeCandidatures', data)
      
    })
  },
  fetchEntrepriseTentativeCandidatures({ commit },id){
    return get(`/tentativeCandidatures/entreprise/${id}`)
    .then(({ data }) =>{
      commit('addTentativeCandidatures', data)
      
    })
  },
  createTentativeCandidature({ commit }, tentativeCandidature){
    return post('/tentativeCandidatures', tentativeCandidature)
    .then(({ data }) =>{
      commit('addTentativeCandidature', data)
      
    })
  },
  updateTentativeCandidature({ commit }, tentativeCandidature){
    return put(`/tentativeCandidatures/${tentativeCandidature.id}`, tentativeCandidature)
    .then(({ data }) =>{
      commit('replaceTentativeCandidature', data)
      
    })
  },
  createOrUpdateTentativeCandidature({ dispatch }, tentativeCandidature){
    if(tentativeCandidature.id !== undefined){
      return dispatch('updateTentativeCandidature', tentativeCandidature)
    }
    else {
      return dispatch('createTentativeCandidature', tentativeCandidature)
    }
    
  },
  selectTentativeCandidature({ commit }, tentativeCandidature){
    commit('setTentativeCandidature', tentativeCandidature)
  }
}