export const mutations = {
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addCommune(state, commune){
    state.communes = [ ...state.communes, commune ]
  },
  replaceCommune(state, commune){
    state.communes = state.communes.map(struc => struc.id === commune.id ? commune : struc )
  },
  setCommune(state, commune){
    state.commune = commune
  },
}