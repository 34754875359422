export const getters = {
  zoneVulnerables(state){
    return state.zoneVulnerables
  },
  zoneVulnerable(state){
    return state.zoneVulnerable
  },
  pagination(state){
    return state.pagination
  }
}