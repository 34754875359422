import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addAtouts(state, data){
    console.log(data)
    state.atouts = [ ...data.content ]
  },

  addAtout(state, atout){
    state.atouts = [ ...state.atouts, atout ]
  },
  replaceAtout(state, atout){
    state.atouts = state.atouts.map(struc => struc.id === atout.id ? atout : struc )
  },
  setAtout(state, atout){
    state.atout = atout
  },
}