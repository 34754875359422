export const getters = {
  langues(state){
    return state.langues
  },
  langue(state){
    return state.langue
  },
  pagination(state){
    return state.pagination
  }
}