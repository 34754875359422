export const mutations = {
  addPasDeTravails(state, pasDeTravails){
    state.pasDeTravails = [ ...pasDeTravails ]
  },
  addPasDeTravail(state, pasDeTravail){
    state.pasDeTravails = [ ...state.pasDeTravails, pasDeTravail ]
  },
  replacePasDeTravail(state, pasDeTravail){
    state.pasDeTravails = state.pasDeTravails.map(struc => struc.id === pasDeTravail.id ? pasDeTravail : struc )
  },
  setPasDeTravail(state, pasDeTravail){
    state.pasDeTravail = pasDeTravail
  },
}