// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'


export const actions = {
  fetchNeTravailPass({ commit }){
    return get('/netravailpas')
    .then(({ data }) =>{
      commit('addNeTravailPass', data)
    })
  },
  createNeTravailPas({ commit }, netravailpas){
    return post('/netravailpas', netravailpas)
    .then(({ data }) =>{
      commit('addNeTravailPas', data)
    })
  },
  updateNeTravailPas({ commit }, netravailpas){
    return put(`/netravailpas/${netravailpas.id}`, netravailpas)
    .then(({ data }) =>{
      commit('replaceNeTravailPas', data)
    })
  },
  createOrUpdateNeTravailPas({ dispatch }, netravailpas){
    if(netravailpas.id !== undefined){
      return dispatch('updateNeTravailPas', netravailpas)
    }
    else {
      return dispatch('createNeTravailPas', netravailpas)
    }
  },
  selectNeTravailPas({ commit }, netravailpas){
    commit('setNeTravailPas', netravailpas)
  },
  deleteNeTravailPas({ commit }, netravailpas){
    return remove(`/netravailpas/${netravailpas.id}`, netravailpas)
    .then(({ data }) =>{
      commit('addNeTravailPass', data)
    })
  }
}