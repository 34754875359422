// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchNiveauSatisfactions({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/niveauSatisfactions?size=${pageSize}&page=${pageNumber}`)
    .then(({ data }) =>{
      commit('addNiveauSatisfactions', data)
    })
  },
  createNiveauSatisfaction({ commit }, niveauSatisfaction){
    return post('/niveauSatisfactions', niveauSatisfaction)
    .then(({ data }) => {
      commit('addNiveauSatisfaction', data)
    })
  },
  updateNiveauSatisfaction({ commit }, niveauSatisfaction){
    return put(`/niveauSatisfactions/${niveauSatisfaction.id}`, niveauSatisfaction)
    .then(({ data }) =>{
      commit('replaceNiveauSatisfaction', data)
    })
  },
  deleteNiveauSatisfaction({ commit }, niveauSatisfaction){
    return remove(`/niveauSatisfactions/${niveauSatisfaction.id}`, niveauSatisfaction)
    .then(() =>{
      commit('removeNiveauSatisfaction', niveauSatisfaction)
    })
  },
  createOrUpdateNiveauSatisfaction({ dispatch }, niveauSatisfaction){
    if(niveauSatisfaction.id !== undefined){
      return dispatch('updateNiveauSatisfaction', niveauSatisfaction)
    }
    else {
      return dispatch('createNiveauSatisfaction', niveauSatisfaction)
    }
  },
  selectNiveauSatisfaction({ commit }, niveauSatisfaction){
    commit('setNiveauSatisfaction', niveauSatisfaction)
  }
}