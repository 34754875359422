export const getters = {
  participationActivites(state){
    return state.participationActivites
  },
  participationActivite(state){
    return state.participationActivite
  },
  pagination(state){
    return state.pagination
  }
}