import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addArretContrats(state, data){
    state.pagination = extractPaginationInfo(data)
    state.arretContrats = [...data.content] 
  },
  replaceArretContrat(state, arretContrat){
    state.arretContrats = state.arretContrats.map(struc => struc.id === arretContrat.id ? arretContrat : struc )
  },
  removeArretContrat(state, arretContrat){
    state.arretContrats = state.arretContrats.filter(cand => cand.id !== arretContrat.id)
  },
  setArretContrat(state, arretContrat){
    state.arretContrat = arretContrat
  },
  setArretContratEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}