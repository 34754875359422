// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchTypePlacements({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typePlacements?size=${pageSize}&page=${pageNumber}&sort=libelle,asc`)
    .then(({ data }) =>{
      commit('addTypePlacements', data)
    })
  },
  createTypePlacement({ commit }, typePlacement){
    return post('/typePlacements', typePlacement)
    .then(({ data }) => {
      commit('addTypePlacement', data)
    })
  },
  updateTypePlacement({ commit }, typePlacement){
    return put(`/typePlacements/${typePlacement.id}`, typePlacement)
    .then(({ data }) =>{
      commit('replaceTypePlacement', data)
    })
  },
  deleteTypePlacement({ commit }, typePlacement){
    return remove(`/typePlacements/${typePlacement.id}`, typePlacement)
    .then(() =>{
      commit('removeTypePlacement', typePlacement)
    })
  },
  createOrUpdateTypePlacement({ dispatch }, typePlacement){
    if(typePlacement.id !== undefined){
      return dispatch('updateTypePlacement', typePlacement)
    }
    else {
      return dispatch('createTypePlacement', typePlacement)
    }
  },
  selectTypePlacement({ commit }, typePlacement){
    commit('setTypePlacement', typePlacement)
  }
}