export const getters = {
  missionnaires(state){
    return state.missionnaires
  },
  missionnaire(state){
    return state.missionnaire
  },
  pagination(state){
    return state.pagination
  }
}