import { post, put, get} from '@/api/api'
import evaluation from '.'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchEvaluations({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/evaluationConstats?${queryParams}`)
    .then(({ data }) =>{
      commit('addEvaluations', data)
      
    })
  },
  fetchEvaluationsByConstat({ commit },constat){
    console.log(constat)
    return get(`/evaluationConstats/constat/${constat.id}`)
    .then(({ data }) =>{
      commit('addEvaluations', data)
      
    })
  },
  fetchEvaluation({ commit, state }, params = {}){
    let { evaluationId } = params
    return get(`/evaluationConstats/${evaluationId}`)
    .then(({ data }) =>{
      commit('setEvaluation', data)
      
      return data
    })
  },
  fetchCandidatureEvaluation({ commit, state }, params = {}){
    let { candidatureId } = params
    return get(`/candidatures/${candidatureId}/evaluation`)
    .then(({ data }) =>{
      commit('setEvaluation', data)
      
      return data
    })
  },  
  createEvaluation({ commit }, evaluation){
    return post('/evaluationConstats', evaluation)
    .then(({ data }) =>{
      commit('addEvaluation', data)
      
      return data
    })
  },

  createNewEvaluation({ commit }, evaluation){
    return post('/evaluationConstats/list', evaluation)
    .then(({ data }) =>{
      commit('addEvaluation', data)
      
      return data
    })
  },

  updateEvaluation({ commit }, evaluation){
    return put(`/evaluationConstats/${evaluation.id}`, evaluation)
    .then(({ data }) =>{
      commit('replaceEvaluation', data)
      commit('setEvaluation', data)

      return data
    })
  },
  createOrUpdateEvaluation({ dispatch }, evaluation){
    if(evaluation.id !== undefined){
      return dispatch('updateEvaluation', evaluation)
    }
    else {
      return dispatch('createEvaluation', evaluation)
    }
  },
  selectEvaluation({ commit }, evaluation){
    commit('setEvaluation', evaluation)
  }
}