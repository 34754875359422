export const getters = {
  romes(state){
    return state.romes
  },
  rome(state){
    return state.rome
  },
  pagination(state){
    return state.pagination
  }
}