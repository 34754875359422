import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addCredits(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.credits = [ ...content ]
  },
  addCredit(state, credit){
    state.credits = [ credit, ...state.credits]
  },
  replaceCredit(state, credit){
    state.credits = state.credits.map(struc => struc.id === credit.id ? credit : struc )
  },
  removeCredit(state, credit){
    state.credits = state.credits.filter(struc => struc.id !== credit.id)
  },
  setCredit(state, credit){
    state.credit = credit
  },
}