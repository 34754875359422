import { post, put, get, remove} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchPointPresences({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    const { extraQueryArg='' } = params
    return get(`/pointPresences?${queryParams}&${extraQueryArg}`)
    .then(({ data }) =>{
      commit('addPointPresences', data)
      return data
    })
  },
  fetchPointPresence({ commit, state }, params = {}){
    let { pointPresenceId } = params
    return get(`/pointPresences/${pointPresenceId}`)
    .then(({ data }) =>{
      commit('setPointPresence', data)
      return data
    })
  },
  createPointPresence({ commit }, pointPresence){
    return post('/pointPresences', pointPresence)
    .then(({ data }) =>{
      commit('addPointPresence', data)
      return data
    })
  },
  updatePointPresence({ commit }, pointPresence){
    return put(`/pointPresences/${pointPresence.id}`, pointPresence)
    .then(({ data }) =>{
      commit('replacePointPresence', data)
      commit('setPointPresence', data)

      return data
    })
  },
  cloturerPointPresence({ commit }, pointPresence){
    return put(`/pointPresences/${pointPresence.id}/cloturer`, pointPresence)
    .then(({ data }) =>{
      commit('replacePointPresence', data)
      commit('setPointPresence', data)
      return data
    })
  },
  validerPointPresence({ commit }, pointPresence){
    return put(`/pointPresences/${pointPresence.id}/valider`, pointPresence)
    .then(({ data }) =>{
      commit('replacePointPresence', data)
      commit('setPointPresence', data)
      return data
    })
  },
  createOrUpdatePointPresence({ dispatch }, pointPresence){
    if(pointPresence.id !== undefined){
      return dispatch('updatePointPresence', pointPresence)
    }
    else {
      return dispatch('createPointPresence', pointPresence)
    }
  },
  selectPointPresence({ commit }, pointPresence){
    commit('setPointPresence', pointPresence)
  }
}