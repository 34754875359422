export const mutations = {
  addCompetences(state, competences){
    state.competences = [ ...competences ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addCompetence(state, competence){
    state.competences = [ ...state.competences, competence ]
  },
  replaceCompetence(state, competence){
    state.competences = state.competences.map(struc => struc.id === competence.id ? competence : struc )
  },
  setCompetence(state, competence){
    state.competence = competence
  },
}