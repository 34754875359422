// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchRapports({ commit }){
    return get('/rapports')
    .then(({ data }) =>{
      commit('addRapports', data)
    })
  },
  fetchRapportsAudit({ commit },id){
    return get(`/rapports/audit/${id}`)
    .then(({ data }) =>{
      commit('addRapports', data)
    })
  },
  createRapport({ commit }, rapport){
    return post('/rapports', rapport)
    .then(({ data }) =>{
      commit('addRapport', data)
    })
  },
  updateRapport({ commit }, rapport){
    return put(`/rapports/${rapport.id}`, rapport)
    .then(({ data }) =>{
      commit('replaceRapport', data)
    })
  },
  createOrUpdateRapport({ dispatch }, rapport){
    if(rapport.id !== undefined){
      return dispatch('updateRapport', rapport)
    }
    else {
      return dispatch('createRapport', rapport)
    }
  },
  selectRapport({ commit }, rapport){
    commit('setRapport', rapport)
  }
}