// import axios from 'axios'
import { post, put, get} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchCandidatureSelectors({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/candidatures?${queryParams}`)
    .then(({ data }) =>{
      commit('addCandidatureSelectors', data)
    })
  },
  fetchTopNCandidatureSelectors({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/candidatures/topCandidatures?sort=evaluation.totalPoints,desc&${queryParams}`)
    .then(({ data }) =>{
      commit('addCandidatureSelectors', data)
    })
  },
}