export const mutations = {
  addEthnies(state, data){
    state.ethnies = [ ...data ]
  },
  addEthnie(state, ethnie){
    state.ethnies = [ ...state.ethnies, ethnie ]
  },
  replaceEthnie(state, ethnie){
    state.ethnies = state.ethnies.map(struc => struc.id === ethnie.id ? ethnie : struc )
  },
  setEthnie(state, ethnie){
    state.ethnie = ethnie
  },
}