// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
    fetchPrerequisMicroprojets({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    let { extraQueryArg } = params
    queryParams = `${queryParams}&${extraQueryArg}`
    return get(`/prerequisMicroprojets?${queryParams}`)
    .then(({ data }) =>{
      commit('addPrerequisMicroprojets', data)
      return data.content
    })
  },
  fetchPrerequisMicroprojet({ commit, state }, params = {}){
    let { prerequisMicroprojetId } = params
    return get(`/prerequisMicroprojets/${prerequisMicroprojetId}`)
    .then(({ data }) =>{
      // commit('addPrerequisMicroprojet', data)
      return data
    })
  },
//   fetchPrerequisMicroprojetByMicroprojetId({ commit, state }, params = {}){
//     let { microprojetId } = params
//     return get(`/prerequisMicroprojets/${prerequisMicroprojetId}/`)
//     .then(({ data }) =>{
//       // commit('addPrerequisMicroprojet', data)
//       return data
//     })
//   },
  createPrerequisMicroprojet({ commit }, prerequisMicroprojet){
    return post('/prerequisMicroprojets', prerequisMicroprojet)
    .then(({ data }) => {
      commit('addPrerequisMicroprojet', data)
      return data
    })
  },
  updatePrerequisMicroprojet({ commit }, prerequisMicroprojet){
    return put(`/prerequisMicroprojets/${prerequisMicroprojet.id}`, prerequisMicroprojet)
    .then(({ data }) =>{
      commit('replacePrerequisMicroprojet', data)
      return data
    })
  },
  deletePrerequisMicroprojet({ commit }, prerequisMicroprojet){
    return remove(`/prerequisMicroprojets/${prerequisMicroprojet.id}`, prerequisMicroprojet)
    .then(() =>{
      commit('removePrerequisMicroprojet', prerequisMicroprojet)
    })
  },
  validerPrerequisMicroprojet({ commit }, prerequisMicroprojet){
      return put(`/prerequisMicroprojets/${prerequisMicroprojet.id}/valider`, prerequisMicroprojet)
      .then(({ data }) =>{
        commit('replacePrerequisMicroprojet', data)
        return data
      })
    },
  createOrUpdatePrerequisMicroprojet({ dispatch }, prerequisMicroprojet){
    if(prerequisMicroprojet.id !== undefined){
      return dispatch('updatePrerequisMicroprojet', prerequisMicroprojet)
    }
    else {
      return dispatch('createPrerequisMicroprojet', prerequisMicroprojet)
    }
  },
  selectPrerequisMicroprojet({ commit }, prerequisMicroprojet){
    commit('setPrerequisMicroprojet', prerequisMicroprojet)
  }
}