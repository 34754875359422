// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'


export const actions = {
  fetchMaitriseLangues({ commit }){
    return get('/maitriseLangues')
    .then(({ data }) =>{
      commit('addMaitriseLangues', data)
      return data
    })
  },
  createMaitriseLangue({ commit }, maitriseLangue){
    return post('/maitriseLangues', maitriseLangue)
    .then(({ data }) =>{
      commit('addMaitriseLangue', data)
      return data
    })
  },
  updateMaitriseLangue({ commit }, maitriseLangue){
    return put(`/maitriseLangues/${maitriseLangue.id}`, maitriseLangue)
    .then(({ data }) =>{
      commit('replaceMaitriseLangue', data)
      return data
    })
  },
  createOrUpdateMaitriseLangue({ dispatch }, maitriseLangue){
    if(maitriseLangue.id !== undefined){
      return dispatch('updateMaitriseLangue', maitriseLangue)
    }
    else {
      return dispatch('createMaitriseLangue', maitriseLangue)
    }
  },
  deleteMaitriseLangue({ commit }, {id}){
    return remove(`/maitriseLangues/${id}`)
    .then(({ data }) =>{
      return data
    })
  }
}