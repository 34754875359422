import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addDemandeurSelectors(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    state.demandeurSelectors = [ ...content ]
  },
  addDemandeurSelector(state, demandeurSelector){
    state.demandeurSelectors = [ ...state.demandeurSelectors, demandeurSelector ]
  },
  replaceDemandeurSelector(state, demandeurSelector){
    state.demandeurSelectors = state.demandeurSelectors.map(struc => struc.id === demandeurSelector.id ? demandeurSelector : struc )
  },
  setDemandeurSelector(state, demandeurSelector){
    state.demandeurSelector = demandeurSelector
  },
}