export const getters = {
  contratObjectifs(state){
    return state.contratObjectifs
  },
  contratObjectif(state){
    return state.contratObjectif
  },
  pagination(state){
    return state.pagination
  }
}