// import axios from 'axios'
import { post, put, get, remove  } from '../../api/api'


export const actions = {
  fetchAzoliPieces({ commit }){
    return get('/azoliPieces')
    .then(({ data }) =>{
      commit('addAzoliPieces', data)
      return data
    })
  },
  createAzoliPiece({ commit }, azoliPiece){
    return post('/azoliPieces', azoliPiece)
    .then(({ data }) =>{
      commit('addAzoliPiece', data)
      return data
    })
  },
  updateAzoliPiece({ commit }, azoliPiece){
    return put(`/azoliPieces/${azoliPiece.id}`, azoliPiece)
    .then(({ data }) =>{
      commit('replaceAzoliPiece', data)
      return data
    })
  },
  createOrUpdateAzoliPiece({ dispatch }, azoliPiece){
    if(azoliPiece.id !== undefined){
      return dispatch('updateAzoliPiece', azoliPiece)
    }
    else {
      return dispatch('createAzoliPiece', azoliPiece)
    }
  },
  deleteAzoliPiece({ commit }, {id}){
    return remove(`/azoliPieces/${id}`)
    .then(({ data }) =>{
      return data
    })
  }
}