export const mutations = {
  addNationalites(state, nationalites){
    state.nationalites = [ ...nationalites ]
  },
  addNationalite(state, nationalite){
    state.nationalites = [ ...state.nationalites, nationalite ]
  },
  replaceNationalite(state, nationalite){
    state.nationalites = state.nationalites.map(struc => struc.id === nationalite.id ? nationalite : struc )
  },
  setNationalite(state, nationalite){
    state.nationalite = nationalite
  },
}