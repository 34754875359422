export const getters = {
  contrats(state){
    return state.contrats
  },
  contrat(state){
    return state.contrat
  },
  pagination(state){
    return state.pagination
  }
}