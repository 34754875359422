// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchReponsescreenings({ commit }){
    return get('/reponsescreenings')
    .then(({ data }) =>{
      commit('addReponsescreenings', data)
    })
  },
  fetchScreeningsReponseBySecteur({ commit },param){
    
    return get(`/reponsescreenings/secteur/${param.secteurId}/microprojet/${param.microprojetId}`)
    .then(({ data }) =>{
      commit('addReponsescreenings2', data)
    })
  },
  createReponsescreening({ commit }, reponsescreening){
    return post('/reponsescreenings', reponsescreening)
    .then(({ data }) =>{
      commit('setReponsescreening', data)
      commit('addReponsescreening', data)
    })
  },
  updateReponsescreening({ commit }, reponsescreening){
    return put(`/reponsescreenings/${reponsescreening.id}`, reponsescreening)
    .then(({ data }) =>{
      commit('setReponsescreening', data)
      commit('replaceReponsescreening', data)
    })
  },
  updateReponsescreeningList({ commit }, param){
    console.log(param)
    return put(`/reponsescreenings/list/screening/${param.screeningId}`, param.reponsescreenings)
    .then(({ data }) =>{
      commit('setReponsescreening', data)
      commit('replaceReponsescreening', data)
    })
  },
  updateReponsescreeningOne({ commit }, param){
    console.log(param)
    return put(`/reponsescreenings/${param.reponsescreening.id}/screening/${param.idSreening}`,param.reponsescreening)
    .then(({ data }) =>{
      commit('replaceReponsescreening', data)
    })
  },
  
  createOrUpdateReponsescreening({ dispatch }, reponsescreening){
    if(reponsescreening.id !== undefined){
      return dispatch('updateReponsescreening', reponsescreening)
    }
    else {
      return dispatch('createReponsescreening', reponsescreening)
    }
  },
  selectReponsescreening({ commit }, reponsescreening){
    commit('setReponsescreening', reponsescreening)
  }
}