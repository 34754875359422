// import Home from "../views/Home.vue";

export const globalRoutes = [
  {
    path: "",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/404",
        name: "404",
        component: () => import("../views/Page404.vue"),
      },
      {
        path: "/connection",
        name: "accounts.logins",
        component: () => import("../views/accounts/SignIn.vue"),
      },
      {
        path: "/export",
        name: "common.export",
        component: () => import("../views/ExcelExporter.vue"),
      },
      {
        path: "/sec/media/gallerie",
        name: "common.sec.media.gallerie",
        component: () => import("../views/SecuredMediaExplorer.vue"),
      },
      {
        path: "/register/demandeur",
        name: "accounts.register.de",
        component: () => import("../views/accounts/RegisterDemandeur.vue"),
      },
      {
        path: "/register/employer",
        name: "accounts.register.emp",
        component: () => import("../views/accounts/RegisterEmployer.vue"),
      },
      {
        path: "/registration/successful",
        name: "accounts.registration.successful",
        component: () => import("../views/accounts/RegistrationSuccessful.vue"),
      },
      {
        path: "/registration/entreprise/successful",
        name: "accounts.registration.entreprise.successful",
        component: () => import("../views/accounts/RegistrationEntrepriseSuccessful.vue"),
      },
      {
        path: "/registration/souscription/successful",
        name: "accounts.registration.souscription.successful",
        component: () => import("../views/accounts/SouscriptionSuccessful.vue"),
      },
      {
        path: "/confirm",
        name: "accounts.registration.confirmation",
        component: () =>
          import("../views/accounts/RegistrationConfirmation.vue"),
      },
      {
        path: "participations/:participationId/confirmation",
        name: "espace.common.participations.confirmation",
        component: () =>
          import("../views/confirmation/ParticipationConfirmation.vue"),
        props: true,
      },
      {
        path: "/reset-password",
        name: "accounts.registration.resetPassword",
        component: () => import("../views/accounts/PasswordReset.vue"),
      },
      {
        path: "/register/employer",
        name: "accounts.register.ent",
        component: () => import("../views/accounts/RegisterEmployer.vue"),
      },
      {
        path: "/login/demandeur",
        name: "accounts.login.de",
        component: () => import("../views/accounts/SignInDemandeur.vue"),
      },
      {
        path: "/login/admin",
        name: "accounts.login.admin",
        redirect: { name: "accounts.login.de" },
      },
      {
        path: "/login/conseiller",
        name: "accounts.login.ce",
        redirect: { name: "accounts.login.de" },
      },
      {
        path: "/logout",
        name: "accounts.logout",
        component: () => import("../views/accounts/Logout.vue"),
      },
      {
        path: "/login/chef-antenne",
        name: "accounts.login.ca",
        redirect: { name: "accounts.login.de" },
      },
      {
        path: "/login/entreprise",
        name: "accounts.login.ent",
        component: () => import("../views/accounts/SignInEmployeur.vue"),
      },
      {
        path: "/login/service-public",
        name: "accounts.login.agent",
        component: () => import("../views/accounts/SignInAgent.vue"),
      },
      {
        path: "/email-form",
        name: "email.form",
        component: () => import("../views/email-validation/EmailForm.vue"),
      },
      {
        path: "/email-code-validation",
        name: "email.verification",
        component: () =>
          import("../views/email-validation/EmailVerification.vue"),
      },
      {
        path: "/offre",
        name: "offers",
        component: () => import("../views/Offer.vue"),
      },
      {
        path: "/details-actualite/:actualiteId",
        name: "details.actualite",
        component: () => import("../views/espace/admin/DetailsActualite.vue"),
        props: true,
      },
      {
        path: "/portail-offres",
        name: "portail.offres.list",
        // component: () => import("../views/portail/PortailOffreList.vue"),
        component: () => import("../views/portail/PortailTousOffreList.vue"),
      },
      {
        path: "/portail-offre-details/:offreSlug",
        name: "portail.offre.details",
        component: () =>
          import("../views/portail/PortailOffreDetails.vue"),
        props: true,
      },
      {
        path: "/portail-offres-externe",
        name: "portail.offres.externe.list",
        component: () => import("../views/portail/PortailOffreExterneList.vue"),
      },

    ],
  },
];