const EMAIL_KEY = 'EMAIL_KEY'
const EMAIL_VERIFIED_KEY = 'EMAIL_VERIFIED_KEY'

export const EmailVerifier = {
  saveEmail (email) {
    localStorage.setItem(EMAIL_KEY, email)
  },
  
  getCurrentEmail () {
    return localStorage.getItem(EMAIL_KEY)
  },

  hasPendingVerification () {
    return localStorage.getItem(EMAIL_KEY) != undefined && localStorage.getItem(EMAIL_VERIFIED_KEY) !== true
  },
  
  removeEmail () {
    localStorage.removeItem(EMAIL_KEY)
  },

  isEmailVerified () {
    localStorage.setItem(EMAIL_KEY, 'ing.huguesboni@gmail.com')
    return true
    // return localStorage.getItem(EMAIL_VERIFIED_KEY) || false
  },

  setEmailVerified (verified = true) {
    localStorage.setItem(EMAIL_VERIFIED_KEY, verified)
  },

  reset () {
    localStorage.removeItem(EMAIL_KEY)
    localStorage.removeItem(EMAIL_VERIFIED_KEY)
  }
}