import { downloadFileWithAxiosPost } from '@/api/api'
import { exportToPdfSpecs, exportToExcelSpecs } from './export-specs'

export const actions = {
  exportToFile({ },  params = {}){
    const { queryParams, apiPath, fileName = 'file', meta } = params
    if(apiPath == null) throw Error(`'apiPath' value is required. store/exporter/exportToFile`)
    let querySpec = exportToPdfSpecs[apiPath]
    return downloadFileWithAxiosPost(`${apiPath}?${queryParams}`, fileName, {
      ...querySpec,
      ...meta,
    })
    .then(({ data }) =>{
      return data
    })
  },
  exportToPdf({ dispatch },  params = {}){
    const { queryParams, apiPath, fileName = 'file.pdf', meta } = params
    if(apiPath == null) throw Error(`'apiPath' value is required. store/exporter/exportToPdf`)
    let querySpec = exportToPdfSpecs[apiPath]
    return downloadFileWithAxiosPost(`${apiPath}?${queryParams}`, fileName, {
      ...querySpec,
      ...meta,
    })
    .then(({ data }) =>{
      return data
    })
  },
  exportToExcel({ }, params = {  }){
    const { queryParams, apiPath, fileName = 'file.xlsx', meta } = params
    if(apiPath == null) throw Error(`'apiPath' value is required. store/exporter/exportToExcel`)

    let querySpec = exportToExcelSpecs[apiPath]
    console.clear()
    console.log(querySpec)
    console.log(exportToExcelSpecs)
    return downloadFileWithAxiosPost(`${apiPath}?${queryParams}`, fileName, {
      ...querySpec,
      ...meta,
    })
    .then(({ data }) =>{
      return data
    })
  },
}