// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchArretContrats({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/arretContrats?${queryParams}`)
    .then(({ data }) =>{
      commit('addArretContrats', data)
      return data
    })
  },
  fetchArretContrat({ commit, state }, params = {}){
    let { arretContratId } = params
    return get(`/arretContrats/${arretContratId}`)
    .then(({ data }) =>{
      commit('setArretContrat', data)
      return data
    })
  },
  createArretContrat({ commit }, arretContrat){
    return post('/arretContrats', arretContrat)
    .then(({ data }) =>{
      commit('addArretContrat', data)
      return data
    })
  },
  updateArretContrat({ commit }, arretContrat){
    return put(`/arretContrats/${arretContrat.id}`, arretContrat)
    .then(({ data }) =>{
      commit('replaceArretContrat', data)
      commit('setArretContrat', data)

      return data
    })
  },
  createOrUpdateArretContrat({ dispatch }, arretContrat){
    if(arretContrat.id !== undefined){
      return dispatch('updateArretContrat', arretContrat)
    }
    else {
      return dispatch('createArretContrat', arretContrat)
    }
  },
  selectArretContrat({ commit }, arretContrat){
    commit('setArretContrat', arretContrat)
  }
}