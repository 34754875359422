export const getters = {
  credits(state){
    return state.credits
  },
  credit(state){
    return state.credit
  },
  pagination(state){
    return state.pagination
  }
}