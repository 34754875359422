// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchInstructions({ commit }){
    return get('/instructions')
    .then(({ data }) =>{
      commit('addInstructions', data)
    })
  },
  createInstruction({ commit }, instruction){
    return post('/instructions', instruction)
    .then(({ data }) =>{
      commit('addInstruction', data)
    })
  },
  updateInstruction({ commit }, instruction){
    return put(`/instructions/${instruction.id}`, instruction)
    .then(({ data }) =>{
      commit('replaceInstruction', data)
    })
  },
  createOrUpdateInstruction({ dispatch }, instruction){
    if(instruction.id !== undefined){
      return dispatch('updateInstruction', instruction)
    }
    else {
      return dispatch('createInstruction', instruction)
    }
  },
  selectInstruction({ commit }, instruction){
    commit('setInstruction', instruction)
  }
}