// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchLangues({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/langues?size=${pageSize}&page=${pageNumber}&sort=nom`)
    .then(({ data }) =>{
      commit('addLangues', data)
    })
  },
  createLangue({ commit }, langue){
    return post('/langues', langue)
    .then(({ data }) =>{
      commit('addLangue', data)
    })
  },
  updateLangue({ commit }, langue){
    return put(`/langues/${langue.id}`, langue)
    .then(({ data }) =>{
      commit('replaceLangue', data)
    })
  },
  createOrUpdateLangue({ dispatch }, langue){
    if(langue.id !== undefined){
      return dispatch('updateLangue', langue)
    }
    else {
      return dispatch('createLangue', langue)
    }
  },
  selectLangue({ commit }, langue){
    commit('setLangue', langue)
  }
}