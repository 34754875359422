// import axios from 'axios'
import { post, put, get } from "@/api/api";
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchActionRequises({ commit, state }, params = {}) {
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/actionrequises?${queryParams}`).then(({ data }) => {
      commit("addActionRequises", data);
    });
  },
  fetchActionRequisesByConstat({ commit }, actionrequise) {
    return get(`/actionrequises/constat/${actionrequise.id}`).then(
      ({ data }) => {
        commit("addActionRequises", data);
        return data;
      }
    );
  },

  fetchActionRequisesByEntreprise({ commit }, entrepriseId) {
    return get(`/actionrequises/entreprise/${entrepriseId}`).then(
      ({ data }) => {
        commit("addActionRequises", data);
        return data;
      }
    );
  },
  createActionRequise({ commit }, actionrequise) {
    return post("/actionrequises", actionrequise).then(({ data }) => {
      commit("addActionRequise", data);
    });
  },
  updateActionRequise({ commit }, actionrequise) {
    return put(`/actionrequises/${actionrequise.id}`, actionrequise).then(
      ({ data }) => {
        commit("replaceActionRequise", data);
      }
    );
  },
  createOrUpdateActionRequise({ dispatch }, actionrequise) {
    if (actionrequise.id !== undefined) {
      return dispatch("updateActionRequise", actionrequise);
    } else {
      return dispatch("createActionRequise", actionrequise);
    }
  },
  selectActionRequise({ commit }, actionrequise) {
    commit("setActionRequise", actionrequise);
  },
};
