export const getters = {
  arretContrats(state){
    return state.arretContrats
  },
  arretContrat(state){
    return state.arretContrat
  },
  pagination(state){
    return state.pagination
  }
}