import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addParticipationActivites(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.participationActivites = [ ...content ]
  },
  addParticipationActivite(state, participationActivite){
    state.participationActivites = [ participationActivite, ...state.participationActivites]
  },
  replaceParticipationActivite(state, participationActivite){
    state.participationActivites = state.participationActivites.map(struc => struc.id === participationActivite.id ? participationActivite : struc )
  },
  removeParticipationActivite(state, participationActivite){
    state.participationActivites = state.participationActivites.filter(struc => struc.id !== participationActivite.id)
  },
  setParticipationActivite(state, participationActivite){
    state.participationActivite = participationActivite
  },
}