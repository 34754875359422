import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addParametres(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.parametres = [ ...content ]
  },
  addParametre(state, parametre){
    state.parametres = [ parametre, ...state.parametres]
  },
  replaceParametre(state, parametre){
    state.parametres = state.parametres.map(struc => struc.id === parametre.id ? parametre : struc )
  },
  removeParametre(state, parametre){
    state.parametres = state.parametres.filter(struc => struc.id !== parametre.id)
  },
  setParametre(state, parametre){
    state.parametre = parametre
  },
}