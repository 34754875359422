// import { extractPaginationInfo } from '@/utils/pagination-util'
import { extractPaginationInfo } from "@/utils/pagination-util";
export const mutations = {
    addCandidatures(state, data) {
        const { content } = data;
        state.pagination = extractPaginationInfo(data);
        state.candidatures = [...content];
    },
    addCommunes(state, communes) {
        state.communes = [...communes];
    },
    addCandidature(state, candidature) {
        state.candidatures = [...state.candidatures, candidature];
    },
    replaceCandidature(state, candidature) {
        state.candidatures = state.candidatures.map((struc) =>
            struc.id === candidature.id ? candidature : struc
        );
    },
    removeCandidature(state, candidature) {
        state.candidatures = state.candidatures.filter(
            (cand) => cand.id !== candidature.id
        );
    },
    setCandidature(state, candidature) {
        state.candidature = candidature;
    },
    setCandidatureEvaluation(state, evaluation) {
        state.evaluation = evaluation;
    },
};
