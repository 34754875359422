import { TokenService } from "@/api/localStorage";

let userRoles = TokenService.getTokenInfo().roles || [];
let roleName =
  userRoles.length > 0
    ? userRoles[0].slice(5, userRoles[0].length).toLowerCase()
    : "";

export const SIDE_MENU_AGENT = [
  {
    to: { name: "espace.common.azolis.home", params: { userRole: roleName } },
    label: "Accueil",
    privileges: ["MENU.HOME_STATISTIC"],
  },
  {
    to: { name: "espace.common.agent.list", params: { userRole: roleName } },
    label: "Affectations DE",
    privileges: ["MENU.AFFECTATION_DE"],
  },
  {
    to: {
      name: "espace.common.entreprise.affectation",
      params: { userRole: roleName },
    },
    label: "Affectations entreprise",
    privileges: ["MENU.AFFECTATION_ENTREPRISE"],
  },
  {
    to: {
      name: "espace.common.entreprise.list",
      params: { userRole: roleName },
    },
    label: "Liste des entreprises",
    privileges: ["MENU.ENTREPRISE_LIST"],
  },
  {
    to: {
      name: "espace.common.participation-programme.list",
      params: { userRole: roleName },
    },
    label: "Liste des programmes",
    privileges: ["MENU.PROGRAMME_LIST"],
  },

  {
    to: {
      name: "espace.common.de-prescription.list",
      params: { userRole: roleName },
    },
    label: "Liste des prescriptions",
    privileges: ["MENU.PRESCRIPTION_LIST"],
  },
  {
    to: {
      name: "espace.common.microprojets.list",
      params: { userRole: roleName },
    },
    label: "Liste des microprojets",
    privileges: ["MENU.MICROPROJET_LIST"],
  },
  {
    to: {
      name: "espace.common.de-consigne.list",
      params: { userRole: roleName },
    },
    label: "Liste des consignes",
    privileges: ["MENU.CONSIGNE_LIST"],
  },
  {
    to: {
      name: "espace.common.de-placement.list",
      params: { userRole: roleName },
    },
    label: "Liste des placements",
    privileges: ["MENU.PLACEMENT_LIST"],
  },
  {
    to: {
      name: "espace.common.participation-placement.list",
      params: { userRole: roleName },
    },
    label: "Liste des placés",
    privileges: ["MENU.PLACEMENT_LIST"],
  },
  {
    to: {
      name: "espace.common.de-stage-formation.list",
      params: { userRole: roleName },
    },
    label: "Stages et Formations",
    privileges: ["MENU.STAGE_FORMATION_LIST"],
  },
  {
    to: {
      name: "espace.common.de-suivi-stage-formation.list",
      params: { userRole: roleName },
    },
    label: "Suivis Stages et Formations",
    privileges: ["MENU.SUIVI_FORMATION_LIST"],
  },
  {
    to: {
      name: "espace.common.de-tentative-candidature.list",
      params: { userRole: roleName },
    },
    label: "Clients potentiels",
    privileges: ["MENU.CONSIGNE_LIST"],
  },
  {
    to: {
      name: "espace.entreprise.suivi-placement.list",
      params: { userRole: roleName },
    },
    label: "Prise services",
    privileges: ["MENU.PAYEMENT_LIST"],
  },
  {
    to: {
      name: "espace.common.payement-indemnite.list",
      params: { userRole: roleName },
    },
    label: "Payement indemnités",
    privileges: ["MENU.PAYEMENT_LIST"],
  },
  // {
  //   to: {
  //     name: "espace.common.de-avantage-financier.list",
  //     params: { userRole: roleName },
  //   },
  //   label: "Payement indemnités",
  //   privileges: ["MENU.PAYEMENT_LIST"],
  // },

  // {
  //   to: {
  //     name: "espace.common.presence-poste.list",
  //     params: { userRole: roleName },
  //   },
  //   label: "Liste Présence au poste",
  //   privileges: ['MENU.PAYEMENT_LIST'],
  // },
  // {
  //   to: {
  //     name: "espace.common.prise-service.list",
  //     params: { userRole: roleName },
  //   },
  //   label: 'Liste Prises de service ',
  //   privileges: ['MENU.PAYEMENT_LIST'],
  // },
  // {
  //   to: {
  //     name: "espace.common.arret-service.list",
  //     params: { userRole: roleName },
  //   },
  //   label: 'Liste Arrêts de service',
  //   privileges: ['MENU.PAYEMENT_LIST'],
  // },
  // {
  //   to: {
  //     name: "espace.common.point-presence.list",
  //     params: { userRole: roleName },
  //   },
  //   label: 'Liste point de présence',
  //   privileges: ['MENU.PAYEMENT_LIST'],
  // },
  // {
  //   to: {
  //     name: "espace.common.places.list",
  //     params: { userRole: roleName },
  //   },
  //   label: 'Liste des placés',
  //   privileges: ['MENU.PAYEMENT_LIST'],
  // },
  {
    to: {
      name: "espace.common.auditeur.list",
      params: { userRole: roleName },
    },
    label: "Liste auditeurs/commentateurs",
    privileges: ["MENU.AUDITEUR_LIST"],
  },
  {
    to: {
      name: "espace.common.offre.externe.list",
      params: { userRole: roleName },
    },
    label: "Offres Externe",
    privileges: ["MENU.OFFRE_EXTERNE_LIST"],
  },
  {
    to: {
      name: "espace.common.enquete-poste-programme.list",
      params: { userRole: roleName },
    },
    label: "Enquête post-programme",
    privileges: ["MENU.ENQUETE_POSTE_PROGRAMME_LIST"],
  },
];
