import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addReponseEntrepreneuriales(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.reponseEntrepreneuriales = [ ...content ]
  },
  addReponseEntrepreneuriale(state, reponseEntrepreneuriale){
    state.reponseEntrepreneuriales = [ reponseEntrepreneuriale, ...state.reponseEntrepreneuriales]
  },
  replaceReponseEntrepreneuriale(state, reponseEntrepreneuriale){
    state.reponseEntrepreneuriales = state.reponseEntrepreneuriales.map(struc => struc.id === reponseEntrepreneuriale.id ? reponseEntrepreneuriale : struc )
  },
  removeReponseEntrepreneuriale(state, reponseEntrepreneuriale){
    state.reponseEntrepreneuriales = state.reponseEntrepreneuriales.filter(struc => struc.id !== reponseEntrepreneuriale.id)
  },
  setReponseEntrepreneuriale(state, reponseEntrepreneuriale){
    state.reponseEntrepreneuriale = reponseEntrepreneuriale
  },
}