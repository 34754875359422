export const getters = {
  stampStatistics(state){
    let statistics =  state.stampStatistics
    if(state.stampStatistics instanceof Array){
      if(state.activeStructure == null) {
        statistics = {
          consommes: 0,
          nonConsommes: 0,
          totalTimbre: 0
        }
        state.stampStatistics.forEach(el => {
          statistics.consommes += el.consommes
          statistics.nonConsommes += el.nonConsommes
          statistics.totalTimbre += el.totalTimbre
        });
      }
      else {
        // console.log(state.stampStatistics, state.activeStructure)
        // state.stampStatistics.forEach(el => {
          //   if(el.structure.id == state.activeStructure.id) return el
          // })
        for (let i = 0; i< state.stampStatistics.length; i++){
          const el = state.stampStatistics[i]
          if(el.structure.id == state.activeStructure.id){
            statistics.consommes = el.consommes
            statistics.nonConsommes = el.nonConsommes
            statistics.totalTimbre = el.totalTimbre
            break
          }
        }
      }
    }
    return statistics 
  },
  detailedStampStatistics (state) {
    let statistics = state.stampStatistics
    if(state.activeStructure !== null) {
      statistics = state.stampStatistics.filter(el => el.structure.id == state.activeStructure.id)
    }
    return statistics
  },
  acteStampStatistics (state) {
    const statistics = state.acteStampStatistics
    // if(state.activeStructure !== null) {
    //   statistics = state.stampStatistics.filter(el => el.structure.id == state.activeStructure.id)
    // }
    return statistics
  },
  activeStructure(state){
    return state.activeStructure
  },
  deCount(state){
    return state.deCount
  },
  activiteCount(state){
    return state.activiteCount
  }
}