export const getters = {
  entreprises(state){
    return state.entreprises
  },
  entreprise(state){
    return state.entreprise
  },
  pagination(state){
    return state.pagination
  }
}