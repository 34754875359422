// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchActivitePlanifies({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size, extraUrlParam='' } = params
    let queryParams = buildQueryParams(params, state.pagination)
    if(queryParams.indexOf('size') == -1) queryParams += `&size=${pageSize}&page=${pageNumber}`
    
    return get(`/activitePlanifies?${queryParams}&${extraUrlParam}`)
      .then(({ data }) => {
        commit('addActivitePlanifies', data)
        return data.content
      }).catch(err => err)
  },
  createActivitePlanifie({ commit }, activitePlanifie){
    return post('/activitePlanifies', activitePlanifie)
    .then(({ data }) => {
      commit('addActivitePlanifie', data)
      return data
    })
  },
  fetchActiviteRapportActivite({ commit }, { activiteId }){
    // return new Promise((resolv, reject) => {
    // }).catch(err => reject(err))
    return get(`/activitePlanifies/${activiteId}/rapportActivite`)
    .then(({ data }) => {
      commit('setRapportActivite', data)
      return data

    })
  },
  fetchActiviteParticipationStatistics({ commit }, { activiteId }){
    return get(`/activitePlanifies/${activiteId}/participationStatistics`)
    .then(({ data }) => {
      return data

    })
  },
  fetchActivitePlanifie({ commit }, { activiteId }){
    return get(`/activitePlanifies/${activiteId}/`)
    .then(({ data }) => {
      commit('addActivitePlanifie', data)
      return data
    })
  },
  updateActivitePlanifieStatus({ commit }, activitePlanifie){
    return put(`/activitePlanifies/${activitePlanifie.id}/setStatus`, activitePlanifie)
    .then(({ data }) =>{
      commit('replaceActivitePlanifie', data)
      return data
    })
  },
  updateActivitePlanifie({ commit }, activitePlanifie){
    return put(`/activitePlanifies/${activitePlanifie.id}`, activitePlanifie)
    .then(({ data }) =>{
      commit('replaceActivitePlanifie', data)
      return data
    })
  },
  deleteActivitePlanifie({ commit }, activitePlanifie){
    return remove(`/activitePlanifies/${activitePlanifie.id}`, activitePlanifie)
    .then(() =>{
      commit('removeActivitePlanifie', activitePlanifie)
      return data
    })
  },
  createOrUpdateActivitePlanifie({ dispatch }, activitePlanifie){
    if(activitePlanifie.id !== undefined){
      return dispatch('updateActivitePlanifie', activitePlanifie)
    }
    else {
      return dispatch('createActivitePlanifie', activitePlanifie)
    }
  },
  selectActivitePlanifie({ commit }, activitePlanifie){
    commit('setActivitePlanifie', activitePlanifie)
  }
}