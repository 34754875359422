export const getters = {
  regions(state){
    return state.regions
  },
  region(state){
    return state.region
  },
  pagination(state){
    return state.pagination
  }
}