<template>
  <div class="form-control">
    <input
      ref="checker"
      class="form-control"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
      }"
      @blur="loadData($event)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    checkerType: {
      validator(value) {
        return ['PHONE', 'EMAIL'].includes(value)
      }
    },
  },
  methods: {
    ...mapActions({
      checkAvailablity: 'account/checkAvailablitity'
    }),
    loadData(evt){      
      this.checkAvailablity({
        type: this.checkerType,
        value: evt.target.value,
      }).then(data => {

      })
    }
  }
}
</script>