// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchOrganismeFonctions({ commit, state }, params = {}){
    console.clear()
    console.log(state.pagination)
    let { pageNumber=0, pageSize=state.pagination.size } = params
    let urlParam = `size=${pageSize}&page=${pageNumber}`
    return get(`/organismeFonctions?${urlParam}`)
    .then(({ data }) => {
      console.log(urlParam)
      commit('addOrganismeFonctions', data)
    })
  },
  createOrganismeFonction({ commit }, organismeFonction){
    return post('/organismeFonctions', organismeFonction)
    .then(({ data }) =>{
      commit('addOrganismeFonction', data)
    })
  },
  updateOrganismeFonction({ commit }, organismeFonction){
    return put(`/organismeFonctions/${organismeFonction.id}`, organismeFonction)
    .then(({ data }) =>{
      commit('replaceOrganismeFonction', data)
    })
  },
  createOrUpdateOrganismeFonction({ dispatch }, organismeFonction){
    if(organismeFonction.id !== undefined){
      return dispatch('updateOrganismeFonction', organismeFonction)
    }
    else {
      return dispatch('createOrganismeFonction', organismeFonction)
    }
  },
  selectOrganismeFonction({ commit }, organismeFonction){
    commit('setOrganismeFonction', organismeFonction)
  }
}