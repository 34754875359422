export const getters = {
  typeContrats(state){
    return state.typeContrats
  },
  typeContrat(state){
    return state.typeContrat
  },
  pagination(state){
    return state.pagination
  }
}