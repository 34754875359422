// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchChiffreAffaires({ commit }){
    return get('/chiffreAffaires')
    .then(({ data }) =>{
      commit('addChiffreAffaires', data)
    })
  },
  createChiffreAffaire({ commit }, chiffreaffaire){
    return post('/chiffreAffaires', chiffreaffaire)
    .then(({ data }) =>{
      commit('addChiffreAffaire', data)
    })
  },
  updateChiffreAffaire({ commit }, chiffreaffaire){
    return put(`/chiffreAffaires/${chiffreaffaire.id}`, chiffreaffaire)
    .then(({ data }) =>{
      commit('replaceChiffreAffaire', data)
    })
  },
  createOrUpdateChiffreAffaire({ dispatch }, chiffreaffaire){
    if(chiffreaffaire.id !== undefined){
      return dispatch('updateChiffreAffaire', chiffreaffaire)
    }
    else {
      return dispatch('createChiffreAffaire', chiffreaffaire)
    }
  },
  selectChiffreAffaire({ commit }, chiffreaffaire){
    commit('setChiffreAffaire', chiffreaffaire)
  }
}