export const getters = {
  natureContrats(state){
    return state.natureContrats
  },
  natureContrat(state){
    return state.natureContrat
  },
  pagination(state){
    return state.pagination
  }
}