import { post, put, get, api } from '@/api/api'


export const actions = {
  fetchTransfertEntreprises({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/transfertEntreprises?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addTransfertEntreprises', data)
    })
  },
  transfererVersConseiller({ commit }, params = {}){
    let { agent, dossiers } = params
    return post(`/transfertEntreprises/transfertMultipleVersConseiller`,{
      dossiers: dossiers,
      transfereA: agent
    }).then(({ data }) =>{
      console.log(data)
      return data
    }).catch(err => console.error(err))
  },
  transfererVersAntenne({ commit }, params = {}){
    let { antenne, dossiers } = params
    return post(`/transfertEntreprises/transfertMultipleVersAntenne`,{
      dossiers: dossiers,
      antenneDestination: antenne
    }).then(({ data }) => {
      console.log(data)
      return data
    }).catch(err => console.error(err))
  },
  createTransfertEntreprise({ commit }, transfertEntreprise){
    return post('/transfertEntreprises', transfertEntreprise)
    .then(({ data }) =>{
      commit('addTransfertEntreprise', data)
    })
  },
  updateTransfertEntreprise({ commit }, transfertEntreprise){
    return put(`/transfertEntreprises/${transfertEntreprise.id}`, transfertEntreprise)
    .then(({ data }) =>{
      commit('replaceTransfertEntreprise', data)
    })
  },
  deleteTransfertEntreprise({ commit }, transfertEntreprise){
    return api.delete(`/transfertEntreprises/${transfertEntreprise.id}`)
    .then(() =>{
      commit('removeTransfertEntreprise', transfertEntreprise)
    })
  },
  createOrUpdateTransfertEntreprise({ dispatch }, transfertEntreprise){
    if(transfertEntreprise.id !== undefined){
      return dispatch('updateTransfertEntreprise', transfertEntreprise)
    }
    else {
      return dispatch('createTransfertEntreprise', transfertEntreprise)
    }
  },
  selectTransfertEntreprise({ commit }, transfertEntreprise){
    commit('setTransfertEntreprise', transfertEntreprise)
  }
}