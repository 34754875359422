import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addEvaluationEntrepreneuriales(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.evaluationEntrepreneuriales = [ ...content ]
  },
  addEvaluationEntrepreneuriale(state, evaluationEntrepreneuriale){
    state.evaluationEntrepreneuriales = [ evaluationEntrepreneuriale, ...state.evaluationEntrepreneuriales]
  },
  replaceEvaluationEntrepreneuriale(state, evaluationEntrepreneuriale){
    state.evaluationEntrepreneuriales = state.evaluationEntrepreneuriales.map(struc => struc.id === evaluationEntrepreneuriale.id ? evaluationEntrepreneuriale : struc )
  },
  removeEvaluationEntrepreneuriale(state, evaluationEntrepreneuriale){
    state.evaluationEntrepreneuriales = state.evaluationEntrepreneuriales.filter(struc => struc.id !== evaluationEntrepreneuriale.id)
  },
  setEvaluationEntrepreneuriale(state, evaluationEntrepreneuriale){
    state.evaluationEntrepreneuriale = evaluationEntrepreneuriale
  },
}