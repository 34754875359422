import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchAuditeurs({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/auditeurs?${queryParams}`)
    .then(({ data }) =>{
      commit('addAuditeurs', data)
      return data
    })
  },
  fetchAuditeur({ commit, state }, params = {}){
    let { auditeurId } = params
    return get(`/auditeurs/${auditeurId}`)
    .then(({ data }) =>{
      commit('setAuditeur', data)
      return data
    })
  },
  synchronizer({ }, params = {}){
    return post(`/auditeurs/office/synchronizer`, {})
    .then(({ data }) =>{
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { auditeurId } = params
    return downloadFileWithAxiosPost(`/auditeurs/exportToPdf`, 'file.pdf', {
      title: 'LISTE DE PRESENCE AU POSTE',
      subtitle: 'Pour le mois de NOV. 2022',
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
      metas: {
        'ENTREPRISE' : 'HUo',
        'N° CONTRAT' : '',
        'DATE PLACEMENT' : '',
        'N° PLACEMENT' : '',
        'PERIODE' : '',
        'MOIS' : '',
      },
    })
    .then(({ data }) =>{
      commit('setAuditeur', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { auditeurId } = params
    return downloadFileWithAxiosPost(`/auditeurs/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setAuditeur', data)
      return data
    })
  },
  createAuditeur({ commit }, auditeur){
    return post('/auditeurs', auditeur)
    .then(({ data }) =>{
      commit('addAuditeur', data)
      return data
    })
  },
  updateAuditeur({ commit }, auditeur){
    return put(`/auditeurs/${auditeur.id}`, auditeur)
    .then(({ data }) =>{
      commit('replaceAuditeur', data)
      commit('setAuditeur', data)

      return data
    })
  },
  createOrUpdateAuditeur({ dispatch }, auditeur){
    if(auditeur.id !== undefined){
      return dispatch('updateAuditeur', auditeur)
    }
    else {
      return dispatch('createAuditeur', auditeur)
    }
  },
  selectAuditeur({ commit }, auditeur){
    commit('setAuditeur', auditeur)
  }
}