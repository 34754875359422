import { post, put, get, remove } from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'
import { uploadFile } from "../../api/file-service";
import { API_URL } from "../../config/api-config";


export const actions = {
  fetchPriseEnCharges({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size, extraUrlParam='' } = params
    let queryParams = buildQueryParams(params, state.pagination)
    return new Promise((resolv, reject) => {
      return get(`/priseEnCharges?${queryParams}size=${pageSize}&page=${pageNumber}&${extraUrlParam}`)
      .then(({ data }) => {
        commit('addPriseEnCharges', data)
        resolv(data.content)
      }).catch(err => reject(err))
    })
  },
  fetchPriseEnCharge({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size, extraUrlParam='' } = params
    let queryParams = buildQueryParams(params, state.pagination)
    return new Promise((resolv, reject) => {
      return get(`/priseEnCharges?${queryParams}size=${pageSize}&page=${pageNumber}&${extraUrlParam}`)
      .then(({ data }) => {
        commit('addPriseEnCharges', data)
        resolv(data.content)
      }).catch(err => reject(err))
    })
  },
  createPriseEnCharge({ commit }, priseEnCharge){
    return post('/priseEnCharges', priseEnCharge)
    .then(({ data }) => {
      commit('addPriseEnCharge', data)
    })
  },
  fetchActiviteRapportActivite({ commit }, { activiteId }){
    return new Promise((resolv, reject) => {
      return get(`/priseEnCharges/${activiteId}/rapportActivite`)
      .then(({ data }) => {
        commit('addPriseEnCharge', data)
        resolv(data)
      }).catch(err => reject(err))
  })
  },
  updatePriseEnChargeStatus({ commit }, priseEnCharge){
    return put(`/priseEnCharges/${priseEnCharge.id}/setStatus`, priseEnCharge)
    .then(({ data }) =>{
      commit('replacePriseEnCharge', data)
    })
  },
  updatePriseEnChargeCheminEmploi({ commit }, priseEnCharge){
    return put(`/priseEnCharges/${priseEnCharge.id}/updateCheminEmploi`, priseEnCharge)
    .then(({ data }) =>{
      commit('replacePriseEnCharge', data)
      return data
    })
  },
  updatePriseEnCharge({ commit }, priseEnCharge){
    return put(`/priseEnCharges/${priseEnCharge.id}`, priseEnCharge)
    .then(({ data }) =>{
      commit('replacePriseEnCharge', data)
    })
  },
  deletePriseEnCharge({ commit }, priseEnCharge){
    return remove(`/priseEnCharges/${priseEnCharge.id}`, priseEnCharge)
    .then(() =>{
      commit('removePriseEnCharge', priseEnCharge)
    })
  },
  createOrUpdatePriseEnCharge({ dispatch }, priseEnCharge){
    if(priseEnCharge.id !== undefined){
      return dispatch('updatePriseEnCharge', priseEnCharge)
    }
    else {
      return dispatch('createPriseEnCharge', priseEnCharge)
    }
  
  },
  appliquerAutoPriseEnCharge({}, params = {}) {
    const { file } = params;
    const data = {  };
    return new Promise((resolv, reject) => {
        const onSuccess = (d) => {
            return resolv(d);
        };
        const onError = (err) => {
            console.log(err)
            return reject(err);
        };

        const onProgress = (val) => {
            console.log(val)
        };

        uploadFile(
            `${API_URL}/priseEnCharges/statutValidationNpis`,
            // `${API_URL}/priseEnCharges/appliquerAutoPec`,
            file,
            data,
            "POST",
            "file",
            onSuccess,
            onError,
            onProgress
        );
    });
},
  selectPriseEnCharge({ commit }, priseEnCharge){
    commit('setPriseEnCharge', priseEnCharge)
  }
}