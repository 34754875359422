export const getters = {
  typeParentes(state){
    return state.typeParentes
  },
  communes(state){
    return state.communes
  },
  typeParente(state){
    return state.typeParente
  }
}