export const mutations = {
  addAzoliPieces(state, azoliPieces){
    state.azoliPieces = [ ...azoliPieces ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addAzoliPiece(state, azoliPiece){
    state.azoliPieces = [ ...state.azoliPieces, azoliPiece ]
  },
  replaceAzoliPiece(state, azoliPiece){
    state.azoliPieces = state.azoliPieces.map(struc => struc.id === azoliPiece.id ? azoliPiece : struc )
  },
  setAzoliPiece(state, azoliPiece){
    state.azoliPiece = azoliPiece
  },
}