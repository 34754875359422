import Vue from 'vue'
import Vue2Filters from 'vue2-filters'

Vue.use(Vue2Filters)

Vue.filter('age', (value) => {
  return Vue.moment().diff(value, 'years')
})

Vue.filter('formatDate', (value) => {
  return Vue.moment(value).format('DD-MM-YYYY')
})