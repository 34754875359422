export const getters = {
  agents(state){
    return state.agents
  },
  agentWithStatistics(state){
    return state.agentWithStatistics
  },
  agent(state){
    return state.agent
  },
  pagination(state){
    return state.pagination
  }
}