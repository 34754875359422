import { post, put, get, downloadFileWithAxiosPost} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchOrdreMissions({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/ordreMissions?${queryParams}`)
    .then(({ data }) =>{
      commit('addOrdreMissions', data)
      return data
    })
  },
  fetchOrdreMission({ commit, state }, params = {}){
    let { ordreMissionId } = params
    return get(`/ordreMissions/${ordreMissionId}`)
    .then(({ data }) =>{
      commit('setOrdreMission', data)
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { ordreMissionId } = params
    return downloadFileWithAxiosPost(`/ordreMissions/exportToPdf`, 'file.pdf', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setOrdreMission', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { ordreMissionId } = params
    return downloadFileWithAxiosPost(`/ordreMissions/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setOrdreMission', data)
      return data
    })
  },
  createOrdreMission({ commit }, ordreMission){
    return post('/ordreMissions', ordreMission)
    .then(({ data }) =>{
      commit('addOrdreMission', data)
      return data
    })
  },
  updateOrdreMission({ commit }, ordreMission){
    return put(`/ordreMissions/${ordreMission.id}`, ordreMission)
    .then(({ data }) =>{
      commit('replaceOrdreMission', data)
      commit('setOrdreMission', data)

      return data
    })
  },
  createOrUpdateOrdreMission({ dispatch }, ordreMission){
    if(ordreMission.id !== undefined){
      return dispatch('updateOrdreMission', ordreMission)
    }
    else {
      return dispatch('createOrdreMission', ordreMission)
    }
  },
  selectOrdreMission({ commit }, ordreMission){
    commit('setOrdreMission', ordreMission)
  }
}