export const getters = {
  actualites(state){
    return state.actualites
  },
  actualite(state){
    return state.actualite
  },
  pagination(state){
    return state.pagination
  }
}