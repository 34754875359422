import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addApiClients(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)
    console.log(state.pagination)
    state.apiClients = [ ...content ]
  },
  addApiClient(state, apiClient){
    state.apiClients = [ ...state.apiClients, apiClient ]
  },
  replaceApiClient(state, apiClient){
    state.apiClients = state.apiClients.map(struc => struc.id === apiClient.id ? apiClient : struc )
  },
  setApiClient(state, apiClient){
    state.apiClient = apiClient
  },
}