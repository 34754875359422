// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'

export const actions = {
  fetchTypeContrats({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/typeContrats?size=${pageSize}&page=${pageNumber}&sort=libelle,asc`)
    .then(({ data }) =>{
      commit('addTypeContrats', data)
    })
  },
  createTypeContrat({ commit }, typeContrat){
    return post('/typeContrats', typeContrat)
    .then(({ data }) => {
      commit('addTypeContrat', data)
    })
  },
  updateTypeContrat({ commit }, typeContrat){
    return put(`/typeContrats/${typeContrat.id}`, typeContrat)
    .then(({ data }) =>{
      commit('replaceTypeContrat', data)
    })
  },
  deleteTypeContrat({ commit }, typeContrat){
    return remove(`/typeContrats/${typeContrat.id}`, typeContrat)
    .then(() =>{
      commit('removeTypeContrat', typeContrat)
    })
  },
  createOrUpdateTypeContrat({ dispatch }, typeContrat){
    if(typeContrat.id !== undefined){
      return dispatch('updateTypeContrat', typeContrat)
    }
    else {
      return dispatch('createTypeContrat', typeContrat)
    }
  },
  selectTypeContrat({ commit }, typeContrat){
    commit('setTypeContrat', typeContrat)
  }
}