export const getters = {
  typeIndicateurs(state){
    return state.typeIndicateurs
  },
  typeIndicateur(state){
    return state.typeIndicateur
  },
  pagination(state){
    return state.pagination
  }
}