export const getters = {
  microprojets(state){
    return state.microprojets
  },
  microprojet(state){
    return state.microprojet
  },
  pagination(state){
    return state.pagination
  }
}