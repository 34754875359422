export const mutations = {
  addServiceRendus(state, serviceRendus){
    state.serviceRendus = [ ...serviceRendus ]
  },
  addCommunes(state, communes){
    state.communes = [ ...communes ]
  },
  addServiceRendu(state, serviceRendu){
    state.serviceRendus = [ ...state.serviceRendus, serviceRendu ]
  },
  replaceServiceRendu(state, serviceRendu){
    state.serviceRendus = state.serviceRendus.map(struc => struc.id === serviceRendu.id ? serviceRendu : struc )
  },
  setServiceRendu(state, serviceRendu){
    state.serviceRendu = serviceRendu
  },
}