// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'


export const actions = {
  fetchParticipationProgrammes({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/participationProgrammes?${queryParams}`)
    .then(({ data }) =>{
      commit('addParticipationProgrammes', data)
      return data.content
    })
  },
  createParticipationProgramme({ commit }, participationProgramme){
    return post('/participationProgrammes', participationProgramme)
    .then(({ data }) => {
      commit('addParticipationProgramme', data)
      return data
    })
  },
  updateParticipationProgramme({ commit }, participationProgramme){
    return put(`/participationProgrammes/${participationProgramme.id}`, participationProgramme)
    .then(({ data }) =>{
      commit('replaceParticipationProgramme', data)
      return data
    })
  },
  deleteParticipationProgramme({ commit }, participationProgramme){
    return remove(`/participationProgrammes/${participationProgramme.id}`, participationProgramme)
    .then(() =>{
      commit('removeParticipationProgramme', participationProgramme)
    })
  },
  createOrUpdateParticipationProgramme({ dispatch }, participationProgramme){
    if(participationProgramme.id !== undefined){
      return dispatch('updateParticipationProgramme', participationProgramme)
    }
    else {
      return dispatch('createParticipationProgramme', participationProgramme)
    }
  },
  selectParticipationProgramme({ commit }, participationProgramme){
    commit('setParticipationProgramme', participationProgramme)
  }
}