import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addPerspectiveOptions(state, data){
    state.pagination = extractPaginationInfo(data)
    state.perspectiveOptions = [...data.content] 
  },
  addPerspectiveOption(state, perspectiveOption){
    state.perspectiveOptions = [ ...state.perspectiveOptions, perspectiveOption ]
  },
  replacePerspectiveOption(state, perspectiveOption){
    state.perspectiveOptions = state.perspectiveOptions.map(struc => struc.id === perspectiveOption.id ? perspectiveOption : struc )
  },
  removePerspectiveOption(state, perspectiveOption){
    state.perspectiveOptions = state.perspectiveOptions.filter(cand => cand.id !== perspectiveOption.id)
  },
  setPerspectiveOption(state, perspectiveOption){
    state.perspectiveOption = perspectiveOption
  },
  setPerspectiveOptionEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}