export const getters = {
  cycleProductions(state){
    return state.cycleProductions
  },
  communes(state){
    return state.communes
  },
  cycleProduction(state){
    return state.cycleProduction
  }
}