// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'


export const actions = {
  fetchTageActivites({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/tageActivites?size=${pageSize}&page=${pageNumber}&sort=libelle&omit=tageActivite`)
    .then(({ data }) =>{
      commit('addTageActivites', data)
    })
  },
  createTageActivite({ commit }, tageActivite){
    return post('/tageActivites', tageActivite)
    .then(({ data }) => {
      commit('addTageActivite', data)
    })
  },
  updateTageActivite({ commit }, tageActivite){
    return put(`/tageActivites/${tageActivite.id}`, tageActivite)
    .then(({ data }) =>{
      commit('replaceTageActivite', data)
    })
  },
  deleteTageActivite({ commit }, tageActivite){
    return remove(`/tageActivites/${tageActivite.id}`, tageActivite)
    .then(() =>{
      commit('removeTageActivite', tageActivite)
    })
  },
  createOrUpdateTageActivite({ dispatch }, tageActivite){
    if(tageActivite.id !== undefined){
      return dispatch('updateTageActivite', tageActivite)
    }
    else {
      return dispatch('createTageActivite', tageActivite)
    }
  },
  selectTageActivite({ commit }, tageActivite){
    commit('setTageActivite', tageActivite)
  }
}