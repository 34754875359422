export const getters = {
  statutProfessionnels(state){
    return state.statutProfessionnels
  },
  statutProfessionnel(state){
    return state.statutProfessionnel
  },
  pagination(state){
    return state.pagination
  }
}