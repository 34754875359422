<template>
  <div>
    <div :class="sizeClasses" class="form-floating">
      <!-- <div ref="prependDiv" v-if="$slots.prepend || prepend" class="input-group-prepend">
        <slot name="prepend">
          <span class="input-group-text">{{ prepend }}</span>
        </slot>
      </div> -->
      <input
        ref="input"
        id="metier"
        type="text"
        :class="`form-control ${inputClass}`"
        :aria-label="placeholder"
        name="metier"
        :value="inputValue"
        @focus="handleFocus"
        @blur="handleBlur"
        v-force-cursor-to-end
        @input="handleInput($event.target.value)"
        autocomplete="off"
      />
      <slot name="label">
        <label for="metier">Métier que tu as appris</label>
      </slot>

<!-- <div class="input-group-append">
  <button class="btn btn-outline-secondary" type="button" id="button-addon1">Button</button>
</div> -->
      <!-- <div v-if="$slots.append || append" class="input-group-append">
        <slot name="append">
          <span class="input-group-text">{{ append }}</span>
        </slot>
      </div> -->
    </div>
    <vue-bootstrap-typeahead-list
      class="vbt-autcomplete-list"
      ref="list"
      v-show="isFocused && data.length > 0"
      :query="inputValue"
      :data="formattedData"
      :background-variant="backgroundVariant"
      :text-variant="textVariant"
      :activeItem="selectedItem"
      :maxMatches="maxMatches"
      :minMatchingChars="minMatchingChars"
      @hit="handleHit"
    >
      <!-- pass down all scoped slots -->
      <template v-for="(slot, slotName) in $scopedSlots" :slot="slotName" slot-scope="{ data, htmlText }">
        <slot :name="slotName" v-bind="{ data, htmlText, activeIem }"></slot>
      </template>
      <!-- below is the right solution, however if the user does not provide a scoped slot, vue will still set $scopedSlots.suggestion to a blank scope
      <template v-if="$scopedSlots.suggestion" slot="suggestion" slot-scope="{ data, htmlText }">
        <slot name="suggestion" v-bind="{ data, htmlText }" />
      </template>-->
    </vue-bootstrap-typeahead-list>
  </div>
</template>

<script>
import VueBootstrapTypeaheadList from './VueBootstrapTypeaheadList.vue'
import ResizeObserver from 'resize-observer-polyfill'

export default {
  name: 'VueBootstrapTypehead',

  components: {
    VueBootstrapTypeaheadList
  },

  props: {
    size: {
      type: String,
      default: null,
      validator: size => ['lg', 'sm'].indexOf(size) > -1
    },
    value: {
      type: String
    },
    data: {
      type: Array,
      required: true,
      validator: d => d instanceof Array
    },
    serializer: {
      type: Function,
      default: (d) => d,
      validator: d => d instanceof Function
    },
    backgroundVariant: String,
    textVariant: String,
    inputClass: {
      type: String,
      default: ''
    },
    optionLabel: {
      type: String,
      default: 'libelle'
    },
    optionValue: {
      type: String,
      default: ''
    },
    maxMatches: {
      type: Number,
      default: 10
    },
    minMatchingChars: {
      type: Number,
      default: 2
    },
    placeholder: String,
    prepend: String,
    append: String
  },

  computed: {
    sizeClasses() {
      return ''
      // return this.size ? `input-group input-group-${this.size}` : 'input-group'
    },
    matchedItems(){
      return this.$refs.list.matchedItems
    },
    formattedData() {
      if (!(this.data instanceof Array)) {
        return []
      }
      return this.data.map((d, i) => {
        return {
          id: i,
          data: d,
          text: this.serializer(d)
        }
      })
    }
  },

  methods: {
    resizeList(el) {
      const rect = el.getBoundingClientRect()
      const listStyle = this.$refs.list.$el.style

      // Set the width of the list on resize
      listStyle.width = rect.width + 'px'

      // Set the margin when the prepend prop or slot is populated
      // (setting the "left" CSS property doesn't work)
      if (this.$refs.prependDiv) {
        const prependRect = this.$refs.prependDiv.getBoundingClientRect()
        listStyle.marginLeft = prependRect.width + 'px'
      }
    },

    handleHit(evt) {
      if (this.value !== 'undefined') {
        this.$emit('input', evt.text)
      }

      this.inputValue = evt.text
      this.$emit('hit', evt.data)
      this.selectedItem = evt.data
      this.$refs.input.blur()
      this.isFocused = false
    },
    handleFocus(/* evt */){
      this.isFocused = true
      // let old = this.inputValue
      // this.inputValue = ''
      // this.inputValue = old
    },
    handleBlur(evt) {
      const tgt = evt.relatedTarget
      if (tgt && tgt.classList.contains('vbst-item')) {
        return
      }
      this.isFocused = false
      // if(this.matchedItems.length == 0){
      // }
      this.$emit('input', this.value)
      if(this.selectedItem instanceof Object){
        this.inputValue = this.selectedItem[this.optionLabel]
      }
    },

    handleInput(newValue) {
      this.inputValue = newValue
      console.log(newValue)
      // If v-model is being used, emit an input event
      if (typeof this.value !== 'undefined') {
        this.$emit('input', newValue)
      }
    }
  },

  data() {
    return {
      isFocused: false,
      inputValue: '',
      selectedItem: undefined
    }
  },

  mounted() {
    this.$_ro = new ResizeObserver(() => {
      this.resizeList(this.$refs.input)
    })
    this.$_ro.observe(this.$refs.input)
    this.$_ro.observe(this.$refs.list.$el)
  },

  beforeDestroy() {
    this.$_ro.disconnect()
  }
}
</script>

<style scoped>
  .vbt-autcomplete-list {
    padding-top: 5px;
    position: absolute;
    max-height: 350px;
    overflow-y: auto;
    z-index: 999;
  }
</style>
