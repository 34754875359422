// import axios from 'axios'
import { post, put, get} from '@/api/api'


export const actions = {
  fetchNotifications({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/notifications?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addNotifications', data)
    })
  },
  fetchAgentNotifications({ commit, state }, params = {}){
    let { agentId,pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/agents/${agentId}/notifications?size=${pageSize}&page=${pageNumber}&sorted=true`)
    .then(({ data }) =>{
      commit('addNotifications', data)
    })
  },
  createNotification({ commit }, notification){
    return post('/notifications', notification)
    .then(({ data }) =>{
      commit('addNotification', data)
    })
  },
  updateNotification({ commit }, notification){
    return put(`/notifications/${notification.id}`, notification)
    .then(({ data }) =>{
      commit('replaceNotification', data)
    })
  },
  createOrUpdateNotification({ dispatch }, notification){
    if(notification.id !== undefined){
      return dispatch('updateNotification', notification)
    }
    else {
      return dispatch('createNotification', notification)
    }
  },
  selectNotification({ commit }, notification){
    commit('setNotification', notification)
  }
}