import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addBesoinFormations(state, data){
    const { content } = data
    state.pagination = extractPaginationInfo(data)

    state.besoinFormations = [ ...content ]
  },
  addBesoinFormation(state, besoinFormation){
    state.besoinFormations = [ besoinFormation, ...state.besoinFormations]
  },
  replaceBesoinFormation(state, besoinFormation){
    state.besoinFormations = state.besoinFormations.map(struc => struc.id === besoinFormation.id ? besoinFormation : struc )
  },
  removeBesoinFormation(state, besoinFormation){
    state.besoinFormations = state.besoinFormations.filter(struc => struc.id !== besoinFormation.id)
  },
  setBesoinFormation(state, besoinFormation){
    state.besoinFormation = besoinFormation
  },
}