export const getters = {
  actionrequises(state){
    
    return state.actionRequises
  },

  actionrequise(state){
    
    return state.actionRequises
  },
  pagination(state){
    return state.pagination
  }
}