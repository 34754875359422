import { extractPaginationInfo } from '@/utils/pagination-util'
export const mutations = {
  addAppreciationStagiaires(state, data){
    state.pagination = extractPaginationInfo(data)
    state.appreciationStagiaires = [...data.content] 
  },
  addAppreciationStagiaire(state, appreciationStagiaire){
    state.appreciationStagiaires = [ ...state.appreciationStagiaires, appreciationStagiaire ]
  },
  replaceAppreciationStagiaire(state, appreciationStagiaire){
    state.appreciationStagiaires = state.appreciationStagiaires.map(struc => struc.id === appreciationStagiaire.id ? appreciationStagiaire : struc )
  },
  removeAppreciationStagiaire(state, appreciationStagiaire){
    state.appreciationStagiaires = state.appreciationStagiaires.filter(cand => cand.id !== appreciationStagiaire.id)
  },
  setAppreciationStagiaire(state, appreciationStagiaire){
    state.appreciationStagiaire = appreciationStagiaire
  },
  setAppreciationStagiaireEvaluation(state, evaluation){
    state.evaluation = evaluation
  },
}